import { IS_TENANT, TENANT_PREFIX_PATH } from "configs/AppConfig";
import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  CURRENCY_LIST,
  CURRENCY_ADD,
  CURRENCY_COUNT,
  CURRENCY_UPDATE,
  CURRENCY_DELETE,
  CURRENCY_MAPPER,
  CURRENCY_BY_ID,
  CURRENCY_SYNC,
} = API_PATH;

export const CurrencyService_ = {
  getCurrencies: (filter) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : "") + CURRENCY_LIST,
      method: "GET",
      params: filter,
    });
  },
  syncCurrencies: (filter) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : "") + CURRENCY_SYNC(),
      method: "POST",
      params: filter,
    });
  },
  getCurrencyId: (currencyId) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : "") + CURRENCY_BY_ID(currencyId),
      method: "GET",
    });
  },

  countCurrency: (filter) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : "") + CURRENCY_COUNT,
      method: "GET",
      params: filter,
    });
  },

  addCurrency: (currency) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : "") + CURRENCY_ADD,
      method: "POST",
      data: currency,
    });
  },
  updateCurrency: (currencyId, currency) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : "") + CURRENCY_UPDATE(currencyId),
      method: "PUT",
      data: currency,
    });
  },
  currencyMapper: () => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : "") + CURRENCY_MAPPER(),
      method: "GET",
    });
  },
  deleteCurrency: (currencyId) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : "") + CURRENCY_DELETE(currencyId),
      method: "DELETE",
    });
  },
};

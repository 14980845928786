import React, { useState, useEffect } from "react";
import { Form, Button, message, Space, Modal, Layout } from "antd";

import { ADD, EDIT } from "../../../../../constants/common.constant";
import Utils from "utils/common";
import ProductDetail from "./product-detail";
import { QuestionCircleFilled } from "@ant-design/icons";
import { ProductService_ } from "../../../../../services/Tenant/product.service";
import { Content, Footer } from "antd/lib/layout/layout";

const ProductForm = (props) => {
  const {
    productDetail,
    isDrawerView,
    onDrawerClose,
    setIsSubmitted,
    setIsNext,
  } = props;

  const [form] = Form.useForm();
  const [uploadedImg, setImage] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const productId = productDetail?.id;
  const mode = productId ? EDIT : ADD;
  useEffect(() => {
    if (productId && form) {
      form.setFieldsValue({
        ...productDetail,
        brand: {
          label: productDetail?.brand?.name,
          value: productDetail?.brand?.id,
        },
        uom: {
          label: productDetail?.uom?.name,
          value: productDetail?.uom?.id,
        },
        category: {
          label: productDetail?.category?.category,
          value: productDetail?.category?.id,
        },
        description: productDetail?.description,
        noteStatus: productDetail?.noteStatus,
        moqQuantity: productDetail?.moqQuantity,
        countryOfOrigin: {
          key: productDetail?.countryOfOrigin[0],
          label: productDetail?.countryOfOrigin[0],
          value: productDetail?.countryOfOrigin[0],
        },
      });
    }
  }, [productId, form]);

  const handleUploadChange = (info) => {
    Utils.getBase64(info.file.originFileObj, (imageUrl) => {
      setImage(imageUrl);
      setUploadLoading(true);
    });
  };

  const onFinish = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        const { ...values_ } = values;
        values.trimPartNumber = values.partNumber.trim();
        values_.trimPartNumber = values.trimPartNumber.toLowerCase();
        values_.brand = values?.brand?.value;
        values_.uom = values?.uom?.value;
        values_.category = values?.category?.value;
        values_.replacementProduct = values?.replacementProduct?.value;
        values_.moqQuantity = values?.moq ? values?.moqQuantity : 0;
        values_.countryOfOrigin = [values_.countryOfOrigin.value];
        if (Object.keys(values_.image).length < 1)
          return message.error("Please enter all required field ");
        delete values_.moq;
        // delete values_.hsCode;
        delete values_.replacementProduct;

        if (mode === "EDIT") {
          ProductService_.updateProduct(productId, values_)
            .then((data) => {
              setIsSubmitted(true);
              message.success(`Product updated successfully`);
              onDrawerClose();
            })
            .finally((_) => setSubmitLoading(false));
        } else {
          ProductService_.addProduct(values_)
            .then((data) => {
              if (props.setNewproduct_) {
                props.setNewproduct_(data.id);
              }
              setIsSubmitted(true);
              form.resetFields();
              message.success(`Product added successfully`);
              onDrawerClose();
            })
            .finally((_) => setSubmitLoading(false));
        }
      })
      .catch((info) => {
        setSubmitLoading(false);
        message.error("Please enter all required field ");
      });
  };

  const onFinishNext = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        const { ...values_ } = values;
        const trimPartNumber = values.partNumber.trim();
        values_.trimPartNumber = trimPartNumber.toLowerCase();
        values_.partNumber = values.partNumber;
        values_.brand = values?.brand?.value;
        values_.uom = values?.uom?.value;
        values_.category = values?.category?.value;
        values_.moqQuantity = values?.moq ? values?.moqQuantity : "";
        values_.countryOfOrigin = [values_.countryOfOrigin.value];
        delete values_.moq;

        values_.replacementProduct = values?.replacementProduct?.value;

        ProductService_.addProduct(values_)
          .then((data) => {
            setIsSubmitted(true);
            form.resetFields();
            message.success(`Product added successfully`);
            setIsNext(true);
          })
          .finally((_) => setSubmitLoading(false));
      })
      .catch((info) => {
        setSubmitLoading(false);
        message.error("Please enter all required field ");
      });
  };

  return (
    <>
      <Form
        layout={isDrawerView ? "horizontal" : "vertical"}
        form={form}
        name="product-form"
        autoComplete="off"
      >
        <Layout>
          <Content>
            <ProductDetail
              {...props}
              setSelectedImageFile={setSelectedImageFile}
              uploadedImg={uploadedImg}
              uploadLoading={uploadLoading}
              handleUploadChange={handleUploadChange}
              form={form}
              pageType={props.pageType}
              mode={mode}
              productDetail={productDetail}
            />
          </Content>

          {isDrawerView ? (
            <Footer>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    form.validateFields().then(() => {
                      Modal.confirm({
                        title: "Confirm",
                        icon: <QuestionCircleFilled />,
                        content: "Are you sure to proceed.",
                        okText: "Yes",
                        cancelText: "No",
                        onOk: () => {
                          onFinish(true);
                        },
                      });
                    });
                  }}
                  htmlType="submit"
                  loading={submitLoading}
                  disabled={submitLoading}
                  size="small"
                >
                  {mode === ADD ? "Save" : `Save`}
                </Button>
                {mode === ADD ? (
                  props.pageType === "PRODUCT" ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        form.validateFields().then(() => {
                          Modal.confirm({
                            title: "Confirm",
                            icon: <QuestionCircleFilled />,
                            content: "Are you sure to proceed.",
                            okText: "Yes",
                            cancelText: "No",
                            onOk: () => {
                              onFinishNext(true);
                            },
                          });
                        });
                      }}
                      htmlType="submit"
                      loading={submitLoading}
                      disabled={submitLoading}
                      size="small"
                      isProcessed="true"
                    >
                      {mode === ADD ? "Save & Next" : `Save`}
                    </Button>
                  ) : (
                    <> </>
                  )
                ) : (
                  <> </>
                )}
                <Button
                  onClick={() => {
                    onDrawerClose();
                  }}
                  size="small"
                >
                  Cancel
                </Button>
              </Space>
            </Footer>
          ) : (
            <></>
          )}
        </Layout>
      </Form>
    </>
  );
};

export default ProductForm;

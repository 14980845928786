import { Form, Modal, PageHeader, Space } from "antd";
import EnquiryRefSelect from "components/shared-components/EnquiryRefSelect";
import Loading from "components/shared-components/Loading";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { openTabInEnquiryMenu } from "redux/actions/Enquiry";
import { QuotationsPageService } from "services/Tenant/quotations.service";

const EnquiryMenuDrawer = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const enquirytab = useSelector((state) => state.enquiry);
  console.log(enquirytab, "enquirytabenquirytab");
  function handleChange(value) {
    setLoading(true);
    QuotationsPageService.getQuotationEnquiryDetails(value.value)
      .then((data) => {
        history.push(
          `/app/enquiry-view/${value.value}/${enquirytab?.tabKeyName}`
        );
        dispatch(openTabInEnquiryMenu(false));
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <div>
      {" "}
      <Modal
        bodyStyle={{ paddingTop: 0 }}
        title="Select Enquiry"
        visible={enquirytab?.openEnquiryTabInEnquiryMenu}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        // onOk={handleOk}
        onCancel={() => dispatch(openTabInEnquiryMenu(false))}
        footer={null}
      >
        {loading && <Loading />}
        <Form
          // onFieldsChange={handleFormChange}
          layout={"horizontal"}
          // form={form}
          name="rfq-form"
          autoComplete="off"
        ></Form>
        <PageHeader
          className="custom-header"
          ghost={false}
          title=""
          subTitle=""
          extra={[
            <Space>
              <span onClick={""} type="default" size="small"></span>
            </Space>,
          ]}
        />
        <Form.Item name="enqRef" label="Enquiry Ref">
          <div style={{ marginLeft: 24 }}>
            <EnquiryRefSelect
              onChange={handleChange}
              clearOnSelect
              disabled={loading}
            />
          </div>
        </Form.Item>
      </Modal>
    </div>
  );
};

export default EnquiryMenuDrawer;

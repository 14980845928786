import { IS_TENANT, TENANT_PREFIX_PATH } from "configs/AppConfig";
import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  COUNTRY_LIST,
  COUNTRY_ADD,
  COUNTRY_COUNT,
  COUNTRY_UPDATE,
  COUNTRY_DELETE,
  COUNTRY_BY_ID,
} = API_PATH;

export const CountryService_ = {
  getCountries: (filter) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + COUNTRY_LIST,
      method: "GET",
      params: filter,
    });
  },
  getCountryId: (countryId) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + COUNTRY_BY_ID(countryId),
      method: "GET",
    });
  },

  countCountry: (filter) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + COUNTRY_COUNT,
      method: "GET",
      params: filter,
    });
  },

  addCountry: (country) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + COUNTRY_ADD,
      method: "POST",
      data: country,
    });
  },
  updateCountry: (countryId, country) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + COUNTRY_UPDATE(countryId),
      method: "PUT",
      data: country,
    });
  },
  deleteCountry: (countryId) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + COUNTRY_DELETE(countryId),
      method: "DELETE",
    });
  },
};

import { IS_TENANT, TENANT_PREFIX_PATH } from "configs/AppConfig";
import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  ADDRESS_TYPE_LIST,
  ADD_ADDRESS_TYPE,
  ADDRESS_TYPE_COUNT,
  ADDRESS_TYPE_UPDATE,
  ADDRESS_TYPE_DELETE,
  ADDRESS_TYPE_BY_ID,
} = API_PATH;

export const AddressTypeService_ = {
  getAddressType: (filter) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + ADDRESS_TYPE_LIST,
      method: "GET",
      params: filter,
    });
  },
  getAddressTypeId: (addressTypeId) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + ADDRESS_TYPE_BY_ID(addressTypeId),
      method: "GET",
    });
  },

  countAddressTypes: (filter) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + ADDRESS_TYPE_COUNT,
      method: "GET",
      params: filter,
    });
  },

  addAddressType: (addresstype) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + ADD_ADDRESS_TYPE,
      method: "POST",
      data: addresstype,
    });
  },
  updateAddressType: (addressTypeId, addresstype) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + ADDRESS_TYPE_UPDATE(addressTypeId),
      method: "PUT",
      data: addresstype,
    });
  },
  deleteAddressType: (addressTypeId) => {
    return fetch_({
      url: (IS_TENANT ? TENANT_PREFIX_PATH : '') + ADDRESS_TYPE_DELETE(addressTypeId),
      method: "DELETE",
    });
  },
};

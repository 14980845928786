import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  PROCESS_PO_PRODCUTS_LIST,
  ENQUIRY_RFQ_RESOURCE_FINALISED_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_SUBMIT_FINALISED_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_ENQUIRY_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_CERTIFICATES,
  ENQUIRY_RFQ_RESOURCE_TOTAL_FINALISED_CERTIFICATES,
  CREATE_PURCHASE_ORDER,
  FINALIZE_PROCESS_PO_PRODCUTS_LIST,
  PURCHASE_ORDER_LIST,
  PURCHASE_ORDER_LIST_COUNT,
  PURCHASE_ORDER_DETAIL,
  PURCHASE_ORDER_PRODUCTS_LIST,
  PURCHASE_ORDER_CERTIFICATES_LIST,
  CREATE_PO_RECEIVING,
  RECEIVE_PO_PRODUCTS,
  RECEIVE_PO_RECEIVING_TABS,
  RECEIVE_PO_RECEIVING_TABS_DETAILS,
  RECEIVE_PO_PRODUCTS_PO_QTY,
  RECEIVE_PO_PRODUCTS_PO_RECEIVE_QTY,
} = API_PATH;

export const ReceivePoService = {
  getPurcahseOrderReceiveTabs: (purchaseOrderId, filter) => {
    return fetch_({
      url: RECEIVE_PO_RECEIVING_TABS(purchaseOrderId, filter),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderReceivingProductsPoQty: (purchaseOrderId, filter) => {
    return fetch_({
      url: RECEIVE_PO_PRODUCTS_PO_QTY(purchaseOrderId, filter),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderReceivingProductsReceiveQty: (purchaseOrderId, filter) => {
    return fetch_({
      url: RECEIVE_PO_PRODUCTS_PO_RECEIVE_QTY(purchaseOrderId, filter),
      method: "GET",
      // params: filter,
    });
  },
  getPurcahseOrderReceiveDetails: (purchaseOrderId, receiveBatchId, filter) => {
    return fetch_({
      url: RECEIVE_PO_RECEIVING_TABS_DETAILS(
        purchaseOrderId,
        receiveBatchId,
        filter
      ),
      method: "GET",
      // params: filter,
    });
  },
  getReceivePoProducts: (purchaseOrderId) => {
    return fetch_({
      url: RECEIVE_PO_PRODUCTS(purchaseOrderId),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderById: (purchaseOrderId) => {
    return fetch_({
      url: PURCHASE_ORDER_DETAIL(purchaseOrderId),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderProductsById: (purchaseOrderId) => {
    return fetch_({
      url: PURCHASE_ORDER_PRODUCTS_LIST(purchaseOrderId),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderCertificatesById: (purchaseOrderId) => {
    return fetch_({
      url: PURCHASE_ORDER_CERTIFICATES_LIST(purchaseOrderId),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderList: (filter) => {
    return fetch_({
      url: PURCHASE_ORDER_LIST(),
      method: "GET",
      params: filter,
    });
  },
  getPurchaseOrderListCount: (filter) => {
    return fetch_({
      url: PURCHASE_ORDER_LIST_COUNT(),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryProductsRfqs: (enquiryId, enquiryProductId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_ENQUIRY_PRODUCT_DETAIL(
        enquiryId,
        enquiryProductId
      ),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryFinalisedProductsDetails: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_FINALISED_PRODUCT_DETAIL(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryReviewFinalisedProductsDetails: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_PRODUCT_DETAIL(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryReviewFinalisedCertificates: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_CERTIFICATES(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryFinalisedCertificatesTotal: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_TOTAL_FINALISED_CERTIFICATES(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  createEnquiryFinalisedProducts: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_SUBMIT_FINALISED_PRODUCT_DETAIL(enquiryId),
      method: "POST",
      data: filter,
    });
  },
  createPoReceiving: (purchaseOrderId, filter) => {
    return fetch_({
      url: CREATE_PO_RECEIVING(purchaseOrderId),
      method: "POST",
      data: filter,
    });
  },
  createPurchaseOrder: (filter) => {
    return fetch_({
      url: CREATE_PURCHASE_ORDER(),
      method: "POST",
      data: filter,
    });
  },
};

import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { NAV_PATH } from "configs/NavigationConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${NAV_PATH.dashboard}`}
          exact
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${NAV_PATH.tenants}/registrations`}
          exact
          component={lazy(() => import(`./tenants`))}
        />
        <Route
          path={`${NAV_PATH.tenants}/accounts`}
          exact
          component={lazy(() => import(`./tenants`))}
        />
        <Route
          path={`${NAV_PATH.addressType}`}
          exact
          component={lazy(() => import(`./address-type`))}
        />
        <Route
          path={`${NAV_PATH.deliveryExpenses}`}
          exact
          component={lazy(() => import(`./delivery-expenses/index`))}
        />
        <Route
          path={`${NAV_PATH.quotationlist}`}
          exact
          component={lazy(() => import(`./quotation-list/index`))}
        />
        <Route
          path={`${NAV_PATH.businessType}`}
          exact
          component={lazy(() => import(`./business-type`))}
        />
        <Route
          path={`${NAV_PATH.taxType}`}
          exact
          component={lazy(() => import(`./tax-type`))}
        />
        <Route
          path={`${NAV_PATH.impexType}`}
          exact
          component={lazy(() => import(`./impex-type`))}
        />
        <Route
          path={`${NAV_PATH.organisation}`}
          exact
          component={lazy(() => import(`./organisation`))}
        />
        <Route
          path={`${NAV_PATH.organisation}/view/:organisationId`}
          exact
          component={lazy(() => import(`./organisation/edit-org`))}
        />
        <Route
          path={`${NAV_PATH.organisation}/edit/:organisationId`}
          exact
          component={lazy(() => import(`./organisation/edit-org`))}
        />
        <Route
          path={`${NAV_PATH.customerList}`}
          exact
          component={lazy(() => import(`./organisation/customer-list`))}
        />
        <Route
          path={`${NAV_PATH.organisation}/customer-view/:customerId`}
          exact
          component={lazy(() =>
            import(`./organisation/customer-list/customer-view`)
          )}
        />
        <Route
          path={`${NAV_PATH.vendorList}`}
          exact
          component={lazy(() => import(`./organisation/vendor-list`))}
        />
        <Route
          path={`${NAV_PATH.organisation}/vendor-view/:vendorId`}
          exact
          component={lazy(() =>
            import(`./organisation/vendor-list/vendor-view`)
          )}
        />
        <Route
          path={`${NAV_PATH.contactList}`}
          exact
          component={lazy(() => import(`./organisation/contact-list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/catalogue`}
          component={lazy(() => import(`./catalogue`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/all-lines`}
          component={lazy(() => import(`./lines/index`))}
        />

        <Route
          path={`${NAV_PATH.activeLines}`}
          component={lazy(() => import(`./lines/index`))}
        />
        <Route
          path={`${NAV_PATH.overdueLines}`}
          component={lazy(() => import(`./lines/index`))}
        />
        <Route
          path={`${NAV_PATH.queryLines}`}
          component={lazy(() => import(`./lines/index`))}
        />
        <Route
          path={`${NAV_PATH.users}`}
          exact
          component={lazy(() => import(`./users`))}
        />
        <Route
          path={`${NAV_PATH.bulkRfq}`}
          exact
          component={lazy(() => import(`./rfq/bulk-rfq`))}
        />
        <Route
          path={`${NAV_PATH.profile}`}
          exact
          component={lazy(() => import(`./users/profiles`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/enquiry-view/:enquiryId/:tabName`}
          exact
          component={lazy(() => import(`./enquiry/enquiry-view/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/finalise-products/:enquiryId`}
          // exact
          component={lazy(() => import(`./enquiry/finalize-products/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/finalise-products/`}
          // exact
          component={lazy(() => import(`./enquiry/finalize-products/index`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/review-finalise-products/:enquiryId`}
          // exact
          component={lazy(() =>
            import(`./enquiry/review-finalized-products/index`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/review-finalise-products/`}
          // exact
          component={lazy(() =>
            import(`./enquiry/review-finalized-products/index`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/quotation/:enquiryId/:tabName`}
          // exact
          component={lazy(() =>
            import(`./enquiry/quotation/quotation-tabs/tabs`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/quotation/`}
          // exact
          component={lazy(() =>
            import(`./enquiry/quotation/quotation-tabs/tabs`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/preview/quotation/:quotationId/:isDrawer`}
          // exact
          component={lazy(() =>
            import(`./enquiry/quotation/quotation-tabs/preview`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/rfq`}
          component={lazy(() => import(`./rfq`))}
        />
        <Route
          path={`${NAV_PATH.openrfq}`}
          component={lazy(() => import(`./rfq`))}
        />
        <Route
          path={`${NAV_PATH.overduerfq}`}
          component={lazy(() => import(`./rfq`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/detailed-rfq`}
          component={lazy(() => import(`./detailed-rfq`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/rfq-view/:rfqId`}
          exact
          component={lazy(() => import(`./rfq/rfq-view/index`))}
        />

        <Route
          path={`${NAV_PATH.customerContract}`}
          exact
          component={lazy(() =>
            import(`./organisation/customer-list/customer-contract`)
          )}
        />

        <Route
          path={`${APP_PREFIX_PATH}/contract-view/:contractId/:isDrawer`}
          exact
          component={lazy(() =>
            import(
              `./organisation/customer-list/customer-contract/contract-view/index`
            )
          )}
        />

        <Route
          path={`${APP_PREFIX_PATH}/customer-order/`}
          // exact
          component={lazy(() => import(`./customer-order/index`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/customer-order-view/:orderId/:isDrawer`}
          exact
          component={lazy(() =>
            import(`./customer-order/customer-order-view/index`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/vendor-order/`}
          exact
          component={lazy(() => import(`./purchase-order/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/vendor-order/:orderId/:activeTab`}
          // exact
          component={lazy(() =>
            import(`./purchase-order/vendor-order-view/index`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/vendor-order/:orderId/received/:sub`}
          // exact
          component={lazy(() =>
            import(`./purchase-order/vendor-order-view/index`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/vendor-order/:orderId/qa-qc/:subActiveTab`}
          // exact
          component={lazy(() =>
            import(`./purchase-order/vendor-order-view/index`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/po-product-list/`}
          // exact
          component={lazy(() => import(`./purchase-order/product-list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/inventory/`}
          // exact
          component={lazy(() => import(`./inventory/index`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/address/`}
          // exact
          component={lazy(() => import(`./address/index`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/bank/`}
          // exact
          component={lazy(() => import(`./bank/index`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/purchase-requirment/`}
          // exact
          component={lazy(() => import(`./purchase-requirment/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/order-rfq/`}
          // exact
          component={lazy(() => import(`./order-rfq/index`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/order-rfq-view/:orderId`}
          exact
          component={lazy(() => import(`./order-rfq/order-rfq-view/index`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/order-rfq-view/:orderId/finalise`}
          // exact
          component={lazy(() => import(`./order-rfq/finalize-products/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/consignments/`}
          // exact
          component={lazy(() => import(`./consignment-instruction/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/consignment/:orderId/:tabKey/:packageId`}
          exact
          component={lazy(() =>
            import(`./consignment-instruction/consignment-view/index`)
          )}
        />

        <Route
          path={`${APP_PREFIX_PATH}/package/`}
          // exact
          component={lazy(() => import(`./package/index`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/awaiting-qa-qc-product`}
          component={lazy(() => import(`./qa-qc/awaiting-qa-qc`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/awaiting-qa-qc-approval-product`}
          component={lazy(() => import(`./qa-qc/awaiting-qa-qc-approval`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/awaiting-qa-qc`}
          component={lazy(() => import(`./qa-qc/awaiting-qa-qc-list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/awaiting-qa-qc-approval`}
          component={lazy(() => import(`./qa-qc/awaiting-qa-qc-approval-list`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/procured-list`}
          component={lazy(() => import(`./procured-list/index`))}
        />
        <Route
          path={`${NAV_PATH.groupcompanies}`}
          exact
          component={lazy(() => import(`./group-companies`))}
        />
        <Route
          path={`${NAV_PATH.linkBrands}`}
          exact
          component={lazy(() => import(`./link-brand`))}
        />
        <Route
          path={`${NAV_PATH.enquiryLines}`}
          exact
          component={lazy(() => import(`./enquiry`))}
        />
        <Route
          path={`${NAV_PATH.activeEnquiryLines}`}
          exact
          component={lazy(() => import(`./enquiry`))}
        />
        <Route
          path={`${NAV_PATH.internalChat}`}
          exact
          component={lazy(() => import(`./chat`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/view/daily-lines-products/:enquiryId`}
          exact
          component={lazy(() => import(`./daily-lines-products`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${NAV_PATH.dashboard}`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);

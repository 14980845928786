import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "antd";
import { InboxOutlined, DownloadOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import { BrandService_ } from "services/Tenant/brand.service";
import { DEFAULT_PAGE_SIZE } from "constants/common.constant";
import Dragger from "antd/lib/upload/Dragger";
import { ROW_GUTTER } from "constants/ThemeConstant";

const BulkBrandDetail = ({
  form,
  resetcsvData,
  handleDisabled,
  onResetDataComplete,
  handleUploaded,
}) => {
  const [brandList, setBrandList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [invalidData, setInvalidData] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (resetcsvData) {
      setCSVData([]);
      setInvalidData([]);
      onResetDataComplete();
      handleDisabled(true);
    }
  }, [resetcsvData]);

  useEffect(() => {
    setCSVData([]);
    setInvalidData([]);
    handleDisabled(true);
  }, []);

  useEffect(() => {
    setCSVData([]);
    setInvalidData([]);
    handleDisabled(true);
  }, []);
  useEffect(() => {
    if (invalidData?.length) {
      handleDisabled(true);
    }
  }, [handleDisabled, invalidData?.length]);

  const findInvalidData = (response) => {
    const invalidData = response.filter((item) => item?.invalidReason);
    const validData = response.filter((item) => !item?.invalidReason);
    return {
      invalidData,
      validData,
    };
  };

  const fileHandler = (info) => {
    if (info.fileList && info.fileList.length > 0) {
      setLoading(true);
      const file = info.fileList[0].originFileObj;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("confirmed", "0");
      formData.append("uploadType", "ADD_BRAND");
      handleUploaded(file);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      BrandService_.addBulkBrands(formData, config)
        .then(function (response) {
          setTotalRecords(response);
          const { invalidData, validData } = findInvalidData(response);
          if (invalidData.length > 0) {
            setInvalidData(invalidData);
          }
          setCSVData(response);
          handleDisabled(false);
        })
        .catch(function (error) {
          console.error("Error:", error);
          setRefresh((prev) => prev + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const downloadProducts = () => {
    const fullCsvData = convertToCSV(csvData);
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(
      new Blob([fullCsvData], { type: "text/csv" })
    );
    downloadLink.setAttribute("download", `Non-Matching-brands.csv`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return `${header}\n${rows.join("\n")}`;
  };
  const fetchData = async (reset, loadedFilter) => {
    BrandService_.getBrand({
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
      search: "",
      orderBy: "createdAt",
      order: "DESC",
      where: {},
    }).then((res) => {
      setBrandList(res);
    });
    // .finally((_) => setIsLoading(false));
  };
  useEffect(() => {
    fetchData(true);
  }, []);
  const tableColumns = [
    {
      title: "id",
      dataIndex: "brandCode",
      render: (brandCode, row) => (
        <a
          // onClick={() => setViewedBrand_(row)}
          style={{ width: 150 }}
          ellipsis={{ tooltip: "-" }}
        >
          {"-"}
        </a>
      ),
    },
    {
      title: "name",
      dataIndex: "name",
      render: (name) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: name }}>
          {name}
        </Text>
      ),
    },
    {
      title: "alias",
      dataIndex: "alias",
      render: (alias) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: alias }}>
          {alias}
        </Text>
      ),
    },

    {
      title: "parent",
      dataIndex: "parent",
      render: (parent, record) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: record?.parent }}>
          {record?.parent}
        </Text>
      ),
    },
    {
      title: "p#",
      dataIndex: "productCounts",
      align: "right",
      render: (productCounts, record) => (
        <Text style={{ width: 150 }} tooltip={productCounts}>
          {productCounts}
        </Text>
      ),
    },
    {
      title: "invalid reason",
      dataIndex: "invalidReason",
      align: "right",
      render: (invalidReason, record) => (
        <Text style={{ width: 150 }} tooltip={invalidReason}>
          {invalidReason}
        </Text>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "actions",
    //   render: (_, row) => {
    //     return (
    //       <div className="text-right">
    //         <EllipsisDropdown
    //           menu={
    //             <Menu>
    //               <ActionDropdownMenu
    //                 actions={[
    //                   {
    //                     label: "Edit Detail",
    //                     icon: <EditOutlined />,
    //                     onClick: () => {
    //                       disptach(showAddEditBrandDrawer(true, row?.id));
    //                       disptach(refreshNewButtonData(true));
    //                     },
    //                   },
    //                   {
    //                     label: "View Detail",
    //                     icon: <EyeOutlined />,
    //                     onClick: () => setViewedBrand_(row),
    //                   },
    //                   {
    //                     label: "Delete",
    //                     icon: <DeleteOutlined />,
    //                     onClick: () => deleteBrand(row),
    //                   },
    //                 ]}
    //               />
    //             </Menu>
    //           }
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];
  const invalidColumns = [
    {
      title: "id",
      dataIndex: "brandCode",
      render: (brandCode, row) => (
        <a
          // onClick={() => setViewedBrand_(row)}
          style={{ width: 150 }}
          ellipsis={{ tooltip: "-" }}
        >
          {"-"}
        </a>
      ),
    },
    {
      title: "name",
      dataIndex: "name",
      render: (name) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: name }}>
          {name}
        </Text>
      ),
    },
    {
      title: "alias",
      dataIndex: "alias",
      render: (alias) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: alias }}>
          {alias}
        </Text>
      ),
    },

    {
      title: "parent",
      dataIndex: "parent",
      render: (parent, record) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: record?.parent }}>
          {record?.parent}
        </Text>
      ),
    },
    {
      title: "p#",
      dataIndex: "productCounts",
      align: "right",
      render: (productCounts, record) => (
        <Text style={{ width: 150 }} tooltip={productCounts}>
          {productCounts}
        </Text>
      ),
    },
    {
      title: "invalid reason",
      dataIndex: "invalidReason",
      align: "right",
      render: (invalidReason, record) => (
        <Text style={{ width: 150 }} tooltip={invalidReason}>
          {invalidReason}
        </Text>
      ),
    },
  ];
  return (
    <>
      {!csvData.length && (
        <div className="hide-name hide-delete-icon custom-upload">
          <Dragger
            className="custom-upload custom-upload-drag"
            name="file"
            multiple={false}
            key={refresh}
            accept=".csv"
            beforeUpload={() => false}
            onPreview={(file) => {
              window.open(
                file?.response?.data?.publicUrl,
                "_blank",
                "noreferrer"
              );
            }}
            onChange={(info) => {
              fileHandler(info);
            }}
            progress={{
              strokeColor: {
                "0%": "#108ee9",
                "100%": "#87d068",
              },
              strokeWidth: 3,
              format: (percent) =>
                percent && `${parseFloat(percent.toFixed(2))}%`,
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">please upload only .csv files.</p>
          </Dragger>
        </div>
      )}
      <Col xs={24} sm={24} md={24}></Col>
      {csvData.length > 0 && (
        <div>
          {invalidData?.length > 0 && (
            <>
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "20px",
                  }}
                >
                  <div class="ant-drawer-title">
                    Invalid Brand(s): {invalidData.length}/{totalRecords.length}
                  </div>
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    icon={<DownloadOutlined />}
                    title="Download"
                    onClick={downloadProducts}
                  />
                </div>
              </>
            </>
          )}{" "}
          <Table
            className="custom-table"
            size="small"
            // loading={isLoading}
            columns={tableColumns}
            dataSource={csvData}
            rowKey="id"
            pagination={false}
            rowClassName={(row) => (row?.invalidReason ? "erronous-row" : "")}
          />
        </div>
      )}
    </>
  );
};

export default BulkBrandDetail;

import React, { useState, useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Input,
  Table,
  Row,
  Col,
  DatePicker,
  Form,
  Select,
  Divider,
  Menu,
} from "antd";
import { Empty } from "antd";

import { ROW_GUTTER } from "constants/ThemeConstant";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import ActionDropdownMenu from "components/shared-components/ActionDropdownMenu";
import Text from "antd/lib/typography/Text";
import Loading from "components/shared-components/Loading";
import { DATE_FORMAT_DD_MM_YYYY } from "constants/DateConstant";
import moment from "moment";
import DynamicUpload from "components/shared-components/DynamicUpload";
import { getUploadIcon } from "utils/utilities";
import PoSelect from "components/shared-components/PoSelect";
import { OrgVendorsService_ } from "services/Tenant/organisation_vendor.service";
import { useSelector } from "react-redux";
import { ProcessPoService } from "services/Tenant/process_po.service";
import { AddressService_ } from "services/Tenant/address.service";
import useSession from "hooks/useSession";
import VendorSelect from "components/shared-components/VendorSelect";

const ReceiveProduct = ({
  props,
  setSelectedImageFile,
  brandDetail,
  isLoading,
  form,
  mode,
  products,
  updatedProductArray2WithPoQuantity,
  setFinalData,
  editableReceiveProduct,
}) => {
  const receiveproduct_ = useSelector((state) => state.receiveproduct);
  const [user] = useSession();

  const [shouldRenderUpload, setShouldRenderUpload] = useState(false);
  const [vendorContactList, setVendorContactList] = useState([]);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState({});
  const [wareHouseAddresses, setWarehouseAddresses] = useState([]);
  const handleReceivingQuantity = (e, index) => {
    const { value } = e.target;
    const newData = [...updatedProductArray2WithPoQuantity];
    newData[index].receive = Number(value);
    setFinalData(newData);
  };
  useEffect(() => {
    AddressService_.getAddress().then((data) => {
      setWarehouseAddresses(
        data?.map((w) => ({
          label: w?.addressName,
          value: w?.addressName,
        }))
      );
    });
  }, []);

  const validateReceive = (_, value) => {
    if (value < 0) {
      return Promise.reject("Receive quantity cannot be less than 0");
    }
    return Promise.resolve();
  };
  const tableColumns = [
    {
      title: "SKU",
      dataIndex: "sku",
      render: (sku, row) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: row?.product?.sku }}>
          <a
            style={{ width: 150 }}
            ellipsis={{ tooltip: row?.product?.sku }}
            target="_blank"
          >
            {row?.product?.sku}
          </a>
        </Text>
      ),
    },
    {
      title: "P/N",
      dataIndex: "part",
      render: (part, row) => (
        <Text
          style={{ width: 100 }}
          ellipsis={{ tooltip: row?.product?.partNumber }}
        >
          {row?.product?.partNumber}
        </Text>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (name, row) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: row?.product?.name }}>
          {row?.product?.name}
        </Text>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      render: (brand, row) => (
        <Text
          style={{ width: 100 }}
          ellipsis={{ tooltip: row?.product?.brand?.name }}
        >
          {row?.product?.brand?.name}
        </Text>
      ),
    },
    {
      title: "PO Qty",
      dataIndex: "qty",
      align: "right",
      render: (qty, row) => (
        <Text style={{ width: 50 }} ellipsis={{ tooltip: row?.poQuantity }}>
          {row?.poQuantity}
        </Text>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (data, row) => {
        const balance = Number(row?.poQuantity) - Number(row?.receiving);
        return (
          <Text
            style={{ width: 50 }}
            ellipsis={{
              tooltip: balance > 0 ? balance : 0,
            }}
          >
            {balance > 0 ? balance : 0}
          </Text>
        );
      },
    },
    {
      title: "Receive",
      dataIndex: "product",
      width: 50,
      render: (unitPrice, record, index) => {
        const balance = Number(record?.poQuantity) - Number(record?.receiving);
        return (
          <Form.Item
            name={`unitPrice[${index}]`}
            initialValue={balance}
            rules={[
              {
                validator: validateReceive,
              },
              {
                pattern: /^[0-9]+$/,
                message: `Receive Quantity must be a number`,
              },
            ]}
            validateTrigger={["onChange"]}
          >
            <Input
              onKeyUp={(e) => handleReceivingQuantity(e, index)}
              style={{ marginTop: "10px" }}
            />
          </Form.Item>
        );
      },
    },
    // {
    //   title: "",
    //   dataIndex: "actions",
    //   render: (_, row) => {
    //     return (
    //       <div className="text-right">
    //         <EllipsisDropdown
    //           menu={
    //             <Menu>
    //               <ActionDropdownMenu
    //                 actions={[
    //                   {
    //                     label: "Remove",
    //                     icon: <EditOutlined />,
    //                   },
    //                 ]}
    //               />
    //             </Menu>
    //           }
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldRenderUpload(true);
    }, 1200);

    return () => clearTimeout(timeout);
  }, []);

  const rules = {
    ref: [
      {
        required: true,
        pattern: /^[a-zA-Z0-9_\-\/\.]+$/,
        message: "Please input valid customer ref",
      },
    ],
    customer: [
      {
        required: true,
        message: "Please select a customer",
      },
    ],
    buyers: [
      {
        required: true,
        message: "Please select a buyer",
      },
    ],
    doc: [
      {
        required: true,
        message: "Please select a doc",
      },
    ],
  };
  const DescriptionItem = ({ title, content }) => (
    <div className="view-description-item">
      <p className="view-description-item-heading">{title}:</p>
      <p className="view-description-item-value">{content}</p>
    </div>
  );
  const getvendorDetails = (vendorId) => {
    OrgVendorsService_.getOrganisationvendordetail(vendorId).then((data) => {
      setVendorContactList(data);
    });
  };
  function handleChangePo(value) {
    // dispatch(showAddEditFinaliseOrderDrawer(true, "quotation", value?.id));
    // setQuotationDetails(value);
    form.setFieldsValue({
      poId: value,
      vendor: value?.vendor?.organisation?.name,
      deliveryVendorId: value?.vendor?.id,
    });
    getvendorDetails(value?.vendor?.id);
  }
  useEffect(() => {
    if (editableReceiveProduct) {
      ProcessPoService.getPurchaseOrderById(
        receiveproduct_?.purchaseOrderId
      ).then((res) => {
        setPurchaseOrderDetails(res);
        form.setFieldsValue({
          vendor: purchaseOrderDetails?.vendor?.organisation?.name,
          deliveryVendorId: purchaseOrderDetails?.vendor?.id,
        });
        if (purchaseOrderDetails?.vendor?.id) {
          getvendorDetails(purchaseOrderDetails?.vendor?.id);
        }
      });
      // .finally((_) => setLoading(false));
    }
  }, [
    editableReceiveProduct,
    purchaseOrderDetails?.vendor?.id,
    purchaseOrderDetails?.vendor?.organisation?.name,
    receiveproduct_?.purchaseOrderId,
  ]);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const userOptions = [
    {
      value: user.userInfo?.id,
      label: user.userInfo?.firstName + " " + user.userInfo?.lastName,
    },
  ];
  return !shouldRenderUpload ? (
    <Loading />
  ) : (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item name="poId" label="PO ID">
            {/* <Select allowClear placeholder="Select a PO ID" showSearch /> */}
            <PoSelect
              key={purchaseOrderDetails?.id}
              onChange={handleChangePo}
              defaultValue={{
                label: purchaseOrderDetails?.purchaseOrderCode,
                value: purchaseOrderDetails?.id,
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="vendor"
            label="Vendor"
            rules={[
              {
                required: true,
                message: "Please select a vendor",
              },
            ]}
          >
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name={`deliveryVendor`}
            label="Del. Vendor"
            // initialValue={selectedVendor?.[0]?.contacts?.[0]?.vendorContactId}
          >
            <VendorSelect clearOnSelect limit={10} inputlength={3} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item name="awb" label="AWB / MAWB">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="receiveDate"
            label="Receive Date"
            rules={[
              {
                required: true,
                message: "Please select a receive date",
              },
            ]}
            initialValue={moment(getCurrentDate(), DATE_FORMAT_DD_MM_YYYY)}
          >
            <DatePicker
              style={{ width: "100%" }}
              format={DATE_FORMAT_DD_MM_YYYY}
              // defaultValue={moment(getCurrentDate(), DATE_FORMAT_DD_MM_YYYY)}
              // disabledDate={disabledbeforeDate}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="receiveById"
            label="Receive By"
            initialValue={user.userInfo?.id}
            rules={[
              {
                required: true,
                message: "Please select a received by user",
              },
            ]}
          >
            <Select options={userOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item name="wareHouse" label="Warehouse">
            {/* <Input /> */}
            <Select
              allowClear
              placeholder="Select a warehouse"
              options={
                Array.isArray(wareHouseAddresses) ? wareHouseAddresses : []
              }
              // onChange={(value) => {
              //   form.setFieldsValue({ deliveryVendorId: value });
              // }}
              onClear={() => {
                form.setFieldsValue({ wareHouse: "" });
              }}
            />
            {/* <Select
              showSearch
              options={deliveryTermList.map(({ id, name }) => ({
                value: name,
                label: name,
              }))}
              onChange={(selectedOption) => {}}
            /> */}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item name="rackLocation" label="Rack Loc">
            <Input />
          </Form.Item>
          <Form.Item name="deliveryVendorId" label="">
            {/* <Input /> */}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item name="receivingProof" label="Receiving Proof">
            <DynamicUpload
              form={form}
              maxCount={1}
              acceptedFileTypes={["application/pdf"]}
              isMultiple={false}
              uploadIcon={getUploadIcon(form, "enquiryDoc")}
              fieldValue={"receivingProof"}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}></Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={24}>
          <Divider style={{ marginTop: 10 }} />
          <div className="table-responsive">
            <Table
              className="custom-table"
              size="small"
              loading={isLoading}
              columns={tableColumns}
              dataSource={updatedProductArray2WithPoQuantity}
              rowKey="id"
              pagination={false}
              onChange=""
              locale={{
                emptyText: (
                  <Empty description="No product available to receive."></Empty>
                ),
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ReceiveProduct;

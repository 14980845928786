import {
  REFRESH_LINK_BRAND,
  SHOW_ADD_EDIT_LINK_BRAND,
} from "redux/constants/LinkBrand";

export const showAddEditLinkBrand = (
  visibility = false,
  editableLinkBrand = null
) => {
  return {
    type: SHOW_ADD_EDIT_LINK_BRAND,
    data: {
      showAddEditLinkBrandDrawer: visibility,
      editableLinkBrand,
    },
  };
};

export const refreshLinkBrand = (refresh = false) => {
  return {
    type: REFRESH_LINK_BRAND,
    data: {
      refreshBulkBrandList: refresh,
    },
  };
};

import React, { useState } from "react";
import { Menu, Dropdown } from "antd";
import {
  SettingOutlined,
  QuestionCircleOutlined,
  ShopOutlined,
  EditOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { Action, Subjects } from "casl/subjects";
import { NAV_PATH } from "configs/NavigationConfig";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const Settings = () => {
  const history = useHistory();
  const menuItem = [
    // {
    //   key: "Currencies",
    //   path: `${NAV_PATH.currencies}`,
    //   title: "Currencies",
    //   submenu: [
    //     {
    //       key: "Currencies List",
    //       path: `${NAV_PATH.currencies}`,
    //       title: "List",
    //       submenu: [],
    //       cap: [[Action.Read, Subjects._COUNTRIES]],
    //     },
    //     {
    //       key: "Currencies Conversion",
    //       path: `${NAV_PATH.currenciesconversions}`,
    //       title: "Currency Conversion",
    //       submenu: [],
    //       cap: [[Action.Read, Subjects._COUNTRIES]],
    //     },
    //   ],
    //   cap: [[Action.Read, Subjects._CURRENCIES]],
    // },
    // {
    //   key: "Business Types",
    //   path: `${NAV_PATH.businessType}`,
    //   title: "Business Types",
    //   submenu: [],
    //   cap: [[Action.Read, Subjects._BUSINESS_TYPES]],
    // },
    // {
    //   key: "Tax Types",
    //   path: `${NAV_PATH.taxType}`,
    //   title: "Tax Types",
    //   submenu: [],
    //   cap: [[Action.Read, Subjects._TAX_TYPES]],
    // },
    // {
    //   key: "Impex Types",
    //   path: `${NAV_PATH.impexType}`,
    //   title: "Impex Types",
    //   submenu: [],
    //   cap: [[Action.Read, Subjects._IMPEX_TYPES]],
    // },
    // {
    //   key: "Address Types",
    //   path: `${NAV_PATH.addressType}`,
    //   title: "Address Types",
    //   submenu: [],
    //   cap: [[Action.Read, Subjects._ADDRESS_TYPES]],
    // },
    // {
    //   key: "Delivery Expenses",
    //   path: `${NAV_PATH.deliveryExpenses}`,
    //   title: "Delivery Expenses",
    //   submenu: [],
    //   cap: [[Action.Read, Subjects.DELIVERY_EXPENSES_TYPES]],
    // },
    // {
    //   key: "TermsConditions",
    //   path: `${NAV_PATH.termsconditions}`,
    //   title: "Terms & Conditions",
    //   submenu: [
    //     {
    //       key: "Quotation",
    //       path: `${NAV_PATH.termsconditions}`,
    //       title: "Quotation",
    //       submenu: [],
    //       cap: [[Action.Read, Subjects._COUNTRIES]],
    //     },
    //     {
    //       key: "RfqQuotation",
    //       path: `${NAV_PATH.rfqtermsconditions}`,
    //       title: "RFQ - Request for Quotation",
    //       submenu: [],
    //       cap: [[Action.Read, Subjects._COUNTRIES]],
    //     },
    //     {
    //       key: "PurchaseOrder",
    //       path: `${NAV_PATH.purchaseOrdertermsconditions}`,
    //       title: "Purchase Order",
    //       submenu: [],
    //       cap: [[Action.Read, Subjects._COUNTRIES]],
    //     },
    //   ],
    //   cap: [[Action.Read, Subjects.TERMS_CONDITIONS]],
    // },
    // {
    //   key: "addresses",
    //   path: `${NAV_PATH.addresses}`,
    //   title: "Addresses",
    //   submenu: [],
    //   cap: [[Action.Read, Subjects.ADDRESSES]],
    // },
    // {
    //   key: "banks",
    //   path: `${NAV_PATH.banks}`,
    //   title: "Banks",
    //   submenu: [],
    //   cap: [[Action.Read, Subjects.BANKS]],
    // },
    {
      key: "Users",
      path: `${NAV_PATH.users}`,
      title: "Users",
      submenu: [
        {
          key: "List",
          path: `${NAV_PATH.users}`,
          title: "List",
          submenu: [],
          cap: [[Action.Read, Subjects._TENANT_USERS]],
        },
        {
          key: "Profile",
          path: `${NAV_PATH.profile}`,
          title: "Profile",
          submenu: [],
          cap: [[Action.Read, Subjects._PROFILES]],
        },
      ],
      cap: [[Action.Read, Subjects._PROFILES]],
    },
    // {
    //   key: "Group Companies",
    //   path: `${NAV_PATH.groupcompanies}`,
    //   title: "Groupcompanies",
    //   submenu: [],
    //   cap: [[Action.Read, Subjects._GROUPCOMPANIES]],
    // },
  ];

  const handleItemClick = (path) => {
    const url = path;
    window.open(url);
  };

  const settingList = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            if (el.submenu && el.submenu.length > 0) {
              return (
                <Menu.SubMenu key={el.key} title={el.title}>
                  {el.submenu.map((subItem) => (
                    <Menu.Item
                      key={subItem.key}
                      onClick={() => handleItemClick(subItem.path)}
                    >
                      {subItem.title}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            } else {
              return (
                <Menu.Item
                  key={el.key}
                  onClick={() => handleItemClick(el.path)}
                >
                  {el.title}
                </Menu.Item>
              );
            }
          })}
        </Menu>
      </div>
    </div>
  );

  return (
    <>
      <Dropdown
        placement="bottomRight"
        overlay={settingList}
        trigger={["click"]}
      >
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item key="settings">
            <SettingOutlined className="nav-icon mx-auto" />
          </Menu.Item>
        </Menu>
      </Dropdown>
    </>
  );
};

export default Settings;

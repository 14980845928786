import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant"
import fetch_ from "../../interceptors/FetchInterceptor"

const { 
    ORGANISATION_VENDOR_LIST,
    ORGANISATION_VENDOR_COUNT,
    ORGANISATION_VENDOR_REGISTER,
    ORGANISATION_VENDOR_UPDATE,
    ORGANISATION_VENDOR_DETAIL,
    ORGANISATION_VENDOR_DELETE,
    ORGANISATION_VENDOR_CONTACT_ASSIGN,
 } = API_PATH;

export const OrgVendorsService_ = {
    getOrganisationvendors: (filter) => {
        return fetch_({
            url: ORGANISATION_VENDOR_LIST,
            method: 'GET',
            params: filter
        })
    },
    countOrganisationvendors: (filter) => {
        return fetch_({
            url: ORGANISATION_VENDOR_COUNT,
            method: 'GET',
            params: filter
        })
    },
    getOrganisationvendordetail: organisationVendorsId => {
        return fetch_({
            url: ORGANISATION_VENDOR_DETAIL(organisationVendorsId),
            method: 'GET',
        })
    },
    addOrganisationvendors: organisation => {
        return fetch_({
            url: ORGANISATION_VENDOR_REGISTER,
            method: 'POST',
            data: organisation
        })
    },

    Organisationvendorscontactassign: (payload, organisation) => {
        return fetch_({
            url: ORGANISATION_VENDOR_CONTACT_ASSIGN((payload.vendorId),(payload.organisationContactId)),
            method: 'POST',
            data: organisation
        })
    },

    updateOrganisationvendors: (organisationVendorsId, organisationvendors) => {
        return fetch_({
            url: ORGANISATION_VENDOR_UPDATE(organisationVendorsId),
            method: 'PUT',
            data: organisationvendors
        })
    },
    deleteOrganisationvendors: (organisationVendorsId) => {
        return fetch_({
            url: ORGANISATION_VENDOR_DELETE(organisationVendorsId),
            method: 'DELETE'
        })
    }
}

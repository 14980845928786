import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Mentions,
  Row,
  Space,
  Switch,
} from "antd";
import RichEditor from "components/shared-components/RichEditor";
import { DATE_FORMAT_DD_MM_YYYY } from "constants/DateConstant";
import { ROW_GUTTER } from "constants/ThemeConstant";
import moment from "moment";
import React from "react";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Card } from "antd";

import { Content, Footer } from "antd/lib/layout/layout";
import { Select } from "antd";
const { Option } = Select;

const { Meta } = Card;

const NotesForm = () => {
  const [form] = Form.useForm();

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div style={{ display: "flex", height: "100%", overflow: "hidden" }}>
      <Layout>
        <Content>
          <Form form={form} layout="horizontal">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div style={{ flex: 1, overflowY: "auto", padding: "20px" }}>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <Meta
                        avatar={
                          <div>
                            <Avatar
                              shape="circle"
                              size={32}
                              src="	https://emilus.themenate.net/img/avatars/thumb-1.jpg"
                            />
                          </div>
                        }
                        title=""
                        description={
                          <div>
                            <p>
                              In publishing and graphic design, Lorem ipsum is a
                              placeholder text commonly used to demonstrate the
                              visual form of a document
                            </p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p style={{ fontSize: 10, marginTop: 8 }}>
                                {new Date().toLocaleDateString()}
                              </p>
                              <div>
                                <Avatar
                                  shape="circle"
                                  size={32}
                                  src="	https://emilus.themenate.net/img/avatars/thumb-1.jpg"
                                />
                                <Avatar
                                  shape="circle"
                                  size={32}
                                  src="	https://emilus.themenate.net/img/avatars/thumb-1.jpg"
                                />
                                <Avatar
                                  shape="circle"
                                  size={32}
                                  src="	https://emilus.themenate.net/img/avatars/thumb-1.jpg"
                                />
                              </div>
                              <Avatar
                                shape="circle"
                                size={32}
                                src="	https://emilus.themenate.net/img/avatars/thumb-1.jpg"
                              />
                              <p style={{ fontSize: 10, marginTop: 8 }}>
                                {new Date().toLocaleDateString()}
                              </p>
                              <div>
                                <Checkbox
                                  checked={true}
                                  // onChange={(e) => setIncludeInOffer(true)}
                                  // disabled
                                ></Checkbox>
                              </div>
                              {/* <p style={{ fontSize: 10 }}>
                            Date: {new Date().toLocaleDateString()}
                          </p> */}
                            </div>
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              {/* <Col xs={2} sm={2} md={1}>
                <div class="vertical-line"></div>
              </Col> */}

              <Col xs={12} sm={12} md={12}>
                <div
                  style={{
                    flexBasis: "300px",
                    padding: "20px",
                    background: "#FAFAFB",
                  }}
                >
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item label="" name="description" rules="">
                      <div className="notes-rich-editor">
                        <RichEditor
                          defaultValue={""}
                          onChange={(value) => {
                            form?.setFieldsValue({
                              description: value,
                            });
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item label="Type" name={"public"}>
                      <Switch
                        size="small"
                        checkedChildren="Private"
                        unCheckedChildren="Public"
                        //   defaultChecked={offered}
                        //   onChange={() => handleClick(index, offered, record)}
                        //   checked={checked[index]}
                        //   disabled={!filtered[0]?.offered}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      name="tagUsers"
                      label="Tag Users"
                      //   rules={[
                      //     {
                      //       validator: checkMention,
                      //     },
                      //   ]}
                    >
                      <Select
                        mode="multiple"
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Users"
                        defaultValue={["china"]}
                        onChange={handleChange}
                        optionLabelProp="label"
                      >
                        <Option value="china" label="China">
                          <Space>
                            <span role="img" aria-label="China">
                              🇨🇳
                            </span>
                            China (中国)
                          </Space>
                        </Option>
                        <Option value="usa" label="USA">
                          <Space>
                            <span role="img" aria-label="USA">
                              🇺🇸
                            </span>
                            USA (美国)
                          </Space>
                        </Option>
                        <Option value="japan" label="Japan">
                          <Space>
                            <span role="img" aria-label="Japan">
                              🇯🇵
                            </span>
                            Japan (日本)
                          </Space>
                        </Option>
                        <Option value="korea" label="Korea">
                          <Space>
                            <span role="img" aria-label="Korea">
                              🇰🇷
                            </span>
                            Korea (韩国)
                          </Space>
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      name="tagRecord"
                      label="Tag Record"
                      //   rules={[
                      //     {
                      //       validator: checkMention,
                      //     },
                      //   ]}
                    >
                      <Select
                        mode="multiple"
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Records"
                        defaultValue={["china"]}
                        onChange={handleChange}
                        optionLabelProp="label"
                      >
                        <Option value="china" label="China">
                          <Space>
                            <span role="img" aria-label="China">
                              🇨🇳
                            </span>
                            China (中国)
                          </Space>
                        </Option>
                        <Option value="usa" label="USA">
                          <Space>
                            <span role="img" aria-label="USA">
                              🇺🇸
                            </span>
                            USA (美国)
                          </Space>
                        </Option>
                        <Option value="japan" label="Japan">
                          <Space>
                            <span role="img" aria-label="Japan">
                              🇯🇵
                            </span>
                            Japan (日本)
                          </Space>
                        </Option>
                        <Option value="korea" label="Korea">
                          <Space>
                            <span role="img" aria-label="Korea">
                              🇰🇷
                            </span>
                            Korea (韩国)
                          </Space>
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Row gutter={ROW_GUTTER}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="deliveryDate" label="Schedule">
                        <DatePicker
                          style={{ width: "100%" }}
                          format={DATE_FORMAT_DD_MM_YYYY}
                          defaultValue={moment(
                            getCurrentDate(),
                            DATE_FORMAT_DD_MM_YYYY
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item name="alert" label="Alert">
                        <Checkbox />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item label="Task" name={"task"}>
                      <Switch
                        size="small"
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        //   defaultChecked={offered}
                        //   onChange={() => handleClick(index, offered, record)}
                        //   checked={checked[index]}
                        //   disabled={!filtered[0]?.offered}
                      />
                    </Form.Item>
                  </Col>
                </div>
              </Col>
            </Row>
          </Form>
        </Content>
        <Footer>
          <Space style={{ float: "right" }}>
            <Button
              type="primary"
              // onClick={() => onFinish()}
              htmlType="submit"
              // loading={submitLoading}
              // disabled={submitLoading}
              size="small"
            >
              Save
            </Button>
            <Button
              onClick={() => {
                //   onDrawerClose();
              }}
              size="small"
            >
              Cancel
            </Button>
          </Space>
        </Footer>
      </Layout>
    </div>
  );
};

export default NotesForm;

import { IS_TENANT } from "configs/AppConfig";
import { SuperAdminAuthService } from "./SuperAdminAuthService";
import { TenantAuthService } from "./TenantAuthService";

export const AuthService = {
    login: (data) => {
        if(IS_TENANT){
            return TenantAuthService.login(data)
        }else{
            return SuperAdminAuthService.login(data)
        }
    },
    resetPasswordLink: (data) => {
        if(IS_TENANT){
            return TenantAuthService.resetPasswordLink(data)
        }else{
            return SuperAdminAuthService.resetPasswordLink(data)
        }
    },
    resetPassword: (data) => {
        if(IS_TENANT){
            return TenantAuthService.resetPassword(data)
        }else{
            return SuperAdminAuthService.resetPassword(data)
        }
    },
    logOut: () => {
        if(IS_TENANT){
            return TenantAuthService.logOut()
        }else{
            return SuperAdminAuthService.logOut()
        }
    },
}

const hostname = window.location.hostname;
const hostnameParts = hostname.split(".");
const NODE_ENV = process.env.NODE_ENV;
const origin =
  NODE_ENV === "development"
    ? "http://localhost:3000/"
    : window.location.origin + "/";

export const tenant =
  NODE_ENV === "development"
    ? "ogpm"
    : hostnameParts.length === 3
    ? hostnameParts[0]
    : null;
// export const tenant = null;
export const isTenant = !!tenant;

localStorage.setItem("isTenant", isTenant);
localStorage.setItem("tenant", tenant);
export const tenantId = (tenantId) => {
  if (tenantId) {
    localStorage.setItem("tenantId", tenantId);
  }
  return localStorage.getItem("tenantId");
};

const dev = {
  APP_NAME: "RFQ Gun",
  API_ENDPOINT_URL: origin,
  SUPER_ADMIN_API_ENDPOINT_URL: origin,
  TENANT_API_ENDPOINT_URL: origin,
  FRONTEND_DOMAIN: "vcuro.com",
};

const prod = {
  APP_NAME: "RFQ Gun",
  API_ENDPOINT_URL: origin,
  SUPER_ADMIN_API_ENDPOINT_URL: origin,
  TENANT_API_ENDPOINT_URL: origin,
  FRONTEND_DOMAIN: "vcuro.com",
};

const getEnv = () => {
  switch (NODE_ENV) {
    case "production":
      return prod;
    default:
      return dev;
  }
};

export const env = getEnv();

export const reactSelectCustomStyle = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '28px',
        height: '28px',
        "&:hover": {
            borderColor: '#699dff',
        },
        "&:focus": {
            borderColor: 'rgb(105, 157, 255)',
            boxShadow: 'rgba(62, 121, 247, 0.2) 0px 0px 0px 2px',
            outline: '0px',
        },
        border: '1px solid rgb(230, 235, 241)'
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '28px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '28px',
    }),
};
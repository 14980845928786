import React from "react";
import { OrgVendorsService_ } from "services/Tenant/organisation_vendor.service";
import AsyncSelectWrapper from "../AsyncSelectWrapper";

const VendorSelect = ({
  onChange,
  defaultValue,
  limit,
  inputlength,
  hideCode = false,
  filterVendors = [],
}) => {
  const fetchEnquiry = async (input) => {
    if (!input || input.length < inputlength) return [];
    let data = await OrgVendorsService_.getOrganisationvendors({
      search: input,
      limit: limit,
    });
    // console.log(filterVendors, "filterVendors");
    // // Filter out vendors that exist in filterVendors
    // data = data.filter((vendor) => !filterVendors.includes(vendor.id));

    const vendorOptions = data.map((o) => {
      if (hideCode) {
        return {
          label: o.organisation?.name,
          value: o.id,
          organisation: o?.organisation?.id,
        };
      } else {
        return {
          label: o.vendorCode + " - " + o.organisation?.name,
          value: o.id,
        };
      }
    });

    return vendorOptions;
  };

  return (
    <AsyncSelectWrapper
      onChange={onChange}
      defaultValue={defaultValue}
      fetchOptions={fetchEnquiry}
      placeholderValue={`Type at least ${inputlength} characters`}
      inputlength={inputlength}
    />
  );
};

export default VendorSelect;

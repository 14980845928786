import React, { useState, useEffect } from "react";
import { Tabs, Form, Button, message, Space, Modal, Layout } from "antd";

import { ADD, EDIT } from "constants/common.constant";
import Utils from "utils/common";
import { Content, Footer } from "antd/lib/layout/layout";
import { QuestionCircleFilled } from "@ant-design/icons";
import { BrandService_ } from "services/Tenant/brand.service";
import { refreshBrandList } from "redux/actions";
import { useDispatch } from "react-redux";
import CustomerOrderDetail from "./customer-order-detail";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const { TabPane } = Tabs;

const CustomerOrderForm = (props) => {
  const { isDrawerView, onDrawerClose, setIsSubmitted, setIsNext } = props;

  const [form] = Form.useForm();
  const [uploadedImg, setImage] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [brandDetail, setBrandDetail] = useState({});
  const brandId = props.brandId;
  const dispatch = useDispatch();
  const mode = brandId ? EDIT : ADD;
  useEffect(() => {
    if (brandId && form) {
      BrandService_.getBranddetail(brandId).then((data) => {
        setBrandDetail(data);
        form.setFieldsValue({
          ...data,
        });
      });
      // .finally(() => setLoading(false));
    }
  }, [brandId, form]);
  const handleUploadChange = (info) => {
    Utils.getBase64(info.file.originFileObj, (imageUrl) => {
      setImage(imageUrl);
      setUploadLoading(true);
    });
  };

  const onFinish = () => {
    dispatch(refreshBrandList(false));

    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        if(values.source === 'Quotation'){
            const url = APP_PREFIX_PATH + `/preview/quotation/${values.quotationId}/true`;
            window.open(url, "_blank");
        }else if(values.source === 'Contract'){
            const url = APP_PREFIX_PATH + `/contract-view/${values.customerId}/true`;
            window.open(url, "_blank");
        }else if(values.source === 'PreviousOrder'){
            const url = APP_PREFIX_PATH + `/customer-order-view/${values.orderId}/true`;
            window.open(url, "_self");
        }else{
            console.log("Please select a source");
        }
        setTimeout(() => {
            onDrawerClose();
          }, 1000);
      
        
   console.log(values);
    
      })
      .catch((info) => {
        setSubmitLoading(false);
        message.error("Please enter all required field ");
      });
  };


  return (
    <>
      <Form
        layout={isDrawerView ? "horizontal" : "vertical"}
        form={form}
        name="brand-form"
      >
        <div className="container">
          <Layout>
            <Content>
              <CustomerOrderDetail
                {...props}
                setSelectedImageFile={setSelectedImageFile}
                uploadedImg={uploadedImg}
                uploadLoading={uploadLoading}
                handleUploadChange={handleUploadChange}
                form={form}
                brandDetail={brandDetail}
              />
            </Content>
            {isDrawerView ? (
              <Footer>
                <Space style={{ float: "right" }}>
             
                  {mode === ADD ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        Modal.confirm({
                          title: "Confirm",
                          icon: <QuestionCircleFilled />,
                          content: "Are you sure to proceed.",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: () => {
                            onFinish(true);
                          },
                        });
                      }}
                      htmlType="submit"
                      loading={submitLoading}
                      disabled={submitLoading}
                      size="small"
                      isProcessed="true"
                    >
                      {mode === ADD ? "Submit" : `Save`}
                    </Button>
                  ) : (
                    <> </>
                  )}
                  <Button
                    onClick={() => {
                      onDrawerClose();
                      form.resetFields();
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                </Space>
              </Footer>
            ) : (
              <></>
            )}
          </Layout>
        </div>
      </Form>
    </>
  );
};

export default CustomerOrderForm;

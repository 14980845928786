import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  ORGANISATION_CUSTOMER_LIST,
  ORGANISATION_CUSTOMER_COUNT,
  ORGANISATION_CUSTOMER_REGISTER,
  ORGANISATION_CUSTOMER_UPDATE,
  ORGANISATION_CUSTOMER_DETAIL,
  ORGANISATION_CUSTOMER_DELETE,
  ORGANISATION_CUSTOMER_CONTACT_ASSIGN,
  CUSTOMER_CONTACT_DETAIL,
  CUSTOMER_PAYMENT_BANKS,
  ADD_CUSTOMER_PAYMENT_BANKS,
  PAYMENT_BANKS_DELETE,
} = API_PATH;

export const OrgCustomersService_ = {
  getOrganisationcustomers: (filter) => {
    return fetch_({
      url: ORGANISATION_CUSTOMER_LIST,
      method: "GET",
      params: filter,
    });
  },
  countOrganisationcustomers: (filter) => {
    return fetch_({
      url: ORGANISATION_CUSTOMER_COUNT,
      method: "GET",
      params: filter,
    });
  },
  getOrganisationcustomerdetail: (organisationCustomersId) => {
    return fetch_({
      url: ORGANISATION_CUSTOMER_DETAIL(organisationCustomersId),
      method: "GET",
    });
  },
  addOrganisationcustomers: (organisation) => {
    return fetch_({
      url: ORGANISATION_CUSTOMER_REGISTER,
      method: "POST",
      data: organisation,
    });
  },

  Organisationcustomerscontactassign: (payload, organisation) => {
    return fetch_({
      url: ORGANISATION_CUSTOMER_CONTACT_ASSIGN(
        payload.customerId,
        payload.organisationContactId
      ),
      method: "POST",
      data: organisation,
    });
  },

  updateOrganisationcustomers: (
    organisationCustomersId,
    organisationcustomers
  ) => {
    return fetch_({
      url: ORGANISATION_CUSTOMER_UPDATE(organisationCustomersId),
      method: "PUT",
      data: organisationcustomers,
    });
  },
  deleteOrganisationcustomers: (organisationCustomersId) => {
    return fetch_({
      url: ORGANISATION_CUSTOMER_DELETE(organisationCustomersId),
      method: "DELETE",
    });
  },
  getCustomerContact: (organisationCustomersId) => {
    return fetch_({
      url: CUSTOMER_CONTACT_DETAIL(organisationCustomersId),
      method: "GET",
    });
  },
  getCustomerPaymentBank: (customersId) => {
    return fetch_({
      url: CUSTOMER_PAYMENT_BANKS(customersId),
      method: "GET",
    });
  },
  addCustomerPaymentBanks: (customersId, organisation) => {
    return fetch_({
      url: ADD_CUSTOMER_PAYMENT_BANKS(customersId),
      method: "POST",
      data: organisation,
    });
  },
  deletePaymentBanks: (customersId, paymentBankId) => {
    return fetch_({
      url: PAYMENT_BANKS_DELETE(customersId, paymentBankId),
      method: "DELETE",
    });
  },
};

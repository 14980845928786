import { API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
    ENQUIRY_RESOURCE_LIST,
    ENQUIRY_RESOURCES_ADD,
    ENQUIRY_RESOURCE_DETAIL,
    ENQUIRY_RESOURCE_ASSIGNED_PRODUCTS,
    ENQUIRY_RESOURCE_UPDATE,
    ENQUIRY_RESOURCE_DELETE,
} = API_PATH;

export const EnquiryResourceService_ = {
    getEnquiryResource: (enquiryId, filter) => {
        return fetch_({
            url: ENQUIRY_RESOURCE_LIST(enquiryId),
            method: "GET",
            params: filter,
        });
    },
    getAssignedProducts: (enquiryId, resourceId, filter) => {
        return fetch_({
            url: ENQUIRY_RESOURCE_ASSIGNED_PRODUCTS(enquiryId,resourceId),
            method: "GET",
            params: filter,
        });
    },
    addEnquiryResource: (enquiryId, resources) => {
        return fetch_({
            url: ENQUIRY_RESOURCES_ADD(enquiryId),
            method: "POST",
            data: resources,
        });
    },

    getEnquiryResourcedetail: (enquiryId, resourceId) => {
        return fetch_({
            url: ENQUIRY_RESOURCE_DETAIL(enquiryId, resourceId),
            method: "GET",
        });
    },

    updateEnquiryResource: (enquiryId, resourceId, resource) => {
        return fetch_({
            url: ENQUIRY_RESOURCE_UPDATE(enquiryId, resourceId),
            method: "PUT",
            data: resource,
        });
    },

    deleteEnquiryResource: (enquiryId, resourceId) => {
        return fetch_({
            url: ENQUIRY_RESOURCE_DELETE(enquiryId, resourceId),
            method: "DELETE",
        });
    },

};

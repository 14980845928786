import {
  REFRESH_PRODUCT_LIST,
  SHOW_ADD_BRAND_DRAWER,
  SHOW_ADD_EDIT_PRODUCT_DRAWER,
  SHOW_EDIT_BRAND_DRAWER,
} from "redux/constants/Catalogue";

const initialState = {
  showAddEditProductDrawer: false,
  editableProduct: null,
  refreshProductList: false,
};
export const catalogue = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_ADD_EDIT_PRODUCT_DRAWER:
      return {
        ...state,
        showAddEditProductDrawer: data.showAddEditProductDrawer,
        editableProduct: data.editableProduct,
      };
    case REFRESH_PRODUCT_LIST:
      return {
        ...state,
        refreshProductList: data.refreshProductList,
      };
    default:
      return state;
  }
};

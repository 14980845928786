import React, { useEffect, useState } from "react";
import { Row, Col, Button, Table } from "antd";
import { ROW_GUTTER } from "constants/ThemeConstant";
import { InboxOutlined, DownloadOutlined } from "@ant-design/icons";
import { getcountry, getemail, gettel, text } from "utils/utilities";
import Text from "antd/lib/typography/Text";
import { DEFAULT_PAGE_SIZE } from "constants/common.constant";
import { OrgService_ } from "services/Tenant/organisation.service";
import Dragger from "antd/lib/upload/Dragger";
import Loading from "components/shared-components/Loading";

const BulkOrganisationDetail = ({
  form,
  resetcsvData,
  handleDisabled,
  onResetDataComplete,
  handleUploaded,
}) => {
  const [brandList, setBrandList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [invalidData, setInvalidData] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (resetcsvData) {
      setCSVData([]);
      setInvalidData([]);
      onResetDataComplete();
      handleDisabled(true);
    }
  }, [resetcsvData]);

  useEffect(() => {
    setCSVData([]);
    setInvalidData([]);
    handleDisabled(true);
  }, []);

  useEffect(() => {
    setCSVData([]);
    setInvalidData([]);
    handleDisabled(true);
  }, []);
  useEffect(() => {
    if (invalidData?.length) {
      handleDisabled(true);
    }
  }, [handleDisabled, invalidData?.length]);
  const findInvalidData = (response) => {
    const invalidData = response.filter((item) => item?.invalidReason);
    const validData = response.filter((item) => !item?.invalidReason);
    return {
      invalidData,
      validData,
    };
  };

  const fileHandler = (info) => {
    if (info.fileList && info.fileList.length > 0) {
      setLoading(true);
      const file = info.fileList[0].originFileObj;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("confirmed", "0");
      formData.append("uploadType", "ADD_ORGANISATION");
      handleUploaded(file);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      OrgService_.addBulkOrganisation(formData, config)
        .then(function (response) {
          setTotalRecords(response);
          const { invalidData, validData } = findInvalidData(response);
          if (invalidData.length > 0) {
            setInvalidData(invalidData);
          }
          setCSVData(response);
          handleDisabled(false);
        })
        .catch(function (error) {
          console.error("Error:", error);
          setRefresh((prev) => prev + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const downloadProducts = () => {
    const fullCsvData = convertToCSV(csvData);
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(
      new Blob([fullCsvData], { type: "text/csv" })
    );
    downloadLink.setAttribute("download", `Non-Matching-organisations.csv`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return `${header}\n${rows.join("\n")}`;
  };
  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name, row) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: name }}>
          {text(name)}
        </Text>
      ),
    },
    {
      title: "Trade As",
      dataIndex: "contactDepartment",
      render: (contactDepartment) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: contactDepartment }}>
          {text(contactDepartment)}
        </Text>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      render: (country) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: country }}>
          {getcountry(country)}
        </Text>
      ),
    },
    {
      title: "Email",
      dataIndex: "contactEmail",
      render: (contactEmail, record) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: contactEmail }}>
          {getemail(contactEmail)}
        </Text>
      ),
    },
    {
      title: "Tel",
      dataIndex: "contactTelephone",
      render: (contactTelephone) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: contactTelephone }}>
          {gettel(contactTelephone)}
        </Text>
      ),
    },
    {
      title: "invalid reason",
      dataIndex: "invalidReason",
      align: "right",
      render: (invalidReason, record) => (
        <Text style={{ width: 150 }} tooltip={invalidReason}>
          {invalidReason}
        </Text>
      ),
    },
  ];
  const invalidColumns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name, row) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: name }}>
          {text(name)}
        </Text>
      ),
    },
    {
      title: "Trade As",
      dataIndex: "contactDepartment",
      render: (contactDepartment) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: contactDepartment }}>
          {text(contactDepartment)}
        </Text>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      render: (country) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: country }}>
          {getcountry(country)}
        </Text>
      ),
    },
    {
      title: "Email",
      dataIndex: "contactEmail",
      render: (contactEmail, record) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: contactEmail }}>
          {getemail(contactEmail)}
        </Text>
      ),
    },
    {
      title: "Tel",
      dataIndex: "contactTelephone",
      render: (contactTelephone) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: contactTelephone }}>
          {gettel(contactTelephone)}
        </Text>
      ),
    },
  ];
  return loading ? (
    <Loading cover="page-center" />
  ) : (
    <>
      {!csvData.length && (
        <div className="hide-name hide-delete-icon custom-upload">
          <Dragger
            className="custom-upload custom-upload-drag"
            name="file"
            multiple={false}
            accept=".csv"
            beforeUpload={() => false}
            key={refresh}
            onPreview={(file) => {
              window.open(
                file?.response?.data?.publicUrl,
                "_blank",
                "noreferrer"
              );
            }}
            onChange={(info) => {
              fileHandler(info);
            }}
            progress={{
              strokeColor: {
                "0%": "#108ee9",
                "100%": "#87d068",
              },
              strokeWidth: 3,
              format: (percent) =>
                percent && `${parseFloat(percent.toFixed(2))}%`,
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">please upload only .csv files.</p>
          </Dragger>
        </div>
      )}
      {csvData.length > 0 && (
        <div>
          {invalidData?.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "20px",
              }}
            >
              <div class="ant-drawer-title">
                Invalid Organisation(s): {invalidData.length}/
                {totalRecords.length}
              </div>
              <Button
                type="link"
                style={{ color: "red" }}
                icon={<DownloadOutlined />}
                title="Download"
                onClick={downloadProducts}
              />
            </div>
          )}{" "}
          <div className="table-responsive">
            <Table
              className="custom-table"
              size="small"
              // loading={isLoading}
              columns={tableColumns}
              dataSource={csvData}
              rowKey="id"
              pagination={false}
              rowClassName={(row) => (row?.invalidReason ? "erronous-row" : "")}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BulkOrganisationDetail;

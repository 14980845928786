import { PUBLIC_REQUEST_KEY, API_PATH } from "../constants/common.constant"
import fetch_ from "../interceptors/FetchInterceptor"

const {SUPER_ADMIN_LOGIN, SUPER_ADMIN_LOGOUT, SUPER_ADMIN_PASSWORD, SUPER_ADMIN_PASSWORD_LINK} = API_PATH;
export const SuperAdminAuthService = {
    login: (data) => {
        return fetch_({
            url: SUPER_ADMIN_LOGIN,
            method: 'POST',
            headers: {
                [PUBLIC_REQUEST_KEY]: true
            },
            data
        })
    },
    resetPasswordLink: (data) => {
        return fetch_({
            url: SUPER_ADMIN_PASSWORD_LINK,
            method: 'POST',
            headers: {
                [PUBLIC_REQUEST_KEY]: true,
            },
            data
        })
    },
    resetPassword: (data) => {
        return fetch_({
            url: SUPER_ADMIN_PASSWORD,
            method: 'POST',
            headers: {
                [PUBLIC_REQUEST_KEY]: true
            },
            data
        })
    },
    logOut: () => {
        return fetch_({
            url: SUPER_ADMIN_LOGOUT,
            method: 'POST',
        })
    },
}

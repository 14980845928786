import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import { catalogue } from "./Catalogue";
import { rfq } from "./Rfq";
import { rfqproduct } from "./RfqProduct";
import { brand } from "./Brand";
import { viewEnquiryProduct } from "./ViewEnquiryProduct";
import { enquiry } from "./Enquiry";
import { customerorder } from "./CustomerOrder";
import { finaliseorder } from "./FinaliseOrder";
import { receiveproduct } from "./receiveProduct";
import { bulkRfq } from "./BulkRfq";
import { notes } from "./Notes";
import { bulkBrand } from "./BulkBrand";
import { bulkOrganisation } from "./BulkOrganisation";
import { linkBrand } from "./LinkBrand";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  catalogue: catalogue,
  rfq: rfq,
  rfqproduct: rfqproduct,
  brand: brand,
  viewEnquiryProduct: viewEnquiryProduct,
  enquiry: enquiry,
  customerorder: customerorder,
  finaliseorder: finaliseorder,
  receiveproduct: receiveproduct,
  notes: notes,
  bulkRfq: bulkRfq,
  bulkBrand: bulkBrand,
  bulkOrganisation: bulkOrganisation,
  linkBrand: linkBrand,
});

export default reducers;

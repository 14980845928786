import { PUBLIC_REQUEST_KEY, API_BASE_URL, API_PATH, ADD_X_TENANT_ID } from "../constants/common.constant"
import fetch_ from "../interceptors/FetchInterceptor"

const {TENANT_LOGIN, TENANT_LOGOUT, TENANT_RESET_PASSWORD, TENANT_RESET_PASSWORD_LINK, TENANT_SIGNUP} = API_PATH;
export const TenantAuthService = {
    signup: (data) => {
        return fetch_({
            url: TENANT_SIGNUP,
            method: 'POST',
            headers: {
                [PUBLIC_REQUEST_KEY]: true
            },
            data
        })
    },
    login: (data) => {
        return fetch_({
            url: TENANT_LOGIN,
            method: 'POST',
            headers: {
                [PUBLIC_REQUEST_KEY]: true
            },
            data
        })
    },
    resetPasswordLink: (data) => {
        return fetch_({
            url: TENANT_RESET_PASSWORD_LINK,
            method: 'POST',
            headers: {
                [PUBLIC_REQUEST_KEY]: true,
            },
            data
        })
    },
    resetPassword: (data) => {
        return fetch_({
            url: TENANT_RESET_PASSWORD,
            method: 'POST',
            headers: {
                [PUBLIC_REQUEST_KEY]: true
            },
            data
        })
    },
    logOut: () => {
        return fetch_({
            url: TENANT_LOGOUT,
            method: 'POST',
        })
    },
}

const _ = require("lodash");

export const maxSizeValidationMessage = (maxSize = 200) => {
  return `File size should be less than ${maxSize}KB!`;
};

export const getAcceptedFileTypes = (fileTypes) => {
  return `You can only upload ${fileTypes?.join("/")}`;
};
export const sortArrayByProperty = (data, sortKey) => {
  if (!Array.isArray(data) || typeof sortKey !== "string") {
    return [];
  }

  return _.sortBy(data, sortKey);
};
export const checkIfNumberIsValid = (value, fixed = 2) => {
  if (typeof value === "number" && isFinite(value) && value !== 0) {
    return value?.toFixed(fixed);
  } else {
    return "N/A";
  }
};

// Quotation Calculations

export const calculateTotalFreight = (freight, fc, totalOfFreights) => {
  return Number(freight) + Number(totalOfFreights);
};

export const calculateProductEXT = (quantity, unitPrice) => {
  return Number(quantity) * Number(unitPrice);
};
export const calculateUnitCurrency = (unitPrice, fc, margin) => {
  return margin !== 0 ? Number(unitPrice) / (Number(fc) * margin) : 0;
};
export const calculateUnitSellCurrency = (unitCur, freightEach) => {
  return Number(unitCur) * Number(freightEach) + Number(unitCur);
};
export const calculateExtSellCuurency = (unitWF, quantity) => {
  return unitWF * quantity;
};
export const calculateFinalPrice = (unitWF, quantity) => {
  return unitWF * quantity;
};

//certificate
export const calculateCertificateEXT = (quantity, unitPrice) => {
  return Number(quantity) * Number(unitPrice);
};
export const calculateCertificateExtSellCurrency = (ext, FC, margin) => {
  return (Number(ext) * Number(FC)) / margin;
};
export function formatAddress(type = "delivery", addressObject) {
  const addressTypes = {
    delivery: [
      "deliveryAddress",
      "deliveryCity",
      "deliveryState",
      "deliveryCountry",
      "deliveryPostCode",
      "deliveryAddressName",
    ],
    collection: [
      "collectionAddress",
      "collectionCity",
      "collectionState",
      "collectionCountry",
      "collectionPostCode",
      "collectionAddressName",
    ],
    billing: [
      "billingAddress",
      "billingCity",
      "billingState",
      "billingCountry",
      "billingPostCode",
      "billingAddressName",
    ],
  };

  const addressFields = addressTypes[type];
  if (!addressFields) {
    return "Invalid address type";
  }

  if (addressObject) {
    const formattedAddress = addressFields
      .map((field) => {
        if (field === `${type}PostCode` && addressObject[field]) {
          return `-${addressObject[field]}`;
        }
        return addressObject.hasOwnProperty(field)
          ? addressObject[field]
          : null;
      })
      .filter((field) => field !== null && field !== "")
      .join(", ");

    return formattedAddress;
  } else {
    return "Address object is undefined.";
  }

  /*const formattedAddress = addressFields
    .map((field) => addressObject[field])
    .filter((field) => field)
    .join(", ");

  return formattedAddress; */
}

export const validateAlias = (_, value) => {
  const htmlScriptRegex = /<[^>]*>|<\/[^>]*>|<script.*?>.*?<\/script>/gi;

  if (htmlScriptRegex.test(value)) {
    return Promise.reject(
      "Please enter a valid alias without HTML or script tags"
    );
  }

  return Promise.resolve();
};
export const DescriptionItem = ({ title, content }) => (
  <div className="view-description-item">
    <p className="view-description-item-heading">{title}:</p>
    <p className="view-description-item-value">{content}</p>
  </div>
);

import {
    REFRESH_CUSTOMERORDER_LIST,
    SHOW_ADD_EDIT_CUSTOMERORDER_DRAWER,
  } from "redux/constants/CustomerOrder";
  
  const initialState = {
    showAddEditCustomerOrderDrawer: false,
    editableCustomerOrder: null,
    refreshCustomerOrderList: false,
  };
  export const customerorder = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
      case SHOW_ADD_EDIT_CUSTOMERORDER_DRAWER:
        return {
          ...state,
          showAddEditCustomerOrderDrawer: data.showAddEditCustomerOrderDrawer,
          editableCustomerOrder: data.editableCustomerOrder,
        };
      case REFRESH_CUSTOMERORDER_LIST:
        return {
          ...state,
          refreshCustomerOrderList: data.refreshCustomerOrderList,
        };
      default:
        return state;
    }
  };
  
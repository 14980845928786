import React from "react";
import { BrandService_ } from "services/Tenant/brand.service";
import AsyncSelectWrapper from "../AsyncSelectWrapper";

const BrandSelect = ({ onChange, defaultValue, limit, inputlength }) => {
  const fetchEnquiry = async (input) => {
    if (!input || input.length < inputlength) return [];

    return BrandService_.getBrand({
      search: input,
      limit: limit,
      where: {
        "al.is_active": true,
      },
    }).then((data) => {
      const brandOptions = data.map((o) => ({
        label: o.name,
        value: o.id,
      }));
      return brandOptions;
    });
  };

  return (
    <AsyncSelectWrapper
      onChange={onChange}
      defaultValue={defaultValue}
      fetchOptions={fetchEnquiry}
      placeholderValue={`Type at least ${inputlength} characters`}
      inputlength={inputlength}
    />
  );
};

export default BrandSelect;

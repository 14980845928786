import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshCustomerOrderList,
  showAddEditCustomerOrderDrawer,
} from "redux/actions";
import CustomerOrderForm from "views/app-views/customer-order/customer-order-form";
//import CustomerOrderForm from "views/app-views/rfq/rfq-form";

export default function CustomerOrderDrawers() {
  const disptach = useDispatch();
  const customerorder_ = useSelector((state) => state.customerorder);
  return (
    <>
      <Drawer
        title={`${
          customerorder_?.editableCustomerOrder
            ? "Add Customer Order"
            : "Add Customer Order"
        } `}
        width={900}
        onClose={() => {
          disptach(showAddEditCustomerOrderDrawer(false));
        }}
        visible={customerorder_?.showAddEditCustomerOrderDrawer}
        className="custom-drawer"
      >
        {customerorder_?.showAddEditCustomerOrderDrawer && (
          <CustomerOrderForm
            setIsSubmitted={() => {}}
            isDrawerView={true}
            onDrawerClose={() => {
              disptach(showAddEditCustomerOrderDrawer(false));
              disptach(refreshCustomerOrderList(true));
            }}
            setIsNext={() => {
              disptach(refreshCustomerOrderList(true));
            }}
            brandId={customerorder_?.editableCustomerOrder}
          />
        )}
      </Drawer>
    </>
  );
}

import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  CUSTOMER_PRODUCT_ADD,
  CUSTOMER_PRODUCT_LIST,
  CUSTOMER_PRODUCT_DESCRIPTION,
  CUSTOMER_ORDER_DETAIL,
  CUSTOMER_ORDER_COUNT,
  CUSTOMER_PURCHASE_REQUIREMENTS,
  CUSTOMER_PURCHASE_REQUIREMENTS_COUNT,
  GET_ALL_LIST_CERTIFICATES,
} = API_PATH;

export const CustomerProductService_ = {
  addCustoerProduct: (customerproduct) => {
    return fetch_({
      url: CUSTOMER_PRODUCT_ADD,
      method: "POST",
      data: customerproduct,
    });
  },

  getCustoerProduct: (filter) => {
    return fetch_({
      url: CUSTOMER_PRODUCT_LIST,
      method: "GET",
      params: filter,
    });
  },
  getCustomerOrderDetail: (customerOrderId, filter) => {
    return fetch_({
      url: CUSTOMER_ORDER_DETAIL(customerOrderId),
      method: "GET",
      params: filter,
    });
  },
  getCustomerOrderPurchaseRequirements: (filter) => {
    return fetch_({
      url: CUSTOMER_PURCHASE_REQUIREMENTS(),
      method: "GET",
      params: filter,
    });
  },
  getCustomerOrderPurchaseRequirementsCount: (filter) => {
    return fetch_({
      url: CUSTOMER_PURCHASE_REQUIREMENTS_COUNT(),
      method: "GET",
      params: filter,
    });
  },
  getCustomerOrderCount: (filter) => {
    return fetch_({
      url: CUSTOMER_ORDER_COUNT(),
      method: "GET",
      params: filter,
    });
  },
  getCustomerOrderListCertificates: (filter) => {
    return fetch_({
      url: GET_ALL_LIST_CERTIFICATES(),
      method: "POST",
      data: filter,
    });
  },

  getCustoerProductDescription: (customerId, productId, filter) => {
    return fetch_({
      url: CUSTOMER_PRODUCT_DESCRIPTION(customerId, productId),
      method: "GET",
      params: filter,
    });
  },
};

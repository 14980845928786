import { notification } from "antd";

export const openNotification = (
  message,
  description,
  type = "info",
  placement = "topRight",
  duration = 4
) => {
  notification[type]({
    message,
    description,
    placement,
    duration,
  });
};

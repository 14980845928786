import {
  REFRESH_BRAND_LIST,
  REFRESH_BRAND_NEW_DATA,
  SHOW_ADD_EDIT_BRAND_DRAWER,
} from "redux/constants/Brand";

const initialState = {
  showAddEditBrandDrawer: false,
  editableBrand: null,
  refreshBrandList: false,
};
export const brand = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_ADD_EDIT_BRAND_DRAWER:
      return {
        ...state,
        showAddEditBrandDrawer: data.showAddEditBrandDrawer,
        editableBrand: data.editableBrand,
      };
    case REFRESH_BRAND_LIST:
      return {
        ...state,
        refreshBrandList: data.refreshBrandList,
      };
    case REFRESH_BRAND_NEW_DATA:
      return {
        ...state,
        refreshNewData: data.refreshNewData,
      };
    default:
      return state;
  }
};

import {
  OPEN_ENQUIRY_TAB_IN_ENQUIRY_MENU,
  REFRESH_ENQUIRY_LIST,
  SHOW_ADD_EDIT_ENQUIRY_DRAWER,
} from "redux/constants/Enquiry";

export const showAddEditEnquiryDrawer = (
  visibility = false,
  editableEnquiry = null
) => {
  return {
    type: SHOW_ADD_EDIT_ENQUIRY_DRAWER,
    data: {
      showAddEditEnquiryDrawer: visibility,
      editableEnquiry,
    },
  };
};

export const refreshEnquiryList = (refresh = false) => {
  return {
    type: REFRESH_ENQUIRY_LIST,
    data: {
      refreshEnquiryList: refresh,
    },
  };
};
export const openTabInEnquiryMenu = (open, tabKeyName) => {
  return {
    type: OPEN_ENQUIRY_TAB_IN_ENQUIRY_MENU,
    data: {
      openEnquiryTabInEnquiryMenu: open,
      tabKeyName: tabKeyName,
    },
  };
};

import React, { useState } from "react";
import { Form, Button, Space, Modal, Layout, message } from "antd";
import { ADD, EDIT } from "../../../constants/common.constant";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Content, Footer } from "antd/lib/layout/layout";
import { RfqService_ } from "services/Tenant/rfq.service";
import { openNotification } from "components/shared-components/NotificationTile";
import BulkOrganisationDetail from "./link-brand-detail";
import LinkBrandDetail from "./link-brand-detail";
import { OrgService_ } from "services/Tenant/organisation.service";
import { refreshLinkBrand } from "redux/actions/LinkBrand";
import { useDispatch } from "react-redux";
import LinkBrandDetailEdit from "./link-brand-detail-edit";
import ManuallyLinkBrands from "./manually-link-brands";

const LinkBrandForm = (props) => {
  const {
    rfqDetail,
    onDrawerClose,
    hideUpload = false,
    presetval,
    organisationData,
    setRefresh,
  } = props;
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productVendors, setProductVendors] = useState([]);
  const [form] = Form.useForm();
  const [resetData, setResetData] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  const handleUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const handleClearData = () => {
    setResetData(true);
  };
  const handleResetDataComplete = () => {
    setResetData(false);
  };
  const handleDisabled = (val) => {
    setDisabled(val);
  };
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        setSubmitLoading(true);
        const formData = new FormData();
        formData.append("file", uploadedFile);
        formData.append("confirmed", "1");
        formData.append("uploadType", "ADD_ORGANISATION_BRANDS");
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        OrgService_.addBulkLinkOrganisation(formData, config)
          .then((response) => {
            setUploadedFile(null);
            handleClearData();
            onDrawerClose();
            message.success(`Link brands added successfully`);
            dispatch(refreshLinkBrand(false));
          })
          .catch(function (error) {
            console.error("Error:", error);
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const onFinishEdit = () => {
    form
      .validateFields()
      .then((values) => {
        setSubmitLoading(true);
        console.log(productVendors, "productVendors");
        const payload = productVendors?.[0]?.map((brand) => ({
          brandId: brand?.item?.value,
        }));
        OrgService_.editLinkOrganisation(organisationData?.id, {
          data: payload,
        })
          .then((response) => {
            onDrawerClose();
            setRefresh((prev) => prev + 1);
            openNotification(
              "Sucess",
              "Link brands updated successfully",
              "success"
            );

            dispatch(refreshLinkBrand(false));
          })
          .catch(function (error) {
            console.error("Error:", error);
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Form
        layout={"horizontal"}
        form={form}
        name="rfq-form"
        autoComplete="off"
      >
        <Layout>
          <Content>
            {/* {!hideUpload && (
              <LinkBrandDetail
                {...props}
                resetcsvData={resetData}
                onResetDataComplete={handleResetDataComplete}
                handleUploaded={handleUploadedFile}
                submitLoading={submitLoading}
                handleDisabled={handleDisabled}
              />
            )}*/}
            {hideUpload && (
              <LinkBrandDetailEdit
                {...props}
                resetcsvData={resetData}
                onResetDataComplete={handleResetDataComplete}
                handleUploaded={handleUploadedFile}
                submitLoading={submitLoading}
                handleDisabled={handleDisabled}
                hideUpload={hideUpload}
                organisationData={organisationData}
                presetval={presetval}
                productVendors={productVendors}
                setProductVendors={setProductVendors}
              />
            )}

            {!hideUpload && (
              <ManuallyLinkBrands onDrawerClose={onDrawerClose} />
            )}
          </Content>

          <Footer>
            <Space>
              <Button
                onClick={() => {
                  onDrawerClose();
                }}
                size="small"
              >
                Cancel
              </Button>
              {/* {!hideUpload && (
                <Button type="default" onClick={handleClearData} size="small">
                  Reset
                </Button>
              )} */}
              <Button
                type="primary"
                onClick={() => (!hideUpload ? onFinish() : onFinishEdit())}
                htmlType="submit"
                size="small"
                disabled={disabled}
              >
                {`Save`}
              </Button>
            </Space>
          </Footer>
        </Layout>
      </Form>
    </>
  );
};

export default LinkBrandForm;

import React, { useState, useEffect } from "react";
import { Form, Button, Space, Modal, message, Layout } from "antd";

import { ADD, EDIT } from "../../../../constants/common.constant";
import Utils from "utils/common";
import Loading from "components/shared-components/Loading";
import EnquiryDetail from "./enquiry-detail";
import { QuestionCircleFilled } from "@ant-design/icons";
import { EnquiryService_ } from "services/Tenant/enquiry.service";
import { DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ } from "../../../../constants/DateConstant";
import moment from "moment";
import { Content, Footer } from "antd/lib/layout/layout";
import { getUploadedFileInfo } from "utils/utilities";
import { useDispatch } from "react-redux";
import { refreshEnquiryList } from "redux/actions/Enquiry";
import { useHistory } from "react-router-dom";

const EnquiryForm = (props) => {
  const {
    enquiryId,
    isDrawerView,
    presetval,
    onDrawerClose,
    setIsSubmitted,
    pageType,
  } = props;

  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [enquiryDetail, setEnquiryDetail] = useState(null);
  const [deliveryAddress_, setDeliveryAddress_] = useState(null);
  const [billlingAddress_, setBillingAddress_] = useState(null);
  const [loading, setLoading] = useState(false);
  const mode = enquiryId ? EDIT : ADD;
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (enquiryId) {
      loadFormData();
    }
  }, [presetval]);

  const loadFormData = () => {
    setLoading(true);
    EnquiryService_.getEnquirydetail(enquiryId).then((data) => {
      console.log(data);
      setEnquiryDetail(data);
      form.setFieldsValue({
        source: data?.source,
        enquiryDate: moment(data?.enquiryDate),
        dueDate: moment(data?.dueDate),
        customerRef: data?.customerRef,
        customer: data?.customer?.id,
        buyer: data?.buyer?.id,
        notes: data?.notes,
        shippingTerm: data?.shippingTerm,
      });
      setLoading(false);
    });
  };

  const onFinish = () => {
    dispatch(refreshEnquiryList(false));

    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        values.enquiryDate = `${moment(values.enquiryDate).format(
          DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ
        )}`;
        values.dueDate = `${moment(values.dueDate).format(
          DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ
        )}`;
        delete values.billto;
        delete values.deliveryto;
        values.customer = values?.customer?.value;
        values.enquiryDoc = getUploadedFileInfo(values.enquiryDoc);
        const combinedAddress = {
          ...deliveryAddress_,
          ...billlingAddress_,
        };

        

        if (mode === "EDIT") {
          EnquiryService_.updateEnquiry(enquiryId, values)
            .then((data) => {
              setIsSubmitted(true);
              message.success(`Enquiry updated successfully`);
              onDrawerClose();
              //history.push(`/app/enquiry-view/${data?.id}/enquiry`);
            })

            .finally((_) => setSubmitLoading(false));
        } else {
          values.enquiryAddress = combinedAddress;
          EnquiryService_.addEnquiry(values)
            .then((data) => {
              setIsSubmitted(true);
              form.resetFields();
              message.success(`Enquiry added successfully`);
              console.log(data, "datadatadata");
              onDrawerClose();
              history.push(`/app/enquiry-view/${data?.id}/enquiry`);
            })

            .finally((_) => setSubmitLoading(false));
        }
      })
      .catch((info) => {
        setSubmitLoading(false);
        message.error("Please enter all required field ");
      });
  };

  return (
    <>
      {loading ? (
        <Loading cover="page-center" />
      ) : (
        <Form
          layout={"horizontal"}
          form={form}
          name="enquiry-form"
          autoComplete="off"
        >
          <Layout>
            <Content>
              <EnquiryDetail
                {...props}
                form={form}
                enquiryDetail={enquiryDetail}
                setDeliveryAddress={setDeliveryAddress_}
                setBillingAddress={setBillingAddress_}
                mode={mode}
              />
            </Content>
            <Footer>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    form.validateFields().then(() => {
                      Modal.confirm({
                        title: "Confirm",
                        icon: <QuestionCircleFilled />,
                        content: "Are you sure to proceed.",
                        okText: "Yes",
                        cancelText: "No",
                        onOk: () => {
                          onFinish(true);
                        },
                      });
                    });
                  }}
                  htmlType="submit"
                  loading={submitLoading}
                  disabled={submitLoading}
                  size="small"
                >
                  {mode === ADD ? "Save" : `Save`}
                </Button>
                <Button
                  onClick={() => {
                    {
                      pageType != "DETAIL" ?? form.resetFields();
                    }
                    onDrawerClose();
                  }}
                  size="small"
                >
                  Cancel
                </Button>
              </Space>
            </Footer>
          </Layout>
        </Form>
      )}
    </>
  );
};

export default EnquiryForm;

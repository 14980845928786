import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  CUSTOMER_CONTRACT_LIST,
  CUSTOMER_CONTRACT_COUNT,
  CUSTOMER_CONTRACT_ADD,
  CUSTOMER_CONTRACT_UPDATE,
  CUSTOMER_CONTRACT_DETAIL,
  CUSTOMER_CONTRACT_DELETE,
  CUSTOMER_CONTACT_DETAIL,
  DOWNLOAD_SAMPLE_SHEET,
  CUSTOMER_CONTRACT_ADD_BULK_PRODUCTS,
  CONTRACT_PRODUCT_DETAIL,
  CUSTOMER_CONTRACT_ADD_BULK_AGREEMENT,
  CONTRACT_AGREEMENT_DETAIL,
  CONTRACT_AGREEMENT_DOC_DETAIL,
  CUSTOMER_CONTRACT_UPDATE_AGREEMENT,
  CUSTOMER_CONTRACT_DELETE_AGREEMENT,
  EXPORT_PRODUCTS,
  CUSTOMER_ORGANISATION_ADDRESS_DETAIL,
  CONTRACT_CERTIFICATE_DETAIL,
  CUSTOMER_CONTRACT_LIST_COUNT,
} = API_PATH;

export const CustomerContractService_ = {
  getCustomerContract: (filter) => {
    return fetch_({
      url: CUSTOMER_CONTRACT_LIST,
      method: "GET",
      params: filter,
    });
  },
  getCustomerContractCount: (contractId, filter) => {
    return fetch_({
      url: CUSTOMER_CONTRACT_LIST_COUNT(contractId),
      method: "GET",
      params: filter,
    });
  },
  countCustomerContract: (filter) => {
    return fetch_({
      url: CUSTOMER_CONTRACT_COUNT,
      method: "GET",
      params: filter,
    });
  },
  getContractdetail: (contractId) => {
    return fetch_({
      url: CUSTOMER_CONTRACT_DETAIL(contractId),
      method: "GET",
    });
  },
  addCustomerContract: (contract) => {
    return fetch_({
      url: CUSTOMER_CONTRACT_ADD,
      method: "POST",
      data: contract,
    });
  },

  updateCustomerContract: (contractId, contract) => {
    return fetch_({
      url: CUSTOMER_CONTRACT_UPDATE(contractId),
      method: "PUT",
      data: contract,
    });
  },
  deleteContract: (contractId) => {
    return fetch_({
      url: CUSTOMER_CONTRACT_DELETE(contractId),
      method: "DELETE",
    });
  },
  getCustomerContact: (organisationCustomersId) => {
    return fetch_({
      url: CUSTOMER_CONTACT_DETAIL(organisationCustomersId),
      method: "GET",
    });
  },
  getCustomerCountryAndCityAddress: (organisationCustomersId) => {
    return fetch_({
      url: CUSTOMER_ORGANISATION_ADDRESS_DETAIL(organisationCustomersId),
      method: "GET",
    });
  },
  downloadSampleSheet: () => {
    return fetch_({
      url: DOWNLOAD_SAMPLE_SHEET,
      method: "GET",
    });
  },

  addBulkProducts: (contractId, formData) => {
    return fetch_({
      url: CUSTOMER_CONTRACT_ADD_BULK_PRODUCTS(contractId),
      method: "POST",
      data: formData,
    });
  },

  getContractProduct: (contractId, filter) => {
    return fetch_({
      url: CONTRACT_PRODUCT_DETAIL(contractId),
      method: "GET",
      params: filter,
    });
  },
  getContractCertificate: (contractId) => {
    return fetch_({
      url: CONTRACT_CERTIFICATE_DETAIL(contractId),
      method: "GET",
    });
  },

  addBulkAgreement: (contractId, formData) => {
    return fetch_({
      url: CUSTOMER_CONTRACT_ADD_BULK_AGREEMENT(contractId),
      method: "POST",
      data: formData,
    });
  },
  getContractAgreement: (contractId) => {
    return fetch_({
      url: CONTRACT_AGREEMENT_DETAIL(contractId),
      method: "GET",
    });
  },

  getContractAgreementDetails: (contractId, agreementId) => {
    return fetch_({
      url: CONTRACT_AGREEMENT_DOC_DETAIL(contractId, agreementId),
      method: "GET",
    });
  },
  updateContractAgreement: (contractId, agreementId, agreement) => {
    return fetch_({
      url: CUSTOMER_CONTRACT_UPDATE_AGREEMENT(contractId, agreementId),
      method: "PUT",
      data: agreement,
    });
  },

  deleteAgreementDoc: (contractId, agreementId) => {
    return fetch_({
      url: CUSTOMER_CONTRACT_DELETE_AGREEMENT(contractId, agreementId),
      method: "DELETE",
    });
  },
  exportProducts: (contractId) => {
    return fetch_({
      url: EXPORT_PRODUCTS(contractId),
      method: "GET",
    });
  },
};

import {
  OPEN_ENQUIRY_TAB_IN_ENQUIRY_MENU,
  REFRESH_ENQUIRY_LIST,
  SHOW_ADD_EDIT_ENQUIRY_DRAWER,
} from "redux/constants/Enquiry";

const initialState = {
  showAddEditEnquiryDrawer: false,
  editableEnquiry: null,
  refreshEnquiryList: false,
  openEnquiryTabInEnquiryMenu: false,
  tabKeyName: "",
};
export const enquiry = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_ADD_EDIT_ENQUIRY_DRAWER:
      return {
        ...state,
        showAddEditEnquiryDrawer: data.showAddEditEnquiryDrawer,
        editableEnquiry: data.editableEnquiry,
      };
    case REFRESH_ENQUIRY_LIST:
      return {
        ...state,
        refreshEnquiryList: data.refreshEnquiryList,
      };
    case OPEN_ENQUIRY_TAB_IN_ENQUIRY_MENU:
      return {
        ...state,
        openEnquiryTabInEnquiryMenu: data.openEnquiryTabInEnquiryMenu,
        tabKeyName: data.tabKeyName,
      };
    default:
      return state;
  }
};

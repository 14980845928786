import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { ProductService_ } from "services/Tenant/product.service";
import { reactSelectCustomStyle } from "styles/react-select-style";
import { PlusCircleOutlined } from "@ant-design/icons";

export default function ProductSelect({
  onChange,
  clearOnSelect = false,
  inputlength,
}) {
  const [input, setInput] = useState("");
  const [value, setValue] = useState(null);
  const isInputLengthValid = input.length >= inputlength;
  const fetchProducts = async (input) => {
    if (!input || input.length < inputlength) return [];
    return ProductService_.getProduct({ search: input }).then((data) => {
      if (data.length <= 0) {
        const dataval = [
          {
            name: (
              <>
                <b>{input}</b> does not exist <PlusCircleOutlined />
              </>
            ),
            id: 1,
          },
        ];
        const productOptions = dataval.map((o) => ({
          label: o.name,
          value: o.id,
        }));
        return productOptions;
      } else {
        const productOptions = data.map((o) => ({
          label: o.name,
          value: o.id,
        }));
        return productOptions;
      }
    });
  };

  return clearOnSelect ? (
    <AsyncSelect
      value={value}
      inputValue={input}
      onInputChange={(value, action) => {
        if (action.action === "menu-close") setInput("");
        else if (action.action === "input-change") setInput(value);
      }}
      styles={reactSelectCustomStyle}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        NoOptionsMessage: () => "",
      }}
      defaultOptions
      placeholder={`Type at least ${inputlength} characters`}
      loadOptions={fetchProducts}
      onChange={(e) => {
        onChange(e);
        setValue(null);
        setInput("");
      }}
      menuIsOpen={isInputLengthValid}
    />
  ) : (
    <AsyncSelect
      styles={reactSelectCustomStyle}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        NoOptionsMessage: () => "",
      }}
      defaultOptions
      placeholder={`Type at least ${inputlength} characters`}
      loadOptions={fetchProducts}
      onChange={(e) => {
        onChange(e);
      }}
      menuIsOpen={isInputLengthValid}
    />
  );
}

import {
  REFRESH_BULK_ORGANISATION,
  SHOW_ADD_EDIT_BULK_ORGANISATION,
} from "redux/constants/BulkOrganisation";

const initialState = {
  showAddEditBulkOrganisationDrawer: false,
  editableBulkOrganisation: null,
  refreshBulkOrganisation: false,
};
export const bulkOrganisation = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_ADD_EDIT_BULK_ORGANISATION:
      return {
        ...state,
        showAddEditBulkOrganisationDrawer:
          data.showAddEditBulkOrganisationDrawer,
        editableBulkOrganisation: data.editableBulkOrganisation,
      };
    case REFRESH_BULK_ORGANISATION:
      return {
        ...state,
        refreshBulkOrganisation: data.refreshBulkOrganisation,
      };
    default:
      return state;
  }
};

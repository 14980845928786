import React from "react";
import { Row, Col, Form, Card } from "antd";
import RichEditor from "components/shared-components/RichEditor";
import TextArea from "antd/lib/input/TextArea";
import Loading from "components/shared-components/Loading";
import { ROW_GUTTER } from "constants/ThemeConstant";

const DetailTab = (props) => {
  const DescriptionItem = ({ title, content }) => (
    <div className="view-description-item">
      <p className="view-description-item-heading">{title}:</p>
      <p className="view-description-item-value">{content}</p>
    </div>
  );
  return (
    !!props?.enquiryProductdetails && (
      <>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Brand"
              content={props?.enquiryProductdetails[0]?.product?.brand?.name}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="UOM"
              content={props?.enquiryProductdetails[0]?.product?.uom?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Qty"
              content={props?.enquiryProductdetails[0]?.quantity}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem
              title="CustRef"
              content={props?.enquiryProductdetails[0]?.customerReference}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Replacement"
              content={
                props?.enquiryProductdetails[0]?.product?.replacementProduct
                  ? "Yes"
                  : "N/A"
              }
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col span={12}>
            <Card className="custom-view-area-for-rich-editor-text-view">
              <Form.Item
                label="Product Description"
                className="readonly-editor mr-3"
              >
                {props?.enquiryProductdetails[0]?.product?.description ? (
                  <RichEditor
                    defaultValue={
                      props?.enquiryProductdetails[0]?.product?.description
                    }
                    readOnly={true}
                  />
                ) : (
                  "N/A"
                )}
              </Form.Item>
            </Card>
          </Col>

          <Col span={12}>
            <Card className="custom-view-area-for-rich-editor-text-view">
              <Form.Item label="Cust. Description" className="readonly-editor">
                {props?.isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {props?.custProductdetails?.description ? (
                      <RichEditor
                        defaultValue={props?.custProductdetails?.description}
                        readOnly={true}
                      />
                    ) : (
                      "N/A"
                    )}
                  </>
                )}
              </Form.Item>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DescriptionItem
              title="Product Note"
              content={props?.productDetails?.userNote ?? "N/A"}
            />
          </Col>

          <Col span={12}>
            <DescriptionItem title="Enquiry Note" content={"N/A"} />
          </Col>
        </Row>
      </>
    )
  );
};

export default DetailTab;

import {
  REFRESH_FINALISE_ORDER_LIST,
  SHOW_ADD_EDIT_FINALISE_ORDER_DRAWER,
} from "redux/constants/FinaliseOrder";

export const showAddEditFinaliseOrderDrawer = (
  visibility = false,
  selectedOption = null,
  quotationId,
  editableFinaliseOrder = null,
  customerId = {}
) => {
  return {
    type: SHOW_ADD_EDIT_FINALISE_ORDER_DRAWER,
    data: {
      showAddEditFinaliseOrderDrawer: visibility,
      selectedOption,
      quotationId,
      editableFinaliseOrder,
      customerId: customerId,
    },
  };
};

export const refreshFinaliseOrderList = (refresh = false) => {
  return {
    type: REFRESH_FINALISE_ORDER_LIST,
    data: {
      refreshFinaliseOrderList: refresh,
    },
  };
};

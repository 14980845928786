import { Menu } from "antd"
import Flex from "../Flex"

export default function ActionDropdownMenu({
    actions = []
}) {

    return (
        <>
            {actions.map(action => {
                return (
                    <Menu.Item onClick={action.onClick}>
                        <Flex alignItems="center">
                            {action.icon}
                            <span className="ml-2">{action.label}</span>
                        </Flex>
                    </Menu.Item>
                )
            })}
        </>
    )
}
import {
  REFRESH_BULK_ORGANISATION,
  SHOW_ADD_EDIT_BULK_ORGANISATION,
} from "redux/constants/BulkOrganisation";

export const showAddEditBulkOrganisation = (
  visibility = false,
  editableBulkOrganisation = null
) => {
  return {
    type: SHOW_ADD_EDIT_BULK_ORGANISATION,
    data: {
      showAddEditBulkOrganisationDrawer: visibility,
      editableBulkOrganisation,
    },
  };
};

export const refreshBulkOrganisation = (refresh = false) => {
  return {
    type: REFRESH_BULK_ORGANISATION,
    data: {
      refreshBulkOrganisation: refresh,
    },
  };
};

import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const { UOM_LIST, UOM_COUNT, UOM_ADD, UOM_UPDATE, UOM_DETAIL, UOM_DELETE } =
  API_PATH;

export const UomService_ = {
  getUom: (filter) => {
    return fetch_({
      url: UOM_LIST,
      method: "GET",
      params: filter,
    });
  },
  countUom: (filter) => {
    return fetch_({
      url: UOM_COUNT,
      method: "GET",
      params: filter,
    });
  },
  getUomdetail: (uomId) => {
    return fetch_({
      url: UOM_DETAIL(uomId),
      method: "GET",
    });
  },
  addUom: (uom) => {
    return fetch_({
      url: UOM_ADD,
      method: "POST",
      data: uom,
    });
  },
  updateUom: (uomId, uom) => {
    return fetch_({
      url: UOM_UPDATE(uomId),
      method: "PUT",
      data: uom,
    });
  },
  deleteUom: (uomId) => {
    return fetch_({
      url: UOM_DELETE(uomId),
      method: "DELETE",
    });
  },
};

import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { showNotesDrawer } from "redux/actions/Notes";
import NotesForm from "views/app-views/notes";

export default function NotesDrawer() {
  const disptach = useDispatch();
  const notes = useSelector((state) => state.notes);
  console.log(notes, "notesnotes");
  return (
    <>
      <Drawer
        title={`Notes`}
        width={"80%"}
        onClose={() => {
          disptach(showNotesDrawer(false));
        }}
        visible={notes?.showNotesDrawer}
        className="custom-drawer"
      >
        {notes?.showNotesDrawer && (
          <NotesForm
            setIsSubmitted={() => {}}
            isDrawerView={true}
            onDrawerClose={() => {
              disptach(showNotesDrawer(true));
            }}
            setIsNext={() => {
              disptach(showNotesDrawer(true));
            }}
          />
        )}
      </Drawer>
    </>
  );
}

import {
  REFRESH_RECEIVEPRODUCT_LIST,
  SHOW_ADD_EDIT_RECEIVEPRODUCT_DRAWER,
} from "redux/constants/receiveProduct";

export const showAddEditReceiveProductDrawer = (
  visibility = false,
  purchaseOrderId = null,
  editableReceiveProduct = null
) => {
  return {
    type: SHOW_ADD_EDIT_RECEIVEPRODUCT_DRAWER,
    data: {
      showAddEditReceiveProductDrawer: visibility,
      purchaseOrderId: purchaseOrderId,
      editableReceiveProduct,
    },
  };
};

export const refreshReceiveProductList = (refresh = false) => {
  return {
    type: REFRESH_RECEIVEPRODUCT_LIST,
    data: {
      refreshReceiveProductList: refresh,
    },
  };
};

import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Input, Row, Col, Card, Form, Upload, Select, Button } from "antd";
import { BrandService_ } from "services/Tenant/brand.service";
import { useForceUpdate } from "hooks/useForceUpdate";
import { ROW_GUTTER } from "constants/ThemeConstant";
import Loading from "components/shared-components/Loading";

const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;

const CustomerOrderDetail = ({
  props,
  setSelectedImageFile,
  brandDetail,
  form,
  mode,
}) => {
  const forceUpdate = useForceUpdate();
  const [shouldRenderUpload, setShouldRenderUpload] = useState(false);
  const [showQuotationInput, setShowQuotationInput] = useState(false);
  const [showContractInput, setShowContractInput] = useState(false);
  const [showPreviousOrderInput, setShowPreviousOrderInput] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldRenderUpload(true);
    }, 1200);

    return () => clearTimeout(timeout);
  }, []);
  const sourceOption = [
    { label: "Quotation", value: "Quotation" },
    { label: "Contract", value: "Contract" },
    { label: "Previous Order", value: "PreviousOrder" },
  ];

  const handleSourceChange = (value) => {
    if (value === "Quotation") {
      setShowQuotationInput(true);
      setShowContractInput(false);
      setShowPreviousOrderInput(false);
    } else if (value === "Contract") {
      setShowContractInput(true);
      setShowPreviousOrderInput(false);
      setShowQuotationInput(false);
    } else if (value === "PreviousOrder") {
      setShowContractInput(false);
      setShowPreviousOrderInput(true);
      setShowQuotationInput(false);
    } else {
      setShowContractInput(false);
      setShowPreviousOrderInput(false);
      setShowQuotationInput(false);
    }
  };

  return !shouldRenderUpload ? (
    <Loading />
  ) : (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={24}>
          <Form.Item
            name="source"
            label="Source"
            rules={[{ required: true, message: "Please select a source" }]}
          >
            <Select options={sourceOption} onChange={handleSourceChange} />
          </Form.Item>
        </Col>
      </Row>
      {showQuotationInput && (
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item name="quotationId" label="Quotation ID">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}
      {showContractInput && (
        <>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name="customer" label="Customer">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name="customerId" label="Contract ID">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      {showPreviousOrderInput && (
        <>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name="customer" label="Customer">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name="orderId" label="Order ID">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CustomerOrderDetail;

import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Divider,
  Tooltip,
  Popconfirm,
  Select,
  PageHeader,
} from "antd";
import { ROW_GUTTER } from "constants/ThemeConstant";
import {
  FileExcelOutlined,
  InboxOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import DynamicUpload from "components/shared-components/DynamicUpload";
import {
  getUploadIconForCSV,
  getcountry,
  getemail,
  gettel,
  text,
} from "utils/utilities";
import Text from "antd/lib/typography/Text";
import { BrandService_ } from "services/Tenant/brand.service";
import { DEFAULT_PAGE_SIZE } from "constants/common.constant";
import { OrgService_ } from "services/Tenant/organisation.service";
import Loading from "components/shared-components/Loading";
import Dragger from "antd/lib/upload/Dragger";

const LinkBrandDetail = ({
  form,
  resetcsvData,
  handleDisabled,
  onResetDataComplete,
  handleUploaded,
}) => {
  const [orgList, setOrgList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [invalidData, setInvalidData] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [brandOptionsData, setBrandOptionsData] = useState([]);
  const [refreshDragger, setRefreshDragger] = useState(0);

  useEffect(() => {
    if (resetcsvData) {
      setCSVData([]);
      setInvalidData([]);
      onResetDataComplete();
      handleDisabled(true);
    }
  }, [resetcsvData]);

  useEffect(() => {
    setCSVData([]);
    setInvalidData([]);
    handleDisabled(true);
  }, []);

  useEffect(() => {
    setCSVData([]);
    setInvalidData([]);
    handleDisabled(true);
  }, []);
  useEffect(() => {
    if (invalidData?.length) {
      handleDisabled(true);
    }
  }, [handleDisabled, invalidData?.length]);

  const findInvalidData = (response) => {
    const invalidData = response.filter((item) => item?.invalidReason);
    const validData = response.filter((item) => !item.invalidReason);
    return {
      invalidData,
      validData,
    };
  };

  const fileHandler = (info) => {
    if (info.fileList && info.fileList.length > 0) {
      setLoading(true);
      const file = info.fileList[0].originFileObj;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("confirmed", "0");
      formData.append("uploadType", "ADD_ORGANISATION_BRANDS");
      handleUploaded(file);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      OrgService_.addBulkLinkOrganisation(formData, config)
        .then(function (response) {
          setTotalRecords(response);
          const { invalidData, validData } = findInvalidData(response);
          if (invalidData.length > 0) {
            setInvalidData(invalidData);
          }
          setCSVData(response);
          handleDisabled(false);
        })
        .catch(function (error) {
          console.error("Error:", error);
          setRefreshDragger((prev) => prev + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const downloadProducts = () => {
    const fullCsvData = convertToCSV(csvData);
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(
      new Blob([fullCsvData], { type: "text/csv" })
    );
    downloadLink.setAttribute("download", `Non-Matching-data.csv`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    return `${header}\n${rows.join("\n")}`;
  };

  const fetchData = async (reset, loadedFilter) => {
    OrgService_.getOrganisation({
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
      search: "",
      orderBy: "createdAt",
      order: "DESC",
      where: {},
    }).then((res) => {
      setOrgList(res);
    });
    // .finally((_) => setIsLoading(false));
  };
  useEffect(() => {
    fetchData(true);
  }, []);
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const fetchEnquiry = async (input) => {
    // if (!input || input.length < 3) return [];
    return BrandService_.getBrand({
      search: input,
      limit: 1000,
      where: {
        "al.is_active": true,
      },
    })
      .then((data) => {
        const brandOptions = data.map((o) => ({
          label: o.name,
          value: o.id,
        }));
        setBrandOptionsData(brandOptions);

        return brandOptions;
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchEnquiry();
  }, []);

  const handleBrandSelection = (id, ids, values) => {
    setProductVendors((prev) => {
      const newValues = [...prev];
      newValues[id] = values.map((value) => ({
        item: value,
        productIndex: id,
      }));

      return newValues;
    });
  };

  const [productVendors, setProductVendors] = useState(
    Array(csvData.length).fill([])
  );
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const tableColumns = [
    {
      title: "vendor",
      dataIndex: "organisationName",
      render: (organisationName) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: organisationName }}>
          {text(organisationName)}
        </Text>
      ),
    },
    {
      title: "brands",
      dataIndex: "brandName",
      render: (brandName, row, index) => {
        return (
          <>
            {/* <Select
            mode="multiple"
            showSearch
            onSearch={(e) => fetchEnquiry(e)}
            filterOption={filterOption}
            // key={index}
            maxTagCount={"responsive"}
            style={{ width: 300 }}
            placeholder="Please select"
            loading={loading}
            // onChange={fetchEnquiry}
            onChange={(e, value) => handleBrandSelection(index, e, value)}
            options={brandOptionsData}
            // onFocus={(e) => setBrandOptionsData([])}
            defaultValue={brandName}
          /> */}
            <Text>{brandName}</Text>
          </>
        );
      },
    },
    {
      title: "invalid reason",
      dataIndex: "invalidReason",
      align: "right",
      render: (invalidReason, record) => (
        <Text style={{ width: 150 }} tooltip={invalidReason}>
          {invalidReason}
        </Text>
      ),
    },
  ];
  const invalidColumns = [
    {
      title: "vendor",
      dataIndex: "organisationName",
      render: (organisationName) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: organisationName }}>
          {text(organisationName)}
        </Text>
      ),
    },
    {
      title: "brands",
      dataIndex: "brandName",
      render: (brandName, row, index) => {
        return (
          <>
            <Select
              mode="multiple"
              showSearch
              onSearch={(e) => fetchEnquiry(e)}
              filterOption={filterOption}
              // key={index}
              maxTagCount={"responsive"}
              style={{ width: 300 }}
              placeholder="Please select"
              loading={loading}
              // onChange={fetchEnquiry}
              onChange={(e, value) => handleBrandSelection(index, e, value)}
              options={brandOptionsData}
              // onFocus={(e) => setBrandOptionsData([])}
              defaultValue={brandName}
            />
          </>
        );
      },
    },
  ];
  return loading || submitLoading ? (
    <Loading cover="page-center" />
  ) : (
    <>
      {invalidData.length > 0 && (
        <PageHeader
          className="custom-header"
          ghost={false}
          title=""
          subTitle=""
          extra={[
            <Tooltip title={"Download Invalid Data"}>
              <Button
                type="link"
                style={{ color: "red" }}
                icon={<DownloadOutlined />}
                title="Download"
                onClick={downloadProducts}
              />
            </Tooltip>,
          ]}
        />
      )}
      {!csvData.length && (
        <>
          <div className="hide-name hide-delete-icon custom-upload">
            <Dragger
              className="custom-upload custom-upload-drag"
              name="file"
              multiple={false}
              accept=".csv"
              beforeUpload={() => false}
              key={refreshDragger}
              onPreview={(file) => {
                window.open(
                  file?.response?.data?.publicUrl,
                  "_blank",
                  "noreferrer"
                );
              }}
              progress={{
                strokeColor: {
                  "0%": "#108ee9",
                  "100%": "#87d068",
                },
                strokeWidth: 3,
                format: (percent) =>
                  percent && `${parseFloat(percent.toFixed(2))}%`,
              }}
              onChange={(info) => {
                fileHandler(info);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">please upload only .csv files.</p>
            </Dragger>
          </div>
        </>
      )}
      {csvData.length > 0 && (
        <div>
          {invalidData?.length > 0 && (
            <>
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "20px",
                  }}
                >
                  <div class="ant-drawer-title">
                    Invalid Brand(s): {invalidData.length}/{totalRecords.length}
                  </div>
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    icon={<DownloadOutlined />}
                    title="Download"
                    onClick={downloadProducts}
                  />
                </div>
              </>
            </>
          )}{" "}
          <div className="table-responsive">
            <Table
              className="custom-table"
              size="small"
              // loading={isLoading}
              columns={tableColumns}
              dataSource={[...csvData, ...invalidData]}
              rowKey="id"
              pagination={false}
              rowClassName={(record) =>
                record?.invalidReason ? "erronous-row" : ""
              }
            />
          </div>
        </div>
      )}
      {/* {invalidData.length > 0 && (
        <>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} md={24}>
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "20px",
                  }}
                >
                  <div class="ant-drawer-title">
                    Incongruous data: {invalidData.length}/{totalRecords.length}
                  </div>
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    title="Download"
                    onClick={downloadProducts}
                  />
                </div>
                <Table
                  columns={invalidColumns}
                  className="custom-table"
                  dataSource={invalidData}
                  pagination={false}
                  size="small"
                />
              </>
            </Col>
          </Row>
        </>
      )} */}
    </>
  );
};

export default LinkBrandDetail;

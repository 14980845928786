import { REFRESH_CUSTOMERORDER_LIST, SHOW_ADD_EDIT_CUSTOMERORDER_DRAWER } from "redux/constants/CustomerOrder"

export const showAddEditCustomerOrderDrawer = (visibility = false, editableCustomerOrder = null) => {
    return {
        type: SHOW_ADD_EDIT_CUSTOMERORDER_DRAWER,
        data: {
            showAddEditCustomerOrderDrawer: visibility,
            editableCustomerOrder
        }
    }
}

export const refreshCustomerOrderList = (refresh = false) => {
    return {
        type: REFRESH_CUSTOMERORDER_LIST,
        data: {
            refreshCustomerOrderList: refresh,
        }
    }
}
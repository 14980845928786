import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Menu,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import PartNumberSelect from "components/shared-components/ContractProductSelect";

const AddContractProductsDrawer = ({
  contractDetails,
  contractProducts,
  setOpenAddContractProductsDrawer,
  setProductList,
  productList,
}) => {
  const [form] = Form.useForm();
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editedProducts, setEditedProducts] = useState({});

  const [selectedProducts, setSelectedProducts] = useState(
    () => productList || []
  );
  const [products, setProducts] = useState(null);
  const handleRowSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: handleRowSelectionChange,
  };
  const DescriptionItem = ({ title, content }) => (
    <div className="view-description-item">
      <p className="view-description-item-heading">{title}:</p>
      <p className="view-description-item-value">{content}</p>
    </div>
  );
  const addProducts = () => {
    const selectedProduct = contractProducts?.filter(
      (c) => c?.product?.partNumber === products?.product?.partNumber
    );
    setSelectedProducts((prev) =>
      _.uniqBy([...prev, ...selectedProduct], "id")
    );
  };
  const handleChange = (productId, field, newValue) => {
    const updatedProducts = selectedProducts.map((product) => {
      if (product.id === productId) {
        return {
          ...product,
          [field]: newValue,
        };
      }
      return product;
    });

    setSelectedProducts(updatedProducts);

    setEditedProducts((prevEditedProducts) => ({
      ...prevEditedProducts,
      [productId]: {
        ...prevEditedProducts[productId],
        [field]: newValue,
      },
    }));
  };
  const tableColumns = [
    {
      title: "Customer Ref",
      dataIndex: "sku",
      render: (sku, row) => (
        <Text
          style={{ width: 100 }}
          ellipsis={{ tooltip: row?.customerReference }}
        >
          {row?.customerReference}
        </Text>
      ),
    },
    {
      title: "SKU",
      dataIndex: "sku",
      render: (sku, row) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: row?.product?.sku }}>
          {row?.product?.sku}
        </Text>
      ),
    },
    {
      title: "P/N.",
      dataIndex: "part",
      render: (part, row) => (
        <Text
          style={{ width: 100 }}
          ellipsis={{ tooltip: row?.product?.partNumber }}
        >
          {row?.product?.partNumber}
        </Text>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (name, row) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: row?.product?.name }}>
          {row?.product?.name}
        </Text>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      render: (brand, row) => (
        <Text
          style={{ width: 100 }}
          ellipsis={{ tooltip: row?.product?.brand?.name }}
        >
          {row?.product?.brand?.name}
        </Text>
      ),
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      render: (quantity, row) => (
        <Input
          style={{ width: 100 }}
          value={editedProducts[row.id]?.quantity || quantity}
          onChange={(e) => handleChange(row.id, "quantity", e.target.value)}
        />
      ),
    },
    {
      title: "Del Loc",
      dataIndex: "deliveryLocation",
      render: (deliveryLocation, row) => (
        <Input
          style={{ width: 100 }}
          value={editedProducts[row.id]?.deliveryLocation || deliveryLocation}
          onChange={(e) =>
            handleChange(row.id, "deliveryLocation", e.target.value)
          }
        />
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "actions",
    //   render: (_, row) => {
    //     return (
    //       <div className="text-right">
    //         <EllipsisDropdown
    //           menu={
    //             <Menu>
    //               <ActionDropdownMenu
    //                 actions={[
    //                   {
    //                     label: "Remove",
    //                   },
    //                 ]}
    //               />
    //             </Menu>
    //           }
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    form.setFieldsValue({
      customer: contractDetails?.customer?.organisation?.name,
    });
  }, []);
  return (
    <div>
      {" "}
      <Form
        layout={"horizontal"}
        form={form}
        name="enquiry-form"
        autoComplete="off"
      >
        <Layout>
          <Content>
            <Col span={12}>
              <DescriptionItem
                title="Customer"
                content={contractDetails?.customer?.organisation?.name}
              />
            </Col>
            <Col span={12}>
              <DescriptionItem
                title="Contract ID"
                content={contractDetails?.contractCode}
              />
            </Col>
            <Row>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="partnumber" label="P/n">
                  {console.log(
                    contractDetails?.id,
                    "contractDetails?.idcontractDetails?.idcontractDetails?.id"
                  )}
                  <PartNumberSelect
                    value={contractDetails?.id}
                    key={contractDetails?.id}
                    onChange={(selectedOption) => {
                      setProducts(selectedOption);
                    }}
                    limit={10}
                    inputlength={3}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={4}>
                <div className="text-right">
                  <Button onClick={addProducts} type="primary" size="small">
                    Add
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="table-responsive">
              <Table
                className="custom-table"
                size="small"
                //   loading={isLoading}
                // rowSelection={{
                //   type: selectionType,
                //   ...rowSelection,
                // }}
                columns={tableColumns}
                dataSource={selectedProducts}
                rowKey="id"
                pagination={false}
                onChange=""
              />
            </div>
          </Content>
          <Footer>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  setProductList(selectedProducts);
                  setOpenAddContractProductsDrawer(false);
                }}
                htmlType="submit"
                size="small"
              >
                Save
              </Button>

              <Button
                onClick={() => {
                  setOpenAddContractProductsDrawer(false);
                }}
                size="small"
              >
                Cancel
              </Button>
            </Space>
          </Footer>
        </Layout>
      </Form>
    </div>
  );
};

export default AddContractProductsDrawer;

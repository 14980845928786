import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  ORGANISATION_ADDRESS_LIST,
  ORGANISATION_ADDRESS_COUNT,
  ORGANISATION_ADDRESS_REGISTER,
  ORGANISATION_ADDRESS_UPDATE,
  ORGANISATION_ADDRESS_DETAIL,
  ORGANISATION_ADDRESS_DELETE,
} = API_PATH;

export const OrgAddressService_ = {
  getOrganisationaddress: (filter) => {
    return fetch_({
      url: ORGANISATION_ADDRESS_LIST,
      method: "GET",
      params: filter,
    });
  },
  countOrganisationaddress: (filter) => {
    return fetch_({
      url: ORGANISATION_ADDRESS_COUNT,
      method: "GET",
      params: filter,
    });
  },
  getOrganisationaddressdetail: (organisationAddressId) => {
    return fetch_({
      url: ORGANISATION_ADDRESS_DETAIL(organisationAddressId),
      method: "GET",
    });
  },
  addOrganisationaddress: (organisation) => {
    return fetch_({
      url: ORGANISATION_ADDRESS_REGISTER,
      method: "POST",
      data: organisation,
    });
  },
  updateOrganisationaddress: (organisationAddressId, organisationaddress) => {
    return fetch_({
      url: ORGANISATION_ADDRESS_UPDATE(organisationAddressId),
      method: "PUT",
      data: organisationaddress,
    });
  },
  deleteOrganisationaddress: (organisationAddressId) => {
    return fetch_({
      url: ORGANISATION_ADDRESS_DELETE(organisationAddressId),
      method: "DELETE",
    });
  },
};

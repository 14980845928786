import {
  GET_CURRENT_VIEW_ENQUIRY_PRODUCT_TAB,
  SHOW_ADD_EDIT_VIEW_ENQUIERY_DRAWER,
} from "redux/constants/ViewEnquiryProduct";

export const showAddEditViewEnquiryProductDrawer = (
  visibility = false,
  enquiryId,
  enquiryProductId,
  productId,
  tab
) => {
  return {
    type: SHOW_ADD_EDIT_VIEW_ENQUIERY_DRAWER,
    data: {
      showAddEditViewEnquiryProductDrawer: visibility,
      enquiryId,
      enquiryProductId,
      productId,
      tab,
    },
  };
};

import { Button, Input, Layout, Space, Table } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import { BrandService_ } from "services/Tenant/brand.service";
import { text } from "utils/utilities";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";

import Loading from "components/shared-components/Loading";
const AddBrandsDrawer = ({
  setSelectedVendors,
  editData,
  onDrawerClose,
  selectedVendors,
  brandOptionsData,
  setBrandOptionsData,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [preSelectedBrands, setPreSelectedBrands] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  const filteredBrandOptions = brandOptionsData?.filter((option) =>
    option?.label?.toLowerCase().includes(searchedText?.toLowerCase())
  );
  console.log(selectedVendors, "selectedVendorsselectedVendorsselectedVendors");
  const fetchBrandsBasedOnOrganisation = async () => {
    selectedVendors.map((vendor) => {
      if (vendor?.value === editData?.value) {
        if (!vendor?.recallBrandsApi) {
          setLoading(true);

          return BrandService_.getAssignedBrandsBasedOnOrganisation(
            editData?.organisation
          )
            .then((data) => {
              setPreSelectedBrands(data);
              // setSelectedBrands((prev) => [
              //   ...prev,
              //   ...(data?.map((b) => ({
              //     value: b?.brand?.id,
              //     label: b?.brand?.name,
              //   })) || []),
              // ]);
            })
            .finally(() => setLoading(false));
        }
      }
    });
  };

  useEffect(() => {
    fetchBrandsBasedOnOrganisation();
  }, [editData]);

  const onFinish = () => {
    setSelectedVendors((prevSelectedVendors) => {
      const updatedVendors = prevSelectedVendors.map((vendor) => {
        if (vendor?.value === editData?.value) {
          return {
            ...vendor,
            edited: true,
            recallBrandsApi: true,
            selectedBrands:
              selectedBrands?.length > 0
                ? selectedBrands?.map((brand) => ({
                    label: brand?.label,
                    value: brand?.value,
                  }))
                : editData?.selectedBrands?.map((brand) => ({
                    label: brand?.label,
                    value: brand?.value,
                  })),
          };
        }
        return vendor;
      });
      return updatedVendors;
    });
    onDrawerClose();
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setIsDirty(true);
      console.log(selectedRows, "selectedRowsselectedRows");
      setSelectedBrands(selectedRows);
    },
  };
  const tableBrandColumns = [
    {
      title: "brands",
      dataIndex: "label",
      width: 300,
      render: (label, row, index) => (
        <Text ellipsis={{ tooltip: label }}>{text(label)}</Text>
      ),
    },
  ];
  return (
    <>
      <Layout>
        <Content>
          <div
            className="table-responsive decrease-table-width-with-input-fields"
            style={{ marginBottom: 14, marginTop: 10 }}
          >
            <Input
              placeholder="Search Brands"
              prefix={<SearchOutlined size="small" />}
              onChange={(e) => setSearchedText(e.currentTarget.value)}
              style={{
                padding: 5,
                width: 300,
                marginLeft: 12,
                marginBottom: 10,
              }}
            />

            {loading ? (
              <Loading />
            ) : (
              <div className="custom-table-wrapper">
                <Table
                  className="custom-table editable-table remove-hovertable"
                  size="small"
                  loading={loading}
                  columns={tableBrandColumns}
                  showHeader={false}
                  dataSource={filteredBrandOptions.map((item, index) => ({
                    ...item,
                    key: index,
                    column:
                      index < Math.ceil(filteredBrandOptions.length / 2)
                        ? 1
                        : 2,
                  }))}
                  rowKey="value"
                  pagination={false}
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                    preserveSelectedRowKeys: true,
                    defaultSelectedRowKeys: _.uniqBy([
                      ...(editData?.selectedBrands?.map(
                        (brand) => brand?.value
                      ) || []),
                      ...(preSelectedBrands?.map((b) => b?.brand?.id) || []),
                    ]),
                    hideSelectAll: true,
                  }}
                />
              </div>
            )}
          </div>
        </Content>
        <Footer>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                onFinish();
              }}
              htmlType="submit"
              //   loading={submitLoading}
              disabled={!isDirty}
              size="small"
            >
              {`Save`}
            </Button>
            <Button
              onClick={() => {
                onDrawerClose();
                // form.resetFields();
              }}
              size="small"
            >
              Cancel
            </Button>
          </Space>
        </Footer>
      </Layout>
    </>
  );
};

export default AddBrandsDrawer;

import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  RFQ_ADD,
  RFQ_Bulk_ADD,
  RFQ_LIST,
  RFQ_COUNT,
  RFQ_DETAIL,
  RFQ_UPDATE,
  RFQ_REVISION_ADD,
  RFQ_FREIGHTS,
  RFQ_FREIGHTS_ADD,
  ADD_BULK_RFQ,
  RFQ_BULK,
  BULK_RFQ_EXCEL_DOWNLOAD,
  BULK_RFQ_UPLOAD,
  SENT_RFQS,
  SENT_RFQ,
  GET_SENT_RFQS,
  SEND_FOLLOWUPS,
} = API_PATH;

export const RfqService_ = {
  getRfq: (filter) => {
    return fetch_({
      url: RFQ_LIST,
      method: "GET",
      params: filter,
    });
  },
  addBulkRfqData: (formData) => {
    return fetch_({
      url: RFQ_BULK(),
      method: "POST",
      data: formData,
    });
  },
  addRfq: (rfqproduct) => {
    return fetch_({
      url: RFQ_ADD,
      method: "POST",
      data: rfqproduct,
    });
  },

  addBulkRfq: (rfqproduct) => {
    return fetch_({
      url: RFQ_Bulk_ADD,
      method: "POST",
      data: rfqproduct,
    });
  },
  countRfq: (filter) => {
    return fetch_({
      url: RFQ_COUNT,
      method: "GET",
      params: filter,
    });
  },
  addRfqFreights: (rfqId, rfqproduct) => {
    return fetch_({
      url: RFQ_FREIGHTS_ADD(rfqId),
      method: "POST",
      data: rfqproduct,
    });
  },

  getRfqFreights: (rfqId) => {
    return fetch_({
      url: RFQ_FREIGHTS(rfqId),
      method: "GET",
    });
  },
  getRfqdetail: (rfqId) => {
    return fetch_({
      url: RFQ_DETAIL(rfqId),
      method: "GET",
    });
  },
  updateRfq: (rfqId, rfq) => {
    return fetch_({
      url: RFQ_UPDATE(rfqId),
      method: "PUT",
      data: rfq,
    });
  },
  addRfqRevision: (rfqId, rfqdata) => {
    return fetch_({
      url: RFQ_REVISION_ADD(rfqId),
      method: "POST",
      data: rfqdata,
    });
  },
  downloadBulkRfqSampleSheet: () => {
    return fetch_({
      url: BULK_RFQ_EXCEL_DOWNLOAD(),
      method: "GET",
    });
  },
  addBulkRfqUpload: (rfqdata) => {
    return fetch_({
      url: BULK_RFQ_UPLOAD(),
      method: "POST",
      data: rfqdata,
    });
  },
  sentRfq: (rfqId, formData) => {
    return fetch_({
      url: SENT_RFQ(rfqId),
      method: "POST",
      data: formData,
    });
  },
  sentRfqs: (rfqdata) => {
    return fetch_({
      url: SENT_RFQS(),
      method: "POST",
      data: rfqdata,
    });
  },
  sendFollowUps: (rfqdata) => {
    return fetch_({
      url: SEND_FOLLOWUPS(),
      method: "POST",
      data: rfqdata,
    });
  },
  getSentRfqs: (rfqId) => {
    return fetch_({
      url: GET_SENT_RFQS(rfqId),
      method: "GET",
      // data: rfqdata,
    });
  },
};

import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  BRAND_LIST,
  BRAND_COUNT,
  BRAND_ADD,
  BRAND_UPDATE,
  BRAND_DETAIL,
  BRAND_DELETE,
  BRAND_RESTORE,
  BULK_BRAND_SAMPLE_SHEET,
  BRAND_ARCHIVE_COUNT,
  BRAND_ARCHIVE_LIST,
  BULK_BRAND_UPLOAD,
  GET_BRANDS_BASED_ORGANISATION,
  SUBMIT_LINK_BRANDS_TO_ORGANISATION,
  ADD_BULK_BRAND,
} = API_PATH;

export const BrandService_ = {
  getBrand: (filter) => {
    return fetch_({
      url: BRAND_LIST,
      method: "GET",
      params: filter,
    });
  },
  getArchiveBrand: (filter) => {
    return fetch_({
      url: BRAND_ARCHIVE_LIST,
      method: "GET",
      params: filter,
    });
  },
  countBrand: (filter) => {
    return fetch_({
      url: BRAND_COUNT,
      method: "GET",
      params: filter,
    });
  },
  countArchiveBrand: (filter) => {
    return fetch_({
      url: BRAND_ARCHIVE_COUNT,
      method: "GET",
      params: filter,
    });
  },
  getBranddetail: (brandId) => {
    return fetch_({
      url: BRAND_DETAIL(brandId),
      method: "GET",
    });
  },
  addBrand: (brand) => {
    return fetch_({
      url: BRAND_ADD,
      method: "POST",
      data: brand,
    });
  },
  updateBrand: (brandId, brand) => {
    return fetch_({
      url: BRAND_UPDATE(brandId),
      method: "PUT",
      data: brand,
    });
  },
  deleteBrand: (brandId) => {
    return fetch_({
      url: BRAND_DELETE(brandId),
      method: "DELETE",
    });
  },
  restoreBrand: (brandId) => {
    return fetch_({
      url: BRAND_RESTORE(brandId),
      method: "PUT",
    });
  },
  downloadBulkBrandSampleSheet: () => {
    return fetch_({
      url: BULK_BRAND_SAMPLE_SHEET(),
      method: "GET",
    });
  },
  addBulkBrands: (formData) => {
    return fetch_({
      url: BULK_BRAND_UPLOAD(),
      method: "POST",
      data: formData,
    });
  },
  addBulkBrandsWithName: (formData) => {
    return fetch_({
      url: ADD_BULK_BRAND(),
      method: "POST",
      data: formData,
    });
  },
  getAssignedBrandsBasedOnOrganisation: (organisationId) => {
    return fetch_({
      url: GET_BRANDS_BASED_ORGANISATION(organisationId),
      method: "GET",
    });
  },
  submitLinkBrandsToOrganisation: (formData) => {
    return fetch_({
      url: SUBMIT_LINK_BRANDS_TO_ORGANISATION(),
      method: "POST",
      data: formData,
    });
  },
};

import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { refreshBrandList, showAddEditBrandDrawer } from "redux/actions";
import BrandForm from "views/app-views/catalogue/brands/brand-form";

export default function BrandDrawers() {
  const disptach = useDispatch();
  const catalogue_ = useSelector((state) => state.brand);
  const mode = catalogue_?.editableBrand ? "EDIT" : "ADD";
  console.log(catalogue_.refreshNewData);
  return (
    <>
      <Drawer
        title={`${catalogue_?.editableBrand ? "Edit" : "Add"} Brand`}
        width={800}
        onClose={() => {
          disptach(showAddEditBrandDrawer(false));
          //disptach(refreshBrandList(true));
        }}
        visible={catalogue_?.showAddEditBrandDrawer}
        className="custom-drawer"
      >
        <>
          {/* {catalogue_?.showAddEditBrandDrawer && ( */}
          <BrandForm
            setIsSubmitted={() => {}}
            isDrawerView={true}
            onDrawerClose={() => {
              disptach(showAddEditBrandDrawer(false));
              disptach(refreshBrandList(true));
            }}
            onDrawerCloseOnCancel={() => {
              disptach(showAddEditBrandDrawer(false));
              // disptach(refreshBrandList(true));
            }}
            setIsNext={() => {
              disptach(refreshBrandList(true));
            }}
            brandId={catalogue_?.editableBrand}
            refresh={catalogue_.refreshNewData}
          />
          {/*  )} */}
        </>
      </Drawer>
    </>
  );
}

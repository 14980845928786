import {
  REFRESH_RFQ_LIST,
  SHOW_ADD_EDIT_RFQ_DRAWER,
} from "redux/constants/Rfq";

const initialState = {
  showAddEditRfqDrawer: false,
  editableRfq: null,
  refreshRfqList: false,
};
export const rfq = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_ADD_EDIT_RFQ_DRAWER:
      return {
        ...state,
        showAddEditRfqDrawer: data.showAddEditRfqDrawer,
        editableRfq: data.editableRfq,
      };
    case REFRESH_RFQ_LIST:
      return {
        ...state,
        refreshRfqList: data.refreshRfqList,
      };
    default:
      return state;
  }
};

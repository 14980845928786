import {
  REFRESH_BULK_BRAND,
  SHOW_ADD_EDIT_BULK_BRAND,
} from "redux/constants/BulkBrand";

export const showAddEditBulkBrand = (
  visibility = false,
  editableBulkBrand = null
) => {
  return {
    type: SHOW_ADD_EDIT_BULK_BRAND,
    data: {
      showAddEditBulkBrandDrawer: visibility,
      editableBulkBrand,
    },
  };
};

export const refreshBulkBrand = (refresh = false) => {
  return {
    type: REFRESH_BULK_BRAND,
    data: {
      refreshBulkBrand: refresh,
    },
  };
};

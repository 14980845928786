import {
  REFRESH_LINK_BRAND,
  SHOW_ADD_EDIT_LINK_BRAND,
} from "redux/constants/LinkBrand";

const initialState = {
  showAddEditLinkBrandDrawer: false,
  editableLinkBrand: null,
  refreshLinkBrand: false,
};
export const linkBrand = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_ADD_EDIT_LINK_BRAND:
      return {
        ...state,
        showAddEditLinkBrandDrawer: data.showAddEditLinkBrandDrawer,
        editableLinkBrand: data.editableLinkBrand,
      };
    case REFRESH_LINK_BRAND:
      return {
        ...state,
        refreshLinkBrand: data.refreshLinkBrand,
      };
    default:
      return state;
  }
};

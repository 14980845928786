import { Action, Subjects } from "casl/subjects";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { ROLE_TYPE } from "constants/common.constant";
import { showAddEditBrandDrawer } from "redux/actions";
import { showAddEditBulkBrand } from "redux/actions/BulkBrand";
import { showAddEditBulkOrganisation } from "redux/actions/BulkOrganisationAdd";
import { showAddEditLinkBrand } from "redux/actions/LinkBrand";
import { currentUserRole } from "utils/common";
import { BrandService_ } from "services/Tenant/brand.service";
import { OrgService_ } from "services/Tenant/organisation.service";
import { RfqService_ } from "services/Tenant/rfq.service";

export const NAV_PATH = {
  login: `${AUTH_PREFIX_PATH}/login`,
  dashboard: `${APP_PREFIX_PATH}/dashboard`,
  tenants: `${APP_PREFIX_PATH}/tenants`,
  admin: `${APP_PREFIX_PATH}/admin`,
  businessType: `${APP_PREFIX_PATH}/business-type`,
  taxType: `${APP_PREFIX_PATH}/tax-types`,
  impexType: `${APP_PREFIX_PATH}/impex-types`,
  addressType: `${APP_PREFIX_PATH}/address-types`,
  deliveryExpenses: `${APP_PREFIX_PATH}/delivery-expenses`,
  quotationlist: `${APP_PREFIX_PATH}/quotation-list`,
  countries: `${APP_PREFIX_PATH}/countries`,
  currencies: `${APP_PREFIX_PATH}/currencies`,
  organisation: `${APP_PREFIX_PATH}/organisation`,
  contactList: `${APP_PREFIX_PATH}/organisation/contact-list`,
  customerList: `${APP_PREFIX_PATH}/organisation/customer-list`,
  vendorList: `${APP_PREFIX_PATH}/organisation/vendor-list`,
  invoices: `${APP_PREFIX_PATH}/invoices`,
  reports: `${APP_PREFIX_PATH}/reports`,
  users: `${APP_PREFIX_PATH}/users`,
  profile: `${APP_PREFIX_PATH}/profile`,
  uom: `${APP_PREFIX_PATH}/catalogue/uom`,
  brands: `${APP_PREFIX_PATH}/catalogue/brands`,
  archivedbrands: `${APP_PREFIX_PATH}/catalogue/archived-brands`,
  specifications: `${APP_PREFIX_PATH}/catalogue/specifications`,
  certificates: `${APP_PREFIX_PATH}/catalogue/certificates`,
  category: `${APP_PREFIX_PATH}/catalogue/category`,
  archivedcategory: `${APP_PREFIX_PATH}/catalogue/archived-category`,
  products: `${APP_PREFIX_PATH}/catalogue/products`,
  bulkRfq: `${APP_PREFIX_PATH}/bulk-rfq`,
  catalogue: `${APP_PREFIX_PATH}/catalogue`,
  enquiry: `${APP_PREFIX_PATH}/all-lines`,
  activeLines: `${APP_PREFIX_PATH}/active-lines`,
  overdueLines: `${APP_PREFIX_PATH}/overdue-lines`,
  queryLines: `${APP_PREFIX_PATH}/query-lines`,
  rfq: `${APP_PREFIX_PATH}/rfq`,
  openrfq: `${APP_PREFIX_PATH}/open-rfq`,
  overduerfq: `${APP_PREFIX_PATH}/overdue-rfq`,
  detailedrfq: `${APP_PREFIX_PATH}/detailed-rfq`,
  finaliseproducts: `${APP_PREFIX_PATH}/finalise-products/`,
  reviewfinaliseproducts: `${APP_PREFIX_PATH}/review-finalise-products/`,
  customerContract: `${APP_PREFIX_PATH}/organisation/customer-list/customer-contract`,
  quotation: `${APP_PREFIX_PATH}/quotation/`,
  termsconditions: `${APP_PREFIX_PATH}/terms-conditions/quotation`,
  rfqtermsconditions: `${APP_PREFIX_PATH}/terms-conditions/rfq`,
  currenciesconversions: `${APP_PREFIX_PATH}/currencies-conversion`,
  purchaseOrdertermsconditions: `${APP_PREFIX_PATH}/terms-conditions/purchaseOrder`,
  customerOrder: `${APP_PREFIX_PATH}/customer-order`,
  purchaseOrder: `${APP_PREFIX_PATH}/vendor-order`,
  purchaseOrderProduct: `${APP_PREFIX_PATH}/po-product-list`,
  inventory: `${APP_PREFIX_PATH}/inventory`,
  addresses: `${APP_PREFIX_PATH}/address`,
  banks: `${APP_PREFIX_PATH}/bank`,
  purchaseRequirment: `${APP_PREFIX_PATH}/purchase-requirment`,
  orderRfq: `${APP_PREFIX_PATH}/order-rfq`,
  ConsignmentInstruction: `${APP_PREFIX_PATH}/consignments`,
  ConsignmentView: `${APP_PREFIX_PATH}/consignment/undefined/view`,
  Package: `${APP_PREFIX_PATH}/package`,
  discrepancy: `${APP_PREFIX_PATH}/discrepancy`,
  awaitingqaqcproduct: `${APP_PREFIX_PATH}/awaiting-qa-qc-product`,
  awaitingqaqcapprovalproduct: `${APP_PREFIX_PATH}/awaiting-qa-qc-approval-product`,
  procured: `${APP_PREFIX_PATH}/procured-list`,
  awaitingqaqc: `${APP_PREFIX_PATH}/awaiting-qa-qc`,
  awaitingqaqcapproval: `${APP_PREFIX_PATH}/awaiting-qa-qc-approval`,
  groupcompanies: `${APP_PREFIX_PATH}/group-companies`,
  linkBrands: `${APP_PREFIX_PATH}/link-brands`,
  enquiryLines: `${APP_PREFIX_PATH}/daily-lines`,
  activeEnquiryLines: `${APP_PREFIX_PATH}/active-daily-lines`,
  internalChat: `${APP_PREFIX_PATH}/internal-chat`,
  viewDailyLinesProducts: `${APP_PREFIX_PATH}/view/daily-lines-products`,
};

/*Tenant User menu start*/

/*Tenant User menu end*/

/*Supper User menu start*/
const superUserNav = [
  {
    key: "Dashboard",
    path: `${NAV_PATH.dashboard}`,
    title: "Dashboard",
    submenu: [],
    cap: [[Action.Read, Subjects.DASHBOARD]],
  },
  {
    key: "Tenants",
    title: "Tenants",
    cap: [[Action.Read, Subjects.TENANTS]],
    submenu: [
      {
        key: "Registrations",
        path: `${NAV_PATH.tenants}/registrations`,
        title: "Registrations",
        submenu: [],
        cap: [[Action.Read, Subjects.TENANTS]],
      },
      {
        key: "Accounts",
        path: `${NAV_PATH.tenants}/accounts`,
        title: "Accounts",
        submenu: [],
        cap: [[Action.Read, Subjects.TENANTS]],
      },
    ],
  },
  {
    key: "Invoices",
    path: `${NAV_PATH.invoices}`,
    title: "Invoices",
    submenu: [],
    cap: [[Action.Read, Subjects.INVOICE]],
  },
];
const downloadBrandExcel = () => {
  BrandService_.downloadBulkBrandSampleSheet().then((response) => {
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "sample-bulk-brands.csv";
    anchor.click();

    // Release the object URL
    window.URL.revokeObjectURL(url);
  });
};
const downloadOrganisationExcel = () => {
  OrgService_.downloadBulkOrganisationSampleSheet().then((response) => {
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "sample-bulk-organisations.csv";
    anchor.click();

    window.URL.revokeObjectURL(url);
  });
};
const downloadLinkBrandExcel = () => {
  OrgService_.downloadLinkOrganisationSampleSheet().then((response) => {
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "sample-link-brand-organisation.csv";
    anchor.click();

    window.URL.revokeObjectURL(url);
  });
};
const downloadBulkRFQExcel = () => {
  RfqService_.downloadBulkRfqSampleSheet().then((response) => {
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "RFQ-Gun-Sample-Data.csv";
    anchor.click();

    window.URL.revokeObjectURL(url);
  });
};
const tenantUserNav = [
  {
    key: "Dashboard",
    path: `${NAV_PATH.dashboard}`,
    title: "Dashboard",
    submenu: [],
    cap: [[Action.Read, Subjects.DASHBOARD]],
  },

  /*{
    key: "Settings",
    title: "Settings",
    cap: [
      [Action.Read, Subjects._COUNTRIES],
      [Action.Read, Subjects._CURRENCIES],
      [Action.Read, Subjects._BUSINESS_TYPES],
      [Action.Read, Subjects._TAX_TYPES],
      [Action.Read, Subjects._IMPEX_TYPES],
      [Action.Read, Subjects._ADDRESS_TYPES],
    ],
    submenu: [
      {
        key: "Countries",
        path: `${NAV_PATH.countries}`,
        title: "Countries",
        submenu: [],
        cap: [[Action.Read, Subjects._COUNTRIES]],
      },
      {
        key: "Currencies",
        path: `${NAV_PATH.currencies}`,
        title: "Currencies",
        submenu: [
          {
            key: "Currencies List",
            path: `${NAV_PATH.currencies}`,
            title: "List",
            submenu: [],
            cap: [[Action.Read, Subjects._COUNTRIES]],
          },
          {
            key: "Currencies Conversion",
            path: `${NAV_PATH.currenciesconversions}`,
            title: "Currency Conversion",
            submenu: [],
            cap: [[Action.Read, Subjects._COUNTRIES]],
          },
        ],
        cap: [[Action.Read, Subjects._CURRENCIES]],
      },
      {
        key: "Business Types",
        path: `${NAV_PATH.businessType}`,
        title: "Business Types",
        submenu: [],
        cap: [[Action.Read, Subjects._BUSINESS_TYPES]],
      },
      {
        key: "Tax Types",
        path: `${NAV_PATH.taxType}`,
        title: "Tax Types",
        submenu: [],
        cap: [[Action.Read, Subjects._TAX_TYPES]],
      },
      {
        key: "Impex Types",
        path: `${NAV_PATH.impexType}`,
        title: "Impex Types",
        submenu: [],
        cap: [[Action.Read, Subjects._IMPEX_TYPES]],
      },
      {
        key: "Address Types",
        path: `${NAV_PATH.addressType}`,
        title: "Address Types",
        submenu: [],
        cap: [[Action.Read, Subjects._ADDRESS_TYPES]],
      },
      {
        key: "Delivery Expenses",
        path: `${NAV_PATH.deliveryExpenses}`,
        title: "Delivery Expenses",
        submenu: [],
        cap: [[Action.Read, Subjects.DELIVERY_EXPENSES_TYPES]],
      },
      {
        key: "TermsConditions",
        path: `${NAV_PATH.termsconditions}`,
        title: "Terms & Conditions",
        submenu: [
          {
            key: "Quotation",
            path: `${NAV_PATH.termsconditions}`,
            title: "Quotation",
            submenu: [],
            cap: [[Action.Read, Subjects._COUNTRIES]],
          },
          {
            key: "RfqQuotation",
            path: `${NAV_PATH.rfqtermsconditions}`,
            title: "RFQ - Request for Quotation",
            submenu: [],
            cap: [[Action.Read, Subjects._COUNTRIES]],
          },
          {
            key: "PurchaseOrder",
            path: `${NAV_PATH.purchaseOrdertermsconditions}`,
            title: "Purchase Order",
            submenu: [],
            cap: [[Action.Read, Subjects._COUNTRIES]],
          },
        ],
        cap: [[Action.Read, Subjects.TERMS_CONDITIONS]],
      },
      {
        key: "addresses",
        path: `${NAV_PATH.addresses}`,
        title: "Addresses",
        submenu: [],
        cap: [[Action.Read, Subjects.ADDRESSES]],
      },
      {
        key: "banks",
        path: `${NAV_PATH.banks}`,
        title: "Banks",
        submenu: [],
        cap: [[Action.Read, Subjects.BANKS]],
      },
    ],
  }, */
  {
    key: "DailyLines",
    path: `${NAV_PATH.enquiryLines}`,
    title: "Daily Lines",
    submenu: [
      {
        key: "View-All-Lines",
        path: `${NAV_PATH.enquiryLines}`,
        title: "View All",
        submenu: [],
        cap: [[Action.Read, Subjects._TENANT_USERS]],
      },
      {
        key: "Active-All-Lines",
        path: `${NAV_PATH.activeEnquiryLines}`,
        title: " Active",
        submenu: [],
        cap: [[Action.Read, Subjects._TENANT_USERS]],
      },
    ],
    cap: [[Action.Read, Subjects.SUPER_USERS]],
  },
  {
    key: "Lines",
    path: `${NAV_PATH.enquiry}`,
    title: "Lines",
    submenu: [
      {
        key: "View-All-Lines-list",
        path: `${NAV_PATH.enquiry}`,
        title: "View All",
        submenu: [],
        cap: [[Action.Read, Subjects._TENANT_USERS]],
      },
      {
        key: "Active-Lines",
        path: `${NAV_PATH.activeLines}`,
        title: "Active",
        submenu: [],
        cap: [[Action.Read, Subjects._TENANT_USERS]],
      },
      {
        key: "Overdue",
        path: `${NAV_PATH.overdueLines}`,
        title: "Overdue",
        submenu: [],
        cap: [[Action.Read, Subjects._TENANT_USERS]],
      },
      {
        key: "Query",
        path: `${NAV_PATH.queryLines}`,
        title: "Query",
        submenu: [],
        cap: [[Action.Read, Subjects._TENANT_USERS]],
      },
    ],
    cap: [[Action.Read, Subjects.SUPER_USERS]],
  },
  {
    key: "RFQs",
    path: `${NAV_PATH.organisation}`,
    title: "RFQs",
    submenu: [
      {
        key: "View-All-Rfqs",
        path: `${NAV_PATH.rfq}`,
        title: "View",
        submenu: [],
        cap: [[Action.Read, Subjects._TENANT_USERS]],
      },
      {
        key: "Open-rfqs",
        path: `${NAV_PATH.openrfq}`,
        title: "Open",
        submenu: [],

        cap: [[Action.Read, Subjects._TENANT_USERS]],
      },
      {
        key: "Overdue-rfqs",
        path: `${NAV_PATH.overduerfq}`,
        title: "Overdue",
        submenu: [],

        cap: [[Action.Read, Subjects._TENANT_USERS]],
      },
      {
        key: "Bulk Rfq",
        path: `${NAV_PATH.bulkRfq}`,
        // onClick: (dispatch) => {
        //   dispatch(showAddEditBulkRfq(true));
        // },
        title: "Bulk Rfq",
        submenu: [],
        cap: [[Action.Read, Subjects._COUNTRIES]],
      },
      {
        key: "RFQ Template Sheet",
        title: "Download Sample",
        onClick: () => {
          downloadBulkRFQExcel();
        },
        submenu: [],
        cap: [[Action.Read, Subjects._COUNTRIES]],
      },
    ],
    cap: [[Action.Read, Subjects.SUPER_USERS]],
  },
  {
    key: "Brands",
    path: `${NAV_PATH.brands}`,
    title: "Brands",
    submenu: [
      {
        key: "BrandView",
        path: `${NAV_PATH.brands}`,
        title: "View",
        submenu: [],
        cap: [[Action.Read, Subjects._COUNTRIES]],
      },
      {
        key: "Bulk Add",
        title: "Bulk Add",
        onClick: (dispatch) => {
          dispatch(showAddEditBulkBrand(true));
        },
        submenu: [],
        cap: [[Action.Read, Subjects._COUNTRIES]],
      },
      {
        key: "Bulk Add Template Sheet",
        title: "Download Sample",
        onClick: () => {
          downloadBrandExcel();
        },
        submenu: [],
        cap: [[Action.Read, Subjects._COUNTRIES]],
      },
    ],
    cap: [[Action.Read, Subjects.SUPER_USERS]],
  },
  {
    key: "Vendors",
    path: `${NAV_PATH.organisation}`,
    title: "Vendors",
    submenu: [
      {
        key: "Vieworg",
        path: `${NAV_PATH.organisation}`,
        title: "View",
        submenu: [],
        cap: [[Action.Read, Subjects._COUNTRIES]],
      },
      {
        key: "Bulkadd",
        // path: `${NAV_PATH.organisation}`,
        title: "Bulk Add",
        submenu: [],
        onClick: (dispatch) => {
          dispatch(showAddEditBulkOrganisation(true));
        },
        cap: [[Action.Read, Subjects._COUNTRIES]],
      },
      {
        key: "Bulk Add Template Sheet",
        title: "Download Sample",
        onClick: () => {
          downloadOrganisationExcel();
        },
        submenu: [],
        cap: [[Action.Read, Subjects._COUNTRIES]],
      },
      {
        key: "Linkbrands",
        title: "Link Brands",
        path: `${NAV_PATH.linkBrands}`,

        // onClick: (dispatch) => {
        //   dispatch(showAddEditLinkBrand(true));
        // },

        submenu: [],
        cap: [[Action.Read, Subjects._COUNTRIES]],
      },
    ],
    cap: [[Action.Read, Subjects.SUPER_USERS]],
  },
];

const navigationConfig = [
  ...superUserNav,
  ...tenantUserNav,
  // ...masterNavTree,
  // ...enquiryNavTree,
  // ...rfqNavTree,
  // ...quotationNavTree,
  // ...orderNavTree,
  // ...poNavTree,
  // ...consignmentNavTree,
];

export const navigationConfigMenu = () => {
  const navigationConfig = [];
  if (
    [ROLE_TYPE.SUPER_ADMIN, ROLE_TYPE.SUPER_ADMIN_ASSOCIATES].includes(
      currentUserRole()
    )
  ) {
    navigationConfig.push(...superUserNav);
  }
  if (
    [ROLE_TYPE.TENANT_ADMIN, ROLE_TYPE.TENANT_ASSOCIATE].includes(
      currentUserRole()
    )
  ) {
    navigationConfig.push(...tenantUserNav);
  }
  return navigationConfig;
};

export default navigationConfig;

import { API_PATH } from "../../../constants/common.constant";
import fetch_ from "../../../interceptors/FetchInterceptor";

const {
    PRODUCT_RESOURCE_LIST,
    PRODUCT_RESOURCE_COUNT,
    PRODUCT_RESOURCE_ADD,
    PRODUCT_RESOURCES_ADD,
    PRODUCT_RESOURCE_UPDATE,
    PRODUCT_RESOURCE_DETAIL,
    PRODUCT_RESOURCE_DELETE,
} = API_PATH;

export const ProductResourceService_ = {
    getProductResource: (productId, filter) => {
        return fetch_({
            url: PRODUCT_RESOURCE_LIST(productId),
            method: "GET",
            params: filter,
        });
    },
    countProductResource: (productId, filter) => {
        return fetch_({
            url: PRODUCT_RESOURCE_COUNT(productId),
            method: "GET",
            params: filter,
        });
    },
    getProductResourcedetail: (productId, resourceId) => {
        return fetch_({
            url: PRODUCT_RESOURCE_DETAIL(productId, resourceId),
            method: "GET",
        });
    },
    addProductResource: (productId, resource) => {
        return fetch_({
            url: PRODUCT_RESOURCE_ADD(productId),
            method: "POST",
            data: resource,
        });
    },
    addProductResources: (productId, resources) => {
        return fetch_({
            url: PRODUCT_RESOURCES_ADD(productId),
            method: "POST",
            data: resources,
        });
    },
    updateProductResource: (productId, resourceId, resource) => {
        return fetch_({
            url: PRODUCT_RESOURCE_UPDATE(productId, resourceId),
            method: "PUT",
            data: resource,
        });
    },
    deleteProductResource: (productId, resourceId) => {
        return fetch_({
            url: PRODUCT_RESOURCE_DELETE(productId, resourceId),
            method: "DELETE",
        });
    },
};

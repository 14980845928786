import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  ADDRESS_ADD,
  ADDRESS_LIST,
  ADDRESS_COUNT,
  ADDRESS_DETAIL,
  ADDRESS_UPDATE,
  ADDRESS_DELETE,
  TENANT_RFQ_ADDRESS,
  DEFAULT_TENANT_ADDRESS,
  DEFAULT_TENANT_ORGANISATIONS_ADDRESS,
} = API_PATH;

export const AddressService_ = {
  addAddress: (addressdata) => {
    return fetch_({
      url: ADDRESS_ADD,
      method: "POST",
      data: addressdata,
    });
  },
  getDefaultTenantOrgansationsAddress: (vendorId, addressdata) => {
    return fetch_({
      url: DEFAULT_TENANT_ORGANISATIONS_ADDRESS(vendorId),
      method: "GET",
      params: addressdata,
    });
  },
  getDefaultTenantAddress: (addressdata) => {
    return fetch_({
      url: DEFAULT_TENANT_ADDRESS,
      method: "GET",
      params: addressdata,
    });
  },

  getAddress: (filter) => {
    return fetch_({
      url: ADDRESS_LIST,
      method: "GET",
      params: filter,
    });
  },
  countAddress: (filter) => {
    return fetch_({
      url: ADDRESS_COUNT,
      method: "GET",
      params: filter,
    });
  },
  getAddressdetail: (addressId) => {
    return fetch_({
      url: ADDRESS_DETAIL(addressId),
      method: "GET",
    });
  },
  getAllAddress: (filter) => {
    return fetch_({
      url: TENANT_RFQ_ADDRESS(filter),
      method: "GET",
    });
  },

  updateAddress: (addressId, addressdata) => {
    return fetch_({
      url: ADDRESS_UPDATE(addressId),
      method: "PUT",
      data: addressdata,
    });
  },
  deleteAddress: (addressId) => {
    return fetch_({
      url: ADDRESS_DELETE(addressId),
      method: "DELETE",
    });
  },
};

import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Input, Row, Col, Card, Form, Upload, Select, Button } from "antd";
import RichEditor from "../../../../../components/shared-components/RichEditor";
import Utils from "utils/common";
import BrandSelect from "components/shared-components/BrandSelect";
import { BrandService_ } from "../../../../../services/Tenant/brand.service";
import DynamicUpload from "components/shared-components/DynamicUpload";
import { useForceUpdate } from "hooks/useForceUpdate";
import { getUploadIcon } from "utils/utilities";
import Loading from "components/shared-components/Loading";
import { validateAlias } from "utils/helpers";

const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;

const rules = {
  name: [
    {
      required: true,
      message: "Please input brand name",
    },
  ],
  doc: [
    {
      required: true,
      message: "Please select a doc",
    },
  ],
};

const BrandDetail = ({
  props,
  setSelectedImageFile,
  brandDetail,
  form,
  mode,
  loading,
  refresh,
}) => {
  const [brandList, setBrandList] = useState([]);
  const [parentValue, setParentValue] = useState({ value: "", label: "" });
  const [description, setDescription] = useState("");
  const [initialLogo, setInitialLogo] = useState(null);
  useEffect(() => {
    ParentBrand();
  }, []);
  const ParentBrand = () => {
    BrandService_.getBrand().then((data) => {
      setBrandList(data);
    });
  };
  const forceUpdate = useForceUpdate();
  const [shouldRenderUpload, setShouldRenderUpload] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldRenderUpload(true);
    }, 1200);

    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    if (mode === "EDIT") {
      setParentValue({
        value: brandDetail?.parentBrand?.id,
        label: brandDetail?.parentBrand?.name,
      });
      // setDescription(brandDetail?.description);
      // setInitialLogo(brandDetail?.logo?.publicUrl);
    } else {
      setParentValue({ value: "", label: "" });
      setDescription("");
      setInitialLogo(null);
    }
  }, [
    brandDetail?.description,
    brandDetail?.logo?.publicUrl,
    brandDetail?.parentBrand?.id,
    brandDetail?.parentBrand?.name,
    mode,
  ]);
  return !shouldRenderUpload || loading ? (
    <Loading />
  ) : (
    <>
      <Row>
        <Col xs={24} sm={24} md={24}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[...rules.name, { validator: validateAlias }]}
            >
              <Input />
            </Form.Item>
          </Col>

          {/*
<Col xs={24} sm={24} md={24}>
          <Form.Item name="shortCode" label="Short Code" rules="">
            <Input />
          </Form.Item>
        </Col>
*/}

          <Col xs={24} sm={24} md={24}>
            <Form.Item name="parentBrand" label="Parent Brand" rules="">
              <BrandSelect
                defaultValue={parentValue}
                clearOnSelect
                limit={10}
                inputlength={3}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="alias"
              label="Alias"
              rules={[{ validator: validateAlias }]}
            >
              <Input />
            </Form.Item>
          </Col>

          {/* <Col xs={24} sm={24} md={24}>
            <Form.Item name="description" label="Description" rules="">
              {(description || shouldRenderUpload) && (
                <RichEditor
                  defaultValue={description}
                  onChange={(value) => {
                    form?.setFieldsValue({
                      description: value,
                    });
                  }}
                />
              )}
            </Form.Item>
          </Col> */}

          {/* <Col xs={24} sm={24} md={24}>
            <Form.Item label="Logo" rules={rules.doc} name="logo">
              {(brandDetail?.logo?.publicUrl || shouldRenderUpload) && (
                <DynamicUpload
                  form={form}
                  forceUpdate={forceUpdate}
                  maxCount={1}
                  acceptedFileTypes={["image/jpeg", "image/jpg"]}
                  uploadIcon={getUploadIcon(form, "enquiryDoc")}
                  fieldValue={"logo"}
                  initialImage={initialLogo}
                />
              )}
            </Form.Item>
          </Col> */}
        </Col>
      </Row>
    </>
  );
};

export default BrandDetail;

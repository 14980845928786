import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  message,
  Space,
  Row,
  Col,
  Input,
  Divider,
  Select,
  Layout,
} from "antd";
import "react-phone-input-2/lib/style.css";
import { ROW_GUTTER } from "constants/ThemeConstant";
import { CountryService_ } from "services/common/country.service";
import { AddressTypeService_ } from "services/common/addressType.service";
import { OrgAddressService_ } from "services/Tenant/organisation_address.service";
import { Content, Footer } from "antd/lib/layout/layout";
import { EnquiryService_ } from "services/Tenant/enquiry.service";
import { OrgCustomersService_ } from "services/Tenant/organisation_customer.service";

const AddressDrawer = ({
  onDrawerClose,
  initialData,
  presetval,
  setDeliveryAddress,
  mode,
  props,
}) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    countryData();
  }, []);

  useEffect(() => {
    if(mode === 'EDIT'){
      fetchOrgDetail(presetval?.customer?.id);
    }
    form.setFieldsValue(
      {address:presetval?.enquiryAddress?.deliveryAddress,
      country:presetval?.enquiryAddress?.deliveryCountry,
      city:presetval?.enquiryAddress?.deliveryCity,
      state:presetval?.enquiryAddress?.deliveryState,
      postCode:presetval?.enquiryAddress?.deliveryPostCode,
      landmark:presetval?.enquiryAddress?.deliveryLandmark,
    });
  }, [presetval?.enquiryAddress]);

  const fetchOrgDetail = (selectVal) => {
    setIsLoading(true);
    OrgCustomersService_.getOrganisationcustomerdetail(selectVal)
      .then((data) => {
        setAddressList(data);
      })
      .finally(() => setIsLoading(false));
  };


  const countryData = () => {
    CountryService_.getCountries().then((data) => {
      setCountryList(data);
    });
  };

  const onFinish = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        setSubmitLoading(true);
        const {
          address,
          city,
          state,
          country,
          postCode,
          landmark,
        } = values;

        const deliveryAddress = {
          deliveryAddress: address,
          deliveryCity: city, 
          deliveryState: state, 
          deliveryCountry: country, 
          deliveryPostCode: postCode, 
          deliveryLandmark: landmark, 
          deliveryAddressName: addressData?.addressName, 
        };
        if(presetval?.enquiryAddress){
          values.enquiryAddress= deliveryAddress;
          delete values.address;
          delete values.city;
          delete values.country;
          delete values.landmark;
          delete values.postCode;
          delete values.state;
          EnquiryService_.updateEnquiry(presetval?.enquiryAddress?.enquiryId, values)
          .then((data) => {
            message.success(`address updated successfully`);
          }) 
        }else{
          setDeliveryAddress(deliveryAddress);
        }
        onDrawerClose();
      })
      .catch((info) => {
        //message.error("Please enter all required field ");
      })
      .finally((_) => setSubmitLoading(false));
  };

  const rules = {
    country: [
      {
        required: true,
        message: "Please input organisation country",
      },
    ],
  };

  function selectedOption(value) {
    OrgAddressService_.getOrganisationaddressdetail(value).then((data) => {
      setAddressData(data);
        form.setFieldsValue(data);
        form.setFieldsValue({address:data.address1});
        form.resetFields(['deliveryAddress']);
      });
    
  }

  const selectOptions = mode === 'EDIT' ? 
  addressList?.addresses
    ?.filter((address) => address.addressType === "Delivery/Warehouse")
    .map(({ id, address1 }) => ({
      value: id,
      label: address1,
    }))
  : 
  initialData?.addresses
    ?.filter((address) => address.addressType === "Delivery/Warehouse")
    .map(({ id, address1 }) => ({
      value: id,
      label: address1,
    }));
  return (
    <>
      <Form layout={"horizontal"} form={form} name="tenant-form">
        <Layout>
          <Content>
            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item label="Select Address" name={"deliveryAddress"}>
                <Select
    showSearch
    allowClear
    options={selectOptions}
    onSelect={selectedOption}
  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={2}>
            
              </Col>
              <Col xs={24} sm={24} md={10}></Col>
            </Row>
     
            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="address" label="Address">
                  <Input allowClear/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="country" label="Country" rules={rules.country}>
                  <Select
                  allowClear
                    showSearch
                    options={countryList.map(({ id, name }) => ({
                      value: name,
                      label: name,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="city" label="City" rules="">
                  <Input allowClear/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="state" label="State" rules="">
                  <Input allowClear/>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item label="Post Code" name="postCode" rules="">
                  <Input allowClear/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item label="Land Mark" name="landmark" rules="">
                  <Input allowClear/>
                </Form.Item>
              </Col>
            </Row>
          </Content>
          <Footer>
            <Space style={{ float: "right" }}>
              <Button
                type="primary"
                onClick={() => onFinish()}
                htmlType="submit"
                loading={submitLoading}
                disabled={submitLoading}
                size="small"
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  onDrawerClose();
                }}
                size="small"
              >
                Cancel
              </Button>
            </Space>
          </Footer>
        </Layout>
      </Form>
    </>
  );
};

export default AddressDrawer;

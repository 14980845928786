import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichEditor = ({ onChange, defaultValue, readOnly, height }) => {
  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <div>
      <ReactQuill
        defaultValue={defaultValue}
        onChange={handleChange}
        modules={modules}
        readOnly={readOnly ?? false}
        preserveWhitespace={false}
        style={{ height: height || "150px" }}
      />
    </div>
  );
};

export default RichEditor;

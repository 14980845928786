import {
  REFRESH_BULK_BRAND,
  SHOW_ADD_EDIT_BULK_BRAND,
} from "redux/constants/BulkBrand";

const initialState = {
  showAddEditBulkBrandDrawer: false,
  editableBulkBrand: null,
  refreshBulkBrand: false,
};
export const bulkBrand = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_ADD_EDIT_BULK_BRAND:
      return {
        ...state,
        showAddEditBulkBrandDrawer: data.showAddEditBulkBrandDrawer,
        editableBulkBrand: data.editableBulkBrand,
      };
    case REFRESH_BULK_BRAND:
      return {
        ...state,
        refreshBulkBrand: data.refreshBulkBrand,
      };
    default:
      return state;
  }
};

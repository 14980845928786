import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  PRODUCT_LIST,
  PRODUCT_COUNT,
  PRODUCT_ADD,
  PRODUCT_UPDATE,
  PRODUCT_DETAIL,
  PRODUCT_DELETE,
} = API_PATH;

export const ProductService_ = {
  getProduct: (filter) => {
    return fetch_({
      url: PRODUCT_LIST,
      method: "GET",
      params: filter,
    });
  },
  countProduct: (filter) => {
    return fetch_({
      url: PRODUCT_COUNT,
      method: "GET",
      params: filter,
    });
  },
  getProductdetail: (productId) => {
    return fetch_({
      url: PRODUCT_DETAIL(productId),
      method: "GET",
    });
  },
  addProduct: (product) => {
    return fetch_({
      url: PRODUCT_ADD,
      method: "POST",
      data: product,
    });
  },
  updateProduct: (productId, product) => {
    return fetch_({
      url: PRODUCT_UPDATE(productId),
      method: "PUT",
      data: product,
    });
  },
  deleteProduct: (productId) => {
    return fetch_({
      url: PRODUCT_DELETE(productId),
      method: "DELETE",
    });
  },
};

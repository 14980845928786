import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  PROCESS_PO_PRODCUTS_LIST,
  ENQUIRY_RFQ_RESOURCE_FINALISED_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_SUBMIT_FINALISED_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_ENQUIRY_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_CERTIFICATES,
  ENQUIRY_RFQ_RESOURCE_TOTAL_FINALISED_CERTIFICATES,
  CREATE_PURCHASE_ORDER,
  FINALIZE_PROCESS_PO_PRODCUTS_LIST,
  PURCHASE_ORDER_LIST,
  PURCHASE_ORDER_LIST_COUNT,
  PURCHASE_ORDER_DETAIL,
  PURCHASE_ORDER_PRODUCTS_LIST,
  PURCHASE_ORDER_CERTIFICATES_LIST,
  PURCHASE_ORDER_RESOURCE_LIST,
  PURCHASE_ORDER_RESOURCE_ASSIGNED_PRODUCTS,
  PURCHASE_ORDER_RESOURCES_ADD,
  PURCHASE_ORDER_RESOURCE_DETAIL,
  PURCHASE_ORDER_RESOURCE_UPDATE,
  PURCHASE_ORDER_RESOURCE_DELETE,
  PURCHASE_ORDER_TERMS,
  PURCHASE_ORDER_TERMS_CREATE,
  PURCHASE_ORDER_ADDL_COST,
} = API_PATH;

export const ProcessPoService = {
  getProcessPoProducts: (customerOrderId, filter) => {
    return fetch_({
      url: PROCESS_PO_PRODCUTS_LIST(customerOrderId, filter),
      method: "GET",
      // params: filter,
    });
  },
  getFinalizeProcessPoProducts: (customerOrderId) => {
    return fetch_({
      url: FINALIZE_PROCESS_PO_PRODCUTS_LIST(customerOrderId),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderById: (purchaseOrderId) => {
    return fetch_({
      url: PURCHASE_ORDER_DETAIL(purchaseOrderId),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderAddlCost: (purchaseOrderId) => {
    return fetch_({
      url: PURCHASE_ORDER_ADDL_COST(purchaseOrderId),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderTerms: (purchaseOrderId) => {
    return fetch_({
      url: PURCHASE_ORDER_TERMS(purchaseOrderId),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderProductsById: (purchaseOrderId) => {
    return fetch_({
      url: PURCHASE_ORDER_PRODUCTS_LIST(purchaseOrderId),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderCertificatesById: (purchaseOrderId) => {
    return fetch_({
      url: PURCHASE_ORDER_CERTIFICATES_LIST(purchaseOrderId),
      method: "GET",
      // params: filter,
    });
  },
  getPurchaseOrderList: (filter) => {
    return fetch_({
      url: PURCHASE_ORDER_LIST(),
      method: "GET",
      params: filter,
    });
  },
  getPurchaseOrderListCount: (filter) => {
    return fetch_({
      url: PURCHASE_ORDER_LIST_COUNT(),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryProductsRfqs: (enquiryId, enquiryProductId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_ENQUIRY_PRODUCT_DETAIL(
        enquiryId,
        enquiryProductId
      ),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryFinalisedProductsDetails: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_FINALISED_PRODUCT_DETAIL(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryReviewFinalisedProductsDetails: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_PRODUCT_DETAIL(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryReviewFinalisedCertificates: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_CERTIFICATES(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryFinalisedCertificatesTotal: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_TOTAL_FINALISED_CERTIFICATES(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  createEnquiryFinalisedProducts: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_SUBMIT_FINALISED_PRODUCT_DETAIL(enquiryId),
      method: "POST",
      data: filter,
    });
  },
  createPurchaseorderTerms: (enquiryId, filter) => {
    return fetch_({
      url: PURCHASE_ORDER_TERMS_CREATE(enquiryId),
      method: "POST",
      data: filter,
    });
  },
  createPurchaseOrder: (filter) => {
    return fetch_({
      url: CREATE_PURCHASE_ORDER(),
      method: "POST",
      data: filter,
    });
  },
  getPurcahseOrderResource: (purchaseOrderId, filter) => {
    return fetch_({
      url: PURCHASE_ORDER_RESOURCE_LIST(purchaseOrderId),
      method: "GET",
      params: filter,
    });
  },
  getPurcahseOrderAssignedProducts: (purchaseOrderId, resourceId, filter) => {
    return fetch_({
      url: PURCHASE_ORDER_RESOURCE_ASSIGNED_PRODUCTS(
        purchaseOrderId,
        resourceId
      ),
      method: "GET",
      params: filter,
    });
  },
  addPurcahseOrderResource: (purchaseOrderId, resources) => {
    return fetch_({
      url: PURCHASE_ORDER_RESOURCES_ADD(purchaseOrderId),
      method: "POST",
      data: resources,
    });
  },

  getPurcahseOrderResourcedetail: (purchaseOrderId, resourceId) => {
    return fetch_({
      url: PURCHASE_ORDER_RESOURCE_DETAIL(purchaseOrderId, resourceId),
      method: "GET",
    });
  },

  updatePurcahseOrderResource: (purchaseOrderId, resourceId, resource) => {
    return fetch_({
      url: PURCHASE_ORDER_RESOURCE_UPDATE(purchaseOrderId, resourceId),
      method: "PUT",
      data: resource,
    });
  },

  deletePurcahseOrderResource: (purchaseOrderId, resourceId) => {
    return fetch_({
      url: PURCHASE_ORDER_RESOURCE_DELETE(purchaseOrderId, resourceId),
      method: "DELETE",
    });
  },
};

import {
  Button,
  Form,
  Input,
  InputNumber,
  Layout,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import Text from "antd/lib/typography/Text";
import { DATE_FORMAT_DD_MM_YYYY } from "constants/DateConstant";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { text } from "utils/utilities";
import {
  CheckOutlined,
  CloseCircleOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import BrandSelect from "components/shared-components/BrandSelect";
import { showAddEditBrandDrawer } from "redux/actions";
import { useDispatch } from "react-redux";
const EditRecordDrawer = ({ csvData, setCSVData, onDrawerClose }) => {
  const [editableData, setEditableData] = useState({});
  useEffect(() => {
    setEditableData(csvData);
  }, [csvData]);
  const productColumns = [
    {
      title: "record",
      dataIndex: "recordId",
      editable: false,
      render: (recordId, row, index) => (
        <Text style={{ width: 60 }} ellipsis={{ tooltip: recordId }}>
          {text(recordId)}
        </Text>
      ),
    },
    {
      title: "doc date",
      dataIndex: "docDate",
      editable: true,
      render: (docDate, row, index) => (
        <Text
          style={{ width: 60 }}
          ellipsis={{
            tooltip: docDate
              ? moment(docDate).format(DATE_FORMAT_DD_MM_YYYY)
              : "",
          }}
        >
          {docDate ? moment(docDate).format(DATE_FORMAT_DD_MM_YYYY) : ""}
        </Text>
      ),
    },
    {
      title: "p/r",
      dataIndex: "pr",
      editable: true,
      align: "left",
      render: (pr, row) => (
        <Text style={{ width: 60 }} ellipsis={{ tooltip: pr }}>
          {text(pr)}
        </Text>
      ),
    },
    {
      title: "line",
      dataIndex: "lineNumber",
      editable: true,
      render: (lineNumber, row) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: lineNumber }}>
          {text(lineNumber)}
        </Text>
      ),
    },
    {
      title: "material no.",
      dataIndex: "materialNumber",
      editable: true,
      align: "left",
      render: (materialNumber, row) => (
        <Text style={{ width: 120 }} ellipsis={{ tooltip: materialNumber }}>
          {text(materialNumber)}
        </Text>
      ),
    },
    {
      title: "name",
      dataIndex: "material",
      editable: true,
      align: "left",
      width: 200,
      render: (material, row) => {
        return (
          <Text style={{ width: 200 }} ellipsis={{ tooltip: material }}>
            {text(material)}
          </Text>
        );
      },
    },
    {
      title: "qty",
      dataIndex: "quantity",
      editable: true,
      align: "right",
      render: (quantity) => (
        <Text style={{ width: 50 }} ellipsis={{ tooltip: quantity }}>
          {text(quantity)}
        </Text>
      ),
    },
    {
      title: "uom",
      dataIndex: "uom",
      editable: true,
      render: (uom) => (
        <Text style={{ width: 50 }} ellipsis={{ tooltip: uom }}>
          {text(uom)}
        </Text>
      ),
    },
    {
      title: "brand",
      dataIndex: "brand",
      editable: true,
      render: (brand) => (
        <Text style={{ width: 200 }} ellipsis={{ tooltip: brand }}>
          {text(brand)}
        </Text>
      ),
    },
  ];
  const [form] = Form.useForm();
  function createRecordMap(data) {
    console.log(data, "datadatadata");
    const recordMap = {};
    Object.keys(data).forEach((key) => {
      const [fieldName, recordId] = key.split("-");
      if (!recordMap[recordId]) {
        recordMap[recordId] = {};
      }
      recordMap[recordId][fieldName] = data[key];
    });
    return recordMap;
  }
  function updateCsvData(recordMap) {
    setEditableData((prevData) => {
      const updatedProducts = prevData.products.map((product) => {
        const recordId = product.recordId;
        if (recordMap[recordId]) {
          const updatedFields = recordMap[recordId];
          const updatedProduct = { ...product };
          Object.keys(updatedFields).forEach((key) => {
            // Only update the field if it exists in the updatedFields object
            if (updatedFields[key] !== undefined) {
              updatedProduct[key] = updatedFields[key];
            }
          });
          return updatedProduct;
        }
        return product;
      });
      return { ...prevData, products: updatedProducts };
    });
  }

  const onFinish = async () => {
    const row = await form.validateFields();
    const recordMap = createRecordMap(row);
    const updatedEditableData = editableData?.products?.map((item) => {
      const recordId = item.recordId;
      if (recordMap[recordId]) {
        console.log(recordMap, "AAAAAAAAAAAAAAAAAAAAADDDDDDDD");
        const updatedFields = recordMap[recordId];
        return {
          ...item,
          ...updatedFields,
        };
      }
      return item;
    });
    console.log(updatedEditableData, "updated");

    updatedEditableData?.forEach((product) => {
      const allKeysValid = Object.keys(product).every((key) => {
        if (key === "organisations") return true; // Skip the 'organisations' key
        const value = product[key];
        console.log(product, "valuevalue");
        return value !== undefined && value !== null && value !== "";
      });

      // If all keys are valid, set invalidReason to null
      if (allKeysValid) {
        product.invalidReason = null;
      }
    });
    console.log(updatedEditableData, "updatedEditableData");
    setCSVData((prev) => ({ ...prev, products: updatedEditableData }));

    onDrawerClose();
  };
  return (
    <div>
      <Form form={form} component={false}>
        <Layout>
          <Content>
            <div className="table-responsive">
              <Table
                className="custom-table"
                size="small"
                // loading={submitLoading}
                columns={productColumns.map((col) => ({
                  ...col,
                  render: (text, record, index) => {
                    if (!col.editable) {
                      return <Text>{record[col?.dataIndex]}</Text>;
                    }
                    return (
                      <EditableCell
                        title={col.title}
                        dataIndex={col.dataIndex}
                        record={record}
                        index={index}
                        inputType={
                          col.dataIndex === "docDate" ? "date" : "text"
                        }
                        editableData={editableData}
                        setEditableData={setEditableData}
                      />
                    );
                  },
                }))}
                dataSource={editableData?.products || []}
                editable={true}
                rowKey="recordId"
                pagination={false}
                // key={invalidResponseData?.length}
                rowClassName={(row) => (!row?.invalidReason ? "hide-row" : "")}
                // scroll={{ x: 'max-content' }} // Adjust the scroll property to your needs
              />
            </div>
          </Content>
          <Footer>
            <div>
              <Space style={{ float: "right" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    onFinish();
                  }}
                  htmlType="submit"
                  //loading={submitLoading}
                  // disabled={submitLoading}
                  size="small"
                >
                  {"Save"}
                </Button>
                <Button
                  onClick={() => {
                    onDrawerClose();
                  }}
                  size="small"
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Footer>
        </Layout>
      </Form>
    </div>
  );
};
export default EditRecordDrawer;
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  editableData,
  setEditableData,
  ...restProps
}) => {
  console.log(dataIndex, record, "dataIndex");

  const dispatch = useDispatch();

  const handleBrandChange = (value, recordId) => {
    // Find the index of the record with the given recordId
    const recordIndex = editableData.products.findIndex(
      (product) => product.recordId === recordId
    );
    // Copy the editableData to avoid mutating state directly
    const updatedEditableData = [...editableData.products];
    // Update the brand value of the record with the given recordId
    updatedEditableData[recordIndex] = {
      ...updatedEditableData[recordIndex],
      brandId: value?.value,
      brand: value?.label,
      organisations: [],
    };
    const newBrand = {
      id: value?.value,
      name: value?.label,
      organisations: editableData.dealsInAllBrandsOrganisations,
    };
    console.log(updatedEditableData, "updatedEditableData");

    // Update the state with the updated editableData
    setEditableData({
      ...editableData,
      products: updatedEditableData,
      brands: [...editableData.brands, newBrand],
    });
  };
  if (title == "brand") {
    return (
      <td {...restProps}>
        {/* <Form.Item
          name={`${dataIndex}-${record.recordId}`}
          style={{
            margin: 0,
          }}
          initialValue={
            record && record["brandId"] !== undefined ? record["brandId"] : ""
          }
          rules={[
            {
              required: true,
              message: `Please Input Brand Name`,
            },
          ]}
        > */}
        <div style={{ width: 250 }}>
          <BrandSelect
            defaultValue={
              record && record["brandId"] && record["brand"]
                ? [
                    {
                      value: record["brandId"],
                      label: record["brand"],
                    },
                  ]
                : []
            }
            inputlength={3}
            onChange={(value) => handleBrandChange(value, record?.recordId)}
          />
          <div
            type="primary"
            size={"small"}
            style={{
              color: "rgb(62, 121, 247)",
              marginTop: 5,
              cursor: "pointer",
              marginLeft: 5,
              position: "absolute",
              right: -19,
              bottom: 2,
            }}
            onClick={() => dispatch(showAddEditBrandDrawer(true, null, true))}
          >
            <PlusCircleOutlined />
          </div>
        </div>
        {/* </Form.Item> */}
      </td>
    );
  }
  const inputNode =
    inputType === "number" ? (
      <InputNumber />
    ) : (
      <Input placeholder={`Input ${title} ${title == "brand" ? "name" : ""}`} />
    );

  return (
    <td {...restProps}>
      <Form.Item
        name={`${dataIndex}-${record.recordId}`}
        style={{
          margin: 0,
        }}
        initialValue={
          record && record[dataIndex] !== undefined ? record[dataIndex] : ""
        }
        rules={[
          {
            required: true,
            message: `Please Input ${title}`,
          },
        ]}
      >
        {inputNode}
      </Form.Item>
    </td>
  );
};

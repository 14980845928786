import {
  REFRESH_BULK_RFQ,
  SHOW_ADD_EDIT_BULK_RFQ,
} from "redux/constants/BulkRfq";

const initialState = {
  showAddEditBulkRfqDrawer: false,
  editableBulkRfq: null,
  refreshBulkRfq: false,
};
export const bulkRfq = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_ADD_EDIT_BULK_RFQ:
      return {
        ...state,
        showAddEditBulkRfqDrawer: data.showAddEditBulkRfqDrawer,
        editableBulkRfq: data.editableBulkRfq,
      };
    case REFRESH_BULK_RFQ:
      return {
        ...state,
        refreshBulkRfq: data.refreshBulkRfq,
      };
    default:
      return state;
  }
};

import React, { useState } from "react";
import { Upload, message } from "antd";
import { cloneDeep } from "lodash";
import { CommonService_ } from "services/common/common.service";
import { getAcceptedFileTypes, maxSizeValidationMessage } from "utils/helpers";
import { useForceUpdate } from "hooks/useForceUpdate";

const DynamicUpload = ({
  form,
  isMultiple = false,
  maxSize = 200,
  acceptedFileTypes = ["image/jpeg", "image/jpg", "application/png"],
  uploadIcon,
  fieldValue,
  listType = "picture-card",
  maxCount = 1,
  initialImage,
}) => {
  const [isError, setIsError] = useState(false);
  const forceUpdate = useForceUpdate();
  const imageUrl = form.getFieldValue(fieldValue)?.imageUrl || initialImage;
  const [fileList, setFileList] = useState(() =>
    imageUrl
      ? [{ uid: "-1", name: "image", status: "done", url: imageUrl }]
      : []
  );

  const validateFileTypeAndSize = (file) => {
    const isAllowedType = acceptedFileTypes.includes(file.type);
    const isSizeValid = file.size / 1024 / 1024 < maxSize / 1024;
    if (!isAllowedType) {
      message.error(getAcceptedFileTypes(acceptedFileTypes));
      setIsError(true);
      return false;
    }

    if (!isSizeValid) {
      message.error(maxSizeValidationMessage(maxSize));
      setIsError(true);
      return false;
    }
    setIsError(false);
    return true;
  };

  const handleBeforeUpload = (file) => {
    const isValid = validateFileTypeAndSize(file);

    Object.assign(file, {
      status: isValid ? "success" : "error",
      thumbUrl: file.type?.startsWith?.("image/")
        ? file.thumbUrl || URL.createObjectURL(file)
        : undefined,
    });
    setIsError(!isValid);
    if (!isValid) return false;
    return true;
  };

  const handleCustomRequest = async (options) => {
    if (isError) return;
    CommonService_.uploadFile(options, true, (res) => {
      const data = cloneDeep(res ?? {});
      delete data.publicUrl;
      form.setFieldsValue({
        [fieldValue]: data,
      });
      forceUpdate();
    });
  };

  const handleRemove = (file) => {
    setIsError(false);
    form.setFieldsValue({
      [fieldValue]: undefined,
    });
    forceUpdate();
  };

  const handlePreview = (file) => {
    window.open(file?.response?.data?.publicUrl, "_blank", "noreferrer");
  };

  const acceptedFormats = acceptedFileTypes?.join?.(",");
  return (
    <Upload
      className={`custom-upload custom-upload-drag`}
      listType={listType}
      defaultFileList={fileList}
      name="file"
      multiple={isMultiple}
      accept={acceptedFormats}
      beforeUpload={handleBeforeUpload}
      customRequest={handleCustomRequest}
      onRemove={handleRemove}
      onPreview={handlePreview}
      maxCount={maxCount}
    >
      {uploadIcon}
    </Upload>
  );
};

export default DynamicUpload;

import React from "react";
import { UomService_ } from "services/Tenant/uom.service";
import AsyncSelectWrapper from "../AsyncSelectWrapper";

const UomSelect = ({ onChange, defaultValue, limit, inputlength }) => {
  const fetchEnquiry = async (input) => {
    if (!input || input.length < inputlength) return [];

    return UomService_.getUom({
      search: input,
      limit: limit,
      // where: {
      //"al.is_active": true,
      //},
    }).then((data) => {
      const uomOptions = data.map((o) => ({
        label: o.name,
        value: o.id,
      }));
      return uomOptions;
    });
  };

  return (
    <AsyncSelectWrapper
      onChange={onChange}
      defaultValue={defaultValue}
      fetchOptions={fetchEnquiry}
      placeholderValue={`Type at least ${inputlength} characters`}
      inputlength={inputlength}
    />
  );
};

export default UomSelect;

import { SHOW_NOTES_DRAWER } from "redux/constants/Notes";

export const showNotesDrawer = (visibility = null) => {
  return {
    type: SHOW_NOTES_DRAWER,
    data: {
      showNotesDrawer: visibility,
    },
  };
};

import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { refreshProductList, showAddEditProductDrawer } from "redux/actions";
import ProductForm from "views/app-views/catalogue/products/product-form";

export default function CatalogueDrawers() {
  const disptach = useDispatch();
  const catalogue_ = useSelector((state) => state.catalogue);
  return (
    <>
      <Drawer
        title={`${catalogue_?.editableProduct ? "Edit" : "Add"} Product`}
        width={800}
        onClose={() => {
          disptach(showAddEditProductDrawer(false));
        }}
        visible={catalogue_?.showAddEditProductDrawer}
        className="custom-drawer"
      >
        {catalogue_?.showAddEditProductDrawer && (
          <ProductForm
            setIsSubmitted={() => {}}
            isDrawerView={true}
            onDrawerClose={() => {
              disptach(showAddEditProductDrawer(false));
              disptach(refreshProductList(true));
            }}
            setIsNext={() => {
              // disptach(refreshProductList(true))
            }}
            productDetail={catalogue_?.editableProduct}
          />
        )}
      </Drawer>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Table, Select } from "antd";
import { text } from "utils/utilities";
import Text from "antd/lib/typography/Text";
import { BrandService_ } from "services/Tenant/brand.service";
import { DEFAULT_PAGE_SIZE } from "constants/common.constant";
import { OrgService_ } from "services/Tenant/organisation.service";
import Loading from "components/shared-components/Loading";
import _ from "lodash";

const LinkBrandDetailEdit = ({
  form,
  resetcsvData,
  handleDisabled,
  onResetDataComplete,
  handleUploaded,
  presetval,
  organisationData,
  productVendors,
  setProductVendors,
}) => {
  const [orgList, setOrgList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCSVData] = useState([1]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [brandOptionsData, setBrandOptionsData] = useState([]);
  useEffect(() => {
    const defaultData = presetval?.map((o) => ({
      label: o?.brand?.name,
      value: o?.brand?.id,
    }));

    if (defaultData?.length) {
      setBrandOptionsData((prev) => [...prev, ...defaultData]);
    } else {
      setBrandOptionsData([]);
    }
  }, []);

  const fetchData = async (reset, loadedFilter) => {
    OrgService_.getOrganisation({
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
      search: "",
      orderBy: "createdAt",
      order: "DESC",
      where: {},
    }).then((res) => {
      setOrgList(res);
    });
    // .finally((_) => setIsLoading(false));
  };
  useEffect(() => {
    fetchData(true);
  }, []);
  const fetchEnquiry = async (input) => {
    // if (!input || input.length < 3) return [];
    return BrandService_.getBrand({
      search: input,
      limit: 10,
      where: {
        "al.is_active": true,
      },
    })
      .then((data) => {
        const defaultData = presetval?.map((o) => ({
          label: o?.brand?.name,
          value: o?.brand?.id,
        }));
        const brandOptions = data.map((o) => ({
          label: o.name,
          value: o.id,
        }));
        setBrandOptionsData([...defaultData, ...brandOptions]);

        return brandOptions;
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchEnquiry();
  }, []);

  const handleBrandSelection = (id, ids, values) => {
    handleDisabled(false);

    setProductVendors((prev) => {
      const newValues = [...prev];
      newValues[id] = values.map((value) => ({
        item: value,
        productIndex: id,
      }));

      const allSelectedValues = newValues.reduce((acc, curr) => {
        return [...acc, ...curr];
      }, []);

      return newValues;
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const tableColumns = [
    {
      title: "brands",
      dataIndex: "brandName",
      render: (brandName, row, index) => {
        return (
          <Select
            mode="multiple"
            showSearch
            onSearch={(e) => fetchEnquiry(e)}
            filterOption={filterOption}
            // key={index}
            maxTagCount={"responsive"}
            style={{ width: 500 }}
            placeholder="Please select"
            loading={loading}
            // onChange={fetchEnquiry}
            onChange={(e, value) => handleBrandSelection(index, e, value)}
            options={_?.uniqBy(brandOptionsData, "value")}
            // onFocus={(e) => setBrandOptionsData([])}
            defaultValue={presetval?.map((brand) => brand?.brand?.id)}
          />
        );
      },
    },
  ];
  return loading || submitLoading ? (
    <Loading cover="page-center" />
  ) : (
    <>
      {csvData.length > 0 && (
        <div className="table-responsive remove-table-style custom-table-delivery-expenses">
          <Table
            className="custom-table"
            size="small"
            // loading={isLoading}
            columns={tableColumns}
            dataSource={csvData}
            rowKey="id"
            pagination={false}
          />
        </div>
      )}
    </>
  );
};

export default LinkBrandDetailEdit;

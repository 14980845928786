import { API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  QUOTATION_ENQURIY_DETAILS,
  QUOTATION_GET_CURRENCY_DETAILS,
  QUOTATION_GET_VENDORS,
  QUOTATION_UPDATE_CURRENCY_DETAILS,
  QUOTATION_UPDATE_VARIABLES,
  QUOTATION_UPDATE_VENDOR_MARGIN,
  QUOTATION_GET_PRODUCTS,
  QUOTATION_GET_CERTIFICATES,
  QUOTATION_UPDATE_PRODUCTS_MARGIN_DRAWER,
  QUOTATION_UPDATE_PRODUCTS_INLINE_MARGIN_DRAWER,
  QUOTATION_SYNC,
  QUOTATION_UPDATE_VENDOR_FREIGHT_DRAWER,
  QUOTATION_GET_VENDOR_FREIGHT_DRAWER,
  QUOTATION_UPDATE_TERMS,
  QUOTATION_GET_TERMS,
  QUOTATION_GET_DELIVERY_EXPENSES,
  QUOTATION_UPDATE_DELIVERY_EXPENSES,
  QUOTATION_UPDATE_PRODUCT_FINAL_PRICE,
  GENERATE_QUOTATION,
  GET_QUOTATIONS,
  GET_QUOTATIONS_COUNT,
  EDIT_QUOTATION,
  GET_QUOTATION,
  FINALISE_AND_ORDER,
  GET_FINALISE_AND_ORDER_CUSTOMER_ORDER,
  GET_ALL_QUOTATIONS,
  GET_CUSTOMER_ORDER_BASED_ON_ENQUIRY,
  GET_QUOTATIONS_COUNT_BASED_ON_ENQUIRY,
  QUOTATION_UPDATE_CERTIFICATE_MARGIN,
  GET_CUSTOMER_ORDER_BASED_ON_ENQUIRY_COUNT,
} = API_PATH;

export const QuotationsPageService = {
  getQuotationEnquiryDetails: (enquiryId) => {
    return fetch_({
      url: QUOTATION_ENQURIY_DETAILS(enquiryId),
      method: "GET",
    });
  },
  getQuotationDetail: (quotationId) => {
    return fetch_({
      url: GET_QUOTATION(quotationId),
      method: "GET",
    });
  },
  getAllQuotations: (filter) => {
    return fetch_({
      url: GET_ALL_QUOTATIONS(),
      method: "GET",
      params: filter,
    });
  },
  getQuotationsCount: (filter) => {
    return fetch_({
      url: GET_QUOTATIONS_COUNT(),
      method: "GET",
      params: filter,
    });
  },
  getQuotationCurrencyDetails: (quotationId) => {
    return fetch_({
      url: QUOTATION_GET_CURRENCY_DETAILS(quotationId),
      method: "GET",
    });
  },
  getQuotationVendors: (quotationId) => {
    return fetch_({
      url: QUOTATION_GET_VENDORS(quotationId),
      method: "GET",
    });
  },
  getQuotationProducts: (quotationId, filter) => {
    return fetch_({
      url: QUOTATION_GET_PRODUCTS(quotationId),
      method: "GET",
      params: filter,
    });
  },
  getQuotationCertificates: (quotationId) => {
    return fetch_({
      url: QUOTATION_GET_CERTIFICATES(quotationId),
      method: "GET",
    });
  },

  updateCertificateMargin: (quotationId, payload) => {
    return fetch_({
      url: QUOTATION_UPDATE_CERTIFICATE_MARGIN(quotationId),
      method: "PUT",
      data: payload,
    });
  },
  updateQuotationCurrency: (quotationId, payload) => {
    return fetch_({
      url: QUOTATION_UPDATE_CURRENCY_DETAILS(quotationId),
      method: "PUT",
      data: payload,
    });
  },
  editQuotationDrawer: (quotationId, payload) => {
    return fetch_({
      url: EDIT_QUOTATION(quotationId),
      method: "PUT",
      data: payload,
    });
  },
  updateQuotationProductDrawer: (quotationId, payload) => {
    return fetch_({
      url: QUOTATION_UPDATE_PRODUCTS_MARGIN_DRAWER(quotationId),
      method: "PUT",
      data: payload,
    });
  },
  updateQuotationProductMarginInlineDrawer: (quotationId, payload) => {
    return fetch_({
      url: QUOTATION_UPDATE_PRODUCTS_INLINE_MARGIN_DRAWER(quotationId),
      method: "PUT",
      data: payload,
    });
  },
  updateQuotationVariables: (quotationId, payload) => {
    return fetch_({
      url: QUOTATION_UPDATE_VARIABLES(quotationId),
      method: "PUT",
      data: payload,
    });
  },
  updateQuotationVendorMargin: (quotationId, payload) => {
    return fetch_({
      url: QUOTATION_UPDATE_VENDOR_MARGIN(quotationId),
      method: "PUT",
      data: payload,
    });
  },
  updateProductFinalPrice: (quotationId, payload) => {
    return fetch_({
      url: QUOTATION_UPDATE_PRODUCT_FINAL_PRICE(quotationId),
      method: "PUT",
      data: payload,
    });
  },
  syncQuotationScreenData: (enquiryId, payload) => {
    return fetch_({
      url: QUOTATION_SYNC(enquiryId),
      method: "POST",
    });
  },
  updateQuotationVendorFreightsData: (quoatationId, vendorId, payload) => {
    return fetch_({
      url: QUOTATION_UPDATE_VENDOR_FREIGHT_DRAWER(quoatationId, vendorId),
      method: "POST",
      data: payload,
    });
  },

  getQuotationVendorFreightsData: (quoatationId, vendorId) => {
    return fetch_({
      url: QUOTATION_GET_VENDOR_FREIGHT_DRAWER(quoatationId, vendorId),
      method: "GET",
    });
  },
  quotationUpdateTerms: (quoatationId, payload) => {
    return fetch_({
      url: QUOTATION_UPDATE_TERMS(quoatationId),
      method: "POST",
      data: payload,
    });
  },

  quotationGetTerms: (quoatationId) => {
    return fetch_({
      url: QUOTATION_GET_TERMS(quoatationId),
      method: "GET",
    });
  },
  getQuotations: (enquiryId) => {
    return fetch_({
      url: GET_QUOTATIONS(enquiryId),
      method: "GET",
    });
  },
  countQuotationsCountBasedOnEnquiry: (enquiryId) => {
    return fetch_({
      url: GET_QUOTATIONS_COUNT_BASED_ON_ENQUIRY(enquiryId),
      method: "GET",
    });
  },
  quotationUpdateDeliveryExpenses: (quoatationId, payload) => {
    return fetch_({
      url: QUOTATION_UPDATE_DELIVERY_EXPENSES(quoatationId),
      method: "POST",
      data: payload,
    });
  },
  generateQuotation: (quoatationId, payload) => {
    return fetch_({
      url: GENERATE_QUOTATION(quoatationId),
      method: "POST",
      data: payload,
    });
  },
  updateQuotationCustomerOrder: (payload) => {
    return fetch_({
      url: FINALISE_AND_ORDER(),
      method: "POST",
      data: payload,
    });
  },

  getFinaliseCustomerOrder: (filter) => {
    return fetch_({
      url: GET_FINALISE_AND_ORDER_CUSTOMER_ORDER(),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryCustomerOrderCount: (enquiryId, filter) => {
    return fetch_({
      url: GET_CUSTOMER_ORDER_BASED_ON_ENQUIRY_COUNT(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryCustomerOrder: (enquiryId, filter) => {
    return fetch_({
      url: GET_CUSTOMER_ORDER_BASED_ON_ENQUIRY(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  quotationGetDeliveryExpenses: (quoatationId) => {
    return fetch_({
      url: QUOTATION_GET_DELIVERY_EXPENSES(quoatationId),
      method: "GET",
    });
  },
  // addAdditionalTermsConditions: (termsType, formData) => {
  //     return fetch_({
  //         url: ADDITIONAL_TERMS_CONDITIONS_ADD(termsType),
  //         method: 'POST',
  //         data: formData
  //     })
  // },

  // updateAdditionalTermsConditions: (termsType, additionalTermId, formData) => {
  //     return fetch_({
  //         url: ADDITIONAL_TERMS_CONDITIONS_UPDATE(termsType, additionalTermId),
  //         method: 'PUT',
  //         data: formData
  //     })
  // },

  // deleteAddTermsConditions: (termsType, additionalTermId) => {
  //     return fetch_({
  //         url: ADDITIONAL_TERMS_CONDITIONS_DELETE(termsType, additionalTermId),
  //         method: 'DELETE'
  //     })
  // },
};

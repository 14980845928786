import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  ENQUIRY_RFQ_RESOURCE_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_FINALISED_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_SUBMIT_FINALISED_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_ENQUIRY_PRODUCT_DETAIL,
  ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_CERTIFICATES,
  ENQUIRY_RFQ_RESOURCE_TOTAL_FINALISED_CERTIFICATES,
} = API_PATH;

export const EnquiryFinalisedProduct = {
  getEnquiryFinalisedProducts: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_PRODUCT_DETAIL(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryProductsRfqs: (enquiryId, enquiryProductId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_ENQUIRY_PRODUCT_DETAIL(
        enquiryId,
        enquiryProductId
      ),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryFinalisedProductsDetails: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_FINALISED_PRODUCT_DETAIL(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryReviewFinalisedProductsDetails: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_PRODUCT_DETAIL(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryReviewFinalisedCertificates: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_CERTIFICATES(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryFinalisedCertificatesTotal: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_TOTAL_FINALISED_CERTIFICATES(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  createEnquiryFinalisedProducts: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_RFQ_RESOURCE_SUBMIT_FINALISED_PRODUCT_DETAIL(enquiryId),
      method: "POST",
      data: filter,
    });
  },
};

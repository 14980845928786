import { API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
    COMMON_FILE_UPLOAD
} = API_PATH;

export const CommonService_ = {
    uploadFile: (options, withPublicUrl = false, callback) => {
        const { onSuccess, onError, file, onProgress } = options;
        const data = new FormData()
        data.append('file', file)
        data.append('withPublicUrl', withPublicUrl)
        fetch_({
            url: COMMON_FILE_UPLOAD,
            method: "POST",
            data,
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                onProgress && onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        }).then(data => {
            onSuccess(data);
            callback(data)
        }).catch(err => {
            console.log(err)
            onError({ message: `Upload error` });
        })
    },
};

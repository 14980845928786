import {
  REFRESH_BRAND_LIST,
  REFRESH_BRAND_NEW_DATA,
  SHOW_ADD_EDIT_BRAND_DRAWER,
} from "redux/constants/Brand";

export const showAddEditBrandDrawer = (
  visibility = false,
  editableBrand = null
) => {
  return {
    type: SHOW_ADD_EDIT_BRAND_DRAWER,
    data: {
      showAddEditBrandDrawer: visibility,
      editableBrand,
    },
  };
};

export const refreshBrandList = (refresh = false) => {
  return {
    type: REFRESH_BRAND_LIST,
    data: {
      refreshBrandList: refresh,
    },
  };
};
export const refreshNewButtonData = (refreshNewData = false) => {
  return {
    type: REFRESH_BRAND_NEW_DATA,
    data: {
      refreshNewData: refreshNewData,
    },
  };
};

import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Form,
  Table,
  DatePicker,
  Card,
  Divider,
  message,
  Spin,
  Button,
  Tooltip,
  Drawer,
} from "antd";
import { ROW_GUTTER } from "constants/ThemeConstant";
import Text from "antd/lib/typography/Text";
import { CloseCircleOutlined, EditFilled } from "@ant-design/icons";
import { DATE_FORMAT_DD_MM_YYYY } from "constants/DateConstant";
import moment from "moment";
import Loading from "components/shared-components/Loading";
import { EnquiryService_ } from "services/Tenant/enquiry.service";
import EnquiryRefSelect from "components/shared-components/EnquiryRefSelect";
import { EnquiryProductService_ } from "services/Tenant/enquiry_product.service";
import { CurrencyService_ } from "services/common/currencies.service";
import { OrgVendorsService_ } from "services/Tenant/organisation_vendor.service";
import VendorRefSelect from "components/shared-components/VendorRefSelect";
import AddressDrawer from "./delivery-address";
import CommonAddressDrawer from "drawers/commonAddressDrawer";
import EnquirySelect from "components/shared-components/EnquirySelect";
import { AddressService_ } from "services/Tenant/address.service";

const RfqDetail = ({
  props,
  handleSelectedProduct,
  enquiryId,
  form,
  addDeliveryAddress_,
  setAddDeliveryAddress_,
}) => {
  const { TextArea } = Input;
  const [isDetailLoading, setIsDetailLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enquirydetails, setEnquirydetails] = useState(null);
  const [selectedManual, setSelectedManual] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productsListData, setProductsListData] = useState([]);
  const [showProductTable, setShowProductTable] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [selectGroup, setSelectGroup] = useState(true);
  const [currencyList, setCurrencyList] = useState([]);
  const [deliveryAddress, setShowDeliveryAddress] = useState(false);
  const [defaultTenantAddress, setDefaultTenantAddress] = useState({});
  const [filter_, setFilter_] = useState({
    where: {
      addressType: "Delivery/Warehouse",
    },
  });
  useEffect(() => {
    AddressService_.getDefaultTenantAddress(filter_).then((data) => {
      setDefaultTenantAddress(data);
    });
  }, [filter_]);

  const rules = {
    enqRef: [
      {
        required: true,
        message: "Please enter Ref. Record",
      },
    ],
    dueDate: [
      {
        required: true,
        message: "Please select Due Date",
      },
    ],
    deliveryto: [
      {
        required: true,
        message: "Delivery Country is required",
      },
    ],
  };
  useEffect(() => {
    setAddDeliveryAddress_(addDeliveryAddress_);
    form.setFieldsValue({
      deliveryto: addDeliveryAddress_?.deliveryCountry,
    });
  }, [addDeliveryAddress_]);
  useEffect(() => {
    if (enquiryId && form) {
      setIsDetailLoading(true);
      enquiryData(enquiryId);
    }
  }, [enquiryId, form]);

  const showDeliveryAddressDrawer = () => {
    setShowDeliveryAddress(true);
  };
  const groupoptions = [
    { value: "product", label: "All Products" },
    { value: "brand", label: "Select by Brand" },
    { value: "manual", label: "Manual Selection" },
  ];

  useEffect(() => {
    const { address, city, state, country, postCode, landmark } =
      defaultTenantAddress;

    const fullAdress = {
      [`${"delivery"}Address`]: address || "",
      [`${"delivery"}City`]: city || "",
      [`${"delivery"}State`]: state || "",
      [`${"delivery"}Country`]: country || "",
      [`${"delivery"}PostCode`]: postCode || "",
      [`${"delivery"}Landmark`]: landmark || "",
      [`${"delivery"}AddressName`]: defaultTenantAddress?.addressName || "",
    };

    setAddDeliveryAddress_(fullAdress);
  }, [defaultTenantAddress, setAddDeliveryAddress_]);
  console.log(addDeliveryAddress_, "addDeliveryAddress_");
  let deliveryAddressParts = [];
  if (addDeliveryAddress_?.deliveryAddress) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryAddress);
  }
  if (addDeliveryAddress_?.deliveryCity) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryCity);
  }
  if (addDeliveryAddress_?.deliveryState) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryState);
  }
  if (addDeliveryAddress_?.deliveryCountry) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryCountry);
  }
  if (addDeliveryAddress_?.deliveryPostCode) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryPostCode);
  }
  if (addDeliveryAddress_?.deliveryLandmark) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryLandmark);
  }
  const fullDeliveryAddress = deliveryAddressParts.join(", ");
  const disabledbeforeDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const modalcolumns = [
    {
      title: "sku",
      dataIndex: "sku",
      render: (_, row) => (
        <Text ellipsis={{ tooltip: row?.product?.sku }}>
          {<>{row?.product?.sku}</>}
        </Text>
      ),
    },
    {
      title: "PartNo",
      dataIndex: "PartNo",
      render: (_, row) => (
        <Text ellipsis={{ tooltip: row?.product?.partNumber }}>
          {<>{row?.product?.partNumber}</>}
        </Text>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, row) => (
        <Text ellipsis={{ tooltip: row?.product?.name }}>
          {row?.product?.name}
        </Text>
      ),
    },
    {
      title: "Mfr",
      dataIndex: "mfr",
      render: (_, row) => (
        <Text ellipsis={{ tooltip: row?.product?.brand?.name }}>
          {<>{row?.product?.brand?.name}</>}
        </Text>
      ),
    },
    {
      title: "qty",
      dataIndex: "quantity",
      align: "right",
      render: (_, row) => (
        <Text ellipsis={{ tooltip: row?.quantity }}>
          {<>{row?.quantity}</>}
        </Text>
      ),
    },
    {
      title: "Rfq",
      dataIndex: "rfq",
      align: "right",
      render: (_, row) => (
        <Text
          ellipsis={{
            tooltip: `${row.submittedRFQsCount}/${row.totalRFQsCount}`,
          }}
        >
          {`${row.submittedRFQsCount}/${row.totalRFQsCount}`}
        </Text>
      ),
    },
  ];

  function handleVendorChange(value) {
    getvendorDetails(value.value);
  }

  function handleChange(value) {
    setIsDetailLoading(true);
    enquiryData(value.value);
  }

  function handleGroup(value) {
    if (value == "brand") {
      setSelectedBrand(true);
      setSelectedManual(false);
      setSelectedProduct(false);
      setShowProductTable(true);
      setProductsListData("");
    } else if (value == "manual") {
      setSelectedManual(true);
      setSelectedProduct(false);
      setSelectedBrand(false);
      setShowProductTable(true);
      setProductsListData("");
    } else if (value == "product") {
      setSelectedProduct(true);
      setSelectedManual(false);
      setShowProductTable(true);
      setSelectedBrand(false);
      setProductsListData(productList);
    } else {
      setSelectedManual(false);
      setShowProductTable(false);
      setSelectedBrand(false);
      setShowProductTable(false);
    }
  }
  const enquiryProduct = (enquiryId) => {
    EnquiryProductService_.getEnquiryProduct(enquiryId)
      .then((data) => {
        if (data.length > 0) {
          setProductsListData("");
          setProductList(data);
        } else {
          setProductList("");
          setProductsListData("");
          setShowProductTable(false);
        }
      })
      .finally(() => setIsDetailLoading(false));
  };
  const getvendorDetails = (vendorId) => {
    if (!selectedVendor.some((selvender) => selvender.vendor.id === vendorId)) {
      OrgVendorsService_.getOrganisationvendordetail(vendorId).then((data) => {
        setSelectedVendor((prevItems) => [
          ...prevItems,
          { vendor: data.vendor, contacts: data.contacts },
        ]);
      });
    } else {
      OrgVendorsService_.getOrganisationvendordetail(vendorId).then((data) => {
        message.error(
          `${data.vendor?.vendorCode ?? ""} - ${
            data.vendor?.organisation?.name ?? ""
          } : already exists`
        );
      });
    }
  };
  const enquiryData = (selectVal) => {
    EnquiryService_.getEnquirydetail(selectVal)
      .then((data) => {
        setEnquirydetails(data);
        enquiryProduct(selectVal);
      })
      .finally(() => setIsDetailLoading(false));
  };

  function getUniqueBrands(productList) {
    if (productList.length > 0) {
      const uniqueBrands = productList
        .map((item) => ({
          id: item.product.brand.id,
          name: item.product.brand.name,
        }))
        .filter(
          (value, index, self) =>
            self.findIndex((item) => item.id === value.id) === index
        );

      return uniqueBrands;
    }
  }

  const uniqueBrands = getUniqueBrands(productList);

  function handleBrandChange(value) {
    const filteredData = searchBrandData(productList, value);
    setProductsListData(filteredData);
  }

  const searchBrandData = (data, brandIds) => {
    return data.filter((item) => brandIds.includes(item.product.brand.id));
  };

  function handleProductChange(value) {
    const filteredData = searchProductData(productList, value);
    setProductsListData(filteredData);
  }

  const searchProductData = (data, productIds) => {
    return data.filter((item) => productIds.includes(item.product.id));
  };

  if (selectedProduct) {
    handleSelectedProduct(productList);
  } else {
    handleSelectedProduct(productsListData);
  }

  const handleRemoveVendor = (item) => {
    const updatedItems = selectedVendor.filter(
      (data) => data?.vendor?.id !== item?.vendor?.id
    );
    setSelectedVendor(updatedItems);
  };

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        {enquiryId ? (
          <></>
        ) : (
          <>
            {/*  <Col xs={24} sm={24} md={12}>
            <Form.Item name="enqRef" label="Ref Record" rules={rules.enqRef}>
              <EnquiryRefSelect onChange={handleChange} clearOnSelect />
            </Form.Item>
          </Col>*/}

            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="enqRef"
                label="Enq Ref Record"
                rules={rules.enqRef}
              >
                <EnquirySelect
                  onChange={handleChange}
                  clearOnSelect
                  limit={10}
                  inputlength={3}
                />
              </Form.Item>
            </Col>
          </>
        )}

        <Col xs={24} sm={24} md={12}>
          {enquirydetails ? (
            <Form.Item label="Reference">
              {enquirydetails?.enquiryCode}
            </Form.Item>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      {isDetailLoading ? (
        <Loading cover="page-center" styles={{ marginTop: 50 }} />
      ) : (
        enquirydetails && (
          <>
            {productList ? (
              <Row gutter={ROW_GUTTER}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="brand" label="Select Group">
                    <Select options={groupoptions} onChange={handleGroup} />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  {selectedBrand ? (
                    <Form.Item name="brands" label="Brands">
                      <Select
                        showSearch
                        filterOption={(inputValue, option) =>
                          option.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                        mode="multiple"
                        size={"small"}
                        maxTagCount={"responsive"}
                        placeholder="Please select"
                        defaultValue={[]}
                        options={uniqueBrands?.map(({ id, name }) => ({
                          label: name,
                          value: id,
                        }))}
                        onChange={handleBrandChange}
                      />
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                  {selectedManual ? (
                    <Form.Item name="products" label="Products">
                      <Select
                        showSearch
                        filterOption={(inputValue, option) =>
                          option.label
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                        mode="multiple"
                        size={"small"}
                        maxTagCount={"responsive"}
                        placeholder="Please select"
                        defaultValue={[]}
                        options={productList.map(({ id, product }) => ({
                          value: product?.id,
                          label: product?.partNumber + " - " + product?.name,
                        }))}
                        onChange={handleProductChange}
                      />
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            ) : (
              <>
                <div>No product found</div>
              </>
            )}

            {showProductTable && (
              <>
                <Divider style={{ margin: "1px" }} />
                <Spin spinning={isLoading}>
                  <div className="table-responsive">
                    {productsListData.length > 0 ? (
                      <Table
                        className="custom-table"
                        showHeader={true}
                        size="small"
                        loading={isLoading}
                        columns={modalcolumns}
                        dataSource={productsListData}
                        pagination={false}
                        scroll={{ y: 100 }}
                      />
                    ) : (
                      <div>No data to display</div>
                    )}
                  </div>
                </Spin>
                <Divider
                  style={{ margin: "10px 0px 0px 0px", visibility: "hidden" }}
                />
              </>
            )}
            {productList && showProductTable ? (
              <>
                <Row gutter={ROW_GUTTER}>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="dueDate"
                      label="Due Date"
                      // rules={rules.dueDate}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format={DATE_FORMAT_DD_MM_YYYY}
                        disabledDate={disabledbeforeDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item name="notes" label="Note" rules="">
                      <TextArea autoSize />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={ROW_GUTTER}>
                  <Col xs={24} sm={24} md={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Item
                        label={
                          <span>
                            Delivery to.&nbsp;
                            <Tooltip
                              trigger={["hover"]}
                              placement="topLeft"
                              title="Replace"
                            >
                              <EditFilled
                                onClick={showDeliveryAddressDrawer}
                                style={{ color: "#3e79f7" }}
                              />
                            </Tooltip>
                          </span>
                        }
                        name="deliveryto"
                        rules={rules.deliveryto}
                      >
                        {fullDeliveryAddress}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row gutter={ROW_GUTTER}>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item label="Vendor ref.">
                      <VendorRefSelect
                        onChange={handleVendorChange}
                        clearOnSelect
                        limit={10}
                        inputlength={3}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}></Col>
                </Row>
              </>
            ) : (
              <></>
            )}
            {selectedVendor.map((item, index) => (
              <Row gutter={ROW_GUTTER} key={item?.vendor?.id}>
                <Col xs={24} sm={24} md={10}>
                  <Form.Item
                    label="Name"
                    name={["vendor", item?.vendor?.id, "vendorid"]}
                    initialValue={item?.vendor?.id}
                  >
                    <Input
                      type="hidden"
                      key={item?.vendor?.id}
                      value={item?.vendor?.id}
                      defaultValue={item?.vendor?.id}
                    />
                    {item?.vendor?.organisation?.name}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={10}>
                  <Form.Item
                    name={["contact", item?.vendor?.id, "vendorContactId"]}
                    label="Contact"
                    initialValue={{
                      key: item?.contacts[0]?.vendorContactId,
                      label: `${item?.contacts[0]?.firstName} ${item?.contacts[0]?.lastName}`,
                    }} // Assuming this is the ID you want to preselect
                  >
                    <Select
                      labelInValue
                      value={{
                        key: item?.contacts[0]?.vendorContactId,
                        label: `${item?.contacts[0]?.firstName} ${item?.contacts[0]?.lastName}`,
                      }}
                      options={item?.contacts.map(
                        ({ vendorContactId, firstName, lastName }) => ({
                          value: vendorContactId,
                          label: `${firstName} ${lastName}`,
                        })
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={4}>
                  <a
                    key={item?.vendor?.id}
                    onClick={() => handleRemoveVendor(item)}
                  >
                    <CloseCircleOutlined style={{ color: "#f5222d" }} />
                  </a>
                </Col>
              </Row>
            ))}
          </>
        )
      )}
      <Drawer
        title="Delivery to. Address"
        width={700}
        onClose={() => {
          setShowDeliveryAddress(false);
        }}
        visible={deliveryAddress}
        className="custom-drawer"
      >
        {deliveryAddress && (
          <CommonAddressDrawer
            onDrawerClose={() => {
              setShowDeliveryAddress(false);
            }}
            mode="ADD"
            setAddress={setAddDeliveryAddress_}
            presetval={addDeliveryAddress_}
            addressTypeName="deliveryAddress"
            isTeanant="true"
            teanantAddressfilter="Delivery/Warehouse"
            defaultAddress={defaultTenantAddress}
          />
        )}
      </Drawer>
    </>
  );
};

export default RfqDetail;

import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  ORGANISATION_LIST,
  ORGANISATION_COUNT,
  ORGANISATION_REGISTER,
  ORGANISATION_UPDATE,
  ORGANISATION_DETAIL,
  ORGANISATION_DELETE,
  BULK_ORGANISATION_SAMPLE_SHEET,
  BULK_ORGANISATION_UPLOAD,
  LINK_ORGANISATION_SAMPLE_SHEET,
  LINK_ORGANISATION_UPLOAD,
  EDIT_LINK_ORGANISATION,
} = API_PATH;

export const OrgService_ = {
  getOrganisation: (filter) => {
    return fetch_({
      url: ORGANISATION_LIST,
      method: "GET",
      params: filter,
    });
  },
  countOrganisation: (filter) => {
    return fetch_({
      url: ORGANISATION_COUNT,
      method: "GET",
      params: filter,
    });
  },
  getOrganisationdetail: (organisationId) => {
    return fetch_({
      url: ORGANISATION_DETAIL(organisationId),
      method: "GET",
    });
  },
  addOrganisation: (organisation) => {
    return fetch_({
      url: ORGANISATION_REGISTER,
      method: "POST",
      data: organisation,
    });
  },
  updateOrganisation: (organisationId, organisation) => {
    return fetch_({
      url: ORGANISATION_UPDATE(organisationId),
      method: "PUT",
      data: organisation,
    });
  },
  deleteOrganisation: (organisationId) => {
    return fetch_({
      url: ORGANISATION_DELETE(organisationId),
      method: "DELETE",
    });
  },
  downloadBulkOrganisationSampleSheet: () => {
    return fetch_({
      url: BULK_ORGANISATION_SAMPLE_SHEET(),
      method: "GET",
    });
  },
  addBulkOrganisation: (formData) => {
    return fetch_({
      url: BULK_ORGANISATION_UPLOAD(),
      method: "POST",
      data: formData,
    });
  },
  downloadLinkOrganisationSampleSheet: () => {
    return fetch_({
      url: LINK_ORGANISATION_SAMPLE_SHEET(),
      method: "GET",
    });
  },
  addBulkLinkOrganisation: (formData) => {
    return fetch_({
      url: LINK_ORGANISATION_UPLOAD(),
      method: "POST",
      data: formData,
    });
  },
  editLinkOrganisation: (orgId, formData) => {
    return fetch_({
      url: EDIT_LINK_ORGANISATION(orgId),
      method: "PUT",
      data: formData,
    });
  },
};

import { Button, Modal } from "antd";
import { DEFAULT_PAGE_SIZE } from "constants/common.constant";
import {
  DeleteOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

export const baseFilterCriteria = (config) => {
  const baseFilter = {
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
    search: "",
    orderBy: "createdAt",
    order: "DESC",
    where: {},
  };
  return baseFilter;
};

export const deleteHandler = ({ onOk, subject }) => {
  Modal.confirm({
    title: "Confirm",
    icon: <DeleteOutlined />,
    content: `Are you sure to delete the ${subject}`,
    okText: "Yes",
    cancelText: "No",
    onOk: onOk,
  });
};

export const restoreHandler = ({ onOk, subject }) => {
  Modal.confirm({
    title: "Confirm",
    icon: <CheckCircleOutlined />,
    content: `Are you sure to restore the ${subject}`,
    okText: "Yes",
    cancelText: "No",
    onOk: onOk,
  });
};

export const text = (text) => {
  return text;
};

export const getemail = (email) => {
  return email;
};

export const getcountry = (country) => {
  return country;
};

export const gettel = (tel) => {
  return tel;
};

export const getUploadIcon = (form, namePath, hideUploadIcon = true) => {
  return form.getFieldValue(namePath) ? null : (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
};
export const getDownloadIcon = (form, namePath, hideUploadIcon = true) => {
  return (
    <div>
      <DownloadOutlined />
      <div className="ant-upload-text">Download</div>
    </div>
  );
};
export const getUploadIconForCSV = (form, namePath, hideUploadIcon = true) => {
  return form.getFieldValue(namePath) ? null : (
    <div>
      <UploadOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
};

export const getUploadedFileInfo = (file) => {
  return file
    ? {
        basePath: file?.basePath,
        fileName: file?.fileName,
        uploadType: file?.uploadType,
      }
    : undefined;
};

export const contactType = () => [
  { name: "Buyer" },
  { name: "Sales" },
  { name: "Expediting" },
  { name: "Accounts" },
  { name: "Admin" },
];

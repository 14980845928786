import React from "react";
import AsyncSelect from "react-select/async";
import { CustomerContractService_ } from "services/Tenant/customer_contract.service";
import { reactSelectCustomStyle } from "styles/react-select-style";

export default function PartNumberSelect({
  onChange,
  defaultValue,
  key,
  value,
}) {
  const fetchBrands = async (input) => {
    console.log(value);
    if (!input && !value) return [];
    return CustomerContractService_.getContractProduct(value, {
      search: input,
    }).then((data) => {
      const brandOptions = data.map((o) => ({
        ...o,
        label: o?.product?.partNumber,
        value: o?.product?.partNumber,
      }));
      return brandOptions;
    });
  };

  return (
    <AsyncSelect
      //   key={key}
      styles={reactSelectCustomStyle}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        NoOptionsMessage: () => "",
      }}
      defaultOptions
      placeholder="Type & Select"
      loadOptions={fetchBrands}
      onChange={onChange}
      defaultValue={defaultValue}
      openMenuOnClick={false}
    />
  );
}

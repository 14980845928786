import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  refreshReceiveProductList,
  showAddEditReceiveProductDrawer,
} from "redux/actions";
import ReceiveProductForm from "views/app-views/purchase-order/vendor-order-form/receive-product-form";

export default function ReceiveProductDrawers() {
  const disptach = useDispatch();
  const receiveproduct_ = useSelector((state) => state.receiveproduct);
  return (
    <>
      <Drawer
        title={`${
          receiveproduct_?.editableReceiveProduct
            ? "Receive PO Products"
            : "Receive PO Products"
        } `}
        width={900}
        onClose={() => {
          disptach(showAddEditReceiveProductDrawer(false));
        }}
        visible={receiveproduct_?.showAddEditReceiveProductDrawer}
        className="custom-drawer"
      >
        {receiveproduct_?.showAddEditReceiveProductDrawer && (
          <ReceiveProductForm
            setIsSubmitted={() => {}}
            isDrawerView={true}
            onDrawerClose={() => {
              disptach(showAddEditReceiveProductDrawer(false));
              disptach(refreshReceiveProductList(true));
            }}
            setIsNext={() => {
              disptach(refreshReceiveProductList(true));
            }}
            brandId={receiveproduct_?.editableReceiveProduct}
            purchaseOrderId={receiveproduct_?.purchaseOrderId}
            editableReceiveProduct={receiveproduct_?.editableReceiveProduct}
          />
        )}
      </Drawer>
    </>
  );
}

export const Subjects = {
  //additional access
  TENANTS_PROCESS: "tenant_process",
  DASHBOARD: "dashboard",

  //public
  SUPER_USERS: "super_users",
  TENANTS: "tenants",
  PROFILES: "profiles",
  INVOICE: "invoice",
  ADDRESS_TYPES: "address_types",
  DELIVERY_EXPENSES_TYPES: "delivery_expenses_types",
  TERMS_CONDITIONS: "terms_conditions",
  BUSINESS_TYPES: "business_types",
  TAX_TYPES: "tax_types",
  IMPEX_TYPES: "impex_types",
  CURRENCIES: "currencies",
  CURRENCIES_CONVERSION: "currencies_mapper",
  COUNTRIES: "countries",

  //tenant
  _TENANT_USERS: "users",
  _PRODUCTS: "products",
  _PROFILES: "profiles",
  _ADDRESS_TYPES: "address_types",
  _BUSINESS_TYPES: "business_types",
  _TAX_TYPES: "tax_types",
  _IMPEX_TYPES: "impex_types",
  _CURRENCIES: "currencies",
  _CURRENCIES_CONVERSION: "currencies_mapper",
  _COUNTRIES: "countries",

  _ORGANISATIONS: "organisations",
  _CUSTOMERS: "customers",
  _VENDORS: "vendors",
};

export const Action = {
  Manage: "manage",
  Create: "create",
  Read: "read",
  Update: "update",
  Delete: "delete",
};

import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { refreshRfqList, showAddEditRfqDrawer } from "redux/actions";
import RfqForm from "views/app-views/rfq/rfq-form";

export default function RfqDrawers() {
  const disptach = useDispatch();
  const rfq_ = useSelector((state) => state.rfq);
  return (
    <>
      <Drawer
        title={`${rfq_?.editableRfq ? "Quick RFQ" : "Quick RFQ"} `}
        width={800}
        onClose={() => {
          disptach(showAddEditRfqDrawer(false));
        }}
        visible={rfq_?.showAddEditRfqDrawer}
        className="custom-drawer"
      >
        {rfq_?.showAddEditRfqDrawer && (
          <RfqForm
            setIsSubmitted={() => {}}
            isDrawerView={true}
            onDrawerClose={() => {
              disptach(showAddEditRfqDrawer(false));
              disptach(refreshRfqList(true));
            }}
            rfqDetail={rfq_?.editableRfq}
          />
        )}
      </Drawer>
    </>
  );
}

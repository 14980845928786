import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { refreshRfqList } from "redux/actions";
import { showAddEditBulkOrganisation } from "redux/actions/BulkOrganisationAdd";
import {
  refreshLinkBrand,
  showAddEditLinkBrand,
} from "redux/actions/LinkBrand";
import BulkBrandForm from "views/app-views/bulk-brand";
import BulkOrganisationForm from "views/app-views/bulk-organisation";
import LinkBrandForm from "views/app-views/link-brand";

export default function LinkBrand() {
  const disptach = useDispatch();
  const linkBrand = useSelector((state) => state.linkBrand);
  return (
    <>
      <Drawer
        title={`Link Brand`}
        width={"90%"}
        onClose={() => {
          disptach(showAddEditLinkBrand(false));
        }}
        visible={linkBrand?.showAddEditLinkBrandDrawer}
        className="custom-drawer"
      >
        {linkBrand?.showAddEditLinkBrandDrawer && (
          <LinkBrandForm
            setIsSubmitted={() => {}}
            isDrawerView={true}
            onDrawerClose={() => {
              disptach(showAddEditLinkBrand(false));
              disptach(refreshLinkBrand(true));
            }}
            rfqDetail={linkBrand?.editableRfq}
          />
        )}
      </Drawer>
    </>
  );
}

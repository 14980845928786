import {
  REFRESH_PRODUCT_LIST,
  SHOW_ADD_BRAND_DRAWER,
  SHOW_ADD_EDIT_PRODUCT_DRAWER,
  SHOW_EDIT_BRAND_DRAWER,
} from "redux/constants/Catalogue";

export const showAddEditProductDrawer = (
  visibility = false,
  editableProduct = null
) => {
  return {
    type: SHOW_ADD_EDIT_PRODUCT_DRAWER,
    data: {
      showAddEditProductDrawer: visibility,
      editableProduct,
    },
  };
};

export const refreshProductList = (refresh = false) => {
  return {
    type: REFRESH_PRODUCT_LIST,
    data: {
      refreshProductList: refresh,
    },
  };
};

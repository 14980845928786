import React, { useState } from "react";
import { Form, Button, Space, Modal, Layout, message } from "antd";
import { ADD, EDIT } from "../../../../constants/common.constant";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Content, Footer } from "antd/lib/layout/layout";
import RfqDetail from "./rfq-detail";
import { RfqService_ } from "services/Tenant/rfq.service";
import { openNotification } from "components/shared-components/NotificationTile";

const RfqForm = (props) => {
  const { rfqDetail, onDrawerClose } = props;
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const enquiryId = rfqDetail;
  const mode = enquiryId ? EDIT : ADD;
  const [addDeliveryAddress_, setAddDeliveryAddress_] = useState({
    deliveryAddress: "",
    deliveryAddressName: "",
    deliveryCity: "",
    deliveryCountry: "",
    deliveryLandmark: "",
    deliveryPostCode: "",
    deliveryState: "",
  });

  const handleSelectedProd = (saveProd) => {
    setSelectedProduct(saveProd);
  };

  const onFinish = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        const combinedAddress = {
          ...addDeliveryAddress_,
        };
        // const vendorRefDetails = values.vendor.map((item, index) => {
        //   return {
        //     ...item,
        //     ...values.contact[index],
        //   };
        // });

        const enquiryProduct = selectedProduct.map((prod) => {
          return {
            enquiryProduct: prod.id,
            rfqQuantity: prod.quantity,
          };
        });
        const payload = Object.keys(values.contact).map((vendorId) => ({
          vendor: vendorId,
          contact: values.contact[vendorId]?.vendorContactId?.key || null,
        }));
        const selectedvendor = payload.map((vendor) => {
          return {
            vendor: vendor.vendor,
            contact: vendor.contact,
            enquiry: enquiryId ? enquiryId : values.enqRef?.value,
            dueDate: values.dueDate,
            notes: values.notes,
            products: enquiryProduct,
            rfqAddress: combinedAddress,
          };
        });

        RfqService_.addBulkRfq(selectedvendor)
          .then((data) => {
            onDrawerClose();
            //message.success(`Rfq added successfully`);
            openNotification("Success", `Rfq added successfully`, "success");
          })
          .finally((_) => setSubmitLoading(false));
      })
      .catch((info) => {
        setSubmitLoading(false);
        message.error("Please enter all required field ");
      });
  };

  return (
    <>
      <Form
        layout={"horizontal"}
        form={form}
        name="rfq-form"
        autoComplete="off"
      >
        <Layout>
          <Content>
            <RfqDetail
              {...props}
              handleSelectedProduct={handleSelectedProd}
              form={form}
              enquiryId={enquiryId}
              addDeliveryAddress_={addDeliveryAddress_}
              setAddDeliveryAddress_={setAddDeliveryAddress_}
            />
          </Content>

          <Footer>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  form.validateFields().then(() => {
                    Modal.confirm({
                      title: "Confirm",
                      icon: <QuestionCircleFilled />,
                      content: "Are you sure to proceed.",
                      okText: "Yes",
                      cancelText: "No",
                      onOk: () => {
                        onFinish(true);
                      },
                    });
                  });
                }}
                htmlType="submit"
                loading={submitLoading}
                disabled={submitLoading}
                size="small"
              >
                {mode === ADD ? "Save" : `Save`}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  onDrawerClose();
                }}
                size="small"
              >
                Cancel
              </Button>
            </Space>
          </Footer>
        </Layout>
      </Form>
    </>
  );
};

export default RfqForm;

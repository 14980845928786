import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Divider, List } from "antd";

import DetailTab from "./view-product-tabs/detailTab";
import RfqTab from "./view-product-tabs/rfqTab";
import HistoryTab from "./view-product-tabs/historyTab";
import ViewResourceTab from "./view-product-tabs/viewResourceTab";
import ViewCertificateTab from "./view-product-tabs/viewCertificateTab";
import { useSelector } from "react-redux";
import { EnquiryService_ } from "services/Tenant/enquiry.service";
import { EnquiryProductService_ } from "services/Tenant/enquiry_product.service";
import { CustomerProductService_ } from "services/Tenant/customer_product.service";

const { TabPane } = Tabs;

const ViewProductForm = ({ props, certificateTab, productDetails }) => {
  const viewEnquiryProduct = useSelector((state) => state.viewEnquiryProduct);
  const [activeTab, setActiveTab] = useState(() =>
    viewEnquiryProduct?.tab == "rfq"
      ? "4"
      : viewEnquiryProduct?.tab == "certificates"
      ? "3"
      : "1"
  );
  const enquiryId = viewEnquiryProduct?.enquiryId;
  const enquiryProductId = viewEnquiryProduct?.enquiryProductId;
  // const productId = viewEnquiryProduct?.productId;
  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [custProductdetails, setCustProductdetails] = useState(null);
  const [enquiryProductdetails, setEnquiryProductdetails] = useState(null);
  const [productId, setProductId] = useState(null);
  const data = [
    {
      sku: enquiryProductdetails?.[0]?.product?.sku,
      name: enquiryProductdetails?.[0]?.product?.name,
      partNumber: enquiryProductdetails?.[0]?.product?.partNumber,
      type: <a href=""></a>,
    },
  ];

  useEffect(() => {
    setActiveTab(certificateTab);
  }, [certificateTab, productDetails]);

  function handleTabChange(key) {
    setActiveTab(key);
  }

  const getProductList = (enquiryId) => {
    // setIsLoading(true);
    if (enquiryId) {
      EnquiryService_.getEnquirydetail(enquiryId).then((data) => {
        setCustomerId(data?.customer?.id);
        EnquiryProductService_.getEnquiryProductdetail(
          enquiryId,
          enquiryProductId
        ).then((data) => {
          setTimeout(() => {
            customerProductData(customerId, data[0]?.product?.id);
            setProductId(data[0]?.product?.id);
          }, 100);
          setEnquiryProductdetails(data);
        });
      });
      // .finally(() => setIsLoading(false));
    }
  };
  useEffect(() => {
    setCustProductdetails(null);
    getProductList(enquiryId, enquiryProductId);
  }, [enquiryId, enquiryProductId, productId]);

  const customerProductData = (customerId, productId) => {
    setIsLoading(true);
    if (customerId) {
      CustomerProductService_.getCustoerProductDescription(
        customerId,
        productId
      )
        .then((data) => {
          setCustProductdetails(data);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <>
      <div className="container">
        <Row>
          <Col span={24}>
            <List itemLayout="horizontal" dataSource={data} />
          </Col>
        </Row>

        <Divider className="ant-divider-horizontal-tab" />
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Details" key="1">
            <DetailTab
              productDetails={productDetails}
              enquiryId={enquiryId}
              enquiryProductId={enquiryProductId}
              productId={productId}
              custProductdetails={custProductdetails}
              enquiryProductdetails={enquiryProductdetails}
              isLoading={isLoading}
            />
          </TabPane>
          {/* <TabPane tab="Resource" key="2">
            <ViewResourceTab enquiryId={enquiryId} productId={productId} />
          </TabPane>
          <TabPane tab="Certificate" key="3">
            <ViewCertificateTab
              enquiryId={enquiryId}
              enquiryProductId={enquiryProductId}
            />
          </TabPane> */}
          <TabPane tab="RFQs" key="4">
            <RfqTab enquiryId={enquiryId} enquiryProductId={enquiryProductId} />
          </TabPane>
          {/* <TabPane tab="History" key="5">
            <HistoryTab />
          </TabPane> */}
        </Tabs>
      </div>
    </>
  );
};

export default ViewProductForm;

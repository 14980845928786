import { SHOW_ADD_EDIT_VIEW_ENQUIERY_DRAWER } from "redux/constants/ViewEnquiryProduct";

const initialState = {
  showAddEditViewEnquiryProductDrawer: false,
  enquiryId: undefined,
  enquiryProductId: undefined,
  productId: undefined,
  tab: "details",
};
export const viewEnquiryProduct = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_ADD_EDIT_VIEW_ENQUIERY_DRAWER:
      return {
        ...state,
        showAddEditViewEnquiryProductDrawer:
          data.showAddEditViewEnquiryProductDrawer,
        enquiryId: data.enquiryId,
        enquiryProductId: data.enquiryProductId,
        productId: data.productId,
        tab: data.tab,
      };

    default:
      return state;
  }
};

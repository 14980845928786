import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshEnquiryList,
  showAddEditEnquiryDrawer,
} from "redux/actions/Enquiry";
import EnquiryForm from "views/app-views/enquiry/enquiry-form";

export default function EnquiryDrawer() {
  const disptach = useDispatch();
  const catalogue_ = useSelector((state) => state.enquiry);
  //console.log(catalogue_, "catalogue_catalogue_catalogue_catalogue_");
  return (
    <>
      <Drawer
        title={`${catalogue_?.editableEnquiry ? "Edit" : "Add"} Enquiry`}
        width={800}
        onClose={() => {
          disptach(showAddEditEnquiryDrawer(false));
          disptach(refreshEnquiryList(true));
        }}
        visible={catalogue_?.showAddEditEnquiryDrawer}
        className="custom-drawer"
      >
        {catalogue_?.showAddEditEnquiryDrawer && (
          <EnquiryForm
            setIsSubmitted={() => {}}
            isDrawerView={true}
            onDrawerClose={() => {
              disptach(refreshEnquiryList(true));
              disptach(showAddEditEnquiryDrawer(false));
            }}
            setIsNext={() => {
              disptach(refreshEnquiryList(true));
            }}
            enquiryId={catalogue_?.editableEnquiry}
          />
        )}
      </Drawer>
    </>
  );
}

import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { reactSelectCustomStyle } from "styles/react-select-style";

const AsyncSelectWrapper = ({
  onChange,
  defaultValue,
  fetchOptions,
  menuIsOpen,
  onMenuClose,
  placeholderValue,
  inputlength,
  ...otherProps
}) => {
  const [open, setOpen] = useState(() => menuIsOpen || false);

  const handleMenuClose = () => {
    setOpen(false);
    if (onMenuClose) {
      onMenuClose();
    }
  };

  const handleInputChange = (input) => {
    setOpen(input && input.length >= inputlength);
  };

  return (
    <AsyncSelect
      styles={reactSelectCustomStyle}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        NoOptionsMessage: () => (
          <span style={{ paddingLeft: "5px" }}>No record found.</span>
        ),
      }}
      placeholder={placeholderValue}
      defaultOptions
      loadOptions={fetchOptions}
      onChange={onChange}
      defaultValue={defaultValue}
      openMenuOnClick={false}
      menuIsOpen={open}
      onMenuClose={handleMenuClose}
      onInputChange={handleInputChange}
      {...otherProps}
    />
  );
};

export default AsyncSelectWrapper;

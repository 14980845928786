import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { refreshRfqList } from "redux/actions";
import {
  refreshBulkBrand,
  showAddEditBulkBrand,
} from "redux/actions/BulkBrand";
import BulkBrandForm from "views/app-views/bulk-brand";

export default function BulkBrand() {
  const disptach = useDispatch();
  const bulkBrand = useSelector((state) => state.bulkBrand);
  return (
    <>
      <Drawer
        title={`Bulk Brand`}
        width={800}
        onClose={() => {
          disptach(showAddEditBulkBrand(false));
          disptach(refreshBulkBrand(true));
        }}
        visible={bulkBrand?.showAddEditBulkBrandDrawer}
        className="custom-drawer"
      >
        {bulkBrand?.showAddEditBulkBrandDrawer && (
          <BulkBrandForm
            setIsSubmitted={() => {}}
            isDrawerView={true}
            onDrawerClose={() => {
              disptach(showAddEditBulkBrand(false));
              disptach(refreshBulkBrand(true));
            }}
            rfqDetail={bulkBrand?.editableRfq}
          />
        )}
      </Drawer>
    </>
  );
}

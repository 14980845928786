import {
  REFRESH_RECEIVEPRODUCT_LIST,
  SHOW_ADD_EDIT_RECEIVEPRODUCT_DRAWER,
} from "redux/constants/receiveProduct";

const initialState = {
  showAddEditReceiveProductDrawer: false,
  purchaseOrderId: null,
  editableReceiveProduct: null,
  refreshReceiveProductList: false,
};
export const receiveproduct = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_ADD_EDIT_RECEIVEPRODUCT_DRAWER:
      return {
        ...state,
        showAddEditReceiveProductDrawer: data.showAddEditReceiveProductDrawer,
        purchaseOrderId: data.purchaseOrderId,
        editableReceiveProduct: data.editableReceiveProduct,
      };
    case REFRESH_RECEIVEPRODUCT_LIST:
      return {
        ...state,
        refreshReceiveProductList: data.refreshReceiveProductList,
      };
    default:
      return state;
  }
};

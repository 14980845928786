import React, { useState, useEffect } from "react";
import { Tabs, Form, Button, message, Space, Modal, Layout } from "antd";

import { ADD, EDIT } from "../../../../../constants/common.constant";
import Utils from "utils/common";
import { Content, Footer } from "antd/lib/layout/layout";
import BrandDetail from "./brand-detail";
import { QuestionCircleFilled } from "@ant-design/icons";
import { BrandService_ } from "../../../../../services/Tenant/brand.service";
import { refreshBrandList, refreshNewButtonData } from "redux/actions";
import { useDispatch } from "react-redux";

const { TabPane } = Tabs;

const BrandForm = (props) => {
  const {
    isDrawerView,
    onDrawerClose,
    onDrawerCloseOnCancel,
    setIsSubmitted,
    setIsNext,
    refresh,
  } = props;

  const [form] = Form.useForm();
  const [uploadedImg, setImage] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [brandDetail, setBrandDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const brandId = props.brandId;
  const dispatch = useDispatch();
  const mode = brandId ? EDIT : ADD;
  const disptach = useDispatch();
  useEffect(() => {
    if (refresh && mode === "ADD") {
      form.resetFields();
      disptach(refreshNewButtonData(false));
      console.log(refresh, mode, "LLLLLLLLLLLLLL");
    }
  }, [refresh, mode]);
  console.log(refresh, mode, "LLLLLLKKKKKKKKKK");
  useEffect(() => {
    if (brandId && form) {
      setLoading(true);
      BrandService_.getBranddetail(brandId)
        .then((data) => {
          setBrandDetail(data);
          form.setFieldsValue({
            ...data,
          });
        })
        .finally(() => setLoading(false));
    }
  }, [brandId, form]);
  const handleUploadChange = (info) => {
    Utils.getBase64(info.file.originFileObj, (imageUrl) => {
      setImage(imageUrl);
      setUploadLoading(true);
    });
  };

  const onFinish = () => {
    dispatch(refreshBrandList(false));

    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        //values.alias = values.alias == '' ? values.alias : 'null';
        if (values.alias == "") {
          values.alias = null;
        }
        if (values.parentBrand?.value) {
          values.parentBrand = values.parentBrand?.value;
        } else {
          delete values.parentBrand;
        }

        if (mode === "EDIT") {
          BrandService_.updateBrand(brandId, values)
            .then((data) => {
              setIsSubmitted(true);
              message.success(`Brand updated successfully`);
              onDrawerClose();
            })
            .finally((_) => setSubmitLoading(false));
        } else {
          BrandService_.addBrand(values)
            .then((data) => {
              setIsSubmitted(true);
              form.resetFields();
              message.success(`Brand added successfully`);
              onDrawerClose();
            })
            .finally((_) => setSubmitLoading(false));
        }
      })
      .catch((info) => {
        setSubmitLoading(false);
        console.log(info, "DDDDDDDDDDDDD");

        message.error("Please enter all required field ");
      });
  };

  const onFinishnext = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        if (values.alias == "") {
          values.alias = null;
        }
        values.description =
          typeof values.description !== undefined ? values.description : "";
        values.parentBrand = values.parentBrand?.value;

        delete values.logo.publicUrl;

        if (mode === "EDIT") {
          BrandService_.updateBrand(brandId, values)
            .then((data) => {
              setIsSubmitted(true);
              message.success(`Brand updated successfully`);
              onDrawerClose();
            })
            .finally((_) => setSubmitLoading(false));
        } else {
          BrandService_.addBrand(values)
            .then((data) => {
              setIsSubmitted(true);
              form.resetFields();
              message.success(`Brand added successfully`);
              setIsNext();
            })
            .finally((_) => setSubmitLoading(false));
        }
      })
      .catch((info) => {
        setSubmitLoading(false);
        message.error("Please enter all required field ");
      });
  };
  return (
    <>
      <Form
        layout={isDrawerView ? "horizontal" : "vertical"}
        form={form}
        name="brand-form"
      >
        <div className="container">
          <Layout>
            <Content>
              <BrandDetail
                {...props}
                setSelectedImageFile={setSelectedImageFile}
                uploadedImg={uploadedImg}
                uploadLoading={uploadLoading}
                handleUploadChange={handleUploadChange}
                form={form}
                brandDetail={brandDetail}
                loading={loading}
                refresh={refresh}
                mode={mode}
              />
            </Content>
            {isDrawerView ? (
              <Footer>
                <Space style={{ float: "right" }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      Modal.confirm({
                        title: "Confirm",
                        icon: <QuestionCircleFilled />,
                        content: "Are you sure to proceed.",
                        okText: "Yes",
                        cancelText: "No",
                        onOk: () => {
                          onFinish(true);
                        },
                      });
                    }}
                    htmlType="submit"
                    loading={submitLoading}
                    disabled={submitLoading}
                    size="small"
                  >
                    {mode === ADD ? "Save" : `Save`}
                  </Button>
                  {/* {mode === ADD ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        Modal.confirm({
                          title: "Confirm",
                          icon: <QuestionCircleFilled />,
                          content: "Are you sure to proceed.",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: () => {
                            onFinishnext(true);
                          },
                        });
                      }}
                      htmlType="submit"
                      loading={submitLoading}
                      disabled={submitLoading}
                      size="small"
                      isProcessed="true"
                    >
                      {mode === ADD ? "Save & Next" : `Save`}
                    </Button>
                  ) : (
                    <> </>
                  )} */}
                  <Button
                    onClick={() => {
                      if (mode === "EDIT") {
                        onDrawerClose();
                      } else {
                        onDrawerCloseOnCancel();
                      }
                      form.resetFields();
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                </Space>
              </Footer>
            ) : (
              <></>
            )}
          </Layout>
        </div>
      </Form>
    </>
  );
};

export default BrandForm;

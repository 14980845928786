import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  message,
  Space,
  Row,
  Col,
  Input,
  Divider,
  Select,
  Layout,
} from "antd";
import "react-phone-input-2/lib/style.css";
import { ROW_GUTTER } from "constants/ThemeConstant";
import { CountryService_ } from "services/common/country.service";
import { AddressTypeService_ } from "services/common/addressType.service";
import { OrgAddressService_ } from "services/Tenant/organisation_address.service";
import { Content, Footer } from "antd/lib/layout/layout";
import { OrgCustomersService_ } from "services/Tenant/organisation_customer.service";
import { AddressService_ } from "services/Tenant/address.service";

const CommonAddressDrawer = ({
  onDrawerClose,
  initialData,
  presetval,
  setAddress,
  mode,
  props,
  customerId,
  addressTypeName,
  isTeanant,
  teanantAddressfilter,
  onSave,
  defaultAddress,
}) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [addressCategory, setAddressCategory] = useState("");
  const [tenantAddresses, setTenantAddresses] = useState([]);

  useEffect(() => {
    countryData();
    addressTypeData();
    if (isTeanant) {
      tenantAddress();
    }
  }, []);

  const countryData = () => {
    CountryService_.getCountries().then((data) => {
      setCountryList(data);
    });
  };
  const addressTypeData = () => {
    AddressTypeService_.getAddressType().then((data) => {
      setAddressTypeList(data);
    });
  };
  const tenantAddress = () => {
    AddressService_.getAddress().then((data) => {
      console.log(data);
      setTenantAddresses(data);
    });
  };

  useEffect(() => {
    switch (addressTypeName) {
      case "collectionAddress":
        setAddressCategory("collection");
        break;
      case "billingAddress":
        setAddressCategory("billing");
        break;
      case "deliveryAddress":
        setAddressCategory("delivery");
        break;

      default:
        break;
    }
  }, [addressTypeName]);

  const onFinish = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        setSubmitLoading(true);
        const { address, city, state, country, postCode, landmark } = values;

        const fullAdress = {
          [`${addressCategory}Address`]: address,
          [`${addressCategory}City`]: city,
          [`${addressCategory}State`]: state,
          [`${addressCategory}Country`]: country,
          [`${addressCategory}PostCode`]: postCode,
          [`${addressCategory}Landmark`]: landmark,
          [`${addressCategory}AddressName`]: addressData?.addressName,
        };

        setAddress(fullAdress);

        onDrawerClose();
        if (onSave) {
          onSave(fullAdress);
        }
      })
      .catch((info) => {
        // message.error("Something went wrong!");
      })
      .finally((_) => setSubmitLoading(false));
  };

  const rules = {
    country: [
      {
        required: true,
        message: "Please input organisation country",
      },
    ],
  };

  function selectedOption(value) {
    if (isTeanant) {
      AddressService_.getAddressdetail(value).then((data) => {
        setAddressData(data);
        form.setFieldsValue(data);
        form.setFieldsValue({ address: data.address });
        form.resetFields([addressTypeName]);
      });
    } else {
      OrgAddressService_.getOrganisationaddressdetail(value).then((data) => {
        setAddressData(data);
        form.setFieldsValue(data);
        form.setFieldsValue({ address: data.address1 });
        form.resetFields([addressTypeName]);
      });
    }
  }
  useEffect(() => {
    if (mode !== "ADD") {
      fetchOrgDetail(customerId);
    }
    form.setFieldsValue({
      address:
        presetval[`${addressCategory}Address`] || defaultAddress?.address || "",
      country:
        presetval[`${addressCategory}Country`] || defaultAddress?.country || "",
      city: presetval[`${addressCategory}City`] || defaultAddress?.city || "",
      state:
        presetval[`${addressCategory}State`] || defaultAddress?.state || "",
      postCode:
        presetval[`${addressCategory}PostCode`] ||
        defaultAddress?.postCode ||
        "",
      landmark:
        presetval[`${addressCategory}Landmark`] ||
        defaultAddress?.landmark ||
        "",
    });
  }, [
    presetval,
    customerId,
    mode,
    addressCategory,
    form,
    defaultAddress?.address,
    defaultAddress?.country,
    defaultAddress?.city,
    defaultAddress?.state,
    defaultAddress?.postCode,
    defaultAddress?.landmark,
  ]);

  const fetchOrgDetail = (selectVal) => {
    setIsLoading(true);
    OrgCustomersService_.getOrganisationcustomerdetail(selectVal)
      .then((data) => {
        setAddressList(data);
      })
      .finally(() => setIsLoading(false));
  };

  const selectOptions = addressList?.addresses
    ?.filter((address) => address.addressType === "Billing Address")
    .map(({ id, address1 }) => ({
      value: id,
      label: address1,
    }));

  const tenantOptions = tenantAddresses
    ?.filter((address) => address.addressType === teanantAddressfilter)
    .map(({ id, address }) => ({
      value: id,
      label: address,
    }));

  return (
    <>
      <Form layout={"horizontal"} form={form} name="tenant-form">
        <Layout>
          <Content>
            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item label="Select Address" name={addressTypeName}>
                  <Select
                    showSearch
                    allowClear
                    options={
                      selectOptions ||
                      tenantOptions ||
                      initialData?.addresses
                        ?.filter(
                          (address) =>
                            address.addressType === "Collection Address"
                        )
                        .map(({ id, address1 }) => ({
                          value: id,
                          label: address1,
                        }))
                    }
                    onSelect={selectedOption}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={2}></Col>
              <Col xs={24} sm={24} md={10}></Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="address" label="Address">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="country" label="Country" rules={rules.country}>
                  <Select
                    allowClear
                    showSearch
                    options={countryList.map(({ id, name }) => ({
                      value: name,
                      label: name,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="city" label="City" rules="">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="state" label="State" rules="">
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item label="Post Code" name="postCode" rules="">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item label="Land Mark" name="landmark" rules="">
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
          </Content>
          <Footer>
            <Space style={{ float: "right" }}>
              <Button
                type="primary"
                onClick={() => onFinish()}
                htmlType="submit"
                loading={submitLoading}
                disabled={submitLoading}
                size="small"
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  onDrawerClose();
                }}
                size="small"
              >
                Cancel
              </Button>
            </Space>
          </Footer>
        </Layout>
      </Form>
    </>
  );
};

export default CommonAddressDrawer;

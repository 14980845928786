import { SHOW_NOTES_DRAWER } from "redux/constants/Notes";

const initialState = {
  showNotesDrawer: false,
};
export const notes = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_NOTES_DRAWER:
      return {
        ...state,
        showNotesDrawer: data.showNotesDrawer,
      };

    default:
      return state;
  }
};

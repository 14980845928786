import React, { useEffect, useState } from "react";
import { Menu, Row, Table } from "antd";
import { ROW_GUTTER } from "constants/ThemeConstant";
import Text from "antd/lib/typography/Text";
import { EnquiryFinalisedProduct } from "services/Tenant/enquiry_finalised_product.service";
import { FilePdfOutlined } from "@ant-design/icons";
import Loading from "components/shared-components/Loading";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import ActionDropdownMenu from "components/shared-components/ActionDropdownMenu";
import { EyeOutlined } from "@ant-design/icons";

const RfqTab = ({ enquiryId, enquiryProductId }) => {
  const [isLoading, setIsLoading] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const fetchResourceDetail = () => {
    setIsLoading(true);
    EnquiryFinalisedProduct.getEnquiryProductsRfqs(enquiryId, enquiryProductId)
      .then((data) => {
        setInitialData(data);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchResourceDetail();
  }, []);

  const tableColumns = [
    {
      title: "Ref",
      dataIndex: "rfqCode",
      render: (_, row) => {
        console.log(row);
        return (
          <a
            href={`/app/rfq-view/${row?.id}`}
            style={{ width: 70 }}
            ellipsis={{ tooltip: row.rfqCode }}
            target="_blank"
          >
            {row.rfqCode}
          </a>
        );
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      render: (_, row) => (
        <Text ellipsis={{ tooltip: row?.vendorOrganisation?.name }}>
          {<>{row?.vendorOrganisation?.name}</>}
        </Text>
      ),
    },
    {
      title: "Curr.",
      dataIndex: "currency",
      render: (_, row) => (
        <Text ellipsis={{ tooltip: row?.currency }}>
          {<>{row?.currency}</>}
        </Text>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (_, row) => {
        const finalPrice = row?.products?.filter(
          (product) => enquiryProductId === product?.enquiryProduct
        );

        return (
          <Text ellipsis={{ tooltip: finalPrice?.[0]?.unitPrice }}>
            {
              <>
                {finalPrice?.[0]?.unitPrice ? finalPrice?.[0]?.unitPrice : ""}
              </>
            }
          </Text>
        );
      },
    },
    {
      title: "Other",
      dataIndex: "other",
      render: (_, row) => {
        const other = parseInt(row?.freight) + parseInt(row?.packaging);
        return (
          <Text ellipsis={{ tooltip: other }}>{<>{other ? other : ""}</>}</Text>
        );
      },
    },
    {
      title: "Lead",
      dataIndex: "lead",
      render: (_, row) => {
        const lead = row?.products?.filter(
          (product) => enquiryProductId === product?.enquiryProduct
        );
        return (
          <Text ellipsis={{ tooltip: lead?.[0]?.unitPrice }}>
            {<>{lead?.[0]?.unitPrice}</>}
          </Text>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, row) => (
        <Text ellipsis={{ tooltip: row?.status }}>{<>{row?.status}</>}</Text>
      ),
    },

    // {
    //   title: "",
    //   dataIndex: "view",
    //   render: (_, row) => {
    //     console.log(row);
    //     return (
    //       <EllipsisDropdown
    //         menu={
    //           <Menu>
    //             <ActionDropdownMenu
    //               actions={[
    //                 {
    //                   label: "View Details",
    //                   icon: <EyeOutlined />,
    //                   onClick: () => history.push(`/app/rfq-view/${row?.id}`),
    //                 },
    //               ]}
    //             />
    //           </Menu>
    //         }
    //       />
    //     );
    //   },
    // },
  ];
  return isLoading ? (
    <Loading cover="page-center" />
  ) : (
    <>
      <Table
        className="custom-table"
        showHeader={true}
        size="small"
        loading={isLoading}
        columns={tableColumns}
        dataSource={initialData}
        rowKey="id"
        pagination={false}
        onChange=""
      />
    </>
  );
};

export default RfqTab;

import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  ENQUIRY_LIST,
  ENQUIRY_COUNT,
  ENQUIRY_ADD,
  ENQUIRY_UPDATE,
  ENQUIRY_DETAIL,
  ENQUIRY_DELETE,
  ENQUIRY_STATUS_UPDATE,
} = API_PATH;

export const EnquiryService_ = {
  getEnquiry: (filter) => {
    return fetch_({
      url: ENQUIRY_LIST,
      method: "GET",
      params: filter,
    });
  },
  countEnquiry: (filter) => {
    return fetch_({
      url: ENQUIRY_COUNT,
      method: "GET",
      params: filter,
    });
  },
  getEnquirydetail: (enquiryId) => {
    return fetch_({
      url: ENQUIRY_DETAIL(enquiryId),
      method: "GET",
    });
  },
  addEnquiry: (enquiry) => {
    return fetch_({
      url: ENQUIRY_ADD,
      method: "POST",
      data: enquiry,
    });
  },
  updateEnquiry: (enquiryId, enquiry) => {
    return fetch_({
      url: ENQUIRY_UPDATE(enquiryId),
      method: "PUT",
      data: enquiry,
    });
  },
  updateEnquiryStatus: (enquiryId, enquiry) => {
    return fetch_({
      url: ENQUIRY_STATUS_UPDATE(enquiryId),
      method: "PUT",
      data: enquiry,
    });
  },
  deleteEnquiry: (enquiryId) => {
    return fetch_({
      url: ENQUIRY_DELETE(enquiryId),
      method: "DELETE",
    });
  },
};

import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { EnquiryService_ } from "services/Tenant/enquiry.service";
import { reactSelectCustomStyle } from "styles/react-select-style";

export default function EnquirySelect({ onChange, defaultValue }) {
  const [open, setOpen] = useState(false);
  const fetchEnquiry = async (input) => {
    //if (!input) return [];
    if (!input || input.length < 3) return [];
    return EnquiryService_.getEnquiry({
      search: input,
      limit: 10,
      where: {
        "al.is_active": true,
      },
    }).then((data) => {
      const enquiryOptions = data.map((o) => ({ label: o.enquiryCode, value: o.id }));
      return enquiryOptions;
    });
  };

  return (
    <AsyncSelect
      styles={reactSelectCustomStyle}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        NoOptionsMessage: () => "",
      }}
      defaultOptions
      placeholder="Type & Select"
      loadOptions={fetchEnquiry}
      onChange={onChange}
      defaultValue={defaultValue}
      openMenuOnClick={false}
      menuIsOpen={open}
      onMenuClose={() => setOpen(false)}
      onInputChange={(e) => {
        if (!e) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      }}
    />
  );
}

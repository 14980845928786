import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  ENQUIRY_PRODUCT__CERTIFICATE_ADD,
  ENQUIRY_PRODUCT__CERTIFICATE_LIST,
  ENQUIRY_PRODUCT_CERTIFICATE_DELETE,
  ENQUIRY_PRODUCT__ASSIGN_CERTIFICATE_LIST,
  ENQUIRY_PRODUCT__CERTIFICATE_ADD_TYPE_MANUFACTURER,
} = API_PATH;

export const EnquiryProductCertificateService_ = {
  addEnquiryProductCertificate: (
    enquiryId,
    enquiryProductId,
    enquiryproductcertificate
  ) => {
    return fetch_({
      url: ENQUIRY_PRODUCT__CERTIFICATE_ADD(enquiryId, enquiryProductId),
      method: "POST",
      data: enquiryproductcertificate,
    });
  },
  addEnquiryProductCertificateTypeManufacturer: (
    enquiryId,
    certificateId,
    brandId,
    note
  ) => {
    return fetch_({
      url: ENQUIRY_PRODUCT__CERTIFICATE_ADD_TYPE_MANUFACTURER(
        enquiryId,
        certificateId,
        brandId
      ),
      method: "POST",
      data: note,
    });
  },

  getEnquiryProductCertificate: (enquiryId, enquiryProductId, filter) => {
    return fetch_({
      url: ENQUIRY_PRODUCT__CERTIFICATE_LIST(enquiryId, enquiryProductId),
      method: "GET",
      params: filter,
    });
  },
  assignCertificatesToEnquiryFinalisedProducts: (enquiryId, data) => {
    return fetch_({
      url: ENQUIRY_PRODUCT__ASSIGN_CERTIFICATE_LIST(enquiryId),
      method: "POST",
      data: data,
    });
  },

  deleteEnquiryProductCertificate: (
    enquiryId,
    enquiryProductId,
    enquiryProductCertificateId
  ) => {
    return fetch_({
      url: ENQUIRY_PRODUCT_CERTIFICATE_DELETE(
        enquiryId,
        enquiryProductId,
        enquiryProductCertificateId
      ),
      method: "DELETE",
    });
  },
};

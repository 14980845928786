import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { refreshRfqList } from "redux/actions";
import {
  refreshBulkOrganisation,
  showAddEditBulkOrganisation,
} from "redux/actions/BulkOrganisationAdd";
import BulkBrandForm from "views/app-views/bulk-brand";
import BulkOrganisationForm from "views/app-views/bulk-organisation";

export default function BulkOrganisation() {
  const disptach = useDispatch();
  const bulkOrganisation = useSelector((state) => state.bulkOrganisation);
  return (
    <>
      <Drawer
        title={`Bulk Organisation`}
        width={1000}
        onClose={() => {
          disptach(showAddEditBulkOrganisation(false));
          disptach(refreshBulkOrganisation(true));
        }}
        visible={bulkOrganisation?.showAddEditBulkOrganisationDrawer}
        className="custom-drawer"
      >
        {bulkOrganisation?.showAddEditBulkOrganisationDrawer && (
          <BulkOrganisationForm
            setIsSubmitted={() => {}}
            isDrawerView={true}
            onDrawerClose={() => {
              disptach(showAddEditBulkOrganisation(false));
              disptach(refreshBulkOrganisation(true));
            }}
            rfqDetail={bulkOrganisation?.editableRfq}
          />
        )}
      </Drawer>
    </>
  );
}

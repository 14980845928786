import React, { useState } from "react";
import { Form, Layout, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import { RfqService_ } from "services/Tenant/rfq.service";
import BulkRfqDetail from "./bulk-rfq-drawer-detail";
import { useDispatch } from "react-redux";

const BulkRfqForm = (props) => {
  const { rfqDetail, onDrawerClose } = props;
  const [form] = Form.useForm();
  const [resetData, setResetData] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const handleUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const handleClearData = () => {
    setResetData(true);
  };
  const handleResetDataComplete = () => {
    setResetData(false);
  };
  const handleDisabled = (val) => {
    setDisabled(val);
  };
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        setSubmitLoading(true);
        const formData = new FormData();
        formData.append("file", uploadedFile);
        formData.append("confirmed", "1");
        formData.append("uploadType", "ADD_ORGANISATION");
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        RfqService_.addBulkRfqUpload(formData, config)
          .then((response) => {
            setUploadedFile(null);
            handleClearData();
            onDrawerClose();
            message.success(`Bulk Rfq added successfully`);
            // dispatch(refreshBulkOrganisation(false));
          })
          .catch(function (error) {
            console.error("Error:", error);
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Form
        layout={"horizontal"}
        form={form}
        name="rfq-form"
        autoComplete="off"
      >
        <Layout>
          <Content>
            <BulkRfqDetail
              {...props}
              form={form}
              resetcsvData={resetData}
              onResetDataComplete={handleResetDataComplete}
              handleUploaded={handleUploadedFile}
              submitLoading={submitLoading}
              handleDisabled={handleDisabled}
              handleClearData={handleClearData}
            />
          </Content>
        </Layout>
      </Form>
    </>
  );
};

export default BulkRfqForm;

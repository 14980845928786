import React, { useState } from "react";
import { Form, Button, Space, Modal, Layout, message } from "antd";
import { ADD, EDIT } from "../../../constants/common.constant";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Content, Footer } from "antd/lib/layout/layout";
import { RfqService_ } from "services/Tenant/rfq.service";
import { openNotification } from "components/shared-components/NotificationTile";
import BulkBrandDetail from "./bulk-brand-drawer-detail";
import { BrandService_ } from "services/Tenant/brand.service";
import { useDispatch } from "react-redux";
import { refreshBulkBrand } from "redux/actions/BulkBrand";

const BulkBrandForm = (props) => {
  const { rfqDetail, onDrawerClose } = props;
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [form] = Form.useForm();
  const [resetData, setResetData] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const handleUploadedFile = (file) => {
    setUploadedFile(file);
  };

  const handleClearData = () => {
    setResetData(true);
  };
  const handleResetDataComplete = () => {
    setResetData(false);
  };
  const handleDisabled = (val) => {
    setDisabled(val);
  };
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        setSubmitLoading(true);
        const formData = new FormData();
        formData.append("file", uploadedFile);
        formData.append("confirmed", "1");
        formData.append("uploadType", "ADD_BRAND");
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        BrandService_.addBulkBrands(formData, config)
          .then((response) => {
            setUploadedFile(null);
            handleClearData();
            onDrawerClose();
            openNotification(
              "Success",
              `Products added successfully`,
              "success"
            );
            dispatch(refreshBulkBrand(false));
          })
          .catch(function (error) {
            console.error("Error:", error);
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Form
        layout={"horizontal"}
        form={form}
        name="rfq-form"
        autoComplete="off"
      >
        <Layout>
          <Content>
            <BulkBrandDetail
              {...props}
              form={form}
              resetcsvData={resetData}
              onResetDataComplete={handleResetDataComplete}
              handleUploaded={handleUploadedFile}
              submitLoading={submitLoading}
              handleDisabled={handleDisabled}
            />
          </Content>

          <Footer>
            <Space>
              <Button
                onClick={() => {
                  onDrawerClose();
                }}
                size="small"
              >
                Cancel
              </Button>
              <Button type="default" onClick={handleClearData} size="small">
                Reset
              </Button>
              <Button
                type="primary"
                onClick={() => onFinish()}
                htmlType="submit"
                size="small"
                disabled={disabled}
              >
                {`Save`}
              </Button>
            </Space>
          </Footer>
        </Layout>
      </Form>
    </>
  );
};

export default BulkBrandForm;

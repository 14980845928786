import React, { useState, useEffect } from "react";
import { Row, Col, List } from "antd";
import { FilePdfOutlined, FileImageOutlined } from "@ant-design/icons";
import { EnquiryProductCertificateService_ } from "../../../../../services/Tenant/enquiry_product_certificate.service";
import Loading from "components/shared-components/Loading";
const ViewCertificateTab = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialData] = useState([]);

  useEffect(() => {
    if (props?.enquiryProductId) {
      fetchCertificateDetail();
    }
  }, [props?.enquiryProductId]);

  const fetchCertificateDetail = () => {
    setIsLoading(true);
    EnquiryProductCertificateService_.getEnquiryProductCertificate(
      props?.enquiryId,
      props?.enquiryProductId
    )
      .then((data) => {
        setInitialData(data);
      })
      .finally(() => setIsLoading(false));
  };

  return isLoading ? (
    <Loading cover="page-center" />
  ) : (
    <>
      <Row>
        <Col span={24}>
          <List
            itemLayout="horizontal"
            dataSource={initialData}
            renderItem={(item) => (
              <List.Item actions={[]}>
                <List.Item.Meta
                  title={item?.certificate?.name}
                  description={`${item?.certificate?.certificateGroup}, ${
                    item?.note === null ? "" : item?.note
                  }`}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default ViewCertificateTab;

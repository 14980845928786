import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Select,
  Button,
  Table,
  PageHeader,
  Card,
  Popover,
  Tooltip,
  Spin,
  Drawer,
  DatePicker,
  Typography,
  Modal,
  Space,
} from "antd";
import { ROW_GUTTER } from "constants/ThemeConstant";
import {
  InboxOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  DownloadOutlined,
  InfoOutlined,
} from "@ant-design/icons";
import { text } from "utils/utilities";
import { OrgService_ } from "services/Tenant/organisation.service";
import { DEFAULT_PAGE_SIZE } from "constants/common.constant";
import Text from "antd/lib/typography/Text";
import { OrgVendorsService_ } from "services/Tenant/organisation_vendor.service";
import { RfqService_ } from "services/Tenant/rfq.service";
import Dragger from "antd/lib/upload/Dragger";
import VendorSelect from "components/shared-components/VendorSelect";
import { EnquiryService_ } from "services/Tenant/enquiry.service";
import { openNotification } from "components/shared-components/NotificationTile";
import _ from "lodash";
import { BrandService_ } from "services/Tenant/brand.service";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { DATE_FORMAT_DD_MM_YYYY } from "constants/DateConstant";
import EditRecordDrawer from "./edit-record-drawer";

const BulkRfqDetail = ({
  resetcsvData,
  handleDisabled,
  onResetDataComplete,
  handleUploaded,
  handleClearData,
}) => {
  const [orgList, setOrgList] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invalidData, setInvalidData] = useState([]);
  const [validData, setValidData] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submittedProductsData, setSubmittedProductsData] = useState([]);
  const [bulkRfqUploadedFile, setBulkRfqUploadedFile] = useState([]);
  const [
    dealsInAllBrandsOrganisationsOptions,
    setDealsInAllBrandsOrganisationsOptions,
  ] = useState([]);
  const [disabledTooltip, setDisabledTooltip] = useState(true);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [editRecord, setEditRecord] = useState({});
  const [editedRecordSaved, setEditedRecordSaved] = useState(0);
  // const [invalidDataOfMissingBrands, setInvalidDataOfMissingBrands] = useState(
  //   []
  // );

  useEffect(() => {
    if (resetcsvData) {
      setCSVData([]);
      setInvalidData([]);
      onResetDataComplete();
      handleDisabled(true);
      setSubmittedProductsData([]);
    }
  }, [resetcsvData]);

  useEffect(() => {
    setCSVData([]);
    setInvalidData([]);
    handleDisabled(true);
  }, []);

  useEffect(() => {
    setCSVData([]);
    setInvalidData([]);
    handleDisabled(true);
  }, []);
  const onFinish = () => {
    setSubmitLoading(true);
    const missingDueDateRecords = csvData?.products?.filter(
      (record) => !record.dueDate
    );

    if (missingDueDateRecords.length > 0) {
      openNotification("Error", `Due date is missing.`, "error");
      setSubmitLoading(false);
      return;
    }
    const payload = csvData?.products
      ?.filter((f) => !f?.invalidReason)
      ?.map((item) => ({
        pr: item?.pr,
        materialNumber: item?.materialNumber,
        material: item?.material,
        description: item?.description,
        quantity: item?.quantity,
        uom: item?.uom,
        brand: item?.brand,
        brandId: item?.brandId,
        vendors: item?.organisations?.map((v) => v?.vendor?.id),
        recordId: item?.recordId,
        docDate: item?.docDate,
        lineNumber: item?.lineNumber,
        dueDate: item?.dueDate,
      }));

    EnquiryService_.addEnquiry({ data: payload })
      .then((response) => {
        openNotification(
          "Sucess",
          "Rfq Products submitted successfully",
          "success"
        );
        setSubmitLoading(false);
        setSubmittedProductsData(response);
      })
      .catch(function (error) {
        console.error("Error:", error);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const findInvalidData = (response) => {
    const invalidData = response?.filter((item) => item?.invalidReason) || [];
    const validData = response?.filter((item) => !item?.invalidReason) || [];
    return {
      invalidData,
      validData,
    };
  };
  const sentAllRfqs = (rfqIds) => {
    setLoading(true);
    RfqService_.sentRfqs({ ids: rfqIds })
      .then(function (response) {
        openNotification("Sucess", "Rfqs Sent successfully", "success");
      })
      .catch(function (error) {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fileHandler = (info) => {
    setBulkRfqUploadedFile(info);
    if (info.fileList && info.fileList.length > 0) {
      setLoading(true);
      const file = info.fileList[0].originFileObj;
      const formData = new FormData();
      formData.append("file", file);
      handleUploaded(file);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      RfqService_.addBulkRfqUpload(formData, config)
        .then(function (response) {
          setTotalRecords(response?.products);
          const { invalidData, validData } = findInvalidData(
            response?.products
          );
          if (invalidData.length > 0) {
            setInvalidData(invalidData);
          }
          // if (validData.length > 0) {
          setValidData(response?.products);
          const brandsWithDealsInOrganisations = [
            {
              id: "dealsInAllBrandsOrganisations", // Assign a unique id for the new object
              name: "All brands",
              organisations: response.dealsInAllBrandsOrganisations,
            },
            ...response.brands, // Add the original brands data after the new object
          ];
          response.brands = brandsWithDealsInOrganisations;
          const dealsInAllBrandsOrganisations = response?.brands?.find(
            (f) => f?.id == "dealsInAllBrandsOrganisations"
          );
          const dealsInAllBrandsOrganisationsOptions =
            dealsInAllBrandsOrganisations?.organisations?.map((o) => ({
              label: o?.name,
              value: o?.vendor?.id,
              organisation: o?.id,
            }));
          setDealsInAllBrandsOrganisationsOptions(
            dealsInAllBrandsOrganisationsOptions
          );
          const defaultData = response?.brands?.flatMap((brand) =>
            brand?.organisations?.map((o) => ({
              label: o?.name,
              value: o?.vendor?.id,
              organisation: o?.id,
            }))
          );
          if (defaultData?.length) {
            setVendorOptions((prev) => [...prev, ...defaultData]);
          } else {
            setVendorOptions((prev) => [...prev]);
          }
          const brandsMap = response?.brands?.reduce((acc, brand) => {
            acc[brand.id] = brand;
            return acc;
          }, {});

          const productsWithOrganisations = response?.products?.map(
            (product) => {
              console.log(
                brandsMap[product.brandId],
                "brandsMap[product.brandId]"
              );
              const brand = brandsMap[product.brandId];
              if (brand) {
                return {
                  ...product,
                  organisations: [
                    ...brand.organisations,
                    ...response.dealsInAllBrandsOrganisations,
                  ],
                };
              }
              return product;
            }
          );
          console.log(
            productsWithOrganisations,
            dealsInAllBrandsOrganisationsOptions,
            "dealsInAllBrandsOrganisationsOptions"
          );
          setCSVData({
            ...response,
            products: productsWithOrganisations,
          });
          handleDisabled(false);
        })
        .catch(function (error) {
          console.error("Error:", error);
          setRefresh((prev) => prev + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const { invalidData: invalidResponseData, validData: validResponseData } =
    findInvalidData(validData);
  const downloadProducts = () => {
    const dataAddedWithSerialNumberAndValidityColumn = validData?.map(
      (item, index) => {
        const { ["sn"]: serialNumber, organisations, brandId, ...rest } = item;
        return {
          // "s/n": index + 1,
          ...rest,
          // validity: item?.invalidReason ? "No" : "Yes",
        };
      }
    );
    const dataWithoutBrandId = dataAddedWithSerialNumberAndValidityColumn?.map(
      ({ brandId, ...rest }) => rest
    );
    const csvDataAll = convertToCSV(dataAddedWithSerialNumberAndValidityColumn);
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(
      new Blob([csvDataAll], { type: "text/csv" })
    );
    downloadLink.setAttribute("download", `Non-Matching-products.csv`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const orderedHeaders = headers.join(",");

    const rows = data.map((row) => {
      return headers
        .map((header) => {
          const value = row[header];
          return value === null || value === undefined ? "" : `"${value}"`;
        })
        .join(",");
    });

    return `${orderedHeaders}\n${rows.join("\n")}`;
  };
  const addInvalidBrandData = () => {
    const finalInvalidDataWithInvalidBrands = invalidData
      ?.filter((d) => !d?.brandId && d?.brand)
      ?.map((item) => item?.brand);
    Modal.confirm({
      title: "Confirm",
      // icon: <InfoOutlined />,
      content: (
        <div>
          <span>Are you sure to add these brand(s) ?</span>
          {finalInvalidDataWithInvalidBrands?.map((n, i) => (
            <div>
              {i + 1}. {n}
            </div>
          ))}
        </div>
      ),
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        setSubmitLoading(true);
        const payload = finalInvalidDataWithInvalidBrands?.map((n) => ({
          name: n,
        }));

        BrandService_.addBulkBrandsWithName({ data: payload })
          .then((response) => {
            // handleClearData();
            openNotification("Success", `Brands added successfully`, "success");
            fileHandler(bulkRfqUploadedFile);
          })
          .catch(function (error) {
            console.error("Error:", error);
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      },
    });
  };
  const fetchEnquiry = async (input) => {
    if (!input || input.length < 3) {
      setDisabledTooltip(false);
      return OrgVendorsService_.getOrganisationvendors({
        search: input,
        limit: 5,
        where: {
          "al.is_active": true,
        },
      })
        .then((data) => {
          const vendorOptions = data.map((o) => ({
            label: o.organisation?.name,
            value: o.id,
            organisation: o?.organisation?.id,
          }));
          setVendorOptions(vendorOptions);
          return vendorOptions;
        })
        .finally(() => setLoading(false));
    }
    setLoading(true);
    setDisabledTooltip(true);
    return OrgVendorsService_.getOrganisationvendors({
      search: input,
      limit: 5,
      where: {
        "al.is_active": true,
      },
    })
      .then((data) => {
        const vendorOptions = data.map((o) => ({
          label: o.organisation?.name,
          value: o.id,
          organisation: o?.organisation?.id,
        }));
        setVendorOptions(vendorOptions);
        return vendorOptions;
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchEnquiry();
  }, []);
  const fetchData = async (reset, loadedFilter) => {
    OrgService_.getOrganisation({
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
      search: "",
      orderBy: "createdAt",
      order: "DESC",
      where: {},
    }).then((res) => {
      setOrgList(res);
    });
    // .finally((_) => setIsLoading(false));
  };
  useEffect(() => {
    fetchData(true);
  }, []);
  const handleEditRecords = () => {
    setShowEditDrawer(true);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const tableColumns = [
    {
      title: "sn",
      dataIndex: "name",
      render: (name, row, index) => (
        <Text style={{ width: 20 }} ellipsis={{ tooltip: "" }}>
          {text(index + 1)}
        </Text>
      ),
    },
    {
      title: "name",
      dataIndex: "name",
      render: (name) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: name }}>
          {text(name)}
        </Text>
      ),
    },
    {
      title: "vendors",
      dataIndex: "name",
      render: (name, row, index) => {
        return (
          <>
            <Tooltip
              title={
                disabledTooltip ? "" : "Type atleast 3 characters to search"
              }
            >
              <Select
                mode="multiple"
                showSearch
                onSearch={(e) => fetchEnquiry(e)}
                filterOption={filterOption}
                disabled={invalidResponseData?.length > 0}
                allowClear={
                  row?.id === "dealsInAllBrandsOrganisations" ? false : true
                }
                key={index}
                style={{ width: 700 }}
                placeholder="Please type atleast 3 characters to search"
                loading={loading}
                notFoundContent={
                  loading ? <Spin size="small" /> : "No Results Found"
                }
                onChange={(values, option) => {
                  setCSVData((prevState) => {
                    // Make a copy of the previous state
                    const nextState = { ...prevState };

                    // Find the index of the brand in the brands array
                    const brandIndex = nextState.brands.findIndex(
                      (brand) => brand.id === row.id
                    );

                    if (brandIndex !== -1) {
                      // Make a copy of the brand object
                      const updatedBrand = { ...nextState.brands[brandIndex] };

                      // Filter out the removed options from the brand.organisations array
                      const selectedOrganisations = option.map((opt) => ({
                        id: opt.organisation,
                        name:
                          vendorOptions.find((item) => item.value === opt.value)
                            ?.label ?? "",
                        vendor: { id: opt.value },
                      }));

                      // Merge existing and selected organisations, ensuring no duplicates
                      updatedBrand.organisations = [
                        ...updatedBrand.organisations.filter(
                          (org) =>
                            !selectedOrganisations.some(
                              (selectedOrg) =>
                                selectedOrg.vendor.id === org.vendor.id
                            )
                        ),
                        ...selectedOrganisations,
                      ];

                      // Update the brand in the nextState
                      nextState.brands[brandIndex] = updatedBrand;

                      // Update the products that belong to this brand
                      nextState.products = nextState.products.map((product) => {
                        if (product.brandId === row.id) {
                          // Filter out removed options and merge existing with selected
                          product.organisations = [
                            ...product.organisations.filter(
                              (org) =>
                                !selectedOrganisations.some(
                                  (selectedOrg) =>
                                    selectedOrg.vendor.id === org.vendor.id
                                )
                            ),
                            ...selectedOrganisations,
                          ];
                        }
                        return product;
                      });

                      // Return the updated state
                      return nextState;
                    }

                    // Return the previous state if the brand is not found
                    return prevState;
                  });
                }}
                options={
                  row?.id === "dealsInAllBrandsOrganisations"
                    ? _?.uniqBy(
                        dealsInAllBrandsOrganisationsOptions,
                        "value"
                      )?.map((option) => ({
                        ...option,
                        disabled: true,
                      }))
                    : _?.uniqBy(vendorOptions, "value")?.map((option) => ({
                        ...option,
                        label: dealsInAllBrandsOrganisationsOptions
                          .map((dealOption) => dealOption?.value)
                          .includes(option?.value)
                          ? option?.label + " - All Brands"
                          : option?.label,
                        // disabled: dealsInAllBrandsOrganisationsOptions
                        //   .map((dealOption) => dealOption?.value)
                        //   .includes(option?.value),
                      }))
                }
                defaultValue={row?.organisations?.map(
                  (item) => item?.vendor?.id
                )}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];
  console.log(csvData, "GGGGGGGGGg");
  const handleRemoveProductVendor = (brandId, vendorId) => {
    // Filter out the vendor from the product's organisations
    const updatedProducts = csvData?.products?.map((product) => {
      if (product?.brandId === brandId) {
        const updatedOrganisations = product.organisations.filter(
          (org) => org.vendor.id !== vendorId
        );
        return { ...product, organisations: updatedOrganisations };
      }
      return product;
    });

    // Update the products array in the CSV data
    setCSVData((prev) => ({ ...prev, products: updatedProducts }));
  };

  const tableProductVendorPopoverColumns = [
    {
      title: "sn",
      dataIndex: "name",
      render: (name, row, index) => (
        <Text style={{ width: 20 }} ellipsis={{ tooltip: "" }}>
          {text(index + 1)}
        </Text>
      ),
    },
    {
      title: "name",
      dataIndex: "name",
      render: (name, row) => {
        return (
          <Text style={{ width: 150 }} ellipsis={{ tooltip: name }}>
            {text(name)}
          </Text>
        );
      },
    },
    {
      title: "",
      dataIndex: "label",
      render: (label, row, index) => (
        <DeleteOutlined
          style={{ color: "red", cursor: "pointer" }}
          onClick={() =>
            handleRemoveProductVendor(row?.brandId, row?.vendor?.id)
          }
        />
      ),
    },
  ];

  function handleVendorChange(value, id, option) {
    const updatedProducts = csvData?.products?.map((product) => {
      if (product?.brandId === id) {
        // Check if the vendor already exists in the organisations array
        const existingOrganisation = product.organisations.find(
          (org) => org.vendor.id === value.value
        );

        if (existingOrganisation) {
          // If the vendor already exists, update its details
          const updatedOrganisations = product.organisations.map((org) =>
            org.vendor.id === value.value
              ? {
                  ...org,
                  name: value.label,
                }
              : org
          );
          return { ...product, organisations: updatedOrganisations };
        } else {
          // If the vendor doesn't exist, append it to the organisations array
          const updatedProduct = {
            ...product,
            organisations: [
              ...product.organisations,
              {
                id: value?.organisation,
                name: value?.label,
                vendor: { id: value?.value },
              },
            ],
          };
          return updatedProduct;
        }
      }
      return product;
    });

    // Update the products array in the CSV data
    setCSVData((prev) => ({ ...prev, products: updatedProducts }));
  }
  const deleteRow = (row, index) => {
    console.log(row, "QQQQQQQQQQ");
    setCSVData((prev) => {
      return {
        ...prev,
        products: prev?.products?.filter((item, sn) =>
          item?.recordId ? item?.recordId !== row?.recordId : sn !== index
        ),
      };
    });
  };
  useEffect(() => {
    const { invalidData, validData } = findInvalidData(csvData?.products);
    if (invalidData.length > 0) {
      setInvalidData(invalidData);
    }
    // if (validData.length > 0) {
    setValidData(csvData?.products);
  }, [csvData?.products, editedRecordSaved]);

  const productVendorsPopover = (brandId, id, preSelectedVendors) => {
    return (
      <>
        <div style={{ width: 300 }}>
          <VendorSelect
            limit={10}
            hideCode
            inputlength={3}
            onChange={(value, option) => handleVendorChange(value, id, option)}
            filterVendors={_?.uniqBy(
              dealsInAllBrandsOrganisationsOptions,
              "value"
            )?.map((option) => option?.value)}
          />
        </div>
        <div
          className="table-responsive decrease-table-width-with-input-fields"
          style={{ marginBottom: 14, marginTop: 10 }}
        >
          <Table
            className="custom-table remove-hovertable custom-table-delivery-expenses"
            size="small"
            // loading={isLoading}
            columns={tableProductVendorPopoverColumns}
            dataSource={preSelectedVendors?.map((item) => ({
              ...item,
              brandId: brandId,
            }))}
            rowKey="id"
            pagination={false}
          />
        </div>
      </>
    );
  };
  const disabledbeforeDate = (current) => {
    return current && current < moment().startOf("day");
  };
  const handleDueDateChange = (date) => {
    setCSVData((prev) => ({
      ...prev,
      products: prev?.products?.map((p) => ({
        ...p,
        dueDate: date,
      })),
    }));
  };

  const productColumns = [
    {
      title: "sn",
      dataIndex: "name",
      fixed: "left",
      render: (name, row, index) => (
        <Text style={{ width: 20 }} ellipsis={{ tooltip: "" }}>
          {text(index + 1)}
        </Text>
      ),
    },
    {
      title: "record",
      dataIndex: "name",
      fixed: "left",

      render: (name, row, index) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: row?.recordId }}>
          {text(row?.recordId)}
        </Text>
      ),
    },
    {
      title: "doc date",
      dataIndex: "name",
      fixed: "left",

      render: (name, row, index) => (
        <Text
          style={{ width: 100 }}
          ellipsis={{
            tooltip: row?.docDate
              ? moment(row?.docDate).format(DATE_FORMAT_DD_MM_YYYY)
              : "",
          }}
        >
          {row?.docDate
            ? moment(row?.docDate).format(DATE_FORMAT_DD_MM_YYYY)
            : ""}
        </Text>
      ),
    },
    {
      title: "p/r",
      dataIndex: "pr",
      align: "left",
      fixed: "left",

      render: (partNumber, row) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: row?.pr }}>
          {text(row?.pr)}
        </Text>
      ),
    },
    {
      title: "line",
      dataIndex: "pr",
      fixed: "left",

      render: (partNumber, row) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: row?.lineNumber }}>
          {text(row?.lineNumber)}
        </Text>
      ),
    },
    {
      title: "material no.",
      dataIndex: "pr",
      fixed: "left",

      align: "left",
      render: (partNumber, row) => (
        <Text
          style={{ width: 100 }}
          ellipsis={{ tooltip: row?.materialNumber }}
        >
          {text(row?.materialNumber)}
        </Text>
      ),
    },
    {
      title: "name",
      dataIndex: "material",
      align: "left",
      width: 200,
      render: (material, row) => {
        const descriptionWithLineBreaks = row?.description?.replace(
          /\n/g,
          "<br>"
        );

        return (
          <Popover
            content={
              <div
                style={{
                  display: "flex",
                  maxWidth: 500,
                  maxHeight: 300,
                  overflow: "auto",
                }}
              >
                <div>{ReactHtmlParser(descriptionWithLineBreaks || "")}</div>
                <Tooltip title="Copy to clipboard">
                  <Button
                    icon={<CopyOutlined />}
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        ReactHtmlParser(descriptionWithLineBreaks || "")
                      );
                    }}
                    type="primary"
                    size="small"
                    shape="round"
                  ></Button>
                </Tooltip>
              </div>
            }
            trigger="hover"
          >
            <Text style={{ width: 200 }} ellipsis={{ tooltip: material }}>
              {text(material)}
            </Text>
          </Popover>
        );
      },
    },
    {
      title: "qty",
      dataIndex: "quantity",
      align: "right",
      width: 30,
      render: (quantity) => (
        <Text style={{ width: 50 }} ellipsis={{ tooltip: quantity }}>
          {text(quantity)}
        </Text>
      ),
    },
    {
      title: "uom",
      dataIndex: "uom",
      align: "right",
      render: (uom) => (
        <Text style={{ width: 30 }} ellipsis={{ tooltip: uom }}>
          {text(uom)}
        </Text>
      ),
    },
    // {
    //   title: "due",
    //   dataIndex: "dueDate",
    //   width: 100,
    //   render: (dueDate, row, index) => (
    //     <div style={{ width: 100 }}>
    //       {row?.dueDate
    //         ? moment(row?.dueDate).format(DATE_FORMAT_DD_MM_YYYY)
    //         : ""}
    //     </div>
    //   ),
    // },
    {
      title: "brand",
      dataIndex: "brand",
      render: (brand) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: brand }}>
          {text(brand)}
        </Text>
      ),
    },
    {
      title: "vendors",
      dataIndex: "name",
      align: "left",
      width: 200,
      render: (name, row, index) => {
        const vendors = row?.organisations
          ?.map((vendor) => vendor?.name)
          ?.toString()
          ?.split(",")
          ?.join(", ");
        return (
          <div style={{ display: "flex" }}>
            <Text
              style={{ width: 150 }}
              ellipsis={{
                tooltip: vendors,
              }}
            >
              {vendors}
            </Text>
            {!row?.invalidReason && !invalidResponseData.length > 0 && (
              <Popover
                placement={"right"}
                title="Select Vendors"
                key={row?.brandId}
                content={() =>
                  productVendorsPopover(
                    row?.brandId,
                    row?.brandId,
                    row?.organisations
                  )
                }
                trigger={"click"}
              >
                <a>
                  {" "}
                  <EditOutlined />
                </a>
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      title: "invalid reason",
      dataIndex: "invalidReason",
      align: "left",
      width: 100,
      render: (invalidReason, row, index) => {
        return (
          <Text
            style={{ width: 200 }}
            ellipsis={{
              tooltip: invalidReason,
            }}
          >
            {invalidReason}
          </Text>
        );
      },
    },
    {
      title: "",
      dataIndex: "invalidReason",
      align: "left",
      width: 100,
      fixed: "right",
      render: (invalidReason, row, index) => {
        return (
          <div className="text-right" style={{ display: "flex" }}>
            {row?.invalidReason && (
              <Tooltip title="Delete Record">
                <div>
                  <a rel="noreferrer" onClick={() => deleteRow(row, index)}>
                    <DeleteOutlined className="custom-icon-button" />
                  </a>
                </div>
              </Tooltip>
            )}
            {/* <Typography.Link
              // disabled={editingKey !== ""}
              onClick={() => {
                setEditRecord(row);
                setShowEditDrawer(true);
              }}
            >
              <a style={{ marginLeft: 5 }} alignItems="center">
                <EditOutlined className="custom-icon-button" />
              </a>
            </Typography.Link> */}
          </div>
        );
      },
    },
  ];
  const rfqProductsColumns = [
    {
      title: "id",
      dataIndex: "rfqCode",
      render: (rfqCode, row, index) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: rfqCode }}>
          {text(rfqCode)}
        </Text>
      ),
    },
    {
      title: "vendor",
      dataIndex: "organisation",
      align: "left",
      render: (organisation, row) => (
        <Text style={{ width: 150 }} ellipsis={{ tooltip: organisation?.name }}>
          {text(organisation?.name)}
        </Text>
      ),
    },
    {
      title: "contact",
      dataIndex: "material",
      align: "left",
      render: (material, row) => (
        <Text
          style={{ width: 150 }}
          ellipsis={{
            tooltip:
              (row?.organisation?.defaultContact?.firstName || "") +
              " " +
              (row?.organisation?.defaultContact?.lastName || ""),
          }}
        >
          {text(
            (row?.organisation?.defaultContact?.firstName || "") +
              " " +
              (row?.organisation?.defaultContact?.lastName || "")
          )}
        </Text>
      ),
    },
    {
      title: "p#",
      dataIndex: "quantity",
      align: "right",
      width: 30,
      render: (quantity, row) => (
        <Text
          style={{ width: 50 }}
          ellipsis={{ tooltip: row?.products?.length ?? 0 }}
        >
          {text(row?.products?.length ?? 0)}
        </Text>
      ),
    },
    {
      title: "sent",
      dataIndex: "status",
      render: (status) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: status }}>
          {text(status)}
        </Text>
      ),
    },
  ];

  const checkIfEveryproductHasDueDate = csvData?.products?.every(
    (item) => item?.dueDate
  );

  const checkIfInvalidData = csvData?.products?.filter(
    (item) => item?.invalidReason
  );
  console.log(checkIfInvalidData, "checkIfInvalidData");
  const finalInvalidDataWithInvalidBrands = csvData?.products
    ?.filter((d) => !d?.brandId && d?.brand)
    ?.map((item) => item?.brand);
  return (
    <>
      {/* {!csvData.length && ( */}
      {!csvData?.products?.length > 0 && !csvData?.brands?.length > 0 && (
        <Card>
          <Row gutter={ROW_GUTTER}>
            <Col className="" xs={24} sm={24} md={24}>
              <div className="hide-name hide-delete-icon custom-upload">
                <Dragger
                  className="custom-upload custom-upload-drag"
                  // listType="picture-card"
                  name="file"
                  multiple={false}
                  accept=".csv"
                  beforeUpload={() => false}
                  key={refresh}
                  onPreview={(file) => {
                    window.open(
                      file?.response?.data?.publicUrl,
                      "_blank",
                      "noreferrer"
                    );
                  }}
                  onChange={(info) => {
                    fileHandler(info);
                  }}
                  progress={{
                    strokeColor: {
                      "0%": "#108ee9",
                      "100%": "#87d068",
                    },
                    strokeWidth: 3,
                    format: (percent) =>
                      percent && `${parseFloat(percent.toFixed(2))}%`,
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    please upload only .csv files.
                  </p>
                </Dragger>
              </div>
            </Col>
          </Row>
        </Card>
      )}
      <>
        {csvData?.brands?.length > 0 && checkIfInvalidData?.length == 0 && (
          <Card>
            <PageHeader
              className="custom-header"
              ghost={false}
              title="Brands"
              subTitle=""
              extra={[]}
            />
            <div
              className="table-responsive decrease-table-width-with-input-fields"
              style={{ marginBottom: 14 }}
            >
              <Table
                className="custom-table remove-hovertable custom-table-delivery-expenses"
                size="small"
                loading={submitLoading}
                columns={tableColumns}
                dataSource={csvData?.brands}
                rowKey="id"
                pagination={false}
                key={invalidResponseData?.length}
                rowClassName={(row) =>
                  row?.invalidReason ? "erronous-row" : ""
                }
              />
            </div>
          </Card>
        )}
        {csvData?.products?.length > 0 && (
          <Card>
            <PageHeader
              className="custom-header"
              ghost={false}
              title="Products"
              subTitle=""
              extra={[
                <Space>
                  <div style={{ marginRight: "auto" }}>
                    {" "}
                    {/* Use marginRight: "auto" to push the elements to the left */}
                    {invalidResponseData?.length > 0 && (
                      <div>
                        Invalid Products: {invalidResponseData?.length}/
                        {totalRecords?.length}
                      </div>
                    )}
                    {invalidResponseData?.length > 0 && (
                      <Button
                        style={{
                          color: "white",
                          marginRight: 10,
                          backgroundColor: "red",
                          border: "none",
                        }}
                        title="Download"
                        onClick={downloadProducts}
                        size="small"
                        type="primary"
                      >
                        Download Invalid Sheet
                      </Button>
                    )}
                    {finalInvalidDataWithInvalidBrands?.length > 0 && (
                      <Button
                        type="primary"
                        size="small"
                        color="red"
                        onClick={() => addInvalidBrandData()}
                        style={{
                          marginRight: 10,
                          background: "red",
                          border: "none",
                        }}
                      >
                        Add Invalid Brand
                      </Button>
                    )}
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => handleClearData()}
                      style={{ marginRight: 10 }}
                    >
                      Reset
                    </Button>
                    {checkIfInvalidData?.length > 0 && (
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => handleEditRecords()}
                        style={{ marginRight: 10 }}
                        disabled={submittedProductsData?.length}
                      >
                        Edit Invalid Data
                      </Button>
                    )}
                    <DatePicker
                      style={{ marginRight: 10 }}
                      format={DATE_FORMAT_DD_MM_YYYY}
                      disabledDate={disabledbeforeDate}
                      onChange={(date) => handleDueDateChange(date)}
                      placeholder="Due Date"
                    />
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => onFinish()}
                      disabled={
                        invalidResponseData?.length ||
                        submittedProductsData?.length
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </Space>,
              ]}
            />
            <div className="table-responsive">
              <Table
                className="custom-table"
                size="small"
                loading={submitLoading}
                columns={
                  invalidResponseData?.length == 0
                    ? productColumns?.filter(
                        (col) => col.title != "invalid reason"
                      )
                    : productColumns
                }
                dataSource={csvData?.products || []}
                rowKey="id"
                pagination={false}
                key={invalidResponseData?.length}
                rowClassName={(row) =>
                  row?.invalidReason ? "erronous-row" : ""
                }
                scroll={{ x: "max-content" }} // Adjust the scroll property to your needs
              />
            </div>
          </Card>
        )}
        {submittedProductsData?.length > 0 && (
          <Card>
            <PageHeader
              className="custom-header"
              ghost={false}
              title="RFQs"
              subTitle=""
              extra={[
                <div>
                  <Button
                    type="primary"
                    size="small"
                    style={{ marginRight: 10 }}
                    onClick={() =>
                      sentAllRfqs(
                        submittedProductsData?.map((item) => item?.id)
                      )
                    }
                  >
                    Send All
                  </Button>
                </div>,
              ]}
            />
            <div className="table-responsive">
              <Table
                className="custom-table"
                size="small"
                columns={rfqProductsColumns}
                dataSource={submittedProductsData || []}
                rowKey="id"
                pagination={false}
              />
            </div>
          </Card>
        )}
      </>
      <Drawer
        title="Edit Details"
        width={"95%"}
        onClose={() => {
          setShowEditDrawer(false);
        }}
        visible={!!showEditDrawer}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {!!showEditDrawer && (
          <EditRecordDrawer
            onDrawerClose={() => {
              setShowEditDrawer(false);
              // fetchData(true)
            }}
            editRecord={editRecord}
            csvData={csvData}
            setCSVData={setCSVData}
            setEditedRecordSaved={setEditedRecordSaved}
          />
        )}
      </Drawer>
    </>
  );
};

export default BulkRfqDetail;

import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  CATEGORY_LIST,
  CATEGORY_COUNT,
  CATEGORY_ADD,
  CATEGORY_UPDATE,
  CATEGORY_DETAIL,
  CATEGORY_DELETE,
} = API_PATH;

export const CategoryService_ = {
  getCategory: (filter) => {
    return fetch_({
      url: CATEGORY_LIST,
      method: "GET",
      params: filter,
    });
  },
  countCategory: (filter) => {
    return fetch_({
      url: CATEGORY_COUNT,
      method: "GET",
      params: filter,
    });
  },
  getCategorydetail: (categoryId) => {
    return fetch_({
      url: CATEGORY_DETAIL(categoryId),
      method: "GET",
    });
  },
  addCategory: (category) => {
    return fetch_({
      url: CATEGORY_ADD,
      method: "POST",
      data: category,
    });
  },
  updateCategory: (categoryId, category) => {
    return fetch_({
      url: CATEGORY_UPDATE(categoryId),
      method: "PUT",
      data: category,
    });
  },
  deleteCategory: (categoryId) => {
    return fetch_({
      url: CATEGORY_DELETE(categoryId),
      method: "DELETE",
    });
  },
};

import React from "react";
import { CategoryService_ } from "services/Tenant/category.service";
import AsyncSelectWrapper from "../AsyncSelectWrapper";

const CategorySelect = ({ onChange, defaultValue, limit, inputlength }) => {
  const fetchEnquiry = async (input) => {
    if (!input || input.length < inputlength) return [];

    return CategoryService_.getCategory({
      search: input,
      limit: limit,
      // where: {
      //"al.is_active": true,
      //},
    }).then((data) => {
      const categoryOptions = data.map((o) => ({
        label: o.category,
        value: o.id,
      }));
      return categoryOptions;
    });
  };

  return (
    <AsyncSelectWrapper
      onChange={onChange}
      defaultValue={defaultValue}
      fetchOptions={fetchEnquiry}
      placeholderValue={`Type at least ${inputlength} characters`}
      inputlength={inputlength}
    />
  );
};

export default CategorySelect;

import React, { useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Form,
  Drawer,
  DatePicker,
  Avatar,
  message,
  Button,
} from "antd";
import {
  FilePdfOutlined,
  RetweetOutlined,
  EditFilled,
} from "@ant-design/icons";
import { ROW_GUTTER } from "constants/ThemeConstant";
import {
  DATE_FORMAT_DD_MM_YYYY,
  DATE_FORMAT_DD_MM_YYYY_HH_mm,
  DATE_FORMAT_HH_mm,
} from "constants/DateConstant";
import moment from "moment";
import CustomerSelect from "components/shared-components/CustomerSelect";
import { OrgCustomersService_ } from "../../../../services/Tenant/organisation_customer.service";
import { getUploadIcon } from "utils/utilities";

import { useForceUpdate } from "hooks/useForceUpdate";
import { ADD } from "constants/common.constant";
import DynamicUpload from "components/shared-components/DynamicUpload";
import { CommonResourceService_ } from "services/Tenant/common.service";
import Tooltip from "antd/es/tooltip";
import AddressDrawer from "./delivery-address";
import BillingAddressDrawer from "./billing-address";
import { formatAddress } from "utils/helpers";

const { TextArea } = Input;
const EnquiryDetail = ({
  form,
  enquiryDetail,
  setBillingAddress,
  setDeliveryAddress,
  mode,
}) => {
  const [deliveryTermList, setDeliveryList] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
  const [addDeliveryAddress_, setAddDeliveryAddress_] = useState(null);
  const [showBillAddress, setShowBillAddress] = useState(null);
  const [addBillingAddress_, setAddBillingAddress_] = useState(null);

  useEffect(() => {
    setBillingAddress(addBillingAddress_);
    form.setFieldsValue({
      billto: addBillingAddress_,
    });
  }, [addBillingAddress_]);
  useEffect(() => {
    setDeliveryAddress(addDeliveryAddress_);
    form.setFieldsValue({
      deliveryto: addDeliveryAddress_,
    });
  }, [addDeliveryAddress_]);

  let billingAddressParts = [];
  if (addBillingAddress_?.billingAddress) {
    billingAddressParts.push(addBillingAddress_?.billingAddress);
  }
  if (addBillingAddress_?.billingCity) {
    billingAddressParts.push(addBillingAddress_?.billingCity);
  }
  if (addBillingAddress_?.billingState) {
    billingAddressParts.push(addBillingAddress_?.billingState);
  }
  if (addBillingAddress_?.billingCountry) {
    billingAddressParts.push(addBillingAddress_?.billingCountry);
  }
  if (addBillingAddress_?.billingPostCode) {
    billingAddressParts.push(addBillingAddress_?.billingPostCode);
  }
  if (addBillingAddress_?.billingLandmark) {
    billingAddressParts.push(addBillingAddress_?.billingLandmark);
  }
  const fullBillingAddress = billingAddressParts.join(", ");

  let deliveryAddressParts = [];
  if (addDeliveryAddress_?.deliveryAddress) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryAddress);
  }
  if (addDeliveryAddress_?.deliveryCity) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryCity);
  }
  if (addDeliveryAddress_?.deliveryState) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryState);
  }
  if (addDeliveryAddress_?.deliveryCountry) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryCountry);
  }
  if (addDeliveryAddress_?.deliveryPostCode) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryPostCode);
  }
  if (addDeliveryAddress_?.deliveryLandmark) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryLandmark);
  }
  const fullDeliveryAddress = deliveryAddressParts.join(", ");

  const rules = {
    enqDate: [
      {
        required: true,
        message: "Please select enquiry date",
      },
    ],
    dueDate: [
      {
        required: true,
        message: "Please select due data",
      },
    ],
    source: [
      {
        required: true,
        message: "Please select a source",
      },
    ],
    ref: [
      {
        required: true,
        //pattern: /^[a-zA-Z0-9_\-\/\.]+$/,
        pattern: /^[a-zA-Z0-9-/_\.\s]*$/,
        message: "Please input valid customer ref",
      },
    ],
    customer: [
      {
        required: true,
        message: "Please select a customer",
      },
    ],
    buyers: [
      {
        required: true,
        message: "Please select a buyer",
      },
    ],
    billto: [
      {
        required: true,
        message: "Billing Country is required",
      },
    ],
    deliveryto: [
      {
        required: true,
        message: "Delivery Country is required",
      },
    ],
    doc: [
      {
        required: !enquiryDetail?.id,
        message: "Please select a doc",
      },
    ],
  };

  const sourceOptions = [
    { value: "Email", label: "Email" },
    { value: "Phone", label: "Phone" },
    { value: "Web", label: "Web" },
    { value: "Other", label: "Other" },
  ];

  const [custcontactList, setCustContactList] = useState([]);

  if (initialData) {
    form.setFieldsValue({
      //shippingTerm: initialData?.customer?.shippingTerm,
    });
  }

  useEffect(() => {
    deliveryTermData();
  }, []);
  const showDeliveryAddDrawer = () => {
    setShowDeliveryAddress(true);
  };
  const showBillAddressDrawer = () => {
    setShowBillAddress(true);
  };
  const buyerData = (selectVal) => {
    OrgCustomersService_.getCustomerContact(selectVal).then((data) => {
      setCustContactList(data);
    });
  };
  const fetchOrgDetail = (selectVal) => {
    setIsLoading(true);
    OrgCustomersService_.getOrganisationcustomerdetail(selectVal)
      .then((data) => {
        setInitialData(data);
      })
      .finally(() => setIsLoading(false));
  };
  const deliveryTermData = () => {
    CommonResourceService_.getDeliveryTerms().then((data) => {
      setDeliveryList(data);
    });
  };
  const forceUpdate = useForceUpdate();

  function handleChange(value) {
    buyerData(value.value);
    form.setFieldsValue({ buyer: "" });
    fetchOrgDetail(value.value);
  }

  const disabledafterDate = (current) => {
    return current && current > moment().endOf("day");
  };

  useEffect(() => {
    if (enquiryDetail?.id) {
      buyerData(enquiryDetail?.customer?.id);
      fetchOrgDetail(enquiryDetail?.customer?.id);
    }
  }, [enquiryDetail]);

  const disabledbeforeDate = (current) => {
    return current && current < moment().startOf("day");
  };

  return (
    (enquiryDetail || mode === ADD) && (
      <>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="enquiryDate"
              label="Enq Date"
              rules={rules.enqDate}
            >
              <DatePicker
                style={{ width: "100%" }}
                format={DATE_FORMAT_DD_MM_YYYY}
                disabledDate={disabledafterDate}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item name="dueDate" label="Due Date" rules={rules.dueDate}>
              <DatePicker
                showNow={false}
                showTime={{
                  format: DATE_FORMAT_HH_mm,
                  defaultValue: moment().set({ hour: 23, minute: 59 }),
                }}
                style={{ width: "100%" }}
                format={DATE_FORMAT_DD_MM_YYYY_HH_mm}
                disabledDate={disabledbeforeDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="source" label="Source" rules={rules.source}>
              <Select options={sourceOptions} allowClear />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item name="customerRef" label="Cust Ref" rules={rules.ref}>
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="customer" label="Customer" rules={rules.customer}>
              <CustomerSelect
                onChange={handleChange}
                defaultValue={{
                  label: enquiryDetail?.customer?.organisation?.name,
                }}
                clearOnSelect
                limit={10}
                inputlength={3}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="buyer" label="Buyer" rules={rules.buyers}>
              <Select
                allowClear
                placeholder="Select a contact"
                showSearch
                options={custcontactList.map(({ id, organisationContact }) => ({
                  value: id,
                  label: organisationContact.firstName,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Inco Term" name="shippingTerm">
              <Select
                showSearch
                allowClear
                options={deliveryTermList.map(({ id, name }) => ({
                  value: name,
                  label: name,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            {mode == "ADD" ? (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Item
                    label={
                      <span>
                        Bill to.&nbsp;
                        <Tooltip
                          trigger={["hover"]}
                          placement="topLeft"
                          title="Replace"
                        >
                          <EditFilled
                            onClick={showBillAddressDrawer}
                            style={{ color: "#3e79f7" }}
                          />
                        </Tooltip>
                      </span>
                    }
                    name="billto"
                    rules={rules.billto}
                  ></Form.Item>

                  {addBillingAddress_ !== null &&
                    formatAddress("billing", addBillingAddress_)}
                </div>
              </>
            ) : (
              <> </>
            )}
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24} md={12}>
            {mode == "ADD" ? (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Item
                    label={
                      <span>
                        Delivery to.&nbsp;
                        <Tooltip
                          trigger={["hover"]}
                          placement="topLeft"
                          title="Replace"
                        >
                          <EditFilled
                            onClick={showDeliveryAddDrawer}
                            style={{ color: "#3e79f7" }}
                          />
                        </Tooltip>
                      </span>
                    }
                    name="deliveryto"
                    rules={rules.deliveryto}
                  ></Form.Item>
                  {addDeliveryAddress_ !== null &&
                    formatAddress("delivery", addDeliveryAddress_)}
                </div>
              </>
            ) : (
              <> </>
            )}
          </Col>
          <Col xs={24} sm={24} md={12}></Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          {mode !== "ADD" ? (
            <>
              <Col xs={24} sm={24} md={6}>
                <Form.Item label="Enquiry Doc">
                  <a
                    href={enquiryDetail?.enquiryDoc?.publicUrl}
                    title={enquiryDetail?.enquiryDoc?.fileName}
                    target="_blank"
                  >
                    {
                      <Avatar
                        size={64}
                        icon={<FilePdfOutlined style={{ color: "#3e79f7" }} />}
                      />
                    }
                  </a>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={4}>
                <Form.Item name="enquiryDoc" rules={rules.doc}>
                  <DynamicUpload
                    form={form}
                    getUploadIcon={getUploadIcon}
                    acceptedFileTypes={[
                      "image/jpeg",
                      "image/jpg",
                      "application/pdf",
                    ]}
                    uploadIcon={getUploadIcon(form, "enquiryDoc")}
                    fieldValue={"enquiryDoc"}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={14}>
                <p className="ant-upload-hint">
                  <ul>
                    <li>
                      <i>(* Upload to change enquiry doc)</i>
                    </li>
                    <li>
                      please upload only .jpeg and .pdf files. max size 200kb.
                    </li>
                  </ul>
                </p>
              </Col>
            </>
          ) : (
            <>
              <Col xs={24} sm={24} md={6}>
                <Form.Item
                  name="enquiryDoc"
                  label="Enquiry Doc"
                  rules={rules.doc}
                >
                  <DynamicUpload
                    form={form}
                    getUploadIcon={getUploadIcon}
                    acceptedFileTypes={[
                      "image/jpeg",
                      "image/jpg",
                      "application/pdf",
                    ]}
                    isMultiple={true}
                    uploadIcon={getUploadIcon(form, "enquiryDoc")}
                    fieldValue={"enquiryDoc"}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={18}>
                <p className="ant-upload-hint">
                  <ul>
                    <li>
                      please upload only .jpeg and .pdf files. max size 200kb.
                    </li>
                  </ul>
                </p>
              </Col>
            </>
          )}
        </Row>

        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item name="notes" label="Note" rules="">
              {/* <RichEditor
              onChange={(value) => {
                form?.setFieldsValue({
                  description: value,
                });
              }}
              defaultValue={enquiryDetail?.notes}
            /> */}
              <TextArea placeholder="Notes" autoSize />
            </Form.Item>
          </Col>
        </Row>
        <Drawer
          title="Delivery Address"
          width={700}
          onClose={() => {
            setShowDeliveryAddress(false);
          }}
          visible={showDeliveryAddress}
          className="custom-drawer"
        >
          <AddressDrawer
            onDrawerClose={() => {
              setShowDeliveryAddress(false);
            }}
            mode="ADD"
            initialData={initialData}
            setDeliveryAddress={setAddDeliveryAddress_}
          />
        </Drawer>
        <Drawer
          title="Billing Address"
          width={700}
          onClose={() => {
            setShowBillAddress(false);
          }}
          visible={showBillAddress}
          className="custom-drawer"
        >
          <BillingAddressDrawer
            onDrawerClose={() => {
              setShowBillAddress(false);
            }}
            mode="ADD"
            initialData={initialData}
            setBillAddress={setAddBillingAddress_}
          />
        </Drawer>
      </>
    )
  );
};

export default EnquiryDetail;

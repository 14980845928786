import React, { useState, useEffect } from "react";
import { Form, Button, message, Space, Layout } from "antd";

import Utils from "utils/common";
import { Content, Footer } from "antd/lib/layout/layout";
import { BrandService_ } from "services/Tenant/brand.service";
import {
  refreshBrandList,
  refreshReceiveProductList,
  showAddEditReceiveProductDrawer,
} from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import ReceiveProduct from "./receive-product";
import { ReceivePoService } from "services/Tenant/receiver_order.service";

const ReceiveProductForm = (props) => {
  const { onDrawerClose, purchaseOrderId, editableReceiveProduct } = props;

  const [form] = Form.useForm();
  const [uploadedImg, setImage] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [brandDetail, setBrandDetail] = useState({});
  const dispatch = useDispatch();
  const [productsPoQyty, setProductsPoQyty] = useState([]);
  const [productsPoReceivingQyty, setProductsPoReceivingQyty] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [messageDisplayed, setMessageDisplayed] = useState(false);

  useEffect(() => {
    if (purchaseOrderId) {
      ReceivePoService.getReceivePoProducts(purchaseOrderId).then((data) => {
        setProducts(data);
        // form.setFieldsValue({
        //   ...data,
        // });
      });
    }
    // .finally(() => setLoading(false));
  }, [purchaseOrderId]);
  useEffect(() => {
    if (purchaseOrderId) {
      ReceivePoService.getPurchaseOrderReceivingProductsPoQty(
        purchaseOrderId
      ).then((data) => {
        setProductsPoQyty(data);
      });
    }
    // .finally(() => setIsLoading(false));
  }, [purchaseOrderId]);
  useEffect(() => {
    if (purchaseOrderId) {
      ReceivePoService.getPurchaseOrderReceivingProductsReceiveQty(
        purchaseOrderId
      ).then((data) => {
        setProductsPoReceivingQyty(data);
      });
    }
    // .finally(() => setIsLoading(false));
  }, [purchaseOrderId]);

  useEffect(() => {
    const mapArray1 = new Map(
      productsPoQyty?.map((item) => [item.productId, item])
    );
    const mapArray3 = new Map(
      productsPoReceivingQyty?.map((item) => [item.productId, item])
    );
    const updatedArray2 = products?.map((item2) => {
      const item1 = mapArray1?.get(item2.product.id);
      const item3 = mapArray3?.get(item2.product.id);
      if (item1 || item3) {
        const item3Qty = Number(item3?.quantity) || 0;
        console.log(item1);
        return {
          ...item2,
          poQuantity: item1?.quantity || 0,
          receiving: item3?.quantity || 0,
          receive:
            Number(item1?.quantity) - Number(item3?.quantity) > 0
              ? Number(item1?.quantity) - item3Qty
              : item1.quantity,
        };
      }
      return item2;
    });

    const filteredArray = updatedArray2?.filter(
      (f) => Number(f?.poQuantity) - Number(f?.receiving) > 0
    );
    setFinalData(filteredArray);
    // if (filteredArray?.length === 0 && !messageDisplayed) {
    //   message.error("No Products left to be received");
    //   dispatch(showAddEditReceiveProductDrawer(false));
    //   setMessageDisplayed(true);
    // }
  }, [
    dispatch,
    messageDisplayed,
    products,
    productsPoQyty,
    productsPoReceivingQyty,
  ]);

  const handleUploadChange = (info) => {
    Utils.getBase64(info.file.originFileObj, (imageUrl) => {
      setImage(imageUrl);
      setUploadLoading(true);
    });
  };

  const onFinish = () => {
    dispatch(refreshReceiveProductList(false));
    const filteredProductsWithbalanceRemaining = finalData?.filter(
      (f) => Number(f?.poQuantity) - Number(f?.receive) >= 0
    );
    setSubmitLoading(true);

    form
      .validateFields()
      .then((values) => {
        delete values.deliveryVendor;

        const productsPayload = filteredProductsWithbalanceRemaining
          ?.map((p) => ({
            productId: p?.product?.id,
            quantity: p?.receive,
          }))
          ?.filter(
            (f) => f.quantity != null && f.quantity != "" && f.quantity != 0
          );
        Object.keys(values).forEach((key) => {
          if (key.includes("unitPrice")) {
            delete values[key];
          }
        });
        delete values.vendor;
        // values.poId = values.poId.id;
        // values.deliveryVendorId = "78e95db6-c068-4cd7-9a54-c2a0772079c2";
        ReceivePoService.createPoReceiving(purchaseOrderId, {
          ...values,
          products: productsPayload,
        }).then((res) => {
          message.success("Receive PO Products Created Successfully");
          dispatch(showAddEditReceiveProductDrawer(false));
          dispatch(refreshReceiveProductList(true));
        });
      })
      .catch((info) => {
        setSubmitLoading(false);
        message.error(
          "Receive Quantity must be equal to or less than the PO Qty. "
        );
      });
  };

  return (
    <>
      <Form
        layout={"horizontal"}
        form={form}
        name="enquiry-filter-form"
        autoComplete="off"
      >
        <Layout>
          <Content>
            <ReceiveProduct
              {...props}
              setSelectedImageFile={setSelectedImageFile}
              uploadedImg={uploadedImg}
              uploadLoading={uploadLoading}
              handleUploadChange={handleUploadChange}
              form={form}
              brandDetail={brandDetail}
              products={products}
              updatedProductArray2WithPoQuantity={finalData}
              setFinalData={setFinalData}
              editableReceiveProduct={editableReceiveProduct}
            />
          </Content>
          <Footer>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  onFinish(true);
                }}
                htmlType="submit"
                size="small"
                disabled={finalData?.length == 0}
              >
                Submit
              </Button>
              <Button
                onClick={() => {
                  onDrawerClose();
                  form.resetFields();
                }}
                size="small"
              >
                Cancel
              </Button>
            </Space>
          </Footer>
        </Layout>
      </Form>
    </>
  );
};

export default ReceiveProductForm;

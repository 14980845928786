import { API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
    RESOURCES_UPLOAD, PAYMENT_LIST, DELIVERY_LIST
} = API_PATH;

export const CommonResourceService_ = {
    addEnquiryResource: (resources) => {
        return fetch_({
            url: RESOURCES_UPLOAD,
            method: "POST",
            data: resources,
        });
    },

    getPaymentTerms: (filter) => {
        return fetch_({
          url: PAYMENT_LIST,
          method: "GET",
          params: filter,
        });
      },

      getDeliveryTerms: (filter) => {
        return fetch_({
          url: DELIVERY_LIST,
          method: "GET",
          params: filter,
        });
      },
 

};

import {
  REFRESH_FINALISE_ORDER_LIST,
  SHOW_ADD_EDIT_FINALISE_ORDER_DRAWER,
} from "redux/constants/FinaliseOrder";

const initialState = {
  showAddEditFinaliseOrderDrawer: false,
  editableFinaliseOrder: null,
  refreshFinaliseOrderList: false,
  quotationId: "",
  selectedOption: "",
  customerId: {},
};
export const finaliseorder = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SHOW_ADD_EDIT_FINALISE_ORDER_DRAWER:
      return {
        ...state,
        showAddEditFinaliseOrderDrawer: data.showAddEditFinaliseOrderDrawer,
        selectedOption: data.selectedOption,
        quotationId: data.quotationId,
        editableFinaliseOrder: data.editableFinaliseOrder,
        customerId: data.customerId,
      };
    case REFRESH_FINALISE_ORDER_LIST:
      return {
        ...state,
        refreshFinaliseOrderList: data.refreshFinaliseOrderList,
      };
    default:
      return state;
  }
};

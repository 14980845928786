import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Space,
  Modal,
  Divider,
  Layout,
  Row,
  Col,
  Table,
  Input,
  Select,
  PageHeader,
  Menu,
  Drawer,
  message,
  Tooltip,
  DatePicker,
} from "antd";

import Loading from "components/shared-components/Loading";
import {
  QuestionCircleFilled,
  RetweetOutlined,
  EditFilled,
} from "@ant-design/icons";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import ActionDropdownMenu from "components/shared-components/ActionDropdownMenu";
import {
  DATE_FORMAT_DD_MM_YYYY,
  DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ,
} from "constants/DateConstant";
import moment from "moment";
import { Content, Footer } from "antd/lib/layout/layout";
import { getUploadIcon } from "utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import Text from "antd/lib/typography/Text";
import { ROW_GUTTER } from "constants/ThemeConstant";
import DynamicUpload from "components/shared-components/DynamicUpload";
import RichEditor from "components/shared-components/RichEditor";
import { OrgCustomersService_ } from "services/Tenant/organisation_customer.service";
import { CommonResourceService_ } from "services/Tenant/common.service";
import { QuotationsPageService } from "services/Tenant/quotations.service";
import { sortArrayByProperty } from "utils/helpers";
import ContractSelect from "components/shared-components/ContractSelect";
import AddContractProductsDrawer from "./AddContractProductsDrawer";
import { EnquiryService_ } from "services/Tenant/enquiry.service";
import { CustomerContractService_ } from "services/Tenant/customer_contract.service";
import { showAddEditFinaliseOrderDrawer } from "redux/actions/FinaliseOrder";
import QuotationSelect from "components/shared-components/QuotationSelect";
import BillingAddressDrawer from "./bill-address";
import AddressDrawer from "./delivery-address";
import CommonAddressDrawer from "drawers/commonAddressDrawer";

const EditFinaliseAndOrderForm = (props) => {
  const { onDrawerClose, isLoading } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showQuotationInput, setShowQuotationInput] = useState(false);
  const [showContractInput, setShowContractInput] = useState(false);
  const [showPreviousOrderInput, setShowPreviousOrderInput] = useState(false);
  const [paymentTermList, setPaymentTermList] = useState([]);
  const [contractProducts, setContractProducts] = useState([]);
  const [deliveryTermList, setDeliveryList] = useState([]);
  const [quotationCertificatesData, setQuotationCertificatesData] = useState(
    []
  );
  const [selectedDeliveryTerm, setSelectedDeliveryTerm] = useState("");
  const [openAddContractProductsDrawer, setOpenAddContractProductsDrawer] =
    useState(false);
  const [buyer, setBuyer] = useState({});
  const [enuqiryDetails, setEnquiryDetails] = useState({});
  const [contractDetails, setContractDetails] = useState({});
  const [quotationDetails, setQuotationDetails] = useState({});
  const finaliseOrder = useSelector((state) => state.finaliseorder);
  const [address_, setAddress_] = useState(null);
  const [selectedaddress_, setSelectedAddress_] = useState(null);
  const [productList, setProductList] = useState([]);
  const [custcontactList, setCustContactList] = useState([]);
  const [billingaddress_, setBillingAddress_] = useState(null);
  const [showBillAddress, setShowBillAddress] = useState(null);
  const [billaddressData, setBillAddressData] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const [showCollection, setShowCollection] = useState(false);
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
  const [addDeliveryAddress_, setAddDeliveryAddress_] = useState(null);
  const [addBillingAddress_, setAddBillingAddress_] = useState(null);
  const [addCollectionAddress_, setAddCollectionAddress_] = useState(null);
  useEffect(() => {
    setAddDeliveryAddress_(addDeliveryAddress_);
    form.setFieldsValue({
      deliveryto: addDeliveryAddress_?.deliveryCountry,
    });
  }, [addDeliveryAddress_]);

  useEffect(() => {
    setAddBillingAddress_(addBillingAddress_);
    form.setFieldsValue({
      billto: addBillingAddress_?.billingCountry,
    });
  }, [addBillingAddress_]);
  useEffect(() => {
    setAddCollectionAddress_(addCollectionAddress_);
    form.setFieldsValue({
      collto: addCollectionAddress_?.collectionCountry,
    });
  }, [addCollectionAddress_]);
  useEffect(() => {
    paymentTermData();
    deliveryTermData();
  }, []);
  useEffect(() => {
    if (finaliseOrder.selectedOption === "contract") {
      form.setFieldsValue({
        source: "Contract",
      });
      setShowContractInput(true);
    }
  }, [finaliseOrder]);
  useEffect(() => {
    if (finaliseOrder.selectedOption === "quotation") {
      form.setFieldsValue({
        source: "Quotation",
      });
      setShowQuotationInput(true);
    }
  }, [finaliseOrder]);
  useEffect(() => {
    const contractDetails = finaliseOrder?.customerId;
    if (
      finaliseOrder.selectedOption == "quotation" &&
      Object.keys(contractDetails)?.length < 0
    )
      return;
    setContractDetails(contractDetails);
    if (contractDetails?.customer?.id) {
      OrgCustomersService_.getOrganisationcustomerdetail(
        contractDetails?.customer?.id
      ).then((res) => {
        OrgCustomersService_.getCustomerContact(
          contractDetails?.customer?.id
        ).then((data) => {
          setCustContactList(data);
        });
        setBuyer({
          value: contractDetails?.buyer?.id,
          label: contractDetails?.buyer.organisationContact.firstName,
        });
        form.setFieldsValue({
          contractId: contractDetails.id,
          paymentTerm: contractDetails?.payTerm,
          deliveryTerm: contractDetails?.deliveryTerm,

          deliveryAddress: contractDetails?.deliveryAddress,
          shipmentCity: contractDetails?.shipmentCity,
          buyer: contractDetails.buyer.organisationContact.firstName,
          customer: {
            label: res?.customer?.organisation?.name,
            contractDetails: res?.customer?.id,
          },
        });
      });
    }
    if (finaliseOrder?.quotationId) {
      CustomerContractService_.getContractProduct(
        finaliseOrder?.quotationId
      ).then((data) => {
        setContractProducts(data);
      });
    }
  }, [
    finaliseOrder?.customerId,
    finaliseOrder?.quotationId,
    finaliseOrder.selectedOption,
  ]);
  useEffect(() => {
    if (finaliseOrder.selectedOption !== "quotation") return;
    // setProductsLoading(true);
    QuotationsPageService.getQuotationProducts(finaliseOrder.quotationId).then(
      (data, filter_) => {
        const sortedData = sortArrayByProperty(data, "product.name");
        setProductList(sortedData);
      }
    );
    QuotationsPageService.getQuotationDetail(finaliseOrder.quotationId).then(
      (data, filter_) => {
        setQuotationDetails(data);
        form.setFieldsValue({
          quotationId: data.quotationCode,
          paymentTerm: data?.paymentTerm,
          deliveryTerm: data?.deliveryTerm,
          discount: data?.discount,
          validity: data?.validity,
          deliveryAddress: data?.deliveryAddress,
          shipmentCity: data?.shipmentCity,
        });
        setSelectedDeliveryTerm(data?.deliveryTerm);
        EnquiryService_.getEnquirydetail(data.enquiryId)
          .then((data) => {
            setEnquiryDetails(data);
            setBuyer({
              value: data?.buyer?.id,
              label: data.buyer.organisationContact.firstName,
            });

            OrgCustomersService_.getCustomerContact(data?.customer.id).then(
              (data) => {
                setCustContactList(data);
              }
            );
            form.setFieldsValue({
              customer: {
                label: data?.customer?.organisation.name,
                value: data?.customer.id,
              },
              buyer: data.buyer.organisationContact.firstName,
            });
            QuotationsPageService.getQuotationCertificates(
              finaliseOrder.quotationId
            ).then((data) => {
              const sortedData = sortArrayByProperty(data, "certificate.type");
              setQuotationCertificatesData(sortedData);
            });
          })
          .finally(() => setLoading(false));
      }
    );
  }, [finaliseOrder.quotationId]);
  useEffect(() => {
    setAddDeliveryAddress_({
      deliveryAddress: enuqiryDetails?.enquiryAddress?.deliveryAddress,
      deliveryAddressName: enuqiryDetails?.enquiryAddress?.deliveryAddressName,
      deliveryCity: enuqiryDetails?.enquiryAddress?.deliveryCity,
      deliveryCountry: enuqiryDetails?.enquiryAddress?.deliveryCountry,
      deliveryLandmark: enuqiryDetails?.enquiryAddress?.deliveryLandmark,
      deliveryPostCode: enuqiryDetails?.enquiryAddress?.deliveryPostCode,
      deliveryState: enuqiryDetails?.enquiryAddress?.deliveryState,
    });
    setAddBillingAddress_({
      billingAddress: enuqiryDetails?.enquiryAddress?.billingAddress,
      billingAddressName: enuqiryDetails?.enquiryAddress?.billingAddressName,
      billingCity: enuqiryDetails?.enquiryAddress?.billingCity,
      billingCountry: enuqiryDetails?.enquiryAddress?.billingCountry,
      billingLandmark: enuqiryDetails?.enquiryAddress?.billingLandmark,
      billingPostCode: enuqiryDetails?.enquiryAddress?.billingPostCode,
      billingState: enuqiryDetails?.enquiryAddress?.billingState,
    });

    setAddCollectionAddress_({
      collectionAddress: enuqiryDetails?.enquiryAddress?.collectionAddress,
      collectionAddressName:
        enuqiryDetails?.enquiryAddress?.collectionAddressName,
      collectionCity: enuqiryDetails?.enquiryAddress?.collectionCity,
      collectionCountry: enuqiryDetails?.enquiryAddress?.collectionCountry,
      collectionLandmark: enuqiryDetails?.enquiryAddress?.collectionLandmark,
      collectionPostCode: enuqiryDetails?.enquiryAddress?.collectionPostCode,
      collectionState: enuqiryDetails?.enquiryAddress?.collectionState,
    });
  }, [
    enuqiryDetails?.enquiryAddress?.billingAddress,
    enuqiryDetails?.enquiryAddress?.billingAddressName,
    enuqiryDetails?.enquiryAddress?.billingCity,
    enuqiryDetails?.enquiryAddress?.billingCountry,
    enuqiryDetails?.enquiryAddress?.billingLandmark,
    enuqiryDetails?.enquiryAddress?.billingPostCode,
    enuqiryDetails?.enquiryAddress?.billingState,
    enuqiryDetails?.enquiryAddress?.collectionAddress,
    enuqiryDetails?.enquiryAddress?.collectionAddressName,
    enuqiryDetails?.enquiryAddress?.collectionCity,
    enuqiryDetails?.enquiryAddress?.collectionCountry,
    enuqiryDetails?.enquiryAddress?.collectionLandmark,
    enuqiryDetails?.enquiryAddress?.collectionPostCode,
    enuqiryDetails?.enquiryAddress?.collectionState,
    enuqiryDetails?.enquiryAddress?.deliveryAddress,
    enuqiryDetails?.enquiryAddress?.deliveryAddressName,
    enuqiryDetails?.enquiryAddress?.deliveryCity,
    enuqiryDetails?.enquiryAddress?.deliveryCountry,
    enuqiryDetails?.enquiryAddress?.deliveryLandmark,
    enuqiryDetails?.enquiryAddress?.deliveryPostCode,
    enuqiryDetails?.enquiryAddress?.deliveryState,
    setAddBillingAddress_,
    setAddCollectionAddress_,
    setAddDeliveryAddress_,
  ]);

  let billingAddressParts = [];
  if (addBillingAddress_?.billingAddress) {
    billingAddressParts.push(addBillingAddress_?.billingAddress);
  }
  if (addBillingAddress_?.billingCity) {
    billingAddressParts.push(addBillingAddress_?.billingCity);
  }
  if (addBillingAddress_?.billingState) {
    billingAddressParts.push(addBillingAddress_?.billingState);
  }
  if (addBillingAddress_?.billingCountry) {
    billingAddressParts.push(addBillingAddress_?.billingCountry);
  }
  if (addBillingAddress_?.billingPostCode) {
    billingAddressParts.push(addBillingAddress_?.billingPostCode);
  }
  if (addBillingAddress_?.billingLandmark) {
    billingAddressParts.push(addBillingAddress_?.billingLandmark);
  }
  const fullBillingAddress = billingAddressParts.join(", ");

  let deliveryAddressParts = [];
  if (addDeliveryAddress_?.deliveryAddress) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryAddress);
  }
  if (addDeliveryAddress_?.deliveryCity) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryCity);
  }
  if (addDeliveryAddress_?.deliveryState) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryState);
  }
  if (addDeliveryAddress_?.deliveryCountry) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryCountry);
  }
  if (addDeliveryAddress_?.deliveryPostCode) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryPostCode);
  }
  if (addDeliveryAddress_?.deliveryLandmark) {
    deliveryAddressParts.push(addDeliveryAddress_?.deliveryLandmark);
  }
  const fullDeliveryAddress = deliveryAddressParts.join(", ");

  let collectionAddressParts = [];
  if (addCollectionAddress_?.collectionAddress) {
    collectionAddressParts.push(addCollectionAddress_?.collectionAddress);
  }
  if (addCollectionAddress_?.collectionCity) {
    collectionAddressParts.push(addCollectionAddress_?.collectionCity);
  }
  if (addCollectionAddress_?.collectionState) {
    collectionAddressParts.push(addCollectionAddress_?.collectionState);
  }
  if (addCollectionAddress_?.collectionCountry) {
    collectionAddressParts.push(addCollectionAddress_?.collectionCountry);
  }
  if (addCollectionAddress_?.collectionPostCode) {
    collectionAddressParts.push(addCollectionAddress_?.collectionPostCode);
  }
  if (addCollectionAddress_?.collectionLandmark) {
    collectionAddressParts.push(addCollectionAddress_?.collectionLandmark);
  }
  const fullCollectionAddress = collectionAddressParts.join(", ");

  const showBillAddressDrawer = () => {
    setShowBillAddress(true);
  };
  const showDeliveryAddDrawer = () => {
    setShowDeliveryAddress(true);
  };

  const showCollectionDrawer = () => {
    setShowCollection(true);
  };

  const updateQuotationCustomerOrder = () => {
    const productsArrayPayloadQuotation = productList?.map((product) => ({
      productId: product.productId,
      quantity: product.quantity,
      unitPrice: product.unitPrice,
      totalPrice: product.totalPrice,
      leadTime: product.leadTime,
      deliveryLocation: "",
    }));
    const productsArrayPayloadContract = productList?.map((product) => ({
      productId: product.product.id,
      quantity: Number(product.quantity),
      unitPrice: Number(product.price),
      totalPrice: Number(product.price),
      leadTime: 0,
      deliveryLocation: product.deliveryLocation,
    }));
    const payloadCertificateQuotion = quotationCertificatesData?.map((c) => ({
      certificateId: c?.certificateId,
      brandId: c?.brandId,
      productId: c?.productId,
      quantity: c?.quantity,
      unitPrice: c?.unitPrice,
      totalPrice: c?.totalPrice,
      notes:
        "test notes coming static from payload not from API as it is requyired in API but not setting in API",
    }));
    //

    const newArray = productList.map((item) => {
      if (item.product && item.product.brand && item.product.brand.id) {
        // If brand.id is present, update each certificate object
        item.certificates = item?.certificates?.map((certificate) => ({
          ...certificate,
          brand: { id: item.product.brand.id }, // Add brand ID to certificate
        }));
      }
      return item;
    });
    //
    const certificatesArray = newArray?.map((item) => item.certificates);
    const combinedAddress = {
      ...addDeliveryAddress_,
      ...addBillingAddress_,
      ...addCollectionAddress_,
    };

    form.validateFields().then((values) => {
      const payloadQuotation = {
        quotationId: finaliseOrder.quotationId,
        customerPoReference: values.custPoRef,
        customerId: values.customer.value,
        buyerId: values?.buyer.id || buyer?.value,
        poCopy: { ...values.poCopy },
        paymentTerm: values.paymentTerm,
        deliveryTerm: values.deliveryTerm,
        dueDate: values.delDate,
        // deliveryAddress: values.deliveryAddress || "test",
        // billingAddress: values?.billingAddress || "test",
        deliveryLocation: values.collectionDelLoc || "test",
        notes: values.note,
        status: "",
        products: productsArrayPayloadQuotation,
        customerOrderAddress: combinedAddress,
        certificates: payloadCertificateQuotion,
      };
      if (!payloadQuotation.notes) delete payloadQuotation.notes;
      const certificatesPayload = certificatesArray?.flat()?.map((i) => {
        return {
          certificateId: i?.certificate?.id,
          brandId: i?.brand?.id,
          productId: i?.product,
          quantity: 1,
          unitPrice: 0,
          totalPrice: 0,
          notes:
            i?.note ||
            "test notes coming static from payload not from API as it is requyired in API but not setting in API",
        };
      });
      const payloadContract = {
        contractId: values.contractId,
        customerId: values.customer.value || values?.customer?.contractDetails,
        buyerId: values.buyer.id || buyer.value,
        customerPoReference: values.custPoRef,
        poCopy: { ...values.poCopy },
        paymentTerm: values.paymentTerm,
        deliveryTerm: values.deliveryTerm,
        dueDate: values.delDate,
        // deliveryAddress: values.deliveryAddress || "test",
        // billingAddress: values?.billingAddress || "test",
        deliveryLocation: values.collectionDelLoc || "test",
        notes: values.note,
        status: "",
        products: productsArrayPayloadContract,
        customerOrderAddress: combinedAddress,
        certificates: certificatesPayload,
      };
      if (!payloadContract.notes) delete payloadContract.notes;

      const payload =
        finaliseOrder.selectedOption === "quotation"
          ? payloadQuotation
          : payloadContract;
      QuotationsPageService.updateQuotationCustomerOrder(payload)
        .then((data) => {
          dispatch(showAddEditFinaliseOrderDrawer(false));
          message.success("Finalise Order Submitted Succesfully");
        })
        .catch(() => message.error("Something Went Wrong"));
    });
  };
  const buyerData = (selectVal) => {
    OrgCustomersService_.getCustomerContact(selectVal).then((data) => {
      setCustContactList(data);
    });
  };

  function handleChangeContract(value) {
    setContractDetails(value);
    // dispatch(showAddEditFinaliseOrderDrawer(true, "contract", value?.id));

    if (value?.customer?.id) {
      OrgCustomersService_.getOrganisationcustomerdetail(
        value?.customer?.id
      ).then((res) => {
        OrgCustomersService_.getCustomerContact(value?.customer?.id).then(
          (data) => {
            setCustContactList(data);
          }
        );
        setBuyer({
          value: value?.buyer?.id,
          label: value.buyer.organisationContact.firstName,
        });
        form.setFieldsValue({
          contractId: value.id,
          paymentTerm: value?.payTerm,
          deliveryTerm: value?.deliveryTerm,

          deliveryAddress: value?.deliveryAddress,
          shipmentCity: value?.shipmentCity,
          buyer: value.buyer.organisationContact.firstName,
          customer: {
            label: res?.customer?.organisation?.name,
            value: res?.customer?.id,
          },
        });
      });
    }
    CustomerContractService_.getContractProduct(value.value).then((data) => {
      setContractProducts(data);
    });
  }
  function handleChangeQuotation(value) {
    dispatch(showAddEditFinaliseOrderDrawer(true, "quotation", value?.id));
    setQuotationDetails(value);
    form.setFieldsValue({
      paymentTerm: value?.paymentTerm,
      deliveryTerm: value?.deliveryTerm,
      deliveryAddress: value?.deliveryAddress,
      shipmentCity: value?.shipmentCity,
      note: value?.notes || "",
      // buyer: value.buyer.organisationContact.firstName,
      // customer: {
      //   label: res?.customer?.organisation?.name,
      //   value: res?.customer?.id,
      // },
    });
  }

  const paymentTermData = () => {
    CommonResourceService_.getPaymentTerms().then((data) => {
      setPaymentTermList(data);
    });
  };
  const deliveryTermData = () => {
    CommonResourceService_.getDeliveryTerms().then((data) => {
      setDeliveryList(data);
    });
  };
  const handleDeliveryTermChange = (value) => {
    setSelectedDeliveryTerm(value);
  };
  const rules = {
    custPoRef: [
      {
        required: true,
        pattern: /^[a-zA-Z0-9-/_\.\s]*$/,
        message: "Please input Valid Cust. PO ref",
      },
    ],
    deliveryTerm: [
      {
        required: true,
        message: "Please select Delivery Term",
      },
    ],
    paymentTerm: [
      {
        required: true,
        message: "Please select Payment Term",
      },
    ],
    collectionDelLoc: [
      {
        required: true,
        message: "Please select Collection / Del Loc",
      },
    ],
    deliveryAddress: [
      {
        required: true,
        message: "Please input Delivery Address",
      },
    ],
    source: [
      {
        required: true,
        message: "Please select a source",
      },
    ],
    ref: [
      {
        required: true,
        pattern: /^[a-zA-Z0-9_\-\/\.]+$/,
        message: "Please input valid customer ref",
      },
    ],
    customer: [
      {
        required: true,
        message: "Please select a customer",
      },
    ],
    buyers: [
      {
        required: true,
        message: "Please select a buyer",
      },
    ],
    poCopy: [
      {
        required: true,
        message: "Please select a doc",
      },
    ],
  };
  const sourceOptionCommon = [
    { label: "Quotation", value: "Quotation" },
    { label: "Contract", value: "Contract" },
  ];

  const sourceOptionQuotation = [{ label: "Quotation", value: "Quotation" }];
  const sourceOptionContract = [{ label: "Contract", value: "Contract" }];

  const handleSourceChange = (value) => {
    setContractDetails({});
    setQuotationDetails({});
    setBuyer({});
    if (value === "Quotation") {
      // dispatch(showAddEditFinaliseOrderDrawer(true, "quotation"));
      setShowQuotationInput(true);
      setShowContractInput(false);
      setShowPreviousOrderInput(false);
    } else if (value === "Contract") {
      // dispatch(showAddEditFinaliseOrderDrawer(true, "contract"));

      setShowContractInput(true);
      setShowPreviousOrderInput(false);
      setShowQuotationInput(false);
    } else if (value === "PreviousOrder") {
      setShowContractInput(false);
      setShowPreviousOrderInput(true);
      setShowQuotationInput(false);
    } else {
      setShowContractInput(false);
      setShowPreviousOrderInput(false);
      setShowQuotationInput(false);
    }
  };

  const tableColumns = [
    {
      title: "Customer Ref",
      dataIndex: "sku",
      render: (customerRef, row) => (
        <Text
          style={{ width: 100 }}
          ellipsis={{ tooltip: row?.customerRef || row?.customerReference }}
        >
          {row?.customerRef || row?.customerReference || "-"}
        </Text>
      ),
    },
    {
      title: "SKU",
      dataIndex: "sku",
      render: (sku, row) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: row?.product?.sku }}>
          {row?.product?.sku}
        </Text>
      ),
    },
    {
      title: "P/N.",
      dataIndex: "part",
      render: (part, row) => (
        <Text
          style={{ width: 100 }}
          ellipsis={{ tooltip: row?.product?.partNumber }}
        >
          {row?.product?.partNumber}
        </Text>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (name, row) => (
        <Text style={{ width: 100 }} ellipsis={{ tooltip: row?.product?.name }}>
          {row?.product?.name}
        </Text>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      render: (brand, row) => (
        <Text
          style={{ width: 100 }}
          ellipsis={{ tooltip: row?.product?.brand?.name }}
        >
          {row?.product?.brand?.name}
        </Text>
      ),
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      render: (quantity, row) => <Text>{row?.quantity}</Text>,
    },
    {
      title: "Del Loc",
      dataIndex: "deliveryLocation",
      render: (deliveryLocation, row) => {
        return <Text>{row?.deliveryLocation}</Text>;
      },
    },
    // {
    //   title: "",
    //   dataIndex: "actions",
    //   render: (_, row) => {
    //     return (
    //       <div className="text-right">
    //         <EllipsisDropdown
    //           menu={
    //             <Menu>
    //               <ActionDropdownMenu
    //                 actions={[
    //                   {
    //                     label: "Remove",
    //                     icon: <EditOutlined />,
    //                     onClick: () =>
    //                       setProductList((prev) =>
    //                         productList.filter((p) => p.id !== row.id)
    //                       ),
    //                   },
    //                 ]}
    //               />
    //             </Menu>
    //           }
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];
  const defaultCustomerQuotation = {
    value: enuqiryDetails?.customer?.id,
    label: enuqiryDetails?.customer?.organisation?.name,
  };
  const defaultCustomerContract = {
    value: contractDetails?.customer?.id,
    label: contractDetails?.customer?.organisation?.name,
  };
  const DescriptionItem = ({ title, content }) => (
    <div className="view-description-item">
      <p className="view-description-item-heading">{title}:</p>
      <p className="view-description-item-value">{content}</p>
    </div>
  );
  return (
    <>
      {loading ? (
        <Loading cover="page-center" />
      ) : (
        <>
          <Form
            layout={"horizontal"}
            form={form}
            name="enquiry-form"
            autoComplete="off"
          >
            <Layout>
              <Content>
                <>
                  <Row gutter={ROW_GUTTER}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="source"
                        label="Source"
                        rules={[
                          { required: true, message: "Please select a source" },
                        ]}
                      >
                        <Select
                          options={
                            finaliseOrder.selectedOption === "quotation"
                              ? sourceOptionQuotation
                              : finaliseOrder.selectedOption === "contract"
                              ? sourceOptionContract
                              : sourceOptionCommon
                          }
                          onChange={handleSourceChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      {showQuotationInput && (
                        <Form.Item name="quotationId" label="ID">
                          <QuotationSelect
                            key={quotationDetails?.id}
                            onChange={handleChangeQuotation}
                            defaultValue={{
                              label: quotationDetails?.quotationCode,
                              value: quotationDetails?.id,
                            }}
                            clearOnSelect
                            limit={10}
                            inputlength={3}
                          />
                        </Form.Item>
                      )}

                      {showContractInput && (
                        <Form.Item name="contractId" label="ID">
                          <ContractSelect
                            onChange={handleChangeContract}
                            defaultValue={{
                              label: finaliseOrder?.customerId?.contractCode,
                              value: finaliseOrder?.customerId?.id,
                            }}
                            limit={10}
                            inputlength={3}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  {(Object.keys(contractDetails)?.length > 0 ||
                    Object.keys(quotationDetails)?.length > 0) && (
                    <>
                      <Row gutter={ROW_GUTTER}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="customer"
                            label=""
                            rules={rules.customer}
                          >
                            {/* <CustomerSelect
                              key={
                                enuqiryDetails?.customer?.id ||
                                contractDetails?.customer?.id
                              }
                              onChange={handleChange}
                              defaultValue={
                                finaliseOrder.selectedOption === "quotation"
                                  ? defaultCustomerQuotation
                                  : finaliseOrder.selectedOption === "contract"
                                  ? defaultCustomerContract
                                  : {}
                              }
                            /> */}
                            <DescriptionItem
                              title="Customer"
                              content={
                                enuqiryDetails?.customer?.organisation?.name ||
                                contractDetails?.customer?.organisation?.name
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="buyer"
                            label="Buyer"
                            rules={rules.buyers}
                          >
                            <Select
                              allowClear
                              placeholder="Select a contact"
                              showSearch
                              options={custcontactList.map(
                                ({ id, organisationContact }) => ({
                                  value: id,
                                  label: organisationContact.firstName,
                                })
                              )}
                              onChange={(value) =>
                                setBuyer({
                                  value: value,
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={ROW_GUTTER}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="custPoRef"
                            label="Cust. PO ref"
                            rules={rules.custPoRef}
                          >
                            <Input style={{ width: "70%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <div style={{ display: "flex" }}>
                            <Form.Item name="delDate" label="Del Date">
                              <DatePicker format={DATE_FORMAT_DD_MM_YYYY} />
                            </Form.Item>
                            <span style={{ paddingLeft: "5px" }}>
                              {" "}
                              Lead 5 Weeks
                            </span>
                          </div>
                        </Col>
                      </Row>

                      <Row gutter={ROW_GUTTER}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="paymentTerm"
                            label="Pay Term"
                            rules={rules.paymentTerm}
                          >
                            <Select
                              showSearch
                              options={paymentTermList.map(({ id, name }) => ({
                                value: name,
                                label: name,
                              }))}
                              onChange={(selectedOption) => {}}
                              style={{ width: "70%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Form.Item
                              label={
                                <span>
                                  Bill to.&nbsp;
                                  <Tooltip
                                    trigger={["hover"]}
                                    placement="topLeft"
                                    title="Replace"
                                  >
                                    <EditFilled
                                      onClick={showBillAddressDrawer}
                                      style={{ color: "#3e79f7" }}
                                    />
                                  </Tooltip>
                                </span>
                              }
                            ></Form.Item>
                            {fullBillingAddress}
                          </div>
                        </Col>
                      </Row>

                      <Row gutter={ROW_GUTTER}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="deliveryTerm"
                            label="INCO Term"
                            rules={rules.deliveryTerm}
                          >
                            <Select
                              showSearch
                              options={deliveryTermList.map(({ id, name }) => ({
                                value: name,
                                label: name,
                              }))}
                              onChange={handleDeliveryTermChange}
                              style={{ width: "70%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Form.Item
                              label={
                                <span>
                                  Delivery to.&nbsp;
                                  <Tooltip
                                    trigger={["hover"]}
                                    placement="topLeft"
                                    title="Replace"
                                  >
                                    <EditFilled
                                      onClick={showDeliveryAddDrawer}
                                      style={{ color: "#3e79f7" }}
                                    />
                                  </Tooltip>
                                </span>
                              }
                              name="deliveryto"
                            ></Form.Item>
                            {fullDeliveryAddress}
                          </div>
                        </Col>
                      </Row>

                      <Row gutter={ROW_GUTTER}>
                        <Col xs={24} sm={24} md={12}></Col>
                        <Col xs={24} sm={24} md={12}>
                          {selectedDeliveryTerm === "ExWorks" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Form.Item
                                label={
                                  <span>
                                    Collection Loc.&nbsp;
                                    <Tooltip
                                      trigger={["hover"]}
                                      placement="topLeft"
                                      title="Replace"
                                    >
                                      <EditFilled
                                        onClick={showCollectionDrawer}
                                        style={{ color: "#3e79f7" }}
                                      />
                                    </Tooltip>
                                  </span>
                                }
                              ></Form.Item>
                              {fullCollectionAddress}{" "}
                            </div>
                          ) : null}
                        </Col>
                      </Row>

                      <Row gutter={ROW_GUTTER}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="poCopy"
                            label="Po Copy"
                            rules={rules.poCopy}
                          >
                            <DynamicUpload
                              form={form}
                              getUploadIcon={getUploadIcon}
                              acceptedFileTypes={[
                                "image/jpeg",
                                "image/jpg",
                                "application/pdf",
                              ]}
                              uploadIcon={getUploadIcon(form, "poCopy")}
                              fieldValue={"poCopy"}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <label>Note</label>
                          <Form.Item name="note">
                            <RichEditor />
                          </Form.Item>{" "}
                        </Col>
                      </Row>
                      <Row gutter={ROW_GUTTER}>
                        <Col xs={24} sm={24} md={24}>
                          <PageHeader
                            className="custom-header"
                            ghost={false}
                            title="Products"
                            subTitle=""
                            extra={[
                              <Space>
                                {showContractInput && (
                                  <Button
                                    onClick={() =>
                                      setOpenAddContractProductsDrawer(true)
                                    }
                                    type="primary"
                                    size="small"
                                  >
                                    Add Products
                                  </Button>
                                )}
                              </Space>,
                            ]}
                          />
                          <Divider style={{ margin: 0 }} />
                          <div className="table-responsive">
                            <Table
                              className="custom-table"
                              size="small"
                              loading={isLoading}
                              columns={tableColumns}
                              dataSource={productList}
                              rowKey="id"
                              pagination={false}
                              onChange=""
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              </Content>
              <Footer>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      form.validateFields().then(() => {
                        Modal.confirm({
                          title: "Confirm",
                          icon: <QuestionCircleFilled />,
                          content: "Are you sure to proceed.",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: () => {
                            updateQuotationCustomerOrder();
                          },
                        });
                      });
                    }}
                    htmlType="submit"
                    loading={submitLoading}
                    disabled={submitLoading}
                    size="small"
                  >
                    Process
                  </Button>

                  <Button
                    onClick={() => {
                      form.resetFields();
                      onDrawerClose();
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                </Space>
              </Footer>
            </Layout>
          </Form>
          {openAddContractProductsDrawer && (
            <Drawer
              title={`Add Contract Product drawer`}
              width={1000}
              onClose={() => {
                setOpenAddContractProductsDrawer(false);
                // form.resetFields();
              }}
              visible={openAddContractProductsDrawer}
              className="custom-drawer"
            >
              {openAddContractProductsDrawer && (
                <AddContractProductsDrawer
                  onDrawerClose={() => {
                    setOpenAddContractProductsDrawer(false);
                  }}
                  contractDetails={contractDetails}
                  contractProducts={contractProducts}
                  setOpenAddContractProductsDrawer={
                    setOpenAddContractProductsDrawer
                  }
                  productList={productList}
                  setProductList={setProductList}
                />
              )}
            </Drawer>
          )}
        </>
      )}
      <Drawer
        title="Billing Address"
        width={700}
        onClose={() => {
          setShowBillAddress(false);
        }}
        visible={showBillAddress}
        className="custom-drawer"
      >
        <BillingAddressDrawer
          onDrawerClose={() => {
            setShowBillAddress(false);
          }}
          mode="ADD"
          setBillAddress={setAddBillingAddress_}
        />
      </Drawer>
      <Drawer
        title="Delivery Address"
        width={700}
        onClose={() => {
          setShowDeliveryAddress(false);
        }}
        visible={showDeliveryAddress}
        className="custom-drawer"
      >
        <AddressDrawer
          onDrawerClose={() => {
            setShowDeliveryAddress(false);
          }}
          mode="ADD"
          // initialData={initialData}
          setDeliveryAddress={setAddDeliveryAddress_}
        />
      </Drawer>
      <Drawer
        title="Collection Address"
        width={700}
        onClose={() => {
          setShowCollection(false);
        }}
        visible={showCollection}
        className="custom-drawer"
      >
        <CommonAddressDrawer
          onDrawerClose={() => {
            setShowCollection(false);
          }}
          mode="ADD"
          setCollectionAddress_={setAddCollectionAddress_}
          // setSelectedAddress={setSelectedAddress_}
          presetval={addCollectionAddress_}
          addressTypeName="collectionAddress"
          isTeanant="true"
          teanantAddressfilter="Delivery/Warehouse"
        />
      </Drawer>
    </>
  );
};

export default EditFinaliseAndOrderForm;

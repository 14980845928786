import React from "react";
import { EnquiryService_ } from "services/Tenant/enquiry.service";
import AsyncSelectWrapper from "../AsyncSelectWrapper";

const EnquirySelect = ({ onChange, defaultValue, limit, inputlength }) => {
  const fetchEnquiry = async (input) => {
    if (!input || input.length < inputlength) return [];

    return EnquiryService_.getEnquiry({
      search: input,
      limit: limit,
      where: {
        "al.is_active": true,
      },
    }).then((data) => {
      const enquiryOptions = data.map((o) => ({
        label: o.enquiryCode,
        value: o.id,
      }));
      return enquiryOptions;
    });
  };

  return (
    <AsyncSelectWrapper
      onChange={onChange}
      defaultValue={defaultValue}
      fetchOptions={fetchEnquiry}
      placeholderValue={`Type at least ${inputlength} characters`}
      inputlength={inputlength}
    />
  );
};

export default EnquirySelect;

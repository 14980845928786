import React from "react";
import { Badge, Drawer, Space, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { showAddEditViewEnquiryProductDrawer } from "redux/actions/ViewEnquiryProduct";
import ViewProductForm from "views/app-views/enquiry/enquiry-form/view-product";
const ViewEnquiryProductDrawers = () => {
  const disptach = useDispatch();
  const viewEnquiryProduct = useSelector((state) => state.viewEnquiryProduct);

  return (
    <div>
      <Drawer
        title="View Enquiry Product"
        width={900}
        onClose={() => {
          disptach(showAddEditViewEnquiryProductDrawer(null));
        }}
        visible={viewEnquiryProduct.showAddEditViewEnquiryProductDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Row>
              <Col span={24} className="text-right">
                {
                  <Badge
                    count={"H"}
                    style={{
                      backgroundColor: "#fff",
                      color: "#999",
                      boxShadow: "0 0 0 1px #d9d9d9 inset",
                    }}
                  />
                }
                {
                  <Badge
                    count={"R"}
                    style={{
                      backgroundColor: "#fff",
                      color: "#999",
                      boxShadow: "0 0 0 1px #d9d9d9 inset",
                    }}
                  />
                }
                {
                  <Badge
                    count={"V"}
                    style={{
                      backgroundColor: "#fff",
                      color: "#999",
                      boxShadow: "0 0 0 1px #d9d9d9 inset",
                    }}
                  />
                }
                {
                  <Badge
                    count={"Re"}
                    style={{
                      backgroundColor: "#fff",
                      color: "#999",
                      boxShadow: "0 0 0 1px #d9d9d9 inset",
                    }}
                  />
                }

                {
                  <Badge
                    count={"O"}
                    style={{
                      backgroundColor: "#fff",
                      color: "#999",
                      boxShadow: "0 0 0 1px #d9d9d9 inset",
                    }}
                  />
                }
              </Col>
            </Row>
          </Space>
        }
      >
        {viewEnquiryProduct.showAddEditViewEnquiryProductDrawer && (
          <ViewProductForm
            isDrawerView={true}
            onDrawerClose={() => {
              disptach(showAddEditViewEnquiryProductDrawer(null));
              // setViewProduct(null);
            }}
            mode="ADD"
            enquiryId={viewEnquiryProduct?.enquiryId}
            enquiryProductId={viewEnquiryProduct?.enquiryProductId}
          />
        )}
      </Drawer>
    </div>
  );
};

export default ViewEnquiryProductDrawers;

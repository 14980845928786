import {
    REFRESH_RFQ_PRODUCT_LIST,
    SHOW_VIEW_RFQ_PRODUCT_DRAWER,
  } from "redux/constants/RfqProduct";
  
  const initialState = {
    showViewRfqProductDrawer: false,
    viewableRfqProduct: null,
    refreshRfqProductList: false,
  };
  export const rfqproduct = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
      case SHOW_VIEW_RFQ_PRODUCT_DRAWER:
        return {
          ...state,
          showViewRfqProductDrawer: data.showViewRfqProductDrawer,
          viewableRfqProduct: data.viewableRfqProduct,
        };
      case REFRESH_RFQ_PRODUCT_LIST:
        return {
          ...state,
          refreshRfqProductList: data.refreshRfqProductList,
        };
      default:
        return state;
    }
  };
  
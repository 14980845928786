import React from "react";
import { OrgCustomersService_ } from "services/Tenant/organisation_customer.service";
import AsyncSelectWrapper from "../AsyncSelectWrapper";

const CustomerSelect = ({ onChange, defaultValue, limit, inputlength }) => {
  const fetchEnquiry = async (input) => {
    if (!input || input.length < inputlength) return [];

    return OrgCustomersService_.getOrganisationcustomers({
      search: input,
      limit: limit,
      //where: {
      // "al.is_active": true,
      //},
    }).then((data) => {
      const customerOptions = data.map((o) => ({
        label: o.organisation.name,
        value: o.id,
      }));
      return customerOptions;
    });
  };

  return (
    <AsyncSelectWrapper
      onChange={onChange}
      defaultValue={defaultValue}
      fetchOptions={fetchEnquiry}
      placeholderValue={`Type at least ${inputlength} characters`}
      inputlength={inputlength}
    />
  );
};

export default CustomerSelect;

import { REFRESH_RFQ_LIST, SHOW_ADD_EDIT_RFQ_DRAWER } from "redux/constants/Rfq"

export const showAddEditRfqDrawer = (visibility = false, editableRfq = null) => {
    return {
        type: SHOW_ADD_EDIT_RFQ_DRAWER,
        data: {
            showAddEditRfqDrawer: visibility,
            editableRfq
        }
    }
}

export const refreshRfqList = (refresh = false) => {
    return {
        type: REFRESH_RFQ_LIST,
        data: {
            refreshRfqList: refresh,
        }
    }
}
import { AUTH_TOKEN_KEY, USER_ACCESS_KEY, USER_INFO_KEY } from 'constants/common.constant';
import { GetLocalStorage } from 'utils/common';
import {
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  SIGNIN,
  SIGNOUT
} from '../constants/Auth';

export const authInitState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: GetLocalStorage(AUTH_TOKEN_KEY),
  userInfo: GetLocalStorage(USER_INFO_KEY, true),
  access: GetLocalStorage(USER_ACCESS_KEY, true) ?? {
	permissions: [],
	role: null
  },
}

const auth = (state = authInitState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				redirect: '/',
				token: action.token,
				userInfo: action.userInfo,
				access: action.userAccess,
			}
		case SIGNOUT:
			return {
				...state,
				redirect: '/',
				token: null,
				userInfo: {},
				access: {
					permissions: [],
					role: null
				}
			}
		case SHOW_AUTH_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: '/',
				loading: false
			}
		}
		case SIGNUP_SUCCESS: {
			return {
			  ...state,
			  loading: false,
			  token: action.token
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: action.data
			}
		}
		default:
			return state;
	}
}

export default auth
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_TOKEN_KEY, USER_INFO_KEY } from '../constants/common.constant';
import { signOut, authenticated } from '../redux/actions/Auth';
import { authInitState } from '../redux/reducers/Auth';
import { AuthService } from '../services/AuthService';
import { SetLocalStorage } from '../utils/common';

export default function useSession() {

	const [user, setUser] = useState(authInitState);
    const dispatch = useDispatch();

    const auth = useSelector(state => {
        return state.auth;
    });
    useEffect(()=> {
        setUser(auth);
    }, [auth])

    const setUser_ = (userInfo, token) => {
        if(!userInfo) {
            AuthService.logOut();
            dispatch(signOut())
        }else{
            if(token) {
                SetLocalStorage(AUTH_TOKEN_KEY, token)
            }
            SetLocalStorage(USER_INFO_KEY, userInfo)
            dispatch(authenticated({userInfo, token: (token ?? auth.token)}));
        }
    }
    return [user, setUser_];
}

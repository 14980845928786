import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, Select, Modal, Badge, Menu } from "antd";
import { DeleteOutlined, FilePdfOutlined } from "@ant-design/icons";

import Loading from "components/shared-components/Loading";
import useSession from "hooks/useSession";
import Text from "antd/lib/typography/Text";
import { EnquiryResourceService_ } from "../../../../../services/Tenant/enquiry_resource.service";
import { useSelector } from "react-redux";
import { ProductResourceService_ } from "services/Tenant/catalogue/product-resource.service";

const { Option } = Select;

const ViewResourceTab = ({ props, enquiryId, productId }) => {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [user] = useSession();

  useEffect(() => {
    if (enquiryId) {
      fetchResourceDetail();
    }
  }, [enquiryId]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const fetchResourceDetail = () => {
    setIsLoading(true);
    ProductResourceService_.getProductResource(productId)
      .then((data) => {
        setInitialData(data);
      })
      .finally(() => setIsLoading(false));
  };

  const tableColumns = [
    {
      title: "",
      dataIndex: "resourceName",
      render: (_, row) => (
        <Text style={{ width: 17 }} ellipsis={{ tooltip: row.resourceType }}>
          <a
            href={row?.resource?.publicUrl}
            ellipsis={{ tooltip: row.resourceType }}
            target="_blank"
          >
            {<FilePdfOutlined />}
          </a>
        </Text>
      ),
    },
    {
      title: "Name",
      dataIndex: "resourceName",
      render: (_, row) => (
        <Text style={{ width: 87 }} ellipsis={{ tooltip: row.resourceName }}>
          {row.resourceName}
        </Text>
      ),
    },
    {
      title: "Type",
      dataIndex: "resourceType",
      render: (_, row) => (
        <Text ellipsis={{ tooltip: row.resourceType }}>
          {<>{row.resourceType}</>}
        </Text>
      ),
    },
    // {
    //   title: "Public",
    //   dataIndex: "isPublic",
    //   render: (_, row) => (
    //     <Text>
    //       {row?.isPublic === true
    //         ? "Yes"
    //         : row?.isPublic === false
    //         ? "No"
    //         : " "}
    //     </Text>
    //   ),
    // },
  ];

  const modalcolumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "PartNo",
      dataIndex: "partno",
      key: "partno",
    },
  ];

  const modaldata = [
    {
      key: "1",
      name: "Test123",
      partno: "test123",
    },
    {
      key: "2",
      name: "Test1234",
      partno: "test1234",
    },
    {
      key: "3",
      name: "Test1235",
      partno: "test1235",
    },
  ];

  return isLoading ? (
    <Loading cover="page-center" />
  ) : (
    <>
      <Table
        className="custom-table"
        showHeader={true}
        size="small"
        loading={isLoading}
        columns={tableColumns}
        dataSource={initialData}
        rowKey="id"
        pagination={false}
        onChange=""
      />

      <Modal
        title="Assign Product"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Table
          columns={modalcolumns}
          dataSource={modaldata}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default ViewResourceTab;

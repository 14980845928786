import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Form,
  Switch,
  Upload,
  Button,
  Avatar,
} from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import { ROW_GUTTER } from "constants/ThemeConstant";
import Utils from "utils/common";
import { UploadOutlined } from "@ant-design/icons";
import BrandSelect from "components/shared-components/BrandSelect";
import CategorySelect from "components/shared-components/CategorySelect";
import UomSelect from "components/shared-components/UomSelect";
import ProductSelect from "components/shared-components/ProductSelect";
import RichEditor from "../../../../../components/shared-components/RichEditor";
import { CountryService_ } from "services/common/country.service";
import countryList from "../../../../../assets/data/country.data.json";
import DynamicUpload from "components/shared-components/DynamicUpload";
import { getUploadIcon } from "utils/utilities";
const { Option } = Select;
const ProductDetail = ({
  setSelectedImageFile,
  form,
  pageType,
  mode,
  productDetail,
}) => {
  const [CountryList, setCountryList] = useState(countryList);
  const [country, setCountry] = useState(null);

  const rules = {
    name: [
      {
        required: true,
        message: "Please input product name",
      },
    ],
    partno: [
      {
        required: true,
        message: "Please input product partno",
      },
    ],
    brand: [
      {
        required: true,
        message: "Please input product brand",
      },
    ],
    uom: [
      {
        required: true,
        message: "Please input product uom",
      },
    ],
    category: [
      {
        required: true,
        message: "Please input product category",
      },
    ],
    hscode: [
      {
        required: false,
        pattern: /^[A-Za-z0-9]+$/,
        message: "Please enter valid HS Code",
      },
    ],
    doc: [
      {
        required: true,
        message: "Please select a doc",
      },
    ],
  };
  const [moqinput_, setMoqinput_] = useState(false);
  const [replacementinput_, setReplacementinput_] = useState(false);

  const toggleMoq = () => {
    setMoqinput_(!moqinput_);
  };
  const toggleReplacement = () => {
    setReplacementinput_(!replacementinput_);
  };

  function handleChange(value) {
    setCountry(value);
  }

  useEffect(() => {
    if (productDetail?.moqQuantity > 0) {
      setMoqinput_(true);
    }
  }, [productDetail?.moqQuantity]);

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={24}>
          <Form.Item name="partNumber" label="Part No." rules={rules.partno}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={24}>
          <Form.Item name="name" label="Name" rules={rules.name}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item name="brand" label="Brand" rules={rules.brand}>
            <BrandSelect
              defaultValue={{
                label: productDetail?.brand?.name,
              }}
              clearOnSelect
              limit={10}
              inputlength={3}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Form.Item name="uom" label="UOM" rules={rules.uom}>
            <UomSelect
              defaultValue={{
                label: productDetail?.uom?.name,
              }}
              prefetchData={true}
              clearOnSelect
              limit={10}
              inputlength={3}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item name="category" label="Category" rules={rules.category}>
            <CategorySelect
              defaultValue={{
                label: productDetail?.category?.category,
              }}
              clearOnSelect
              limit={10}
              inputlength={3}
            />
          </Form.Item>
        </Col>
        {mode == "EDIT" ? (
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="hazard"
              label="Hazard"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
          </Col>
        ) : (
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="hazard"
              label="Hazard"
              valuePropName=""
              initialValue={false}
            >
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                className="my-switch"
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      {pageType !== "ENQUIRY" ? (
        <>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="countryOfOrigin" label="COO" rules="">
                {/*<Select
                  mode="multiple"
                  size={"small"}
                  placeholder="Please select"
                  defaultValue={[]}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  options={CountryList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  filterOption={(input, option) =>
                    option.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />*/}

                <Select
                  showSearch
                  labelInValue // Use labelInValue to expect an object for value
                  optionLabelProp="title"
                  filterOption={(input, option) => {
                    return (
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    );
                  }}
                  dropdownStyle={{ minWidth: "200px" }}
                >
                  {CountryList.map((item) => (
                    <Option
                      key={item.isoCode}
                      value={item.name}
                      title={item.name}
                    >
                      <div>
                        <span>{item.name}</span> - {item.isoCode}
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Form.Item name="hsCode" label="HS Code" rules={rules.hscode}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="MOQ"
                name="moq"
                valuePropName=""
                initialValue={true}
                rules=""
              >
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={toggleMoq}
                  className="my-switch"
                  defaultChecked={productDetail?.moqQuantity > 0 ? true : false}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              {moqinput_ == true ? (
                <Form.Item name="moqQuantity" label="Qty" rules="">
                  <Input />
                </Form.Item>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Replacement"
                valuePropName=""
                initialValue={true}
                rules=""
              >
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={toggleReplacement}
                  className="my-switch"
                  defaultChecked={
                    productDetail?.replacementProduct ? true : false
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              {replacementinput_ == true ? (
                <Form.Item name="replacementProduct" label="p/n" rules="">
                  <ProductSelect />
                </Form.Item>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="noteStatus"
                label="Alert Note"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch
                  checkedChildren="yes"
                  unCheckedChildren="no"
                  className="my-switch"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name="alertNote" label="Note" rules="">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </>
      ) : (
        <> </>
      )}
      <Row gutter={ROW_GUTTER}>
        {/* <Col xs={24} sm={24} md={12}>
          <Form.Item name="image" label="Photo" rules="">
            <DynamicUpload
              form={form}
              maxCount={1}
              acceptedFileTypes={["image/jpeg", "image/jpg", "image/png"]}
              isMultiple={false}
              uploadIcon={getUploadIcon(form, "enquiryDoc")}
              fieldValue={"image"}
              initialImage={productDetail?.image}
            />
          </Form.Item>
        </Col> */}

        {mode !== "ADD" ? (
          <>
            {/* <Col xs={24} sm={24} md={6}>
              <Form.Item name="image" label="Photo">
                <a
                  href={productDetail?.image?.publicUrl}
                  title={productDetail?.image?.fileName}
                  target="_blank"
                >
                  {
                    <Avatar
                      size={64}
                      icon={<FilePdfOutlined style={{ color: "#3e79f7" }} />}
                    />
                  }
                </a>
              </Form.Item>
            </Col> */}

            <Col xs={24} sm={24} md={4}>
              <Form.Item name="image" rules={rules.doc}>
                <DynamicUpload
                  form={form}
                  maxCount={1}
                  initialImage={productDetail?.image?.publicUrl}
                  acceptedFileTypes={["image/jpeg", "image/jpg", "image/png"]}
                  isMultiple={false}
                  uploadIcon={getUploadIcon(form, "enquiryDoc")}
                  fieldValue={"image"}
                />
              </Form.Item>
            </Col>
          </>
        ) : (
          <>
            <Col xs={24} sm={24} md={6}>
              <Form.Item name="image" label="Photo" rules={rules.doc}>
                <DynamicUpload
                  form={form}
                  maxCount={1}
                  acceptedFileTypes={["image/jpeg", "image/jpg", "image/png"]}
                  isMultiple={false}
                  uploadIcon={getUploadIcon(form, "enquiryDoc")}
                  fieldValue={"image"}
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      {pageType !== "ENQUIRY" ? (
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item name="description" label="" rules="">
              <RichEditor
                defaultValue={productDetail?.description}
                onChange={(value) => {
                  form?.setFieldsValue({
                    description: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <> </>
      )}
    </>
  );
};

export default ProductDetail;

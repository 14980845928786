import { Drawer } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAddEditFinaliseOrderDrawer } from "redux/actions/FinaliseOrder";
import EditFinaliseAndOrderForm from "views/app-views/enquiry/quotation/quotation-tabs/EditOpenfinalise-and-order-form";

const FinaliseOrderDrawer = () => {
  const dispatch = useDispatch();
  const finaliseOrder = useSelector((state) => state.finaliseorder);
  return (
    <div>
      <Drawer
        title={`Customer Order`}
        width={1000}
        onClose={() => {
          dispatch(showAddEditFinaliseOrderDrawer(false));
          // form.resetFields();
        }}
        visible={finaliseOrder?.showAddEditFinaliseOrderDrawer}
        className="custom-drawer"
      >
        {finaliseOrder?.showAddEditFinaliseOrderDrawer && (
          <EditFinaliseAndOrderForm
            onDrawerClose={() => {
              dispatch(showAddEditFinaliseOrderDrawer(false));
            }}
          />
        )}
      </Drawer>
    </div>
  );
};

export default FinaliseOrderDrawer;

import { PUBLIC_REQUEST_KEY, API_PATH } from "../../constants/common.constant";
import fetch_ from "../../interceptors/FetchInterceptor";

const {
  ENQUIRY_PRODUCT_LIST,
  ENQUIRY_PRODUCT_ADD,
  ENQUIRY_PRODUCT_COUNT,
  DELETE_ENQUIRY_PRODUCT,
  ENQUIRY_PRODUCT_DETAIL,
  ENQUIRY_PRODUCT_UPDATE,
  ENQUIRIES_PRODUCT_LIST,
  ENQUIRY_PRODUCT_LIST_COUNT,
  ENQUIRY_LIST_LINES,
  ENQUIRY_LIST_LINES_COUNT,
} = API_PATH;

export const EnquiryProductService_ = {
  getEnquiryLines: (filter) => {
    return fetch_({
      url: ENQUIRY_LIST_LINES(),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryLinesCount: (filter) => {
    return fetch_({
      url: ENQUIRY_LIST_LINES_COUNT(),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryProduct: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_PRODUCT_LIST(enquiryId),
      method: "GET",
      params: filter,
    });
  },
  getEnquiryProductCount: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_PRODUCT_LIST_COUNT(enquiryId),
      method: "GET",
      params: filter,
    });
  },

  addEnquiryProduct: (enquiryId, enquiryproduct) => {
    return fetch_({
      url: ENQUIRY_PRODUCT_ADD(enquiryId),
      method: "POST",
      data: enquiryproduct,
    });
  },
  getEnquiriesProductList: (enquiryId, enquiryproduct) => {
    return fetch_({
      url: ENQUIRIES_PRODUCT_LIST(enquiryId, enquiryproduct),
      method: "GET",
    });
  },

  getEnquiryProductdetail: (enquiryId, enquiryProductId) => {
    return fetch_({
      url: ENQUIRY_PRODUCT_DETAIL(enquiryId, enquiryProductId),
      method: "GET",
    });
  },

  countEnquiryProduct: (enquiryId, filter) => {
    return fetch_({
      url: ENQUIRY_PRODUCT_COUNT(enquiryId),
      method: "GET",
      params: filter,
    });
  },

  updateEnquiryProduct: (enquiryId, enquiryProductId, enquiryproduct) => {
    return fetch_({
      url: ENQUIRY_PRODUCT_UPDATE(enquiryId, enquiryProductId),
      method: "PUT",
      data: enquiryproduct,
    });
  },

  deleteEnquiryProduct: (enquiryId, enquiryProductId) => {
    return fetch_({
      url: DELETE_ENQUIRY_PRODUCT(enquiryId, enquiryProductId),
      method: "DELETE",
    });
  },
};

import { TENANT_PREFIX_PATH } from "configs/AppConfig";

export const API_BASE_URL = "https://your-api-url.com/";

// Config
export const ENTRY_ROUTE = "/auth/login";
export const TOKEN_PAYLOAD_KEY = "authorization";
export const PUBLIC_REQUEST_KEY = "public-request";

export const AUTH_TOKEN_KEY = "token";
export const USER_INFO_KEY = "userInfo";
export const USER_ACCESS_KEY = "user_access";

export const TENANT_KEY = "tenant";
export const X_TENANT_ID = "x-tenant-id";

export const ROLE_TYPE = {
  SUPER_ADMIN: "SUPER_ADMIN",
  SUPER_ADMIN_ASSOCIATES: "SUPER_ADMIN_ASSOCIATES",
  TENANT_ADMIN: "ADMIN",
  TENANT_ASSOCIATE: "ASSOCIATES",
};
export const ROLES = [
  { label: "Super Admin", val: "SUPER_ADMIN" },
  { label: "Super Admin Associate", val: "SUPER_ADMIN_ASSOCIATES" },
  { label: "Tenant Admin", val: "ADMIN" },
  { label: "Tenant Associate", val: "ASSOCIATES" },
];

export const SUPER_ADMIN_ROLES = [
  { label: "Super Admin", value: "SUPER_ADMIN" },
  { label: "Super Admin Associate", value: "SUPER_ADMIN_ASSOCIATES" },
];

export const TENANTS_ROLES = [
  { label: "Tenant Admin", value: "ADMIN" },
  { label: "Tenant Associate", value: "ASSOCIATES" },
];

export const DEFAULT_PAGE_SIZE = 100;

export const ADD = "ADD";
export const EDIT = "EDIT";

export const UPLOAD_DRAG_PROPS = {
  name: "file",
  multiple: true,
  listType: "picture-card",
  showUploadList: false,
  action: null,
};

export const API_PATH = {
  FETCH_TENANT_ID: (domain) => `/tenants/tenantId/${domain}`,
  COMMON_FILE_UPLOAD: `/common/upload`,
  PAYMENT_LIST: "/common/payment-terms",
  DELIVERY_LIST: "/common/delivery-terms",

  SUPER_ADMIN_LOGIN: "/super-user-auth/login",
  SUPER_ADMIN_LOGOUT: "/super-user-auth/logout",
  SUPER_ADMIN_PASSWORD_LINK: "/super-user-auth/reset-password-link",
  SUPER_ADMIN_PASSWORD: "/super-user-auth/reset-password",
  TENANT_LOGIN: TENANT_PREFIX_PATH + "/tenant-auth/login",
  TENANT_LOGOUT: TENANT_PREFIX_PATH + "/tenant-auth/logout",
  TENANT_RESET_PASSWORD_LINK:
    TENANT_PREFIX_PATH + "/tenant-auth/reset-password-link",
  TENANT_RESET_PASSWORD: TENANT_PREFIX_PATH + "/tenant-auth/reset-password",

  TENANT_SIGNUP: "/tenants",
  TENANT_VERIFY_ACCOUNT: "/tenants/verify-account",
  TENANT_COMPLETE_PROFILE: (token) =>
    `/tenants/complete-profile?token=${token}`,

  //superadmin
  TENANT_LIST: "/tenants",
  TENANT_COUNT: "/tenants/count",
  TENANT_REGISTER: "/tenants/add-account",
  TENANT_UPDATE: (tenantId) => `/tenants/${tenantId}`,
  TENANT_DETAIL: (tenantId) => `/tenants/${tenantId}`,
  TENANT_DELETE: (tenantId) => `/tenants/${tenantId}`,
  TENANT_PROCESS: (tenantId) => `/tenants/process/${tenantId}`,

  //super-users
  USERS_LIST: "/super-users",
  USERS_COUNT: "/super-users/count",
  USERS_REGISTER: "/super-users",
  USERS_UPDATE: (userId) => `/super-users/${userId}`,
  USERS_DETAIL: (userId) => `/super-users/${userId}`,
  USERS_DELETE: (userId) => `/super-users/${userId}`,

  //tenant-users
  TENANT_USERS_LIST: "/tenant/tenant-users",
  TENANT_USERS_COUNT: "/tenant/tenant-users/count",
  TENANT_USERS_REGISTER: "/tenant/tenant-users",
  TENANT_USERS_UPDATE: (userId) => `/tenant/tenant-users/${userId}`,
  TENANT_USERS_DETAIL: (userId) => `/tenant/tenant-users/${userId}`,
  TENANT_USERS_DELETE: (userId) => `/tenant/tenant-users/${userId}`,

  //profile
  PROFILE_LIST: () => "/profile",
  PROFILE_COUNT: () => "/profile/count",
  PROFILE_REGISTER: () => "/profile",
  PROFILE_UPDATE: (profileId) => `/profile/${profileId}`,
  PROFILE_DETAIL: (profileId) => `/profile/${profileId}`,
  PROFILE_DELETE: (profileId) => `/profile/${profileId}`,

  // address type
  ADDRESS_TYPE_LIST: "/address-types",
  ADD_ADDRESS_TYPE: "/address-types",
  ADDRESS_TYPE_COUNT: "/address-types/count",
  ADDRESS_TYPE_UPDATE: (addressTypeId) => `/address-types/${addressTypeId}`,
  ADDRESS_TYPE_DELETE: (addressTypeId) => `/address-types/${addressTypeId}`,
  ADDRESS_TYPE_BY_ID: (addressTypeId) => `/address-types/${addressTypeId}`,

  // country
  COUNTRY_LIST: "/countries",
  COUNTRY_ADD: "/countries",
  COUNTRY_COUNT: "/countries/count",
  COUNTRY_UPDATE: (countryId) => `/countries/${countryId}`,
  COUNTRY_DELETE: (countryId) => `/countries/${countryId}`,
  COUNTRY_BY_ID: (countryId) => `/countries/${countryId}`,

  // currency
  CURRENCY_LIST: "/currencies",
  CURRENCY_ADD: "/currencies",
  CURRENCY_COUNT: "/currencies/count",
  CURRENCY_UPDATE: (currencyId) => `/currencies/${currencyId}`,
  CURRENCY_DELETE: (currencyId) => `/currencies/${currencyId}`,
  CURRENCY_BY_ID: (currencyId) => `/currencies/${currencyId}`,
  CURRENCY_MAPPER: () => `/currencies-mapper`,
  CURRENCY_SYNC: () => `/currencies-mapper/bulk-update`,

  // business type
  BUSINESS_TYPE_LIST: "/business-types",
  ADD_BUSINESS_TYPE: "/business-types",
  BUSINESS_TYPE_COUNT: "/business-types/count",
  BUSINESS_TYPE_UPDATE: (businessTypeId) => `/business-types/${businessTypeId}`,
  BUSINESS_TYPE_DELETE: (businessTypeId) => `/business-types/${businessTypeId}`,
  BUSINESS_TYPE_BY_ID: (businessTypeId) => `/business-types/${businessTypeId}`,

  // tax type
  TAX_TYPE_LIST: "/tax-types",
  ADD_TAX_TYPE: "/tax-types",
  TAX_TYPE_COUNT: "/tax-types/count",
  TAX_TYPE_UPDATE: (taxTypeId) => `/tax-types/${taxTypeId}`,
  TAX_TYPE_DELETE: (taxTypeId) => `/tax-types/${taxTypeId}`,
  TAX_TYPE_BY_ID: (taxTypeId) => `/tax-types/${taxTypeId}`,

  // impex type
  IMPEX_TYPE_LIST: "/impex-types",
  ADD_IMPEX_TYPE: "/impex-types",
  IMPEX_TYPE_COUNT: "/impex-types/count",
  IMPEX_TYPE_UPDATE: (impexTypeId) => `/impex-types/${impexTypeId}`,
  IMPEX_TYPE_DELETE: (impexTypeId) => `/impex-types/${impexTypeId}`,
  IMPEX_TYPE_BY_ID: (impexTypeId) => `/impex-types/${impexTypeId}`,

  // organisation list
  ORGANISATION_LIST: "/tenant/organisations",
  ORGANISATION_COUNT: "/tenant/organisations/count",
  ORGANISATION_REGISTER: "/tenant/organisations",
  ORGANISATION_UPDATE: (organisationId) =>
    `/tenant/organisations/${organisationId}`,
  ORGANISATION_DETAIL: (organisationId) =>
    `/tenant/organisations/${organisationId}`,
  ORGANISATION_DELETE: (organisationId) =>
    `/tenant/organisations/${organisationId}`,

  // organisation Address
  ORGANISATION_ADDRESS_LIST: "/tenant/organisations-addresses",
  ORGANISATION_ADDRESS_COUNT: "/tenant/organisations-addresses/count",
  ORGANISATION_ADDRESS_REGISTER: "/tenant/organisations-addresses",
  ORGANISATION_ADDRESS_UPDATE: (organisationAddressId) =>
    `/tenant/organisations-addresses/${organisationAddressId}`,
  ORGANISATION_ADDRESS_DETAIL: (organisationAddressId) =>
    `/tenant/organisations-addresses/${organisationAddressId}`,
  ORGANISATION_ADDRESS_DELETE: (organisationAddressId) =>
    `/tenant/organisations-addresses/${organisationAddressId}`,
  BULK_ORGANISATION_SAMPLE_SHEET: (organisationAddressId) =>
    `/tenant/organisations/bulk/sample-sheet`,
  BULK_ORGANISATION_UPLOAD: (organisationAddressId) =>
    `/tenant/organisations/bulk/upload`,
  //Link Organisation
  LINK_ORGANISATION_SAMPLE_SHEET: (organisationAddressId) =>
    `/tenant/organisations/bulk/brands/sample-sheet`,
  LINK_ORGANISATION_UPLOAD: (organisationAddressId) =>
    `/tenant/organisations/bulk/brands/upload`,
  EDIT_LINK_ORGANISATION: (organisationAddressId) =>
    `/tenant/organisations/organisation/${organisationAddressId}/brands`,
  // organisation Contacts
  ORGANISATION_CONTACTS_LIST: "/tenant/organisation-contacts",
  ORGANISATION_CONTACTS_COUNT: "/tenant/organisation-contacts/count",
  ORGANISATION_CONTACTS_REGISTER: "/tenant/organisation-contacts",
  ORGANISATION_CONTACTS_UPDATE: (organisationContactId) =>
    `/tenant/organisation-contacts/${organisationContactId}`,
  ORGANISATION_CONTACTS_DETAIL: (organisationContactId) =>
    `/tenant/organisation-contacts/${organisationContactId}`,
  ORGANISATION_CONTACTS_DELETE: (organisationContactId) =>
    `/tenant/organisation-contacts/${organisationContactId}`,

  // organisation Banks
  ORGANISATION_BANK_LIST: "/tenant/organisations-banks",
  ORGANISATION_BANK_COUNT: "/tenant/organisations-banks/count",
  ORGANISATION_BANK_REGISTER: "/tenant/organisations-banks",
  ORGANISATION_BANK_UPDATE: (organisationBankId) =>
    `/tenant/organisations-banks/${organisationBankId}`,
  ORGANISATION_BANK_DETAIL: (organisationBankId) =>
    `/tenant/organisations-banks/${organisationBankId}`,
  ORGANISATION_BANK_DELETE: (organisationBankId) =>
    `/tenant/organisations-banks/${organisationBankId}`,

  // organisation Vendor
  ORGANISATION_VENDOR_LIST: "/tenant/vendors",
  ORGANISATION_VENDOR_COUNT: "/tenant/vendors/count",
  ORGANISATION_VENDOR_REGISTER: "/tenant/vendors",
  ORGANISATION_VENDOR_UPDATE: (vendorId) => `/tenant/vendors/${vendorId}`,
  ORGANISATION_VENDOR_DETAIL: (vendorId) => `/tenant/vendors/${vendorId}`,
  ORGANISATION_VENDOR_DELETE: (vendorId) => `/tenant/vendors/${vendorId}`,
  ORGANISATION_VENDOR_CONTACT_ASSIGN: (vendorId, organisationContactId) =>
    `/tenant/vendors/${vendorId}/contacts/${organisationContactId}`,

  // organisation Customer
  ORGANISATION_CUSTOMER_LIST: "/tenant/customers",
  ORGANISATION_CUSTOMER_COUNT: "/tenant/customers/count",
  ORGANISATION_CUSTOMER_REGISTER: "/tenant/customers",
  ORGANISATION_CUSTOMER_UPDATE: (customerId) =>
    `/tenant/customers/${customerId}`,
  ORGANISATION_CUSTOMER_DETAIL: (customerId) =>
    `/tenant/customers/${customerId}`,
  ORGANISATION_CUSTOMER_DELETE: (customerId) =>
    `/tenant/customers/${customerId}`,
  ORGANISATION_CUSTOMER_CONTACT_ASSIGN: (customerId, organisationContactId) =>
    `/tenant/customers/${customerId}/contacts/${organisationContactId}`,
  CUSTOMER_CONTACT_DETAIL: (customerId) =>
    `/tenant/customers/${customerId}/contacts`,
  CUSTOMER_ORGANISATION_ADDRESS_DETAIL: (organisationId) =>
    `/tenant/customers/address/${organisationId}`,

  // payment banks
  CUSTOMER_PAYMENT_BANKS: (customerId) =>
    `/tenant/customers/${customerId}/payment-banks`,
  ADD_CUSTOMER_PAYMENT_BANKS: (customerId) =>
    `/tenant/customers/${customerId}/payment-banks`,
  PAYMENT_BANKS_DELETE: (customerId, paymentBankId) =>
    `/tenant/customers/${customerId}/payment-banks/${paymentBankId}`,

  // brand list
  BRAND_LIST: "/tenant/catalogue/brands",
  BRAND_ARCHIVE_LIST: "/tenant/catalogue/brands/archive",
  BRAND_COUNT: "/tenant/catalogue/brands/count",
  BRAND_ARCHIVE_COUNT: "/tenant/catalogue/brands/archive/count",
  BRAND_ADD: "/tenant/catalogue/brands",
  BRAND_UPDATE: (brandId) => `/tenant/catalogue/brands/${brandId}`,
  BRAND_DETAIL: (brandId) => `/tenant/catalogue/brands/${brandId}`,
  BRAND_DELETE: (brandId) => `/tenant/catalogue/brands/${brandId}`,
  BRAND_RESTORE: (brandId) => `/tenant/catalogue/brands/restore/${brandId}`,
  BULK_BRAND_SAMPLE_SHEET: (brandId) =>
    `/tenant/catalogue/brands/bulk/sample-sheet`,
  BULK_BRAND_UPLOAD: (brandId) => `/tenant/catalogue/brands/bulk/upload`,
  ADD_BULK_BRAND: (brandId) => `/tenant/catalogue/brands/bulk`,
  GET_BRANDS_BASED_ORGANISATION: (organisationId) =>
    `/tenant/organisations/organisation/${organisationId}/brands`,
  SUBMIT_LINK_BRANDS_TO_ORGANISATION: () =>
    `/tenant/organisations/organisation/brands`,

  // specification list
  SPECIFICATION_LIST: "/tenant/catalogue/specifications",
  SPECIFICATION_COUNT: "/tenant/catalogue/specifications/count",
  SPECIFICATION_ADD: "/tenant/catalogue/specifications",
  SPECIFICATION_UPDATE: (specificationId) =>
    `/tenant/catalogue/specifications/${specificationId}`,
  SPECIFICATION_DETAIL: (specificationId) =>
    `/tenant/catalogue/specifications/${specificationId}`,
  SPECIFICATION_DELETE: (specificationId) =>
    `/tenant/catalogue/specifications/${specificationId}`,

  // uom list
  UOM_LIST: "/tenant/catalogue/uom",
  UOM_COUNT: "/tenant/catalogue/uom/count",
  UOM_ADD: "/tenant/catalogue/uom",
  UOM_UPDATE: (uomId) => `/tenant/catalogue/uom/${uomId}`,
  UOM_DETAIL: (uomId) => `/tenant/catalogue/uom/${uomId}`,
  UOM_DELETE: (uomId) => `/tenant/catalogue/uom/${uomId}`,

  // certificate list
  CERTIFICATE_LIST: "/tenant/catalogue/certificates",
  CERTIFICATE_COUNT: "/tenant/catalogue/certificates/count",
  CERTIFICATE_ADD: "/tenant/catalogue/certificates",
  CERTIFICATE_UPDATE: (certificateId) =>
    `/tenant/catalogue/certificates/${certificateId}`,
  CERTIFICATE_DETAIL: (certificateId) =>
    `/tenant/catalogue/certificates/${certificateId}`,
  CERTIFICATE_DELETE: (certificateId) =>
    `/tenant/catalogue/certificates/${certificateId}`,
  CERTIFICATE_GROUP: "/tenant/catalogue/certificates/groups",

  // category list
  CATEGORY_LIST: "/tenant/catalogue/categories",
  CATEGORY_COUNT: "/tenant/catalogue/categories/count",
  CATEGORY_ADD: "/tenant/catalogue/categories",
  CATEGORY_UPDATE: (categoryId) => `/tenant/catalogue/categories/${categoryId}`,
  CATEGORY_DETAIL: (categoryId) => `/tenant/catalogue/categories/${categoryId}`,
  CATEGORY_DELETE: (categoryId) => `/tenant/catalogue/categories/${categoryId}`,

  // product list
  PRODUCT_LIST: "/tenant/catalogue/products",
  PRODUCT_COUNT: "/tenant/catalogue/products/count",
  PRODUCT_ADD: "/tenant/catalogue/products",
  PRODUCT_UPDATE: (productId) => `/tenant/catalogue/products/${productId}`,
  PRODUCT_DETAIL: (productId) => `/tenant/catalogue/products/${productId}`,
  PRODUCT_DELETE: (productId) => `/tenant/catalogue/products/${productId}`,

  // product resources list
  PRODUCT_RESOURCE_LIST: (productId) =>
    `/tenant/catalogue/products/${productId}/resources`,
  PRODUCT_RESOURCE_COUNT: (productId) =>
    `/tenant/catalogue/products/${productId}/resources/count`,
  PRODUCT_RESOURCE_ADD: (productId) =>
    `/tenant/catalogue/products/${productId}/resources`,
  PRODUCT_RESOURCES_ADD: (productId) =>
    `/tenant/catalogue/products/${productId}/resources/bulk`,
  PRODUCT_RESOURCE_UPDATE: (productId, resourceId) =>
    `/tenant/catalogue/products/${productId}/resources/${resourceId}`,
  PRODUCT_RESOURCE_DETAIL: (productId, resourceId) =>
    `/tenant/catalogue/products/${productId}/resources/${resourceId}`,
  PRODUCT_RESOURCE_DELETE: (productId, resourceId) =>
    `/tenant/catalogue/products/${productId}/resources/${resourceId}`,

  // enquiry list
  ENQUIRY_LIST: "/tenant/enquiries",
  ENQUIRY_COUNT: "/tenant/enquiries/count",
  ENQUIRY_ADD: "/tenant/enquiries",
  ENQUIRY_UPDATE: (enquiryId) => `/tenant/enquiries/${enquiryId}`,
  ENQUIRY_STATUS_UPDATE: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/products/bulk`,
  ENQUIRY_DETAIL: (enquiryId) => `/tenant/enquiries/${enquiryId}`,
  ENQUIRY_DELETE: (enquiryId) => `/tenant/enquiries/${enquiryId}`,

  // enquiry product
  ENQUIRY_PRODUCT_ADD: (enquiryId) => `/tenant/enquiries/${enquiryId}/products`,
  ENQUIRY_PRODUCT_LIST: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/products`,
  ENQUIRY_PRODUCT_LIST_COUNT: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/products/count`,

  ENQUIRY_LIST_LINES: () => `/tenant/enquiries/lines`,
  ENQUIRY_LIST_LINES_COUNT: () => `/tenant/enquiries/lines/count`,
  ENQUIRY_PRODUCT_COUNT: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/products/count`,
  DELETE_ENQUIRY_PRODUCT: (enquiryId, enquiryProductId) =>
    `/tenant/enquiries/${enquiryId}/products/${enquiryProductId}`,
  ENQUIRY_PRODUCT_DETAIL: (enquiryId, enquiryProductId) =>
    `/tenant/enquiries/${enquiryId}/products/${enquiryProductId}`,
  ENQUIRY_PRODUCT_UPDATE: (enquiryId, enquiryProductId) =>
    `/tenant/enquiries/${enquiryId}/products/${enquiryProductId}`,
  ENQUIRIES_PRODUCT_LIST: (enquiryId, enquiryProductId) =>
    `/tenant/enquiries/${enquiryId}/products/${enquiryProductId}`,

  // customer product
  CUSTOMER_PRODUCT_ADD: "/tenant/customer-products",
  CUSTOMER_PRODUCT_LIST: "/tenant/customer-products",
  CUSTOMER_PRODUCT_DESCRIPTION: (customerId, productId) =>
    `/tenant/customer-products/customer/${customerId}/product/${productId}`,

  // enquiry product certificate
  ENQUIRY_PRODUCT__CERTIFICATE_ADD: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/certificates`,
  ENQUIRY_PRODUCT__CERTIFICATE_ADD_TYPE_MANUFACTURER: (
    enquiryId,
    certificateId,
    brandId
  ) =>
    `/tenant/enquiries/${enquiryId}/certificates/${certificateId}/brand/${brandId}`,
  ENQUIRY_CERTIFICATE_DELETE_TYPE_MANUFACTURER: (
    enquiryId,
    certificateId,
    brandId
  ) =>
    `/tenant/enquiries/${enquiryId}/certificates/${certificateId}/brand/${brandId}`,
  ENQUIRY_PRODUCT__CERTIFICATE_LIST: (enquiryId, enquiryProductId) =>
    `/tenant/enquiries/${enquiryId}/certificates/product/${enquiryProductId}`,
  ENQUIRY_PRODUCT__ASSIGN_CERTIFICATE_LIST: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/finalized-certificates`,
  ENQUIRY_PRODUCT_CERTIFICATE_DELETE: (
    enquiryId,
    enquiryProductId,
    enquiryProductCertificateId
  ) =>
    `/tenant/enquiries/${enquiryId}/products/${enquiryProductId}/certificates/${enquiryProductCertificateId}`,

  // Enquiry resource
  ENQUIRY_RESOURCE_LIST: (enquiryId) =>
    `/tenant/enquiry/${enquiryId}/resources`,
  ENQUIRY_RESOURCE_ASSIGNED_PRODUCTS: (enquiryId, resourceId) =>
    `/tenant/enquiry/${enquiryId}/resources/${resourceId}/assigned-products`,
  ENQUIRY_RESOURCES_ADD: (enquiryId) =>
    `/tenant/enquiry/${enquiryId}/resources/bulk`,
  ENQUIRY_RESOURCE_DETAIL: (enquiryId, resourceId) =>
    `/tenant/enquiry/${enquiryId}/resources/${resourceId}`,
  ENQUIRY_RESOURCE_UPDATE: (enquiryId, resourceId) =>
    `/tenant/enquiry/${enquiryId}/resources/${resourceId}`,
  ENQUIRY_RESOURCE_DELETE: (enquiryId, resourceId) =>
    `/tenant/enquiry/${enquiryId}/resources/${resourceId}`,

  // rfq
  RFQ_ADD: "/tenant/rfq",
  RFQ_Bulk_ADD: "/tenant/rfq/bulk",
  RFQ_LIST: "/tenant/rfq",
  RFQ_COUNT: "/tenant/rfq/count",
  RFQ_DETAIL: (rfqId) => `/tenant/rfq/${rfqId}`,
  RFQ_UPDATE: (rfqId) => `/tenant/rfq/${rfqId}`,
  RFQ_REVISION_ADD: (rfqId) => `/tenant/rfq/${rfqId}/version`,
  RFQ_FREIGHTS: (rfqId) => `/tenant/rfq/${rfqId}/delivery-expenses`,
  RFQ_FREIGHTS_ADD: (rfqId) => `/tenant/rfq/${rfqId}/delivery-expenses`,
  ADD_BULK_RFQ: (rfqId) => `/tenant/rfq/${rfqId}/delivery-expenses`,

  // rfq product
  RFQ_PRODUCT_LIST: (rfqId) => `/tenant/rfq/${rfqId}/products`,
  RFQ_PRODUCT_UPDATE: (rfqId, rfqProductId) =>
    `/tenant/rfq/${rfqId}/products/${rfqProductId}`,
  RFQ_PRODUCT_ADD: (rfqId) => `/tenant/rfq/${rfqId}/products`,
  RFQ_BULK: (rfqId) => `/tenant/catalogue/brands/bulk/upload`,
  BULK_RFQ_EXCEL_DOWNLOAD: (rfqId) => `/tenant/enquiries/bulk/sample-sheet`,
  BULK_RFQ_UPLOAD: (rfqId) => `/tenant/enquiries/bulk/upload`,
  SENT_RFQS: (rfqId) => `/tenant/rfq/sent-rfqs`,
  SEND_FOLLOWUPS: (rfqId) => `/tenant/rfq/followup`,
  SENT_RFQ: (rfqId) => `/tenant/rfq/sent/${rfqId}`,
  GET_SENT_RFQS: (rfqId) => `/tenant/rfq/sent/${rfqId}`,

  // rfq resource
  RFQ_RESOURCE_LIST: (rfqId) => `/tenant/rfq/${rfqId}/resources`,
  RFQ_RESOURCES_ADD: (rfqId) => `/tenant/rfq/${rfqId}/resources/bulk`,
  RFQ_RESOURCE_ASSIGNED_PRODUCTS: (rfqId, resourceId) =>
    `/tenant/rfq/${rfqId}/resources/${resourceId}/assigned-products`,
  RFQ_RESOURCE_DELETE: (rfqId, resourceId) =>
    `/tenant/rfq/${rfqId}/resources/${resourceId}`,
  RFQ_RESOURCE_DETAIL: (rfqId, resourceId) =>
    `/tenant/rfq/${rfqId}/resources/${resourceId}`,
  RFQ_RESOURCE_UPDATE: (rfqId, resourceId) =>
    `/tenant/rfq/${rfqId}/resources/${resourceId}`,
  RFQ_DETAIL_UPDATE: (rfqId) => `/tenant/rfq/${rfqId}`,

  //rfq enquiry products
  ENQUIRY_RFQ_RESOURCE_PRODUCT_DETAIL: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/finalized-products/rfqs-products`,
  ENQUIRY_RFQ_RESOURCE_ENQUIRY_PRODUCT_DETAIL: (enquiryId, enquiryProductId) =>
    `/tenant/enquiries/${enquiryId}/products/${enquiryProductId}/rfqs`,
  ENQUIRY_RFQ_RESOURCE_FINALISED_PRODUCT_DETAIL: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/finalized-products`,
  ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_PRODUCT_DETAIL: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/finalized-products/review`,
  ENQUIRY_RFQ_RESOURCE_REVIEW_FINALISED_CERTIFICATES: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/finalized-certificates/detailed`,
  ENQUIRY_RFQ_RESOURCE_TOTAL_FINALISED_CERTIFICATES: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/finalized-certificates`,
  ENQUIRY_RFQ_RESOURCE_SUBMIT_FINALISED_PRODUCT_DETAIL: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/finalized-products`,
  // enquiry certificate
  ENQUIRY_CERTIFICATE_ADD: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/certificates`,
  ENQUIRY_CERTIFICATE_LIST: (enquiryId) =>
    `/tenant/enquiries/${enquiryId}/certificates`,
  ENQUIRY_CERTIFICATE_DELETE: (enquiryId, enquiryProductCertificateId) =>
    `/tenant/enquiries/${enquiryId}/certificates/${enquiryProductCertificateId}`,
  ENQUIRY_CERTIFICATE_UPDATE: (enquiryId, enquiryProductCertificateId) =>
    `/tenant/enquiries/${enquiryId}/certificates/${enquiryProductCertificateId}`,

  //Process PO
  PROCESS_PO_PRODCUTS_LIST: (customerOrderId, productIds) =>
    `/tenant/customer-orders/${customerOrderId}/finalized/?${productIds}`,
  FINALIZE_PROCESS_PO_PRODCUTS_LIST: (customerOrderId, productIds) =>
    `/tenant/customer-orders/${customerOrderId}/selected-rfq-products`,
  CREATE_PURCHASE_ORDER: () => `/tenant/purchase-order`,
  PURCHASE_ORDER_LIST: () => `/tenant/purchase-order`,
  PURCHASE_ORDER_LIST_COUNT: () => `/tenant/purchase-order/count`,
  PURCHASE_ORDER_DETAIL: (purchaseOrder) =>
    `/tenant/purchase-order/${purchaseOrder}`,
  PURCHASE_ORDER_PRODUCTS_LIST: (purchaseOrder) =>
    `/tenant/purchase-order/${purchaseOrder}/products`,
  PURCHASE_ORDER_CERTIFICATES_LIST: (purchaseOrder) =>
    `/tenant/purchase-order/${purchaseOrder}/certificates`,
  PURCHASE_ORDER_TERMS: (purchaseOrder) =>
    `/tenant/purchase-order/${purchaseOrder}/terms`,
  PURCHASE_ORDER_TERMS_CREATE: (purchaseOrder) =>
    `/tenant/purchase-order/${purchaseOrder}/terms`,
  PURCHASE_ORDER_ADDL_COST: (purchaseOrder) =>
    `/tenant/purchase-order/${purchaseOrder}/delivery-expenses`,

  PURCHASE_ORDER_RESOURCE_LIST: (enquiryId) =>
    `/tenant/purchase-order/${enquiryId}/resources`,
  PURCHASE_ORDER_RESOURCE_ASSIGNED_PRODUCTS: (enquiryId, resourceId) =>
    `/tenant/purchase-order/${enquiryId}/resources/${resourceId}/assigned-products`,
  PURCHASE_ORDER_RESOURCES_ADD: (enquiryId) =>
    `/tenant/purchase-order/${enquiryId}/resources/bulk`,
  PURCHASE_ORDER_RESOURCE_DETAIL: (enquiryId, resourceId) =>
    `/tenant/purchase-order/${enquiryId}/resources/${resourceId}`,
  PURCHASE_ORDER_RESOURCE_UPDATE: (enquiryId, resourceId) =>
    `/tenant/purchase-order/${enquiryId}/resources/${resourceId}`,
  PURCHASE_ORDER_RESOURCE_DELETE: (enquiryId, resourceId) =>
    `/tenant/purchase-order/${enquiryId}/resources/${resourceId}`,
  //Receive PO
  CREATE_PO_RECEIVING: (purchaseOrder) =>
    `/tenant/purchase-order/${purchaseOrder}/receiving`,
  RECEIVE_PO_PRODUCTS: (purchaseOrder) =>
    `/tenant/purchase-order/${purchaseOrder}/receiving/products`,
  RECEIVE_PO_RECEIVING_TABS: (purchaseOrder) =>
    `/tenant/purchase-order/${purchaseOrder}/receiving`,
  RECEIVE_PO_RECEIVING_TABS_DETAILS: (purchaseOrder, receivingBatchId) =>
    `/tenant/purchase-order/${purchaseOrder}/receiving/${receivingBatchId}`,
  RECEIVE_PO_PRODUCTS_PO_QTY: (purchaseOrder, receivingBatchId) =>
    `/tenant/purchase-order/${purchaseOrder}/receiving/products-po-qty`,
  RECEIVE_PO_PRODUCTS_PO_RECEIVE_QTY: (purchaseOrder, receivingBatchId) =>
    `/tenant/purchase-order/${purchaseOrder}/receiving/received-products-qty`,
  //QA QC
  QA_QC_DETAILS: (purchaseOrder) =>
    `/tenant/purchase-order/${purchaseOrder}/qa-qc`,
  GET_QA_QC_TABS_DETAILS: (purchaseOrder, receivingBatchId) =>
    `/tenant/purchase-order/${purchaseOrder}/qa-qc/${receivingBatchId}`,
  QA_QC_REMARK_UPDATE: (purchaseOrder, receivingBatchId) =>
    `/tenant/purchase-order/${purchaseOrder}/qa-qc/${receivingBatchId}/remark`,
  QA_QC_UPLOAD_IMAGES: (purchaseOrder, receivingBatchId) =>
    `/tenant/purchase-order/${purchaseOrder}/qa-qc/${receivingBatchId}/resources/bulk`,
  QA_QC_GET_IMAGES_DOCS: (purchaseOrder, receivingBatchId) =>
    `/tenant/purchase-order/${purchaseOrder}/qa-qc/${receivingBatchId}/resources`,
  DELETE_QA_QC_IMAGES_AND_DOC: (purchaseOrder, receivingBatchId, resourceId) =>
    `/tenant/purchase-order/${purchaseOrder}/qa-qc/${receivingBatchId}/resources/${resourceId}`,
  QA_QC_SUBMIT_QA_QC: (purchaseOrder, receivingBatchId) =>
    `/tenant/purchase-order/${purchaseOrder}/qa-qc/${receivingBatchId}/submit`,
  QA_QC_APPROVE_QA_QC: (purchaseOrder, receivingBatchId) =>
    `/tenant/purchase-order/${purchaseOrder}/qa-qc/${receivingBatchId}/approve`,
  QA_QC_DISCREPANCIES: (purchaseOrder, receivingBatchId) =>
    `/tenant/purchase-order/${purchaseOrder}/qa-qc/discrepancy`,
  GET_DISCREPANCIES: () => `/tenant/qa-qc/discrepancy`,
  GET_DISCREPANCIES_LIST_COUNT: () => `/tenant/qa-qc/discrepancy/count`,
  GET_WAWITING_QA_QC_LIST: () => `/tenant/qa-qc/awaiting`,
  GET_WAWITING_QA_QC_LIST_COUNT: () => `/tenant/qa-qc/awaiting/count`,
  GET_AWAITING_QA_QC_APPROVAL_LIST: () => `/tenant/qa-qc/awaiting`,
  GET_AWAITING_QA_QC_APPROVAL_LIST_COUNT: () => `/tenant/qa-qc/awaiting/count`,
  QA_QC_UPDATE_DETAILS: (
    purchaseOrder,
    receivingBatchId,
    receiveProductId,
    productId
  ) =>
    `/tenant/purchase-order/${purchaseOrder}/qa-qc/${receivingBatchId}/products/${receiveProductId}/${productId}`,
  //Procurements
  PROCURED_LIST: () => `/tenant/procurements`,
  PROCURED_LIST_COUNT: () => `/tenant/procurements/count`,
  CREATE_PROCURED_CONSIGNMENT: () => `/tenant/consignments`,
  CONSIGNMENT_LIST: () => `/tenant/consignments`,
  CONSIGNMENT_LIST_COUNT: () => `/tenant/consignments/count`,
  PACKAGES_LIST: () => `/tenant/consignments/packages`,
  PACKAGES_LIST_COUNT: () => `/tenant/consignments/packages/count`,
  CONSIGNMENT_DETAIL: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}`,
  UPDATE_CONSIGNMENT_DETAIL: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}`,
  CONSIGNMENT_PRODUCTS: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}/products`,
  CONSIGNMENT_CERTIFICATES: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}/certificates`,
  CONSIGNMENT_UPDATE_CERTIFICATES: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}/certificates`,
  UPDATE_CONSIGNMENT_ADDRESS: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}/address`,
  CONSIGNMENT_PRODUCT_PACKAGES: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}/products/packages`,
  CONSIGNMENT_CREATE_PACKAGES: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}/packages`,
  CONSIGNMENT_SEARCH: (consignmentDetail) => `/tenant/consignments/search`,
  PACKAGE_SEARCH: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}/packages/search`,
  ADD_TO_CONSIGNMENT: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}/add-products`,
  REMOVE_FROM_CONSIGNMENT: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}/remove-products`,
  GET_CONSIGNMENT_ORDERS: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}/orders`,
  CONSIGNMENT_PACKAGE_DETAILS: (consignmentDetail, packageId) =>
    `/tenant/consignments/${consignmentDetail}/packages/${packageId}`,
  CONSIGNMENT_UPDATE_PACKAGE: (consignmentDetail, packageId) =>
    `/tenant/consignments/${consignmentDetail}/packages/${packageId}`,

  ADD_TO_PACKAGE: (consignmentDetail, packageId) =>
    `/tenant/consignments/${consignmentDetail}/packages/${packageId}/add-product`,
  CONSIGNMENT_PACKAGE_PHOTO_UPLOAD: (consignmentDetail, packageId) =>
    `/tenant/consignments/${consignmentDetail}/packages/${packageId}/pictures`,
  CONSIGNMENT_PACKAGE_PHOTO_GET: (consignmentDetail, packageId) =>
    `/tenant/consignments/${consignmentDetail}/packages/${packageId}/pictures`,
  CONSIGNMENT_PACKAGE_PHOTO_DELETE: (
    consignmentDetail,
    packageId,
    packagePictureId
  ) =>
    `/tenant/consignments/${consignmentDetail}/packages/${packageId}/pictures/${packagePictureId}`,
  CONSIGNMENT_PACKAGE_REMOVE_PRODUCTS: (consignmentDetail, packageId) =>
    `/tenant/consignments/${consignmentDetail}/packages/${packageId}/remove-products`,
  CONSIGNMENT_PACKAGES: (consignmentDetail) =>
    `/tenant/consignments/${consignmentDetail}/packages`,
  CONSIGNMENT_DELETE_PACKAGE: (consignmentDetail, packageId) =>
    `/tenant/consignments/${consignmentDetail}/packages/${packageId}`,
  // rfq certificate
  RFQ_CERTIFICATE_LIST: (rfqId) => `/tenant/rfq/${rfqId}/certificates`,
  SYNC_RFQ_CERTIFICATES: (rfqId) =>
    `/tenant/rfq/${rfqId}/sync-versioned-certificates`,
  RFQ_CERTIFICATE_UPDATE_BULK: (rfqId) =>
    `/tenant/rfq/${rfqId}/certificates/bulk`,
  RFQ_CERTIFICATE_UPDATE: (rfqId, rfqCertificateId) =>
    `/tenant/rfq/${rfqId}/certificates/${rfqCertificateId}`,
  RFQ_CERTIFICATE_UPDATE_IN_CASE_OF_MANUFACTURE: (
    rfqId,
    rfqCertificateId,
    brandId
  ) => `/tenant/rfq/${rfqId}/certificates/${rfqCertificateId}/brand/${brandId}`,

  // rfq sent
  RFQ_SENT_LIST: (rfqId) => `/tenant/rfq/sent/${rfqId}`,
  RFQ_SENT_ADD: (rfqId) => `/tenant/rfq/sent/${rfqId}`,

  // customer contract
  CUSTOMER_CONTRACT_LIST: "/tenant/contracts",
  CUSTOMER_CONTRACT_COUNT: "/tenant/contracts/count",
  CUSTOMER_CONTRACT_ADD: "/tenant/contracts",
  CUSTOMER_CONTRACT_LIST_COUNT: (contractId) =>
    `/tenant/contracts/${contractId}/products/count`,
  CUSTOMER_CONTRACT_DETAIL: (contractId) => `/tenant/contracts/${contractId}`,
  CUSTOMER_CONTRACT_UPDATE: (contractId) => `/tenant/contracts/${contractId}`,
  CUSTOMER_CONTRACT_DELETE: (contractId) => `/tenant/contracts/${contractId}`,
  DOWNLOAD_SAMPLE_SHEET: "tenant/contracts/sample-bulk-products",
  CUSTOMER_CONTRACT_ADD_BULK_PRODUCTS: (contractId) =>
    `/tenant/contracts/${contractId}/bulk-products`,
  CONTRACT_PRODUCT_DETAIL: (contractId) =>
    `tenant/contracts/${contractId}/products`,
  CONTRACT_CERTIFICATE_DETAIL: (contractId) =>
    `tenant/contracts/${contractId}/certificates`,
  CUSTOMER_CONTRACT_ADD_BULK_AGREEMENT: (contractId) =>
    `/tenant/contracts/${contractId}/agreements/bulk`,
  CONTRACT_AGREEMENT_DETAIL: (contractId) =>
    `tenant/contracts/${contractId}/agreements`,
  CONTRACT_AGREEMENT_DOC_DETAIL: (contractId, agreementId) =>
    `tenant/contracts/${contractId}/agreements/${agreementId}`,
  CUSTOMER_CONTRACT_UPDATE_AGREEMENT: (contractId, agreementId) =>
    `tenant/contracts/${contractId}/agreements/${agreementId}`,
  CUSTOMER_CONTRACT_DELETE_AGREEMENT: (contractId, agreementId) =>
    `tenant/contracts/${contractId}/agreements/${agreementId}`,
  EXPORT_PRODUCTS: (contractId) =>
    `tenant/contracts/${contractId}/products/export`,
  // Customer Orders

  FINALISE_AND_ORDER: () => `tenant/customer-orders`,
  GET_FINALISE_AND_ORDER_CUSTOMER_ORDER: () => `tenant/customer-orders`,
  GET_CUSTOMER_ORDER_BASED_ON_ENQUIRY: (enquiryId) =>
    `tenant/customer-orders/${enquiryId}/list`,
  GET_CUSTOMER_ORDER_BASED_ON_ENQUIRY_COUNT: (enquiryId) =>
    `tenant/customer-orders/${enquiryId}/count`,
  // Delivery Expences
  DELIVERY_EXPENCES_LIST: "/tenant/delivery-expenses",
  DELIVERY_EXPENCES_COUNT: "/tenant/delivery-expenses/count",
  DELIVERY_EXPENCES_ADD: "/tenant/delivery-expenses",
  DELIVERY_EXPENCES_UPDATE: (expenseDetailId) =>
    `/tenant/delivery-expenses/${expenseDetailId}`,
  DELIVERY_EXPENCES_DELETE: (expenseDetailId) =>
    `/tenant/delivery-expenses/${expenseDetailId}`,

  // Terms Conditions
  TERMS_CONDITIONS_DETAILS: (termsType) =>
    `/tenant/settings/terms-conditions/${termsType}`,
  TERMS_CONDITIONS_UPDATE: (termsType) =>
    `/tenant/settings/terms-conditions/${termsType}`,
  ADDITIONAL_TERMS_CONDITIONS_ADD: (termsType) =>
    `/tenant/settings/terms-conditions/${termsType}/additional`,
  ADDITIONAL_TERMS_CONDITIONS_UPDATE: (termsType, additionalTermId) =>
    `/tenant/settings/terms-conditions/${termsType}/additional/${additionalTermId}`,
  ADDITIONAL_TERMS_CONDITIONS_DELETE: (termsType, additionalTermId) =>
    `/tenant/settings/terms-conditions/${termsType}/additional/${additionalTermId}`,
  // Quotations
  QUOTATION_ENQURIY_DETAILS: (enquiryId) =>
    `/tenant/quotations/enquiry/${enquiryId}`,
  QUOTATION_GET_CURRENCY_DETAILS: (quotationId) =>
    `tenant/quotations/${quotationId}/currencies`,
  QUOTATION_UPDATE_CURRENCY_DETAILS: (quotationId) =>
    `tenant/quotations/${quotationId}/currencies`,
  QUOTATION_UPDATE_VARIABLES: (quotationId) =>
    `tenant/quotations/${quotationId}`,
  QUOTATION_UPDATE_VENDOR_MARGIN: (quotationId) =>
    `tenant/quotations/${quotationId}/vendors-margin`,
  QUOTATION_UPDATE_PRODUCTS_MARGIN_DRAWER: (quotationId) =>
    `tenant/quotations/${quotationId}/products`,
  QUOTATION_UPDATE_PRODUCTS_INLINE_MARGIN_DRAWER: (quotationId) =>
    `tenant/quotations/${quotationId}/products-margin`,
  QUOTATION_SYNC: (enquiryId) => `tenant/quotations/enquiry/${enquiryId}/sync`,
  QUOTATION_GET_VENDORS: (quotationId) =>
    `tenant/quotations/${quotationId}/vendors`,
  QUOTATION_GET_PRODUCTS: (quotationId) =>
    `tenant/quotations/${quotationId}/products`,
  QUOTATION_GET_CERTIFICATES: (quotationId) =>
    `tenant/quotations/${quotationId}/certificates`,
  QUOTATION_UPDATE_VENDOR_FREIGHT_DRAWER: (quotationId, vendorId) =>
    `tenant/quotations/${quotationId}/vendor/${vendorId}/freights`,
  QUOTATION_GET_VENDOR_FREIGHT_DRAWER: (quotationId, vendorId) =>
    `tenant/quotations/${quotationId}/vendor/${vendorId}/freights`,
  QUOTATION_UPDATE_TERMS: (quotationId) =>
    `tenant/quotations/${quotationId}/terms`,
  QUOTATION_GET_TERMS: (quotationId) =>
    `tenant/quotations/${quotationId}/terms`,
  QUOTATION_GET_DELIVERY_EXPENSES: (quotationId) =>
    `tenant/quotations/${quotationId}/delivery-expenses`,
  QUOTATION_UPDATE_DELIVERY_EXPENSES: (quotationId) =>
    `tenant/quotations/${quotationId}/delivery-expenses`,
  QUOTATION_UPDATE_PRODUCT_FINAL_PRICE: (quotationId) =>
    `tenant/quotations/${quotationId}/product-final-price`,
  GENERATE_QUOTATION: (quotationId) =>
    `tenant/quotations/${quotationId}/generate`,
  GET_QUOTATIONS: (enquiryId) => `tenant/quotations/enquiry/${enquiryId}/list`,
  GET_QUOTATIONS_COUNT: (enquiryId) => `tenant/quotations/count`,
  GET_QUOTATIONS_COUNT_BASED_ON_ENQUIRY: (enquiryId) =>
    `tenant/quotations/enquiry/${enquiryId}/quotation-count`,
  EDIT_QUOTATION: (quotationId) => `tenant/quotations/${quotationId}`,
  GET_QUOTATION: (quotationId) => `tenant/quotations/${quotationId}`,
  QUOTATION_UPDATE_CERTIFICATE_MARGIN: (quotationId) =>
    `tenant/quotations/${quotationId}/certificate-margin`,
  GET_ALL_QUOTATIONS: () => `tenant/quotations`,

  //Customer-order-view
  CUSTOMER_ORDER_DETAIL: (customerOrderId) =>
    `tenant/customer-orders/${customerOrderId}`,
  CUSTOMER_ORDER_COUNT: () => `tenant/customer-orders/count`,
  CUSTOMER_PURCHASE_REQUIREMENTS: () =>
    `tenant/customer-orders/purchase-requirements`,
  CUSTOMER_PURCHASE_REQUIREMENTS_COUNT: () =>
    `tenant/customer-orders/purchase-requirements/counts`,
  // Order Rfq

  GET_ORDER_RFQ_LIST: () => `tenant/order-rfq`,
  GET_ORDER_RFQ_LIST_COUNT: () => `tenant/order-rfq/count`,
  ORDER_RFQ_FINALISE_PRODUCTS_GET: (orderRfqId) =>
    `tenant/order-rfq/${orderRfqId}/compare`,
  CREATE_ORDER_RFQ: () => `tenant/order-rfq/bulk`,
  GET_ORDER_RFQ: (orderRfqId) => `tenant/order-rfq/${orderRfqId}`,
  EDIT_ORDER_RFQ: (orderRfqId) => `tenant/order-rfq/${orderRfqId}`,
  GET_ALL_LIST_CERTIFICATES: () =>
    `tenant/customer-orders/purchase-requirements/list-certificates`,
  GET_ORDER_RFQ_PRODUCTS: (orderRfqId) =>
    `tenant/order-rfq/${orderRfqId}/products`,
  UPDATE_ORDER_RFQ_PRODUCT: (orderRfqId, orderRfqProductId) =>
    `tenant/order-rfq/${orderRfqId}/products/${orderRfqProductId}`,
  ORDER_RFQ_PRODUCT_UPDATE_BULK: (orderRfqId) =>
    `tenant/order-rfq/${orderRfqId}/products/bulk`,
  ORDER_RFQ_RESOURCE_DETAIL: (rfqId, resourceId) =>
    `/tenant/order-rfq/${rfqId}/resources/${resourceId}`,
  GET_ORDER_RFQ_CERTIFICATES: (orderRfqId) =>
    `tenant/order-rfq/${orderRfqId}/certificates`,
  UPDATE_ORDER_RFQ_CERTIFICATE_NOTE: (orderRfqId, orderRfqCertificateId) =>
    `tenant/order-rfq/${orderRfqId}/certificates/${orderRfqCertificateId}`,
  UPDATE_ORDER_RFQ_CERTIFICATE_BULK: (orderRfqId, orderRfqCertificateId) =>
    `tenant/order-rfq/${orderRfqId}/certificates/bulk`,
  CREATE_ORDER_RFQ_SENT: (orderRfqId) => `tenant/order-rfq/sent/${orderRfqId}`,
  GET_ORDER_RFQ_SENT: (orderRfqId) => `tenant/order-rfq/sent/${orderRfqId}`,
  ORDER_RFQ_RESOURCES_ADD: (orderRfqId) =>
    `tenant/order-rfq/${orderRfqId}/resources/bulk`,
  ORDER_RFQ_RESOURCES_GET: (orderRfqId) =>
    `tenant/order-rfq/${orderRfqId}/resources`,
  ORDER_RFQ_RESOURCE_UPDATE: (orderRfqId, resourceId) =>
    `tenant/order-rfq/${orderRfqId}/resources/${resourceId}`,
  ORDER_RFQ_RESOURCES_DELETE: (orderRfqId, resourceId) =>
    `tenant/order-rfq/${orderRfqId}/resources/${resourceId}`,
  ORDER_RFQ_RESOURCE_ASSIGNED_PRODUCTS: (orderRfqId, resourceId) =>
    `/tenant/order-rfq/${orderRfqId}/resources/${resourceId}/assigned-products`,
  ORDER_RFQ_PRODUCT_LIST: (orderRfqId) =>
    `/tenant/order-rfq/${orderRfqId}/products`,

  //Address
  ADDRESS_ADD: "/tenant/addresses",
  ADDRESS_LIST: "/tenant/addresses",
  ADDRESS_COUNT: "/tenant/addresses/count",
  DEFAULT_TENANT_ADDRESS: "/tenant/addresses/default",
  DEFAULT_TENANT_ORGANISATIONS_ADDRESS: (vendorId) =>
    `/tenant/organisations-addresses/vendor/${vendorId}`,
  ADDRESS_DETAIL: (addressId) => `/tenant/addresses/${addressId}`,
  ADDRESS_UPDATE: (addressId) => `/tenant/addresses/${addressId}`,
  ADDRESS_DELETE: (addressId) => `/tenant/addresses/${addressId}`,
  TENANT_RFQ_ADDRESS: (filter) => `/tenant/rfq/address/?${filter}`,

  //Bank
  BANK_ADD: "/tenant/banks",
  BANK_LIST: "/tenant/banks",
  BANK_COUNT: "/tenant/banks/count",
  BANK_DETAIL: (bankId) => `/tenant/banks/${bankId}`,
  BANK_UPDATE: (bankId) => `/tenant/banks/${bankId}`,
  BANK_DELETE: (bankId) => `/tenant/banks/${bankId}`,
};

import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Input,
  PageHeader,
  Row,
  Space,
  Table,
} from "antd";
import Text from "antd/lib/typography/Text";
import { ROW_GUTTER } from "constants/ThemeConstant";
import React, { useEffect, useMemo, useState } from "react";
import { text } from "utils/utilities";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import AddBrandsDrawer from "./add-brands-drawer";
import { OrgVendorsService_ } from "services/Tenant/organisation_vendor.service";
import _ from "lodash";
import { BrandService_ } from "services/Tenant/brand.service";
import { openNotification } from "components/shared-components/NotificationTile";

const ManuallyLinkBrands = ({}) => {
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingVendors, setLoadingVendors] = useState(false);
  const [showAddBrandsDrawer, setShowAddBrandsDrawer] = useState(false);
  const [editData, setEditData] = useState({});
  const [reset, setReset] = useState(0);
  const [searchedText, setSearchedText] = useState("");
  const [brandOptionsData, setBrandOptionsData] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const fetchInitialVendorOptions = () => {
    setLoadingVendors(true);
    return OrgVendorsService_.getOrganisationvendors({
      where: {
        "al.is_active": true,
      },
      orderBy: "createdAt",
    })
      .then((data) => {
        const vendorOptions = data.map((o) => ({
          label: o.organisation?.name,
          value: o.id,
          organisation: o?.organisation?.id,
          selectAllbrands: o?.organisation?.dealInAllBrands,
        }));
        setSelectedVendors((prevSelectedVendors) => [...vendorOptions]);

        return vendorOptions;
      })
      .finally(() => setLoadingVendors(false));
  };

  useEffect(() => {
    fetchInitialVendorOptions();
  }, []);

  useEffect(() => {
    if (reset) {
      fetchInitialVendorOptions();
    }
  }, [reset]);

  const handleCheckboxChange = (value, checked) => {
    setSelectedVendors((prevSelectedVendors) => {
      const updatedVendors = [...prevSelectedVendors];
      const rowIndex = updatedVendors.findIndex(
        (vendor) => vendor.value === value
      );
      if (rowIndex !== -1) {
        updatedVendors[rowIndex].selectAllbrands = checked;
        updatedVendors[rowIndex].edited = true;
        updatedVendors[rowIndex].recallBrandsApi = false;
      }
      return updatedVendors;
    });
  };

  const filteredVendorOptions = useMemo(
    () =>
      selectedVendors?.filter((option) =>
        option?.label?.toLowerCase().includes(searchedText?.toLowerCase())
      ),
    [searchedText, selectedVendors]
  );
  const tableProductVendorColumns = [
    {
      title: "sn",
      dataIndex: "name",
      width: 20,
      render: (name, row, index) => (
        <Text style={{ width: 50 }} ellipsis={{ tooltip: "" }}>
          {text(index + 1)}
        </Text>
      ),
    },
    {
      title: "vendor",
      dataIndex: "label",
      width: 300,
      render: (label, row, index) => (
        <Text ellipsis={{ tooltip: label }}>{text(label)}</Text>
      ),
    },
    {
      title: "",
      dataIndex: "selectAllbrands",
      width: 200,
      render: (selectAllbrands, row, index) => (
        <Checkbox
          style={{ marginBottom: 7 }}
          checked={selectAllbrands}
          onChange={(e) => handleCheckboxChange(row?.value, e.target.checked)}
        >
          All Brands
        </Checkbox>
      ),
    },
    {
      title: "",
      dataIndex: "brandName",
      width: 100,
      render: (brandName, row, index) => {
        if (row?.selectAllbrands) {
          return (
            <>
              <Button
                onClick={() => {
                  setShowAddBrandsDrawer(true);
                }}
                size="small"
                type="primary"
                disabled={true}
              >
                <EditOutlined />
              </Button>
            </>
          );
        } else {
          return (
            <>
              <Button
                onClick={() => {
                  setShowAddBrandsDrawer(true);
                  setEditData(row);
                }}
                size="small"
                type="primary"
              >
                <EditOutlined />
              </Button>
            </>
          );
        }
      },
    },
  ];
  const finalisedTableProductVendorColumns = [
    {
      title: "sn",
      dataIndex: "name",
      width: 50,
      render: (name, row, index) => (
        <Text style={{ width: 50 }} ellipsis={{ tooltip: "" }}>
          {text(index + 1)}
        </Text>
      ),
    },
    {
      title: "vendor",
      dataIndex: "label",
      width: 300,

      render: (label, row, index) => (
        <Text ellipsis={{ tooltip: label }}>{text(label)}</Text>
      ),
    },
    {
      title: "brands",
      dataIndex: "brandName",
      width: 700,

      render: (brandName, row, index) => {
        if (row?.selectAllbrands) {
          return <Text>{"All Brands"}</Text>;
        }
        if (row?.selectedBrands?.length > 0) {
          return (
            <Text>
              {row?.selectedBrands?.map((item) => item?.label)?.join(", ")}
            </Text>
          );
        }
      },
    },
  ];
  const fetchAllBrands = async (input) => {
    setLoading(true);
    return BrandService_.getBrand({
      search: input,
      //   limit: 1000,
      where: {
        "al.is_active": true,
      },
    })
      .then((data) => {
        const brandOptions = data.map((o) => ({
          label: o.name,
          value: o.id,
        }));
        setBrandOptionsData(brandOptions);

        return brandOptions;
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAllBrands();
  }, []);

  const submitData = () => {
    const finalData = _.uniqBy(selectedVendors, "organisation")?.filter(
      (f) =>
        f?.edited &&
        (f?.selectAllbrands || Object.keys(f).includes("selectedBrands"))
    );

    const payloadData = {
      brands: finalData
        ?.filter((item) => !item?.selectAllbrands)
        ?.map((item2) => ({
          organisationId: item2?.organisation,
          brands:
            item2?.selectedBrands
              ?.map((brand) => ({
                brands: brand?.value,
              }))
              ?.map((b) => b?.brands)
              ?.flat() || [],
        }))
        ?.flat(),

      organisations: finalData
        ?.filter((item) => item?.selectAllbrands)
        ?.map((allBrands) => ({
          organisationId: allBrands?.organisation,
          dealInAllBrands: true,
        })),
    };
    if (!payloadData?.brands?.length) {
      payloadData.brands = [];
    }
    if (!payloadData?.organisations?.length) {
      payloadData.organisations = [];
    }

    if (!payloadData?.brands?.length && !payloadData?.organisations?.length) {
      return openNotification(
        "Error",
        "Please select brand(s) to link them to organisation(s)",
        "error"
      );
    }
    BrandService_.submitLinkBrandsToOrganisation(payloadData)
      .then((data) => {
        openNotification(
          "Sucess",
          "Brand(s) linked to organisation(s) successfully",
          "success"
        );
      })
      .finally((_) => setSubmitLoading(false));
  };
  return (
    <div>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={10}>
          <Card className="ant-card-view">
            <PageHeader
              className="custom-header"
              ghost={false}
              title=""
              subTitle=""
              extra={[
                <Space>
                  <div
                    style={{
                      width: 250,
                      position: "absolute",
                      left: -5,
                      top: 4,
                    }}
                  >
                    <Input
                      placeholder="Search Vendors"
                      prefix={<SearchOutlined size="small" />}
                      onChange={(e) => setSearchedText(e.currentTarget.value)}
                      style={{ padding: 5, width: 300, marginLeft: 12 }}
                    />
                  </div>
                </Space>,
              ]}
            />
            <Divider style={{ margin: 0 }} />
            <div
              className="table-responsive decrease-table-width-with-input-fields"
              style={{ marginBottom: 14, marginTop: 10 }}
            >
              <Table
                className="custom-table"
                size="small"
                loading={loadingVendors}
                columns={tableProductVendorColumns}
                dataSource={_.uniqBy(filteredVendorOptions, "organisation")}
                rowKey="id"
                pagination={false}
                rowClassName={(record) =>
                  record?.edited ? "accordion-row-color" : ""
                }
              />
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={14}>
          <Card className="ant-card-view">
            <PageHeader
              className="custom-header"
              ghost={false}
              title="Vendors"
              subTitle=""
              extra={[
                <Space>
                  <Col xs={24} sm={24} md={1}>
                    <Button
                      onClick={() => {
                        setReset((prev) => prev + 1);
                      }}
                      size="small"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={1}>
                    <Button
                      style={{ marginLeft: -11 }}
                      size="small"
                      type="primary"
                      onClick={() => submitData()}
                      loading={submitLoading}
                    >
                      Save
                    </Button>
                  </Col>
                </Space>,
              ]}
            />

            <div
              className="table-responsive decrease-table-width-with-input-fields"
              style={{ marginBottom: 14, marginTop: 10 }}
            >
              <Table
                className="custom-table"
                size="small"
                // loading={isLoading}
                columns={finalisedTableProductVendorColumns}
                dataSource={_.uniqBy(selectedVendors, "organisation")?.filter(
                  (f) =>
                    f?.edited &&
                    (f?.selectAllbrands ||
                      Object.keys(f).includes("selectedBrands"))
                )}
                rowKey="id"
                pagination={false}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Drawer
        title="Add Brands"
        width={900}
        onClose={() => {
          setShowAddBrandsDrawer(false);
        }}
        visible={showAddBrandsDrawer}
        className="custom-drawer"
      >
        {showAddBrandsDrawer && (
          <AddBrandsDrawer
            onDrawerClose={() => {
              setShowAddBrandsDrawer(false);
              // handleReload();
            }}
            setSelectedVendors={setSelectedVendors}
            editData={editData}
            selectedVendors={selectedVendors}
            brandOptionsData={brandOptionsData}
            setBrandOptionsData={setBrandOptionsData}
          />
        )}
      </Drawer>
    </div>
  );
};

export default ManuallyLinkBrands;
